/*
Documentation

this page holds all the routes for the dashboard layout

*/

import Tickets                  from '../pages/tickets';
import Directory                  from '../pages/directory';
import Account                  from '../pages/account';

import AppointmentsView         from '../pages/appointments/AppointmentsView'
import AppointmentsAll          from '../pages/appointments/AppointmentsAll'
import AppointmentsSearch       from '../pages/appointments/Search'
import Me       from '../pages/me'

import Calendar                 from '../pages/calendar/Calendar';

import ConsultationsSearch          from '../pages/consultations/Search'
import ConsultationsCreate          from '../pages/consultations/Create'

import CallCenterUsers          from '../pages/call_center'
import CallCenterRedirect       from '../pages/call_center/StartCallRedirect'
import CallCenterAdmin          from '../pages/call_center_admin'
import CallCenterVoicemail      from '../pages/call_center/Voicemail'

import CasesAll                 from '../pages/cases/All';
import CasesCreate              from '../pages/cases/Create/index';
import CasesView                from '../pages/cases/View/index';

import Pipeline                from '../pages/pipeline';

import CaseTemplatesAll         from '../pages/case_templates/All';

import ContactsCreate           from '../pages/contacts/Create';
import ContactsAll              from '../pages/contacts/All';
import ContactsView             from '../pages/contacts/View/index';

import DocumentsUsers           from '../pages/documents/User';
import DocumentsAdmin           from '../pages/documents/Admin';
import DocumentsMerge           from '../pages/documents/Merge';

import LeadsSearch           from '../pages/leads/Search';

import Messenger        from '../pages/messenger/View'

import NotificationsView        from '../pages/notifications/View'
import NotificationsSettings    from '../pages/notifications/Settings'

import TasksUser                from '../pages/tasks/User';
import TasksAdmin               from '../pages/tasks/Admin';

import TemplateDocs             from '../pages/template_docs/index'
import TemplateDocsPDF          from '../pages/template_docs/PDF'

import Stats          from '../pages/stats'

import Unauthorized             from '../pages/unauthorized'
import UtilityFullRedirect      from '../pages/utility/FullRedirect'

import WikisAll                from '../pages/wikis/All'
import WikisView                from '../pages/wikis/View'

import * as privileges          from '_functions/users/privileges'

const routes = () => {

    return[

        /***********************
        ACCOUNT
        ************************/
        {
            path: "/me",
            component: Me,
        },
        /***********************
        ACCOUNT
        ************************/
        {
            path: "/directory",
            component: Directory,
        },
        /***********************
        ACCOUNT
        ************************/
        {
            path: "/settings",
            component: Account,
        },
        /***********************
        ACCOUNT
        ************************/
        {
            path: "/pipeline",
            component: Pipeline,
        },

        /***********************
        Appointments
        ************************/
        {
            path: "/appointments/view/:_id",
            component: privileges.canModerateAppointments() ?  AppointmentsView : Unauthorized,
        },
        {
            path: "/appointments/all/v1",
            component: AppointmentsAll,
        },
        {
            path: "/appointments/all",
            component: AppointmentsSearch,
        },

        /***********************
        Call Center
        ************************/
        {
            path: "/consultations/all",
            component: ConsultationsSearch,
        },
        {
            path: "/consultations/create",
            component: ConsultationsCreate,
        },
        /***********************
        Call Center
        ************************/
        {
            path: "/call_center",
            component: CallCenterUsers,
        },
        {
            path: "/call_center_redirect",
            component: CallCenterRedirect,
        },
        {
            path: "/call_center_admin",
            component: privileges.canViewAdminCallCenter() ? CallCenterAdmin : Unauthorized,
        },
        {
            path: "/call_center/voicemail",
            component: CallCenterVoicemail,
        },
        
        /***********************
        CASE TEMPLATES
        ************************/
        {
            path: "/case_templates/all",
            component: CaseTemplatesAll
        },


        /***********************
        CALENDAR
        ************************/
        {
            path: "/calendar/me",
            component: Calendar,
        },

        /***********************
        CONTACTS
        ************************/
        {
            path: "/contacts/all",
            component: ContactsAll,
        },
        {
            path: "/contacts/create",
            component: privileges.canUpdateContacts() ? ContactsCreate : Unauthorized,

        },
        {
            path: "/contacts/view/:_id",
            component:ContactsView,
        },

        /***********************
        DocumentFolders
        ************************/
        {
            path: "/documents",
            // component: privileges.canModerateTags() ?  DocumentFoldersAll : Unauthorized,
            component: DocumentsUsers
        },
        {
            path: "/documents/admin",
            // component: privileges.canModerateTags() ?  DocumentFoldersAll : Unauthorized,
            component: privileges.canModerateMissingDocs() ?  DocumentsAdmin : Unauthorized
        },
        {
            path: "/documents/merge/:case_id",
            component: DocumentsMerge
        },
        {
            path: "/leads/all",
            component: LeadsSearch
        },

        /***********************
        Messanger
        ************************/
        {
            path: "/messenger",
            component: Messenger,
        },

        /***********************
        Notifications
        ************************/
        {
            path: "/notifications",
            component: NotificationsView,
        },
        {
            path: "/notifications/settings",
            component: NotificationsSettings,
        },

        /***********************
        OVERVIEWS
        ************************/
        {
            path: "/cases/all",
            component: privileges.canViewCases() ? CasesAll : Unauthorized,
        },

        {
            path: "/cases/create",
            component: privileges.canUpdateCases() ? CasesCreate : Unauthorized,

        },
        {
            path: "/cases/view/:_id",
            component: privileges.canViewCases() ? CasesView : Unauthorized,
        },

        
        /***********************
        Stats
        ************************/
        {
            path: "/team/stats",
            component: Stats,
        },

        /***********************
        Tasks
        ************************/
        {
            path: "/tasks/user",
            component: TasksUser,
        },

        {
            path: "/tasks/admin",
            component: privileges.canModerateUnfinishedTasks() ? TasksAdmin : Unauthorized,
        },


        /***********************
        TEMPLATE DOCS
        ************************/
        {
            path: "/template_docs/all",
            component: privileges.canModerateTemplateDocs() ? TemplateDocs : Unauthorized,
        },
        {
            path: "/template_docs/:template_doc_id",
            component: TemplateDocsPDF
        },

        /***********************
        UTILITY
        ************************/
        {
            path: "/full_redirect",
            component: UtilityFullRedirect,
        },

        /***********************
        Wikis
        ************************/
        {
            path: "/help/wikis",
            component: WikisAll,
        },
        {
            path: "/help/wikis/:_id",
            component: WikisView,
        },
        {
            path: "/tickets",
            component: Tickets,
        },


    ]

}

export default routes;