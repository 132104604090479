import { architeckCall } from 'database';
import keys from 'keys';

const actions = (template_word_doc_id, data) => {
    return new Promise (async resolve => {

        const generated = await architeckCall({
            shouldNotShowAndLogError: true,
            method: 'post',
            url: `/api/v1/core/template_word_docs/generate/${template_word_doc_id}`,
            data,
        })

        resolve(generated)

        if(generated.success) {

            // if success create a download link and simulate the click of it
            const id = 'arch-generated-doc-' + Math.floor(new Date()) + '-'
            var a = document.createElement('a')

            a.id = id

            a.setAttribute('download', generated.doc_name);  
            a.href = keys.API_URL + generated.data;

            document.body.appendChild(a);
            a.click()

            // remove the link once it's been clicked
            const element = document.getElementById(id);
            element.parentNode.removeChild(element);

        }

    })
}

export default actions;