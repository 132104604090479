import React, { Component } from 'react';
import { Card, CardFooter } from 'reactstrap';
import PropTypes from 'prop-types'
import { connect } from 'react-redux';

import _contacts from '_functions/contacts';
import _contact_texts from '_functions/contact_texts';
import _template_texts from '_functions/template_texts';

import Body from './Body';
import To from './To';

import ModalSelectTemplateText from './ModalSelectTemplateText'
import ModalTemplateTextsErrors from './ModalTemplateTextsErrors'
import ModalSendPicture from './ModalSendPicture'

import { toggleAlertBS } from 'store/functions/system/system';

import * as ANALYTIC_EVENTS from '_settings/analytic_events';
import _analytics from '_functions/analytics';

import _recent_activity from '_functions/recent_activity';

class SendText extends Component {


    state = {
        body        : "",
        contact     : null,
        showModal   : false,
        showModalErrors   : false,
        showModalSendPicture   : false,
    };

    toggleModal = () => this.setState({showModal: !this.state.showModal})
    toggleModalSendPicture = () => this.setState({showModalSendPicture: !this.state.showModalSendPicture})
    toggleModalErrors = (errors) => this.setState({showModalErrors: !this.state.showModalErrors, errors})

    onSelect = async (values) => {

        const contact = this.state.contact
        const contact_id = contact && contact._id ? contact._id : contact ? contact : undefined

        if(!contact_id) return toggleAlertBS(true, `A contact must be set before generating a template text.`)

        let body = ''

        const generatedText = await _template_texts.generate({contact_id: contact_id, template_id: values.value._id, type: 'text'})
        if(generatedText.success) {

            body = generatedText.data
            if(generatedText.errors.length) this.toggleModalErrors(generatedText.errors)

        }

        this.setState({body})

    }

    onSend = async () => {

        this.setState({error: null})

        const { body,  contact } = this.state

        if(!body) return this.setState({error: 'You cannot send a blank text.'})
        if(body.length > 1500) return;

        if(!contact)  return this.setState({error: 'You must add the contact you are sending this text to.'})
        if(!contact.phone)  return this.setState({error: 'The contact you have selected does not have a valid phone number on file.'})

        const sent = await _contact_texts.create({ 
            user     : this.props.viewing_user._id,
            contact  : contact._id,
            to       : contact.phone,
            value    : body,
            isManual : true
        })

        _recent_activity.update({title: 'Sent Text', value: body})


        if(this.props.onSendingStart) this.props.onSendingStart()

        if(sent.success) {

            if(this.props.onSuccess) this.props.onSuccess(sent.data)

            _analytics.events.create({event: ANALYTIC_EVENTS.TEXTS_SENT});
            if(this.props.isReply) _analytics.events.create({event: ANALYTIC_EVENTS.TEXTS_REPLIED});
            
        } else {

            if(this.props.onError) this.props.onError(sent)

        }

    }

    onBodyChange    = (body)    => this.setState({body})
    onContactChange = (contact) => this.setState({contact})

    fetchContactOnMount = async () => {

        const { contact } = this.props

        if(contact) {

            const foundContact = await _contacts.findById(contact)
            if(foundContact.data) this.setState({contact: foundContact.data})

        }

    }

    componentDidMount = () => {

        this.fetchContactOnMount()
        try { document.getElementById('archk-send-text-body-input').focus() } catch(e) {}

    }

    render() {

        const { error, body, contact, showModal, showModalErrors, showModalSendPicture, errors } = this.state;

        return (

            <>

            <Card className="archk-send-text">

                <To
                    onContactChange={this.onContactChange}
                    contact={contact}
                />

                <Body
                    body={body}
                    onBodyChange={this.onBodyChange}
                />

                {error && <div className="alert alert-warning mb-0">{error}</div>}

                {contact && contact.opt_out_text ? (
                    <CardFooter>
                        <div className="alert alert-warning mb-0 text-center"><i className="fas fa-exclamation-triangle mr-2 " /> This contact has opted out of text messages, sending a message here will NOT get delivered to their phone.</div>
                    </CardFooter>
                ) : null}

                {body && body.length > 1500 ? (

                    <CardFooter className="text-center">
                        <p className="text-sm mb-0">Max Text Length: <span className="text-danger font-weight-bold">{body.length}</span> / 1500</p>
                    </CardFooter>

                ) : null}

                <CardFooter className="text-right">

                    <button onClick={this.toggleModalSendPicture} className="btn btn-outline-info">
                        <i className="fas fa-plus mr-2" /> Send Image
                    </button>

                    <button onClick={this.toggleModal} className="btn btn-outline-success">
                        <i className="fas fa-plus mr-2" /> Templates
                    </button>

                    <button onClick={this.onSend} className="btn btn-success">
                        <i className="fas fa-paper-plane mr-2" /> Send
                    </button>

                </CardFooter>

            </Card>

            <ModalSelectTemplateText
                showModal={showModal}
                toggleModal={this.toggleModal}
                onSelect={this.onSelect}
            />

            <ModalSendPicture 
                showModal={showModalSendPicture}
                toggleModal={this.toggleModalSendPicture}
                contact_id={contact && contact._id ? contact._id : contact}
            />

            <ModalTemplateTextsErrors
                showModal={showModalErrors}
                toggleModal={() => this.toggleModalErrors()}
                errors={errors}
            />

            </>

        )

    }

}

const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,
    };
};

SendText.propTypes = {
    onSuccess         : PropTypes.func,
    onError           : PropTypes.func,
    onSendingStart    : PropTypes.func,
    contact           : PropTypes.string,
}

export default connect(mapStateToProps, '')(SendText);
