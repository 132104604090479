/*
Documentation

This renders a card that shows all appointments for an array of cases passed in
also allows for updating, adding, and removing them.

*/

import LoadingCircle from 'components/markup/loading/Circle';
import moment from 'moment';
import React, { Component } from 'react';
import { Card, CardHeader, CardTitle, Col,  Row } from "reactstrap";
import { io } from 'sockets';
import * as socketEvents from 'sockets/events';
import PropTypes from 'prop-types'
import * as privileges from '_functions/users/privileges'
import _calendar from '_functions/calendar';
import AppointmentsCrud from 'views/dashboard/pages/appointments/components/AppointmentsCrud';
import { toggleAlertBS } from 'store/functions/system/system'
import renderName from 'utils/renderName';

class Appointments extends Component {

    state = {
        appointment: {},
        appointments: null,
        showModal: false,
        canSave: true,

        showAppointmentsCrud: false,
        appointment_id: 'new'
    };

    onSuccess = () => {

        if(this.state.appointment_id === 'new') {
            toggleAlertBS(false, 'Appointment Created Successfully');
        } else {
            toggleAlertBS(false, 'Appointment Update Successfully');
        }

        this.setState({showAppointmentsCrud: false})

    }

    renderCalendar = (ca) => {

        let last_date;
        let have_show_next_date = false;

        const canUpdate = privileges.canModerateAppointments();

         return ca.map(c => {

            let should_render_date = false;

            let date = moment.unix(c.date).format('dddd, MMMM Do - YYYY');

            if(last_date !== date) {
                should_render_date = true
                last_date = moment.unix(c.date).format('dddd, MMMM Do - YYYY');
            }

            let className = ''

            if(!have_show_next_date && c.date > moment().valueOf() / 1000) {
                className = 'bg-success text-white';
                have_show_next_date = true
            }

            if(c.finished_on) {
                className = "text-muted"
            }

            return (

                // <React.Fragment key={c.date}>
                <React.Fragment key={c._id}>

                    {should_render_date ? (

                        <tr className="bg-secondary">
                            <td><b>{last_date}</b></td>
                        </tr>

                    ) : null}

                    <tr  className={className}>

                        <td style={{verticalAlign: 'top'}}>

                            {privileges.canModerateAppointments() && !c.finished_on ? (
                            <i
                                onClick={() => this.setState({showAppointmentsCrud: true, appointment_id: c._id})}
                                className={className ? "fas fa-edit mr-3" : "fas fa-edit text-success mr-3" }
                                style={{cursor: 'pointer', opacity: canUpdate ? 1 : .5}}
                            />
                            ) : null }
                            {moment.unix(c.date).format('h:mm A')} - {moment.unix(c.date_end).format('h:mm A')}
                            <br />
                            <span className="text-capitalize">
                                {c.finished_on ? <i className="fas fa-check text-success mr-2" /> : null} {c.name}
                            </span>
                            {c.finished_on ? (
                                <div>
                                    <div><b>Finished On:</b> {moment.unix(c.finished_on).format('MMM Do, YYYY - h:mm A')}</div>
                                    <div className="text-capitalize"><b>Finished by:</b> {renderName(c.finished_by)}</div>
                                    <div className="text-capitalize"><b>Outcome:</b> {c.outcome}</div>
                                </div>

                            ) : null}
                            {/* <div>
                                {!c.finished_on ? c.confirmed ? (
                                    <span className="font-weight-bold"><i className="fas fa-check mr-2" /> Confirmed By Client</span>
                                ) : (
                                    <span className="font-weight-bold"><i className="fas fa-info-circle mr-2 text-warning" /> Awaiting Client Confirmation</span>
                                ) : null}
                            </div> */}

                        </td>

                    </tr>

                </React.Fragment>
             )

         })
    }

    fetchAppointmentData = async () => {

        const appointments = await _calendar.query.byContacts(this.props.contact_id, '?queryFinished=true')

        if(appointments.success) {

            let newAppointments;

            if(this.state.appointments) {
                newAppointments = [...this.state.appointments]
            } else {
                newAppointments = []
            }

            newAppointments = newAppointments.concat(appointments.data)

            this.setState({appointments: newAppointments})

        }

    }

    addToState = (data) => {

        if(data && data.data && data.data.contact && data.data.contact._id === this.props.contact_id) {

              //assign state, assume it is null to start
            let objs = []
            if(this.state.appointments) objs = [...this.state.appointments]

            if(!(data instanceof Array)) {
                objs.push(data.data)
            } else {
                data.forEach((d, i) => objs.push(data[i].data) )
            }

            //set new state
            this.setState({appointments: objs})

        }

    }

    updateToState = (data) => {

        if(data && data.data && data.data.contact && data.data.contact._id === this.props.contact_id) {

            //assign state, assume it is null to start
            let objs = []
            if(this.state.appointments) objs = [...this.state.appointments]

            //add in updated object
            objs[objs.findIndex(el =>  el._id === data.data._id )] = data.data

            //set new state
            this.setState({appointments: objs})

        }
    }

    deleteToState = (data) => {

        let contact_id = data.data.contact && data.data.contact._id ? data.data.contact._id : data.data.contact

        if(contact_id === this.props.contact_id) {

            //assign state, assume it is null to start
            let objs = []
            if(this.state.appointments) objs = [...this.state.appointments]

            //filter out deleted object
            objs = objs.filter(o =>  o._id !== data.data._id );

            //set state
            this.setState({appointments: objs})

        }

    }

    initListeners = () => {
        io.on(socketEvents.appointments_added, this.addToState)
        io.on(socketEvents.appointments_updated, this.updateToState)
        io.on(socketEvents.appointments_deleted, this.deleteToState)
    }

    componentWillUnmount = () => {
        io.removeListener(socketEvents.appointments_added, this.addToState)
        io.removeListener(socketEvents.appointments_updated, this.updateToState)
        io.removeListener(socketEvents.appointments_deleted, this.deleteToState)
    }


    componentDidMount = () => {

        this.fetchAppointmentData()
        this.initListeners()

    }

    render() {

        let { appointments }  = this.state
        const { navPills } = this.props
        const _case = this.props.case

        if(navPills !== 1 ) return null

        if(appointments && appointments.length) {
            //sort by the time start from beginning to end
            appointments = appointments.sort((a,b) => a.date > b.date ? -1 : 1)
        }

        const { appointment_id, showAppointmentsCrud} = this.state

        return (

            <>

            <Card className="card-color card-warning">

                <CardHeader>
                    <Row>
                        <Col md={6} className="align-self-center">
                            <CardTitle className="mb-0">Reminders</CardTitle>
                        </Col>
                        <Col md={6} className="text-right align-self-center">

                            {privileges.canModerateAppointments() ? (
                            <button
                                onClick={() => this.setState({appointment_id: 'new', showAppointmentsCrud: true})}
                                className="btn btn-s text-center btn-success"
                            >
                                <i className="fas fa-plus mr-2 " /> Add
                            </button>
                            ) : null}
                        </Col>
                    </Row>
                </CardHeader>

                <div className="table-responsive table-not-fixed appointment-table">
                    <table className="table">

                        <thead>
                            <tr>
                                <td className="font-weight-bold">Time</td>

                            </tr>
                        </thead>

                        <tbody>
                        {appointments && appointments.length ? this.renderCalendar(appointments) : (
                            <tr>
                                <td style={{whiteSpace: 'pre'}} >
                                    <span className="text-warning font-weight-bold">There are no appointments for this contact.</span>
                                </td>
                            </tr>
                        )}
                        </tbody>

                    </table>

                    {!appointments  ? (
                        <LoadingCircle with={30} />
                    ) : null}

                </div>

            </Card>

            {showAppointmentsCrud ? (
                <AppointmentsCrud
                    toggleAppointmentsCrud={() => this.setState({showAppointmentsCrud: false})}
                    appointment_id={appointment_id}
                    title={ appointment_id === 'new' ? 'Create Reminder' : 'Update Reminder' }
                    showAppointmentsCrud={showAppointmentsCrud}
                    onSuccess={this.onSuccess}
                    case_id={_case._id}
                    contact_id={_case.contact ? _case.contact._id : null}

                    shouldRedirect={false}
                    isModal={true}
                />
            ) : null }

            </>

        )

    }

}

Appointments.propTypes = {
    contact_id    : PropTypes.string.isRequired,
    navPills      : PropTypes.number.isRequired,
}

export default Appointments;
