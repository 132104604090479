import apiWorker from '../apiWorker'

const automations = {

    create: (data)          => apiWorker('post',   `/api/v1/automations/automations/create`, data),
    update: (_id, data)     => apiWorker('patch',  `/api/v1/automations/automations/update/${_id}`, data),
    delete: (_id)           => apiWorker('delete', `/api/v1/automations/automations/delete/${_id}`),
    all: (query)            => apiWorker('get',    `/api/v1/automations/automations/find${query}`),
    run: ()                 => apiWorker('post',   `/api/v1/automations/automations/run`),

    tasks: {

        create: (data)          => apiWorker('post',    `/api/v1/automations/automation_tasks/create`, data),
        update: (_id, data)     => apiWorker('patch',   `/api/v1/automations/automation_tasks/update/${_id}`, data),
        delete: (_id)           => apiWorker('delete',  `/api/v1/automations/automation_tasks/delete/${_id}`),
        all: (query)            => apiWorker('get',     `/api/v1/automations/automation_tasks/find${query}`),

    },

    assigned: {

        create: (data)          => apiWorker('post',   `/api/v1/automations/automations_assigned/create`, data),
        update: (_id, data)     => apiWorker('patch',  `/api/v1/automations/automations_assigned/update/${_id}`, data),
        updateRunDates: (contact, data)     => apiWorker('patch',  `/api/v1/automations/automations_assigned/updateRunDates/${contact}`, data),
        delete: (_id)           => apiWorker('delete', `/api/v1/automations/automations_assigned/delete/${_id}`),
        clear: (_id)            => apiWorker('delete', `/api/v1/automations/automations_assigned/clear/${_id}`),
        all: (query)            => apiWorker('get',    `/api/v1/automations/automations_assigned/find${query}`),
        reset: (automation_id)  => apiWorker('post',    `/api/v1/automations/automations_assigned/reset/${automation_id}`),

    },

}

export default automations;