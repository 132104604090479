/*
Documentation

Creates the modal for users to be able to transfer to other staff members

*/

import PropTypes from 'prop-types';
import { architeckCall } from 'database';
import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { connect } from 'react-redux';
import { Badge, Modal } from "reactstrap";
import renderName from 'utils/renderName';
import { startTransferListener } from 'store/functions/call_center/call_center'

const { SearchBar } = Search;
const badgeClasses = 'text-s w-100'

class TransferModal extends React.Component {

    state = {
        filter: null
    }

    transferCall = async (row) => {

        if(row.call_phone_default === 'browser') {
            architeckCall({
                shouldNotLogAndShowError: true,
                method: 'post',
                url: '/api/v1/call_center/actions/browser_transfer',
                data: { user: row._id, contact: this.props.contact, isTransfer: true }
            })
        } else {
            architeckCall({
                shouldNotLogAndShowError: true,
                method: 'post',
                url: '/api/v1/call_center/actions/calls/create',
                data: { user: row._id, contact: this.props.contact, isTransfer: true }
            })
        }
     

        this.props.toggleTransferModal()
        startTransferListener(row._id, this.props.contact._id)

    }


    columns = [
        {
            dataField: "name",
            text: "Name",
            filterValue: (cell, row) => row.given_name + ' ' + row.family_name,
            formatter: (cell, row) => <span className="text-capitalize">{renderName(row)}</span>
        },
        {
            dataField: "phone",
            text: "Phone",
        },

        {
            dataField: "status",
            text: "Status",
            formatter: (cell, row) => row.status === 'online' ? <Badge className={badgeClasses} color="success">Online</Badge> : <Badge className={badgeClasses} color="danger">Offline</Badge>

        },

        {
            dataField: "call_conference",
            text: "On Call",
            formatter: (cell, row) => row.call_status === 'on call' ? <Badge className={badgeClasses} color="danger">Yes</Badge> : <Badge className={badgeClasses} color="success">No</Badge>
        },

        {
            dataField: "_id",
            text: "Actions",
            headerStyle: {textAlign: 'right'},
            formatter: (cell, row) => (
                <div className='text-right'>
                    <button onClick={() => this.transferCall(row)} className="btn btn-success btn-sm"><i className="fas fa-phone mr-2" /> Transfer</button>
                </div>
            )
        },
    ]

    pagination = paginationFactory({
        page: 1,
        alwaysShowAllBtns: true,
        showTotal: false,
        withFirstAndLast: false,

        sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
           <></>
        )
    });

    render() {

        const { showModal, users, toggleTransferModal } = this.props

        return (

            <Modal
                className="modal-dialog-centered"
                isOpen={showModal}
                toggle={() => toggleTransferModal()}
                size="lg"
                zIndex={100000}

            >
                <div className="modal-header">

                    <h5 className="modal-title" id="exampleModalLabel">
                        Transfer Call
                    </h5>

                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => toggleTransferModal()}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>

                </div>


                <div className="modal-body">
                    <div className="table-responsive">
                        <ToolkitProvider
                            data={users}
                            keyField="_id"
                            columns={this.columns}
                            search
                        >
                        {props => (
                            <>
                            <div
                                id="datatable-basic_filter"
                                className="dataTables_filter pb pt-3 pb-2"
                                style={{width: '100%'}}
                            >
                                <label>
                                    Search:
                                    <SearchBar
                                        className="form-control-sm"
                                        placeholder="Name, Email, Phone"
                                        {...props.searchProps}
                                    />
                                </label>
                            </div>
                            <div className=" table-responsive table-vertical-align table-not-fixed">
                                <BootstrapTable
                                    pagination={this.pagination}
                                    {...props.baseProps}
                                    bootstrap4={true}
                                    bordered={false}
                                />
                            </div>

                            </>

                        )}
                        </ToolkitProvider>
                    </div>

                </div>

            </Modal>

        );
    }
}


const mapStateToProps = state => {
    return {
        users: state.users.users,
        viewing_user: state.auth.viewing_user,
    };
};

TransferModal.propTypes = {
    toggleTransferModal   : PropTypes.func.isRequired,
    contact               : PropTypes.object.isRequired,
    contact_phone         : PropTypes.string.isRequired,
}

export default connect(mapStateToProps, '')(TransferModal);

