import moment from 'moment';
import { useCallback, useEffect, useState, } from 'react';
import { FormGroup, Input, Row, Col } from 'reactstrap';

// times = { start: 0, end: 86400 }

const Options = () => {
    const hour = 3600;
    const times = [
        { value: hour * 0, name: '12 AM' },
        { value: hour * 1, name: '1 AM' },
        { value: hour * 2, name: '2 AM' },
        { value: hour * 3, name: '3 AM' },
        { value: hour * 4, name: '4 AM' },
        { value: hour * 5, name: '5 AM' },
        { value: hour * 6, name: '6 AM' },
        { value: hour * 7, name: '7 AM' },
        { value: hour * 8, name: '8 AM' },
        { value: hour * 9, name: '9 AM' },
        { value: hour * 10, name: '10 AM' },
        { value: hour * 11, name: '11 AM' },
        { value: hour * 12, name: '12 PM' },
        { value: hour * 13, name: '1 PM' },
        { value: hour * 14, name: '2 PM' },
        { value: hour * 15, name: '3 PM' },
        { value: hour * 16, name: '4 PM' },
        { value: hour * 17, name: '5 PM' },
        { value: hour * 18, name: '6 PM' },
        { value: hour * 19, name: '7 PM' },
        { value: hour * 20, name: '8 PM' },
        { value: hour * 21, name: '9 PM' },
        { value: hour * 22, name: '10 PM' },
        { value: hour * 23, name: '11 PM' },
        { value: hour * 24, name: '12 AM' },
    ]
    return times.map(t => <option value={t.value} key={t.value} >{t.name}</option>)
}

const Day = ({value, property, label, onSelectTime}) => {

    const getValue = useCallback((str) => {
        var sec_num = parseInt(str, 10); // don't forget the second param
        var hours   = Math.floor(sec_num / 3600);
        var minutes = Math.floor((sec_num - (hours * 3600)) / 60);


        let identifier = hours < 12 ? 'AM' : 'PM';
        if(hours > 12) hours = hours - 12;
        if(hours === 0) hours = 12;

    
        if (minutes < 10) {minutes = "0"+minutes;}
        return hours+':'+minutes + ' ' + identifier;
    }, [])

    return (

        <div className="border rounded bg-secondar mb-3">

            <div className="px-3 py-2 border-bottom">
                {!value.start && !value.end ? (
                    <small className="text-warning">{label} Hours: No Times Available</small>
                ) : value.start >= value.end ? (
                    <small className="text-danger"><i className="fas fa-exclamation-triangle " /> End time must be greater than the start time.</small>
                ) : (
                    <small>{label} Hours: <b>{getValue(value.start)}</b> to <b>{getValue(value.end)}</b></small>
                )}
            </div>
            
            <div className="p-3">
                <Row>
                    <Col md={6} className="align-self-center">
                        <FormGroup>
                            <small>Start</small>
                            <Input 
                                type="select"
                                value={value.start.toString()}
                                onChange={(e) => onSelectTime(e.target.value, property, 'start')}
                            >
                                <Options />
                            </Input>
                        </FormGroup>
                    </Col>
                    <Col md={6} className="align-self-center">
                        <FormGroup>
                            <small>End</small>
                            <Input 
                                type="select"
                                value={value.end.toString()}
                                onChange={(e) => onSelectTime(e.target.value, property, 'end')}
                            >
                                <Options />
                            </Input>

                        </FormGroup>
                    </Col>
                </Row>
            </div>

        </div>
    )
}

const StartAndEndOfDay = ({times, setTimes}) => {

    const [state, setState] = useState({
        mon: { start: 32400, end: 61200 },
        tue: { start: 32400, end: 61200 },
        wed: { start: 32400, end: 61200 },
        thu: { start: 32400, end: 61200 },
        fri: { start: 32400, end: 61200 },
        sat: { start: 0, end: 0 },
        sun: { start: 0, end: 0 },
    })

    const days = [
        { property: 'mon', label: 'Monday' },
        { property: 'tue', label: 'Tuesday' },
        { property: 'wed', label: 'Wednesday' },
        { property: 'thu', label: 'Thursday' },
        { property: 'fri', label: 'Friday' },
        { property: 'sat', label: 'Saturday' },
        { property: 'sun', label: 'Sunday' },
    ]

    const onSelectTime = useCallback((value, property, type) => {
        const _state = JSON.parse(JSON.stringify(state))
        _state[property][type] = parseInt(value);

        setState(_state)
        setTimes(_state)
    }, [state, setTimes])

    useEffect(() => {
        setState(times)
    }, [times])

    return (
        <div>
            {days.map((d, i) => (
                <Day 
                    key={i}
                    value={state[d.property]}
                    property={d.property}
                    label={d.label}
                    onSelectTime={onSelectTime}
                />
            ))}
        </div>
    )
        

}

export default StartAndEndOfDay