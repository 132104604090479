import apiWorker from '../apiWorker'

const template_docs_sent = {

    findByCase    : (case_id, hideError)    => apiWorker('get',   `/api/v1/core/template_docs_sent/find/case/${case_id}`, null, hideError),
    findByContact : (contact_id, hideError) => apiWorker('get',   `/api/v1/core/template_docs_sent/find/contact/${contact_id}`, null, hideError),
    update        : (_id, data)             => apiWorker('patch', `/api/v1/core/template_docs_sent/update/${_id}`, data),
    create        : (data)                  => apiWorker('post',  `/api/v1/core/template_docs_sent/create`, data),

}

export default template_docs_sent;