import Sidebar from 'components/layout/Sidebar';
import { connect } from 'react-redux';
import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import React from "react";
import { Helmet } from 'react-helmet';

import AccountDetails from './Details';
import AccountSecurity from './Security';
import AccountProfile from './Profile';
import AccountConfiguration from './Configuration';
import AccountCalendar from './Calendar';

const AccountSettings = ({nylas_invalid, viewing_user}) => (
    <>
        <Helmet>
            <title>Account Settings</title>
            <meta name="description" content="Account Settings" />
        </Helmet>

        <Sidebar 
            navTitle="My Account"
            header={(
                <DashHeaderOpen
                    icon="fas fa-home"
                    icon_link="/dashboard"
                    title={<span>Settings</span>} breadcrumb_1="Account"
                />
            )}
            defaultTab={nylas_invalid ? 4 : 0}
            tabs={[
                { title: 'Details', component: <AccountDetails /> },
                { title: 'Security', component: <AccountSecurity /> },
                { title: 'Profile', component: <AccountProfile /> },
                { title: 'Configuration', component: <AccountConfiguration /> },
                { title: 'Calendar', component: <AccountCalendar /> },
            ]}
        />

    </>
)


const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,
        nylas_invalid: state.system.nylas_invalid,
    };
};

export default connect(mapStateToProps, '')(AccountSettings);
