import { connect } from 'react-redux'
import { Row, Col } from 'reactstrap';

import React, { Component } from 'react';

import { io } from 'sockets';
import * as socketEvents from 'sockets/events';
import moment from 'moment';

import reactHTMLParser from 'react-html-parser'

import { toggleRecentActivity } from 'store/functions/system/system'


class RecentActivity extends Component {

    state = {
        recent_activity: [],
    }

    listenForRecentActivityUpdated = (payload) => {

        const data = payload.data;

        if(data.user_id === this.props.viewing_user._id) {
            let recent_activity = [...this.state.recent_activity];
            recent_activity.unshift({title: data.title, value: data.value, time: data.time})

            if(recent_activity.length > 20) {
                recent_activity = recent_activity.splice(0, recent_activity.length - 1)
            }

            this.setState({recent_activity});
        }

    }

    componentWillUnmount = () => {
        io.off(socketEvents.recent_activity_updated, this.listenForRecentActivityUpdated);
    }

    componentDidMount = () => {
        io.on(socketEvents.recent_activity_updated, this.listenForRecentActivityUpdated);
    }

    render() {


        const RecentActivity = this.props.RecentActivity
        const { recent_activity } = this.state;

        //if this is not show return blank
        if(!RecentActivity.show) { return <></> }

        return (

            <div id="archk-recent-activity" className="archk-recent-activity">

                <div className="close " onClick={() => toggleRecentActivity(false)} />

                <div className="content bg-secondary">

                    <div className="archk-recent-activity-heading bg-white">
                        <Row>
                            <Col xs={9}>
                                Recent Activity
                            </Col>
                            <Col xs={3} className="text-right">
                                <i onClick={() => toggleRecentActivity(false)} className="fas fa-times cursor-pointer " />
                            </Col>
                        </Row>
                    </div>

                    <ul>

                        {recent_activity.length ? recent_activity.map((activity, i) => (
                            <li key={i}>
                                <h4 className="archk-recent-activity-title mb-1">
                                    <Row>
                                        <Col xs={6}>{activity.title}</Col>
                                        <Col xs={6} className="text-right">{moment.unix(activity.time).format('h:mm A')}</Col>
                                    </Row>
                                </h4>
                                <p className="archk-recent-activity-value text-sm mb-0 text-capitalize">
                                    {reactHTMLParser(activity.value)}
                                </p>
                            </li>
                        )) : (
                            <li>
                                There is no recent activity to show.
                                <p className="text-sm mb-0">Recent activity shows up when you view a case or contact, send and email or text, or try to call a contact. It will disappear if you close this tab.</p>
                            </li>
                        )}

                    </ul>

                </div>
            </div>

        )

    }

}

const mapStateToProps = state => {

    return {
        viewing_user: state.auth.viewing_user,
        RecentActivity: state.system.recentActivity
    };
};

export default connect(mapStateToProps, '')(RecentActivity);
