import StandardFormGroup from 'components/functional/inputs/StandardFormGroup';
import States from 'components/markup/inputs/States';
import { CardFooter, Col, Row } from "reactstrap";
import _company_industries from './_company_industries';

const InfoDetails = ({newCase, onSetState}) => (
    <CardFooter id="archk-details-info">

        <h3 className="mb-4">Identifiers</h3>

        <Row>
            <Col lg={6}>
                <StandardFormGroup
                    obj={newCase}
                    objName="newCase"
                    onChange={onSetState}
                    type="text"
                    field="company_name"
                    title="Company Name (Updates Case Name)"
                />
                <StandardFormGroup
                    obj={newCase}
                    objName="newCase"
                    onChange={onSetState}
                    type="text"
                    field="company_dba"
                    title="DBA / Trade Name (if applicable)"
                />
                <StandardFormGroup
                    obj={newCase}
                    objName="newCase"
                    onChange={onSetState}
                    type="text"
                    field="company_ein"
                    title="Company EIN"
                />
             
            </Col>
            <Col lg={6}>
                <StandardFormGroup
                    obj={newCase}
                    objName="newCase"
                    onChange={onSetState}
                    type="select"
                    field="company_type"
                    title="Company Type"
                >
                    <option value=""></option>
                    <option value="Corporation">Corporation</option>
                    <option value="Self-Employed Individuals">Self-Employed Individuals</option>
                    <option value="Limited  Liability Company(LLC)">Limited  Liability Company(LLC)</option>
                    <option value="Sole Proprietorship">Sole Proprietorship</option>
                    <option value="Subchapter S Corporation">Subchapter S Corporation</option>
                    <option value="Limited Liability Partnership">Limited Liability Partnership</option>
                    <option value="Single Member LLC">Single Member LLC</option>
                    <option value="Partnership">Partnership</option>
                    <option value="Cooperative">Cooperative</option>
                    <option value="Non-Profit Organization">Non-Profit Organization</option>
                    <option value="501(c)6 – Non Profit Membership">501(c)6 – Non Profit Membership</option>
                    <option value="501(c)3 – Non Profit">501(c)3 – Non Profit</option>
                    <option value="Professional Association">Professional Association</option>
                    <option value="Non-Profit Childcare Center">Non-Profit Childcare Center</option>
                    <option value="Employee Stock Ownership Plan(ESOP)">Employee Stock Ownership Plan(ESOP)</option>
                    <option value="Independent Contractors">Independent Contractors</option>
                </StandardFormGroup>

                <StandardFormGroup
                    obj={newCase}
                    objName="newCase"
                    onChange={onSetState}
                    type="select"
                    field="company_industry"
                    title="Company Industry"
                >
                    <option value=""></option>
                    {_company_industries.map((j, i) => (
                        <option key={i} value={j}>{j}</option>
                    ))}
                </StandardFormGroup>

            </Col>
        </Row>
       
        <hr />
        <h3 className="mb-4">Company Address</h3>

        <StandardFormGroup
            obj={newCase}
            formGroupClasses="w-50"
            objName="newCase"
            onChange={onSetState}
            field="company_address"
            title="Address"
        />

        <StandardFormGroup
            obj={newCase}
            formGroupClasses="w-50"
            objName="newCase"
            onChange={onSetState}
            field="company_city"
            title="City"
        />

        <StandardFormGroup
            obj={newCase}
            formGroupClasses="w-50"
            description={newCase.company_state && newCase.company_state.length !== 2 ? <span>POSSIBLE STATE: {newCase.company_state}</span> : ''}
            objName="newCase"
            onChange={onSetState}
            field="company_state"
            title="State"
            type="select"
        >
            <option value="">Select State</option>
            <States />
        </StandardFormGroup>

        <StandardFormGroup
            obj={newCase}
            formGroupClasses="w-50"
            objName="newCase"
            field="company_zip"
            title="Zip "
            onChange={onSetState}
        />
        
    </CardFooter>
)

export default InfoDetails