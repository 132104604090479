/*
Documentation

This component renders the bar showing what types of queue calls are open

*/

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Keypad from './Keypad';
import { Link } from 'react-router-dom';

class InfoBar extends Component {

    render() {

        const {  showKeypad, toggleKeypad, setOnCall, canTakeCalls, voicemailCount } = this.props

        const queueData = this.props.queueData ? this.props.queueData : {}

        return (

            <div className="info-bar bg-secondary">
                <div className="content">

                <ul className="pl-0 list-style-none bar-left">
                        <li className="position-relative">
                            <button disabled={!canTakeCalls} onClick={toggleKeypad} className="btn  btn-warning"><i className="fas fa-headset mr-2" /> Dial Number</button>

                            {showKeypad ?
                                <Keypad
                                    toggleKeypad={toggleKeypad}
                                    setOnCall={setOnCall}
                                />
                            : null}
                        </li>
                        <li className="position-relative">
                            <Link to="/dashboard/call_center/voicemail" className="btn  btn-primary"><i className="fas fa-mail-bulk mr-2" /> Voicemail</Link>

                        </li>
                    </ul>

                    <ul className="pl-0 list-style-none bar-right">

                        <li className="ml-3 title">Priority</li>

                        <li className={queueData.newContacts ? queueData.newContacts > 5 ? 'descriptor descriptor-danger' : 'descriptor descriptor-warning' : 'descriptor'}>
                            <span className="descriptor-content">{queueData.newContacts}</span>
                        </li>

                        <li className="ml-3 title">Business</li>

                        <li className={queueData.business ? queueData.business > 5 ? 'descriptor descriptor-danger' : 'descriptor descriptor-warning' : 'descriptor'}>
                            <span className="descriptor-content">{queueData.business}</span>
                        </li>

                        <li className="ml-3 title">Open</li>

                        <li className={queueData.appointments ? queueData.appointments > 5 ? 'descriptor descriptor-danger' : 'descriptor descriptor-warning' : 'descriptor'}>
                            <span className="descriptor-content">{queueData.appointments}</span>
                        </li>

                        <li  className="ml-3 title">Queue</li>

                        <li className={queueData.callQueue ? queueData.callQueue > 5 ? 'descriptor descriptor-danger' : 'descriptor descriptor-warning' : 'descriptor'}>
                            <span className="descriptor-content">{queueData.callQueue}</span>
                        </li>

                        <li  className="ml-3 title">Voicemail</li>

                        <li className={voicemailCount ? voicemailCount > 5 ? 'descriptor descriptor-danger' : 'descriptor descriptor-warning' : 'descriptor'}>
                            <span className="descriptor-content">{voicemailCount}</span>
                        </li>

                    </ul>
                </div>
            </div>

        )

    }

}

InfoBar.propTypes = {
    showKeypad    : PropTypes.bool.isRequired,
    toggleKeypad  : PropTypes.func.isRequired,
    setOnCall     : PropTypes.func.isRequired,
    canTakeCalls  : PropTypes.bool,
    queueData     : PropTypes.object,
}

export default InfoBar
