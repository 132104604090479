import { useState } from 'react';
import { connect } from 'react-redux';
import renderName from 'utils/renderName';
import { capitalize } from 'utils/text';

import { toggleAlertBS } from 'store/functions/system/system';

import SendEmail from 'components/system/SendEmail';
import SendEmailModalWrapper from 'components/system/SendEmail/ModalWrapper';
import keys from 'keys';

const SendReminder = ({_case, party, selectedDocToVoid, viewing_user, children}) => {

    const docName = selectedDocToVoid.name && (selectedDocToVoid.name.includes('Pending Signed Agreement') || selectedDocToVoid.name.includes('Client Services')) ? 'Client Services Agreement' : selectedDocToVoid.name;
    const link = `${keys.PORTAL_URL}/actions/sign/${selectedDocToVoid._id}?token=${party.access_code}`;


    const [showModal, setShowModal] = useState(false);
    return (
       <>
        <span onClick={() => setShowModal(true)}>
            {children}
        </span>
        {console.log(party)}
        <SendEmailModalWrapper
            showModal={showModal}
            toggleModal={() => setShowModal(false)}
        >
            <SendEmail
                body={`Hi${party.contact ? ' ' + capitalize(party.contact.given_name) : ''},<br /><br />This is a reminder that you have a document still pending signature.<br /><b>${docName}<b /><br /><br />Please use the link below to sign the document via a computer or mobile device:<br /><a href="${link}">${link}</a> <br /> <br />If you have any questions please let me know!<br /><br />Sincerely<br/>${capitalize(renderName(viewing_user))}`}
                onSendingStart={() => setShowModal(false)}
                onError={() => toggleAlertBS(true, 'Your email was not sent, please try again or send it from your Office 365 account.')}
                subject="Biz Head Law"
                to={_case.contact && _case.contact.email ? [_case.contact.email]   : []}
                contact={_case.contact ? _case.contact._id : undefined}
                case={_case ? _case._id : undefined}
                division={_case ? _case.division : undefined}
            />
        </SendEmailModalWrapper>
        </>
    )
}



const mapStateToProps = state => {
    return {
    	viewing_user: state.auth.viewing_user,
    };
};

export default connect(mapStateToProps, '')(SendReminder);