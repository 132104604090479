import React, { Component } from 'react';
import { Row, Col, Card, CardBody, Badge, CardFooter, CardHeader, CardTitle } from 'reactstrap';
import OverviewContent from './OverviewContent'
import { connect } from 'react-redux';
import renderName from 'utils/renderName'
import NameCircle from 'components/markup/misc/NameCircle';
import { formatPhone } from 'utils/text'
import PropTypes from 'prop-types'
import { toggleStartCallModal } from 'store/functions/call_center/call_center'
import { Link } from 'react-router-dom';
import * as privileges  from '_functions/users/privileges'

import MergeModal from './MergeModal'
import _cases from '_functions/cases';
import _consultations from '_functions/consultations';

const actionStepLink = 'https://us-east-1.actionstep.com/mym/asfw/workflow/action/overview/action_id/'

class Overview extends Component {


    state = {
        showMergeModal: false,
    };

    toggleMergeModal = () => this.setState({showMergeModal: !this.state.showMergeModal});

    setClientCases = async () => {

        const client_cases = await _cases.find(`?filter=contact__${this.props.contact._id}|deleted__false{bool}`)
        
        if(client_cases.data && !this.props.viewing_user.privileges.includes('can_view_all_cases')) {
            client_cases.data = client_cases.data.filter(c => {
                return c.roles && c.roles.some(r => {
                    return r.user && r.user._id === this.props.viewing_user._id
                }) ? true : false
            })
        }
        if(client_cases.success) this.setState({client_cases: client_cases.data})

    }


    fetchConsultations = async () => {
        const consultations = await _consultations.find(this.props.contact._id);
        this.setState({consultations: consultations.data ? consultations.data : []})
    }

    componentDidMount = () =>  {
        this.setClientCases()
        this.fetchConsultations()
    }

    render() {

        const { showMergeModal, client_cases } = this.state;
        const { contact, navPills, cases, lead_source_entry, createCaseURL, setData } = this.props;

        const status = lead_source_entry && lead_source_entry.status ? lead_source_entry.status : null;



        return (

            navPills === 0 ? (

                <>
                <Card className="card-color card-primary">
                    <CardBody>

                        <Row>
                            <Col lg={3}>

                                <div>
                                    <h2 className="text-capitalize">
                                        <NameCircle width={45} contact={contact} style={{marginRight: 0}} bg="bg-gradient-success" />{' '}
                                        {renderName(contact)}
                                    </h2>
                                    <p className="text-sm mb-0">

                                        <span style={{width: 20, display: 'inline-block'}}>
                                            <i className="text-muted fas fa-envelope" />
                                        </span>
                                        {contact.email ? contact.email : 'No Email Added'} <br />

                                        <span style={{width: 20, display: 'inline-block'}}>
                                            <i className="text-muted fas fa-mobile" />
                                        </span>

                                        {contact.phone ? (
                                            <span className="cursor-pointer" onClick={() => toggleStartCallModal(true, contact)}>
                                                {formatPhone(contact.phone) }
                                            </span>
                                        ) : 'No Phone Added'}
                                    </p>
                                </div>

                            </Col>

                            <Col lg={3}>
                                <div className="text-lg-center">
                                    <h2 className="mt-4 mt-lg-0">Relationships </h2>
                                    <p className="text-sm mb-0">
                                        <span style={{width: 20, display: 'inline-block'}}><i className="text-muted fas fa-users mr-2" /></span>
                                        {contact.relationships ? contact.relationships.length : 0}
                                    </p>
                                </div>
                            </Col>

                            <Col lg={3}>
                                <div className="text-lg-center">
                                    <h2 className="mt-4 mt-lg-0">Cases </h2>
                                    <p className="text-sm mb-0">
                                        <span style={{textAlign: 'left', width: 20, display: 'inline-block'}}><i className="text-muted fas fa-gavel" /></span>
                                        {cases.length}{' '}
                                        <Link to={createCaseURL(contact._id)}><Badge color="success"><i className="fas fa-plus " /> Add</Badge></Link>
                                    </p>
                                </div>
                            </Col>

                            <Col lg={3}>
                                <div className="text-lg-right text-capitalize">
                                    <h2 className="mt-4 mt-lg-0">Status</h2>
                                    {status ? (
                                        <Badge 
                                            className="text-sm" 
                                            color={
                                                status === 'hired' || status === 'potential' ? 'success' :
                                                status === 'rejected client' || status === 'rejected user' || status === 'not contacted' ? 'danger' : 'warning'
                                            }
                                        >
                                            {status === 'not contacted' ? 'no case setup' : status}
                                        </Badge>
                                    ) : '-'}

                                </div>
                            </Col>

                        </Row>

                    </CardBody>
                    <CardFooter>

                        <Row>
                            <Col lg={3}>
                                <div className="text-lg-left">
                                    <h2 className="mt-4 mt-lg-0">Has Been Contacted </h2>
                                    {contact.times_contacted ?
                                        <Badge className="text-sm" color="success"><i className="fas fa-check mr-2" /> Yes</Badge> :
                                        <Badge className="text-sm" color="warning"><i className="fas fa-times mr-2" /> No</Badge>}
                                </div>
                            </Col>

                            <Col lg={3}>
                                {/* <div className="text-lg-center">
                                    <h2>Has Billing Info</h2>
                                    {contact.default_account_vault_id ?
                                        <Badge className="text-sm" color="success"><i className="fas fa-check mr-2" /> Yes</Badge> :
                                        <Badge className="text-sm" color="warning"><i className="fas fa-times mr-2" /> No</Badge>}
                                </div> */}
                            </Col>

                            <Col lg={3}>
                                <div className="text-lg-center" >
                                    <h2 className="mt-4 mt-lg-0">Has Logged In In</h2>
                                        {contact.password ?
                                        <Badge className="text-sm" color="success"><i className="fas fa-check mr-2" /> Yes</Badge> :
                                        <Badge className="text-sm" color="warning"><i className="fas fa-times mr-2" /> No</Badge>}
                                </div>
                            </Col>

                            <Col lg={3}>
                                <div className="text-lg-right">
                                    <h2 className="mt-4 mt-lg-0">Communication</h2>
                                    {!contact.opt_out_email ?
                                        <Badge className="text-sm" color="success"><i className="fas fa-envelope mr-2" /> Yes</Badge> :
                                        <Badge className="text-sm" color="warning"><i className="fas fa-envelope mr-2" /> No</Badge>}
                                    {!contact.opt_out_text ?
                                        <Badge className="text-sm" color="success"><i className="fas fa-mobile mr-2" /> Yes</Badge> :
                                        <Badge className="text-sm" color="warning"><i className="fas fa-mobile mr-2" /> No</Badge>}
                                </div>
                            </Col>

                        </Row>

                    </CardFooter>

                    <CardFooter>

                        <Row>

                            <Col lg={5} className="align-self-center">
                                {privileges.canSeeLeadSources() ? (
                                    <span><b className="text-dark font-weight-bold text-info-original">LEAD  SOURCE:</b> <p className="text-sm mb-0">{contact.lead_source_name}</p></span>
                                ) : null}
                            </Col>

                            {/* <Col lg={7} className="align-self-center text-right">
                                {!contact.merged_to ? (
                                    <button 
                                        onClick={this.toggleMergeModal}
                                        className="btn btn-block btn-sm-inline btn-outline-warning mt-3 mt-md-0"
                                    >
                                        <i className="fas fa-users mr-2" />Merge File
                                    </button>
                                ) : null}

                            </Col> */}

                        </Row>

                       
                    </CardFooter>

                </Card>

                <Card className="card-color card-primary">

                    <CardHeader>
                        <Row>

                            <Col lg={6} className="align-self-center">
                                <CardTitle className="mb-0">Client Cases</CardTitle>
                            </Col>

                            <Col lg={6} className="align-self-center text-right">
                                <Link className="btn btn-success" to={createCaseURL(contact._id)}><i className="fas fa-plus " /> Add</Link>
                            </Col>

                        </Row>
                        
                        <p className="text-sm mb-0">This row shows all cases in which this contact is a client</p>


                    </CardHeader>

                    <CardBody>
                        <Row>

                        {client_cases && client_cases.length ? client_cases.map((client_case, i) => (
                            
                            <Col md={4} key={i}>
                                <Link to={`/dashboard/cases/view/${client_case._id}`}>
                                    <Card
                                        style={{opacity: client_case.deleted ? .5 : 1}}
                                        className="cursor-pointer text-center bg-gradient-success"
                                    >

                                        <CardBody>
                                            <h2 className="my-0 py-0 text-white text-capitalize">{client_case.name}</h2>
                                            <p  className="text-sm text-white">CLIENT</p>
                                        </CardBody>

                                    </Card>
                                </Link>
                            </Col>
                        )) : (
                            <Col xs={12}>
                                <p className="text-sm text-warning font-weight-bold mb-0">
                                    There are no found cases for this contact.
                                </p>
                            </Col>
                        ) }

                        </Row>
                    </CardBody>
                </Card>


                <OverviewContent contact={contact} consultations={this.state.consultations} />

                <MergeModal 
                    fromContact={contact}
                    showModal={showMergeModal}
                    setData={setData}
                    toggleModal={this.toggleMergeModal}            
                />

                </>

            ) : null

        )

    }

}

const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user
    };
};

Overview.propTypes = {
    contact   : PropTypes.object.isRequired,
    navPills  : PropTypes.number.isRequired,
    cases     : PropTypes.array.isRequired,
}

export default connect(mapStateToProps, '')(Overview);
