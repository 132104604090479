/*
Documentation

PROPS

title                   | optional, custom title for the modal
appointment_id          | optional, if passed in the modal pre fills the appointment with the data of the appointment_id, can be "new" if trying to create
case_id                 | optional, if passed we can pull info into the appointment automations from the case
template_appointment    | optional, if passed in a user cant change the appointment type and we pre fil the inputs. cant be used with appointment_id that !== 'new
contact_id              | optional, pre fills contact with this, cant be used with appointment_id that !== 'new
outcome                 | optional, string describing the outcome of the appointment
action                  | used if finishing is true, can either be "create", "update", "delete"
finishing               | if true we try to finish the appointment with that endpoint, else we are either updating or creating based on appointment_id
shouldRedirect          | if true and no appointment is found by id we redirect the page to /dashboard/appointments/all
showAppointmentsCrud    | if isModal is true we show the modal if this is also true
toggleAppointmentsCrud  | if isModal this function is used to toggle it via changing showAppointmentsCrud
isModal                 | if true this component shows up as a modal
onSuccess               | a required function to fire on a success save, returns just the .data property from the API request
onError                 | optional function to fire if api call fails
showLoader              | if true we use the system standard loader when making an API call to show loading
startDate               | optional unix timestamp to pre fill the start time

Example Usage

 <AppointmentsCrud
    appointment_id={}
    template_appointment={}
    contact_id={}
    finishing={true}
    outcome="No Answer"
    action="create"
    shouldRedirect={false}
    title="Schedule Follow Up Appointment"
    toggleAppointmentsCrud={}
    showAppointmentsCrud={}
    onSuccess={}
    isModal={true}
/>

*/

import StandardFormGroup from 'components/functional/inputs/StandardFormGroup';
import NotificationDelete from 'components/functional/notifications/Delete';
import MinuteOptions15 from 'components/markup/dates/MinuteOptions15';
import Circle from 'components/markup/loading/Circle';
import PropTypes from 'prop-types';
import React from "react";
import DatePicker from 'react-datepicker';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Col, FormGroup, Modal, Row } from "reactstrap";
import { toggleStandardLoader, toggleAlertBS } from 'store/functions/system/system';
import renderName from 'utils/renderName';
import { compileDateInputs, decodeDateInputs, decodeUnixDate } from 'utils/time';
import validator from 'utils/validator';
import _appointments from '_functions/appointments';
import ReactSelect from 'components/functional/inputs/ReactSelect';
import _contacts from '_functions/contacts';
import _cases from '_functions/cases';
import _case_parties from '_functions/case_parties';
import { capitalize } from 'utils/text'

import ModalValidation from './components/ModalValidation'
import ModalMarkComplete from './components/ModalMarkComplete'
import AvatarImage from 'components/functional/images/AvatarImage';

import Case from './components/Case'
import Parties from './components/Parties'
import Roles from './components/Roles'
import Contact from './components/Contact'
import Office from './components/Office'
import Users from './components/Users'
import LocationCourt from './components/LocationCourt'
import moment from 'moment';

import * as ANALYTIC_EVENTS from '_settings/analytic_events';
import _analytics from '_functions/analytics';

const required_fields = [
    'name',
    'date',
    'time_start',
    'time_end',
    'type',
    'template_appointment',
]

class AppointmentsCrud extends React.Component {

    state = {
        validationConfirmed: false,
        shouldRedirect: false,
        loaded: false,
        appointment: this.props.appointment ? Object.assign({}, this.props.appointment) : {
            assigned_to: [],
            type: 'attorney'
        },
        showCompleteConfirmation: false,
        showValidation: false,
        isSaving: false,
    }

    toggleCompleteConfirmation = () => this.setState({showCompleteConfirmation: !this.state.showCompleteConfirmation})
    toggleValidationModal = () => this.setState({showValidation: !this.state.showValidation});

    getTemplateName = (template) => {

        if(template && template.name) return template.name;

        const template_appointments = this.props.template_appointments.template_appointments;
        const found_template = template_appointments.find(t => t._id === template);

        if(found_template) return found_template.name;

        return '';

    }

    onValidationConfirmed = (reason) => {

        this.setState({showValidation: false, validationConfirmed: true, reason}, () => {
            this.onSave()
        })

    }

    toggleConfirmation = () => {

        const appointment = Object.assign({}, this.state.appointment)

        _appointments.update(appointment._id, {confirmed: !appointment.confirmed})

        this.setState({appointment: {...appointment, confirmed: !appointment.confirmed}})


    }
    
    // manually mark appointment complete, this will not trigger any
    // step change events or show the modal to create another appointment
    finishAppointment = async (outcome) => {

        toggleStandardLoader(true)

        const action = await _appointments.finish(this.state.appointment._id, {
            outcome,
            action: 'delete',
            finished_by: this.props.viewing_user._id,
        })

        // this will also toggle the modal off
        if(action.success) this.props.onSuccess(action.data)

        toggleStandardLoader(false)

    }

    startAppointment = async (appointment) => {

        const redirect = encodeURIComponent(`/dashboard/call_center?startCall=true&appointment=${appointment._id}&inPerson=${appointment.in_person}`);
        this.setState({shouldRedirect: `/dashboard/full_redirect?redirect=${redirect}`})
    }

    findTemplate = (_id) => {

        const template_appointments = this.props.template_appointments.template_appointments;
        return template_appointments.find(t => t._id === _id);
    }

    // make sure expected time is valid as this used to be a manual entry before
    // being changed over to a select type field
    expectedTimeIsValid = (expected_time) => {

        if(!expected_time) return false;

        if(expected_time === 15) return true;
        if(expected_time === 30) return true;
        if(expected_time === 45) return true;
        if(expected_time === 60) return true;
        if(expected_time === 90) return true;
        if(expected_time === 120) return true;
        if(expected_time === 180) return true;
        if(expected_time === 240) return true;
        if(expected_time === 300) return true;
        if(expected_time === 360) return true;
        if(expected_time === 420) return true;
        if(expected_time === 480) return true;
        if(expected_time === 1440) return true;

        return false

    }

    onInputChange = (e, name) => {

        let appointment = Object.assign({}, this.state.appointment)
        const value = e.target.value;

        // if the start time is set and the end time is empty prefill the end time to be 30 minutes later
        if(name === 'time_start') {

            // set the time end to be the one specified for the appointment or 30 minutes
            let time_end = this.expectedTimeIsValid(appointment.expected_time) ? appointment.expected_time * 60 : 1800;

            this.setState({
                appointment: {
                     ...appointment,
                    time_start: parseInt(value),
                    time_end: parseInt(value) +  time_end,
                    time_startState: 'valid',
                    time_endState: 'valid',
                }
            })

        // if the type is changed remove all pre filled fields
        } else if(name === 'type') {

            // delete pre filled fields
            delete appointment.name
            delete appointment.nameState
            delete appointment.notify_client
            delete appointment.notify_clientState
            delete appointment.location
            delete appointment.locationState
            delete appointment.template_appointment
            delete appointment.template_appointmentState
            delete appointment.assigned_to
            delete appointment.assigned_toState

            appointment.type = value
            appointment.typeState = value ? 'valid' : 'invalid'

            this.setState({appointment})

        } else  {

            validator.onInputChange(value, 'appointment', name, this)

        }

    };

    onAssignContact = (contact) => {

        let appointment = Object.assign({}, this.state.appointment)
        appointment.contact = contact
        appointment.contactState = 'valid'

        const found_template = this.findTemplate(appointment.template_appointment)
        if(found_template) appointment.name =  capitalize( found_template.name + ' - ' + renderName(contact) );

        this.setState({appointment})
    }

    onRemoveContact = () => {
        let appointment = Object.assign({}, this.state.appointment)
        this.setState({appointment: {...appointment, contact: null} })
    }

    onAssignParty = (party, contact) => {
        const appointment = Object.assign({}, this.state.appointment)
        let parties = appointment.parties

        parties = parties.filter(p => p.title !== party)
        parties.push({contact, title: party})

        appointment.parties = parties

        this.setState({appointment})
    }

    onRemoveParty = (party) => {
        const appointment = Object.assign({}, this.state.appointment)
        let parties = appointment.parties

        parties = parties.filter(p => p.title !== party)
        appointment.parties = parties

        this.setState({appointment})
    }

    onAssignRole = (role, user) => {
        const appointment = Object.assign({}, this.state.appointment)
        let roles = appointment.roles

        roles = roles.filter(p => p.title !== role)
        roles.push({user, title: role})

        appointment.roles = roles

        this.setState({appointment})
    }

    onRemoveRole = (role) => {
        const appointment = Object.assign({}, this.state.appointment)
        let roles = appointment.roles

        roles = roles.filter(p => p.title !== role)
        appointment.roles = roles

        this.setState({appointment})
    }

    onAssignUser = (user) => {
        let appointment = Object.assign({}, this.state.appointment)
        // if the selected user is not already assigned add them
        if(!appointment.assigned_to.find(u => u._id === user._id)) {

            appointment.assigned_to.push(user);
            this.setState({appointment})

        }
    }

    onRemoveUser = (user) => {
        let appointment = Object.assign({}, this.state.appointment)
        const assigned_to = appointment.assigned_to.filter(u => u._id !== user._id);
        this.setState({appointment: {...appointment, assigned_to} })
    }

    onAssignCase = (_case) => {
        let appointment = Object.assign({}, this.state.appointment)
        this.setState({appointment: {...appointment, case: _case} })
    }

    onRemoveCase = () => {
        let appointment = Object.assign({}, this.state.appointment)
        this.setState({appointment: {...appointment, case: {}} })
    }

    onAssignLocationCourt = (location_court) => {
        let appointment = Object.assign({}, this.state.appointment)
        this.setState({appointment: {...appointment, location_court} })
    }

    onRemoveLocationCourt = () => {
        let appointment = Object.assign({}, this.state.appointment)
        this.setState({appointment: {...appointment, location_court: {}} })
    }

    onAssignOffice = (office) => {
        let appointment = Object.assign({}, this.state.appointment)
        this.setState({appointment: {...appointment, office} })
    }

    onRemoveOffice = () => {
        let appointment = Object.assign({}, this.state.appointment)
        this.setState({appointment: {...appointment, office: {}} })
    }

    filterAppointmentTemplates = () => {

        const template_appointments = this.props.template_appointments.template_appointments;
        const type = this.state.appointment.type

        const templates_to_show = template_appointments.filter(t => t.type === type);

        let options = []

        if(templates_to_show.length) {
            templates_to_show.forEach(t => options.push({label: t.name, value: t._id}))
        }

        return options

    }

    setValuesFromTemplate = async (_id, contact) => {

        const found_template = this.findTemplate(_id)
        let appointment = Object.assign({}, this.state.appointment);

        if(!found_template) {
            return this.setState({
                appointment: {
                    template_appointment: undefined,
                    ...this.state.appointment
                }
            })
        }

        const foundContact = appointment.contact ? appointment.contact : contact ? contact : undefined

        // set name from contact and template or just template
        const name = foundContact ? capitalize( found_template.name + ' - ' + renderName(foundContact)) : found_template.name

        let _case = {};
        let parties = []
        let roles = []
        let required_parties = [];
        let required_roles = [];

        // if we have a case try to grab the case parties and details
        // from it so we can pre-fill the appointment
        if(this.props.case_id) {

            const values = await Promise.all([
                _cases.findById(this.props.case_id),
                _case_parties.find(`?filter=case__${this.props.case_id}`),
            ])

            _case = values[0] && values[0].data ? values[0].data : {}
            const foundParties = values[1] && values[1].data ? values[1].data : {}

            foundParties.forEach(party => {
                if(found_template.required_fields.includes(`party.${party.title}`)) {
                    parties.push({title: party.title, contact: party.contact})
                }
            })

            if(_case.roles && _case.roles.length) {
                _case.roles.forEach(role => {
                    if(found_template.required_fields.includes(`role.${role.role}`) && role.user) {
                        roles.push({title: role.role, user: role.user})
                    }
                })
            }

        }

        // for the template push all parties and roles
        // into their own required arrays
        found_template.required_fields.forEach(field => {

            if(field.includes('party.')) {
                field = field.split('.')[1]
                required_parties.push(field)
            }
            if(field.includes('role.')) {
                field = field.split('.')[1]
                required_roles.push(field)
            }
        })

        this.setState({
            appointment: {
                ...appointment,
                template_appointment        : found_template._id,
                case                        : _case ? _case : {},
                office                      : _case.office ? _case.office : {},
                location_court              : _case.location_court ? _case.location_court : {},
                required_fields             : found_template.required_fields ? found_template.required_fields : [],
                contact: foundContact,
                roles,
                parties,
                nameState                   : 'valid',
                template_appointmentState   : 'valid',
                ...found_template,
                name,
                _id                         : appointment._id ? appointment._id : undefined,
            },
            required_parties,
            required_roles
        })

    }

    userRequired = () => this.state.appointment.show === 'user' ? true : false

    contactRequired = () => {
        const a = this.state.appointment
        if(a.show !== 'company') return true
        if(a.required_fields && a.required_fields.includes('contact')) return true

        return false
    }

    caseRequired = () => {
        const r = this.state.appointment.required_fields
        return r && r.includes('case') ? true : false
    }

    officeRequired = () => {
        const r = this.state.appointment.required_fields
        return r && r.includes('office') ? true : false
    }

    locationCourtRequired = () => {
        const r = this.state.appointment.required_fields
        return r && r.includes('location_court') ? true : false
    }

    isEmpty = (field) => {
        const appointment = Object.assign({}, this.state.appointment)
        return appointment[field] && appointment[field]._id ? false : true
    }

    needsConfirmation = (appointment) => {
        const name = appointment.name.toLowerCase();
        const url = window.location.href;

        // if we are on the page to view a step no confirmatioon is needed
        if(url.includes('dashboard/cases/view') && url.includes('nav=2')) {
            return false;
        }

        if(
            name.includes('ic office') || 
            name.includes('ic phone') || 
            name.includes('mh office') || 
            name.includes('mh phone') || 
            name.includes('hydra office') || 
            name.includes('hydra phone')
        ) {
            if(!this.state.validationConfirmed) {
                return true
            }
        }

        return false
    }

    onSave = async () => {

        // assume no error
        this.setState({ dateError: null, contactNoPhoneError: false, isSaving: true, associationError: false })

        let action;

        // validate required fields are set
        const validateFields = validator.requiredFields('appointment', required_fields, this)
        if(!validateFields.success) return this.setState({isSaving: false})

        let appointment = Object.assign({}, this.state.appointment)

        // set in person to a boolean
        appointment.in_person = appointment.in_person === true || appointment.in_person === "true" ? true : false;

        let errors = 0

        // check we have an assigned user if this is required
        if(this.userRequired() && !appointment.assigned_to.length) {
            appointment.assigned_toState = 'invalid';
            errors++;
        }

        // validate that all required populations are met
        if(this.contactRequired() && this.isEmpty('contact')) errors++;
        if(this.officeRequired() && this.isEmpty('office')) errors++;
        if(this.locationCourtRequired() && this.isEmpty('location_court')) errors++;
        if(this.caseRequired() && this.isEmpty('case')) errors++;

        // make sure contact has a phone number if the appointment is for the call center
        if(appointment.show === 'call center' && appointment.contact) {
            if(!appointment.contact.phone && !appointment.contact.phone_2 && !appointment.contact.phone_3) {
                this.setState({contactNoPhoneError: true})
                errors++;
            }
        }

        // check we have all required parties set from the template
        this.state.required_parties.forEach(party => {
            if(!appointment.parties.find(p => p.title === party)) errors++
        })

        // check we have all required roles set from the template
        this.state.required_roles.forEach(role => {
            if(!appointment.roles.find(r =>  r.title === role)) errors++
        })

        // if we have errors pass them back
        if(errors) return this.setState({associationError: true, isSaving: false})

        // if dates are not formated right send it back
        const compiledDate = compileDateInputs(appointment)
        if(!compiledDate.success) return this.setState({dateError: compiledDate.message, isSaving: false })

        let location_court = undefined
        if(appointment && appointment.location_court && appointment.location_court._id) {
            location_court = appointment.location_court._id
        }

        let office = undefined
        if(appointment && appointment.office && appointment.office._id) {
            office = appointment.office._id
        }
        
        let _case = undefined
        if(appointment && appointment.case && appointment.case._id) {
            _case = appointment.case._id
        }

        const data = {
            ...appointment,
            ...compiledDate.data,
            location_court,
            office,
            case: _case,
            reason : this.state.reason,
            updated_by : this.props.viewing_user._id,
        }

        if(this.needsConfirmation(data)) {
            this.setState({isSaving: false})
            return this.toggleValidationModal();
        }

        if(this.props.showLoader) toggleStandardLoader(true)

        // we are finishing an appointment passed in
        if(this.props.finishing && data._id) {

            let finished_object = {
                outcome           : this.props.outcome,
                action            : this.props.action,
                finished_by       : this.props.viewing_user._id,
                new_appointment   : data,
            }

            action = await _appointments.finish(data._id, finished_object)

        } else {

            if(data._id) {
                action = await _appointments.update(data._id, data)
            } else {
                action = await _appointments.create(data)
            }

        }

        this.setState({isSaving: false})

        if(this.props.showLoader) toggleStandardLoader(false)

        if(action && action.success) {
            this.props.onSuccess(action.data)

            if(this.props.isStepChange) {
                _analytics.events.create({event: ANALYTIC_EVENTS.APPOINTMENT_STEP_CHANGE});
            } else {
                _analytics.events.create({event: ANALYTIC_EVENTS.APPOINTMENT_MANUAL});
            }

        } else {
            if(this.props.onError) this.props.onError(action)
        }

    }

    setData = async (props) => {

        const { appointment_id } = props

        let contact;

        // if contact id is passed in we are trying to pre fill with their data
        if(props.contact_id) {
            const foundContact = await  _contacts.findById(this.props.contact_id, true)
            if(foundContact.data) contact = foundContact.data
        }

        if(appointment_id && appointment_id !== 'new') {

            toggleStandardLoader(true);

            // try to find the appointment to view/update and if
            // not found redirect to all appointments
            let appointment = await _appointments.findById(appointment_id, true)
            if(!appointment.data && this.props.shouldRedirect !== false) {

                toggleStandardLoader();
                toggleAlertBS('info', `This appointment has been finished or was not found.`)
                return this.setState({shouldRedirect: '/dashboard/appointments/all'})
            }

            appointment = appointment.data

            let required_parties    = []
            let required_roles      = []
            let required_fields     = []

            // if we have a contact on the appointment use that above all else
            if(appointment.contact && appointment.contact._id) contact = appointment.contact

            // if we have required fields on the template appointment pass them into
            // their respected arrays
            if(appointment.template_appointment && appointment.template_appointment.required_fields) {

                required_fields = appointment.template_appointment.required_fields

                appointment.template_appointment.required_fields.forEach(field => {

                    if(field.includes('party.')) {
                        field = field.split('.')[1]
                        required_parties.push(field)
                    }
                    if(field.includes('role.')) {
                        field = field.split('.')[1]
                        required_roles.push(field)
                    }
                })
            }

            // decode the dates back into a format that date picker can understand
            const dates = decodeDateInputs(appointment)

            this.setState({
                loaded: true,
                appointment: {
                    ...appointment,
                    contact,
                    required_fields,
                    date          : dates.date,
                    time_start    : dates.time_start,
                    time_end      : dates.time_end,
                },
                required_parties,
                required_roles,
            })

            toggleStandardLoader(false);

        } else {

            // if we have a start date pre-fill it here
            let dates = props.startDate ? decodeUnixDate(props.startDate) : {}

            this.setState({ loaded: true, appointment: {...this.state.appointment, ...dates, contact} }, async () => {

                // try to get any template appointment that was passed in
                this.setValuesFromTemplate(this.props.template_appointment, contact)

            })

        }

    }

    componentDidMount = async () => {
       this.setData(this.props)
    }

    content = () => {

        if(this.state.shouldRedirect) return <Redirect to={this.state.shouldRedirect} />

        const { appointment, dateError, appointmentToDelete, contactNoPhoneError, associationError } = this.state
        const { required_parties, required_roles, showCompleteConfirmation, showValidation, isSaving } = this.state
        const { template_appointment } = this.props

        return (
            <div>

                {appointment.updated_by ? (
                    <div className="bg-secondary px-3 py-3 mb-3 pb-4">
                        <p className="text-sm text-whte mb-0">
                            <span style={{position: 'relative', top: 2}}>Last updated {moment.unix(appointment.created_at).format('MM/DD/YYYY h:mm A')} by: </span>{' '}
                            <span className="float-right">
                                <AvatarImage
                                    style={{width: 30, height: 30}}
                                    className="mr-3 z-depth-4"
                                    src={appointment.updated_by.avatar}
                                />
                                <b className="font-weight-bold text-capitalize">{renderName(appointment.updated_by)}</b>
                            </span>
                        </p>
                    </div>
                ) : null}

                {/* {appointment._id ? (
                    <p className="text-sm mb-0">{moment(appointment.date_full).format('MM/DD/YYYY - h:mm A')}</p>
                ) : null} */}

                {/* 
                    if template appointment is passed in the user has to create that type of appointment, don't let them change it  
                    here we also check the template was found to account for possible deleted template appointments
                */}
                {template_appointment && appointment.template_appointment && template_appointment === appointment.template_appointment._id ? null :  (
                    <div>
                        {/* <StandardFormGroup
                            obj={appointment}
                            objName="appointment"
                            onChange={(o, f, v) => this.onInputChange({target: { value: v} }, f)}
                            type="select"
                            field="type"
                            title="Who Is This Reminder For?"
                        >
                            <option></option>
                            <option value="attorney">Attorney</option>
                            <option value="client liaison">Client Liaison</option>
                            <option value="paralegal">Paralegal</option>
                        </StandardFormGroup> */}

                        <FormGroup>
                            <label className="form-control-label mb-0">What type of reminder is this?</label>
                            <ReactSelect
                                invalid={appointment.template_appointmentState === 'invalid'}
                                placeholder={appointment.template_appointment ? this.getTemplateName(appointment.template_appointment) : 'Fill in the option above'}
                                onChange={(values) => this.setValuesFromTemplate(values.value) }
                                options={appointment.type ? this.filterAppointmentTemplates() : [] }
                            />
                        </FormGroup>

                        {/* <StandardFormGroup
                            disabled={!appointment.type}
                            obj={appointment}
                            objName="appointment"
                            onChange={(o, f, v) => this.onInputChange({target: { value: v} }, f)}
                            type="select"
                            field="in_person"
                            title="Is this an in office appointment?"
                        >
                            <option value={false}>No</option>
                            <option value={true}>Yes</option>
                        </StandardFormGroup> */}

                        <hr />

                    </div>
                )}

                <Row>

                    <Col lg={6}>

                        <StandardFormGroup
                            obj={appointment}
                            objName="appointment"
                            onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                            field="name"
                            title="Reminder Name"
                        />

                        <StandardFormGroup
                            obj={appointment}
                            objName="appointment"
                            onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                            field="color"
                            type="select"
                            title="Color On Calendar"
                            style={{outline: appointment.color && appointment.color !== 'none' ? 'solid 4px ' + appointment.color : 'none'}}
                        >
                            <option value="none">None</option>
                            <option value="#5e72e4">Blue</option>
                            <option value="#5603ad">Indigo</option>
                            <option value="#800080">Purple</option>
                            <option value="#f3a4b5">Pink</option>
                            <option value="#f5365c">Red</option>
                            <option value="#fb6340">Orange</option>
                            <option value="#ffd600">Yellow</option>
                            <option value="#2dce89">Green</option>
                            <option value="#11cdef">Teal</option>
                            <option value="#2bffc6">Cyan</option>
                        </StandardFormGroup>

                    </Col>

                    <Col lg={6}>

                        <FormGroup>
                            <label className="form-control-label">Reminder Date</label>
                            <div
                                className={
                                    appointment.dateState === 'invalid' ? 'date-picker-wrapper invalid' :
                                    appointment.dateState === 'valid' ? 'date-picker-wrapper valid' : 'date-picker-wrapper'
                                }
                            >
                                <DatePicker
                                    selected={appointment.date}
                                    onChange={date => this.onInputChange({target: { value: date} }, 'date')}
                                />
                            </div>
                        </FormGroup>

                        <Row>

                            <Col lg={6}>
                                <StandardFormGroup
                                    onChange={(o, f, v) => this.onInputChange({target: { value: v} }, f)}
                                    obj={appointment}
                                    objName="appointment"
                                    field="time_start"
                                    type="select"
                                    title="Start Time"
                                    disabled={appointment.date ? false : true}
                                >
                                    <option></option>
                                    <MinuteOptions15
                                        date={appointment.date}
                                    />
                                </StandardFormGroup>
                            </Col>

                            <Col lg={6}>
                                <StandardFormGroup
                                    onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                                    obj={appointment}
                                    objName="appointment"
                                    field="time_end"
                                    type="select"
                                    title="End Time"
                                    disabled={appointment.date ? false : true}

                                >
                                    <option></option>
                                    <MinuteOptions15
                                        date={appointment.date}
                                    />
                                </StandardFormGroup>
                            </Col>

                        </Row>

                        {dateError ? <div className="alert alert-danger">{dateError}</div> : null}

                    </Col>

                </Row>

                <hr />

                <StandardFormGroup
                    onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                    obj={appointment}
                    objName="appointment"
                    field="notes"
                    type="textarea"
                    title="Notes (Optional)"
                />

                <hr />

                <Row>

                    <Contact
                        associationError={associationError}
                        appointment={appointment}
                        contactRequired={this.contactRequired}
                        onAssignContact={this.onAssignContact}
                        onRemoveContact={this.onRemoveContact}
                        contactNoPhoneError={contactNoPhoneError}
                    />

                    <Case
                        associationError={associationError}
                        appointment={appointment}
                        caseRequired={this.caseRequired}
                        onAssignCase={this.onAssignCase}
                        onRemoveCase={this.onRemoveCase}
                    />

                    <Users
                        associationError={associationError}
                        appointment={appointment}
                        userRequired={this.userRequired}
                        onAssignUser={this.onAssignUser}
                        onRemoveUser={this.onRemoveUser}
                    />

                    <Office
                        associationError={associationError}
                        appointment={appointment}
                        officeRequired={this.officeRequired}
                        onAssignOffice={this.onAssignOffice}
                        onRemoveOffice={this.onRemoveOffice}
                    />

                    <LocationCourt
                      associationError={associationError}
                      appointment={appointment}
                      locationCourtRequired={this.locationCourtRequired}
                      onAssignLocationCourt={this.onAssignLocationCourt}
                      onRemoveLocationCourt={this.onRemoveLocationCourt}
                    />



                    {required_parties && required_parties.length ? required_parties.map((party, i) => (
                        <Parties
                            key={i}
                            party={party}
                            associationError={associationError}
                            appointment={appointment}
                            onAssignParty={this.onAssignParty}
                            onRemoveParty={this.onRemoveParty}
                        />
                    )) : null}

                    {required_roles && required_roles.length ? required_roles.map((role, i)=> (
                        <Roles
                            key={i}
                            role={role}
                            associationError={associationError}
                            appointment={appointment}
                            onAssignRole={this.onAssignRole}
                            onRemoveRole={this.onRemoveRole}
                        />
                    )) : null}

                </Row>

                <hr />

                <Row>

                    <Col xs={4}>
                        {appointment._id ? (
                            <button onClick={() => this.setState({appointmentToDelete: appointment})} className="btn btn-danger">Delete Appointment</button>
                        ) : null}
                    </Col>

                    <Col xs={8} className="text-right">

                        {appointment._id ? (
                            <button
                                onClick={this.toggleConfirmation}
                                className={appointment.confirmed ? "btn btn-warning" : "btn btn-success"}
                            >
                                {appointment.confirmed ? 'Un-Confirm' : 'Confirm'}
                            </button>
                        ) : null}
                        {appointment._id && appointment.show !== 'company' ? (
                            <>
                                <button
                                    onClick={this.toggleCompleteConfirmation}
                                    className="btn btn-outline-warning"
                                >
                                    Mark Finished
                                </button>

                                <button
                                    onClick={() => this.startAppointment(appointment)}
                                    className="btn btn-outline-success"
                                >
                                    Start Appointment
                                </button>
                            </>
                        ) : null}
                        <button disabled={isSaving} onClick={this.onSave} className="btn btn-success">Save Reminder</button>
                    </Col>

                </Row>

                {appointmentToDelete && (
                    <NotificationDelete
                        deletionURL={`/api/v1/core/appointments/delete/${appointmentToDelete._id}`}
                        confirmBtnText="Delete Appointment"
                        title={`${appointmentToDelete.name}`}
                        text="Deleting this appointment can not be undone. Proceed with caution."
                        onClose={() => this.setState({appointmentToDelete: null})}
                        onSuccess={this.props.onSuccess}
                    />
                )}

                <ModalMarkComplete
                    showModal={showCompleteConfirmation}
                    toggleModal={this.toggleCompleteConfirmation}
                    onConfirmation={(outcome) => this.finishAppointment(outcome)}
                    zIndex={100002}
                />

                <ModalValidation
                    showModal={showValidation}
                    toggleModal={this.toggleValidationModal}
                    onValidationConfirmed={this.onValidationConfirmed}
                />

            </div>
        )

    }

    render() {

        const { showAppointmentsCrud, toggleAppointmentsCrud, isModal, title } = this.props;
        const { shouldRedirect, loaded } = this.state

        if(shouldRedirect) return <Redirect to={shouldRedirect} />
        if(!loaded) return <div className="py-6"><Circle /></div>

        if(!isModal)  return this.content()

        return (
            <Modal
                className="modal-dialog-centered"
                isOpen={showAppointmentsCrud}
                size="lg"
                fade={false}
                zIndex={100001}
            >
                <div className="modal-header">
                    <h5 className="modal-title">{title ? title : 'Appointment'}</h5>
                    <button
                        aria-label="Close"
                        className="btn btn-outline-danger"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => toggleAppointmentsCrud()}
                    >
                        <span aria-hidden={true}><i className="fas fa-times mr-2 " /> Cancel</span>
                    </button>
                </div>

                <div className="modal-body">
                    {this.content()}
                </div>
            </Modal>
        )

    }
}

const mapStateToProps = state => {

    return {
        template_appointments: state.template_appointments,
        location_courts: state.location_courts.location_courts,
        viewing_user: state.auth.viewing_user
    };
};

AppointmentsCrud.propTypes = {

    //optional
    title                   : PropTypes.string,
    appointment_id          : PropTypes.string,
    template_appointment    : PropTypes.string,
    case_id                 : PropTypes.string,
    contact_id              : PropTypes.string,

    outcome                 : PropTypes.string,
    action                  : PropTypes.string, // create, update, delete

    finishing               : PropTypes.bool,
    shouldRedirect          : PropTypes.bool,
    showAppointmentsCrud    : PropTypes.bool,
    isModal                 : PropTypes.bool,

    onSuccess               : PropTypes.func.isRequired,
    toggleAppointmentsCrud  : PropTypes.func,
    onError                 : PropTypes.func,
    showLoader              : PropTypes.bool,
    startDate               : PropTypes.number,

}

export default connect(mapStateToProps, '')(AppointmentsCrud);
