// import ReactSelect from 'components/functional/inputs/ReactSelect';
import PropTypes from 'prop-types'
import React from "react";
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Nav, NavItem, NavLink } from 'reactstrap'
import classnames from 'classnames';
class Navigation extends React.Component {

    state = {
        shouldRedirect: false,
    }


    onChange = (val) => {

        const _case = this.props.case;

        this.setState({navPills: val})
        if(val === 1 || val === 2 || val === 3 || val === 16 || val === 10 || val === 9 || val === 17 || val === 20) return this.props.setNav(val)
        if(val === 11) return this.setState({shouldRedirect: `/dashboard/contacts/view/${_case.contact._id}`})
        if(val === 15) return this.setState({shouldRedirect: `/dashboard/contacts/view/${_case.contact._id}?nav=2`})
    }

    render() {

        if(this.state.shouldRedirect) { return <Redirect to={this.state.shouldRedirect} /> }

        const navPills = this.props.navPills

        return (

            <div className="pb-3">
                {/* <ReactSelect
                    defaultValue={
                        navPills === 2 ? {value: 2, label: "Tasks" } :
                        navPills === 3 ? {value: 3, label: "Documents" } :
                        navPills === 9 ? {value: 9, label: "Details" } :
                        {value: 1, label: 'Overview' }
                    }
                    onChange={(values) => this.onChange(values.value)}
                    options={[
                        {value: 1, label: 'Overview' },
                        {value: 9, label: "Details" },
                        {value: 20, label: 'Checklist' },
                        {value: 3, label: "Documents" },
                        {value: 2, label: "Tasks" },
                        // {value: 10, label: "Onboarding Forms" },
                        {value: 11, label: "Contact File" },
                    ]}
                /> */}
                <Nav
                    className="nav-fill flex-column flex-sm-row"
                    id="tabs-text"
                    pills
                    role="tablist"
                >
                    <NavItem>
                        <NavLink
                            aria-selected={navPills === 1}
                            className={classnames("mb-sm-3 mb-md-0", {
                                active: navPills === 1
                            })}
                            onClick={e => this.onChange(1)}
                            href="#pablo"
                            role="tab"
                        >
                            Overview
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            aria-selected={navPills === 3}
                            className={classnames("mb-sm-3 mb-md-0", {
                                active: navPills === 3
                            })}
                            onClick={e => this.onChange(3)}
                            href="#pablo"
                            role="tab"
                        >
                            Documents
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            aria-selected={navPills === 9}
                            className={classnames("mb-sm-3 mb-md-0", {
                                active: navPills === 9
                            })}
                            onClick={e => this.onChange(9)}
                            href="#pablo"
                            role="tab"
                        >
                            Details
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            aria-selected={navPills === 2}
                            className={classnames("mb-sm-3 mb-md-0", {
                                active: navPills === 2
                            })}
                            onClick={e => this.onChange(2)}
                            href="#pablo"
                            role="tab"
                        >
                            Tasks
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            aria-selected={navPills === 20}
                            className={classnames("mb-sm-3 mb-md-0", {
                                active: navPills === 20
                            })}
                            onClick={e => this.onChange(20)}
                            href="#pablo"
                            role="tab"
                        >
                            Checklist
                        </NavLink>
                    </NavItem>
                  
                  
                    <NavItem>

                        <NavLink
                            aria-selected={navPills === 11}
                            className={classnames("mb-sm-3 mb-md-0", {
                                active: navPills === 11
                            })}
                            onClick={e => {}}
                            href={`/dashboard/contacts/view/${this.props.case.contact._id}`}
                            target="_blank"
                            role="tab"
                        >
                            Contact File
                        </NavLink>

                    </NavItem>
                </Nav>
            </div>

        );
    }
}

const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,
    };
};

Navigation.propTypes = {
    navPills    : PropTypes.number.isRequired,
    setNav      : PropTypes.func.isRequired,
    case        : PropTypes.object.isRequired,
}

export default connect(mapStateToProps, '')(Navigation);
