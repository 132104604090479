export const capitalize = (text) => {

    if(!text) return undefined

    try {

        let string

        const split = text.split(' ')

        split.forEach(s => {
            s = s.replace(/^./, s[0].toUpperCase());
            string = string ? string + ' ' + s : s
        })

        return string

   } catch(e) {

        console.log(e)
        return text

   }

}

// formats a phone as stored in the database (+15555555555) as (555) 555-5555
export const formatPhone = (phone) => {

    if(!phone) return undefined
    if(typeof phone !== 'string' && typeof phone !== 'number') return undefined

    if(typeof phone === 'number') phone =  '+' + phone.toString()

    if(phone.length !== 12) return phone



    let string1 = phone.slice(2,5)
    let string2 = phone.slice(5, 8)
    let string3 = phone.slice(8, 12)

    return `(${string1}) ${string2}-${string3}`

}

export const addSpaceBeforeCapital = (string) => {
    if(!string) return '';
    return string.replace(/([A-Z])/g, ' $1').trim()
}