
import apiWorker from '../apiWorker'

const users = {

    find:         (query)        => apiWorker('get',    `/api/v1/core/users/find${query}`),
    findById:     (_id, hideErr) => apiWorker('get',    `/api/v1/core/users/find/_id/${_id}`, null, hideErr),
    findByPhone:  (phone,)       => apiWorker('get',    `/api/v1/core/users/find/phone/${phone}`),
    findByEmail:  (email)        => apiWorker('get',    `/api/v1/core/users/find/email/${email}`),

    update:       (_id, data)    => apiWorker('patch',  `/api/v1/core/users/update/${_id}`, data),
    delete:       (_id)          => apiWorker('delete', `/api/v1/core/users/delete/${_id}`),
    create:       (data)         => apiWorker('post',   `/api/v1/core/users/create/`, data),

}

export default users;