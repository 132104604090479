/*
Documentation

This page shows a list of all cases
via api cases can be searched by case name or _id

*/

import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import { useState } from 'react';
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Card, CardHeader, CardTitle, Container, Row, Col, CardBody, CardFooter, FormGroup, Input } from "reactstrap";

import keys from 'keys';
import renderName from 'utils/renderName';
import { toggleAlertBS } from 'store/functions/system/system';

const CopySource = ({l}) => {

    const [copied, setCopied] = useState(false)

    const onSetCopied = () => {
        setCopied(true)
        setTimeout(() => {
            setCopied(false)
        }, 1000)
    }

    return (
        <CopyToClipboard
            text={`${keys.BOOK_URL}?l=${l._id}`}
            onCopy={() => {
                onSetCopied()
            }}
        >
            <div>
                {copied ? 'COPIED! ' : ''}
                <i className="fas fa-clipboard mr-3 text-success cursor-pointer" />
                {keys.BOOK_URL}?l={l._id}
            </div>
        </CopyToClipboard>
    )

}

const CopyUser = ({l}) => {

    const [copied, setCopied] = useState(false)

    const onSetCopied = () => {
        setCopied(true)
        setTimeout(() => {
            setCopied(false)
        }, 1000)
    }

    return (
        <CopyToClipboard
            text={`${keys.BOOK_URL}?u=${l._id}`}
            onCopy={() => {
                onSetCopied()
            }}
        >
            <div>
                {copied ? 'COPIED! ' : ''}
                <i className="fas fa-clipboard mr-3 text-success cursor-pointer" />
                {keys.BOOK_URL}?u={l._id}
            </div>
        </CopyToClipboard>
    )

}

const Directory = ({lead_sources, users}) => {

    const [user, setUser] = useState('false')
    const [source, setSource] = useState('false')

    const onGenerate = () => {
        if(user !== 'false' && source !== 'false') {
            toggleAlertBS(false, `${keys.BOOK_URL}?l=${source}&u=${user}`)
            setUser('false')
            setSource('false')
        } else {
            toggleAlertBS(true, 'Please select both a user and lead source')
        }
    }

    return (
        <Container fluid>

            <Helmet>
                <title>{`Directory`}</title>
                <meta name="description" content="Directory" />
            </Helmet>

            <DashHeaderOpen
                icon="fas fa-home"
                icon_link="/analytics"
                title={<span >Directory</span>} breadcrumb_1="All"
            />

            <Card className="card-color card-primary">

                <CardHeader>
                    <CardTitle className="mb-0">User Directory</CardTitle>
                    <p className="text-sm mb-0"><i className="fas fa-info-circle mr-2 text-info-original " /> Use the user directory to get scheduling links that will only book consultations with the user specified.</p>
                </CardHeader>

                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>User</th>
                                <th className="text-right">URL</th>
                            </tr>
                        </thead>
                        <tbody>
                            {console.log(users)}
                            {users.map(l => (
                                !l.privileges.includes('can_give_attorney_consultation') ? '' : 
                                <tr key={l._id}>
                                    <td className="text-capitalize">{renderName(l)}</td>
                                    <td className="text-right">
                                        <CopyUser l={l} />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>    
            </Card>

            <Card>

                <CardHeader>
                    <CardTitle className="mb-0">Source Directory</CardTitle>
                    <p className="text-sm mb-0"><i className="fas fa-info-circle mr-2 text-info-original " />  Use the source directory to get scheduling links that will associated leads with the lead source specified but will schedule consultations across multiple users.</p>
                </CardHeader>

                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Source</th>
                                <th className="text-right">URL</th>
                            </tr>
                        </thead>
                        <tbody>
                            {lead_sources.map(l => (
                                <tr key={l._id}>
                                    <td>{l.name}</td>
                                    <td className="text-right">
                                        <CopySource l={l} />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
             
            </Card>
           
            <Card>

                <CardHeader>
                    <CardTitle className="mb-0">Source & User Link Generation</CardTitle>
                    <p className="text-sm mb-0"><i className="fas fa-info-circle mr-2 text-info-original " />  Use the tool below to generate a link that will only book with a specified user and will associate any leads that book a consultation with the link to a specific lead source.</p>
                </CardHeader>

               <CardBody>
                <Row>
                    <Col lg={6}>
                        <FormGroup>
                            <label className="form-control-label">Select User</label>
                            <Input
                                type="select"
                                value={user}
                                onChange={(e) => setUser(e.target.value)}
                            >
                                <option value="false"></option>
                                {users.map(l => (
                                    <option key={l._id} value={l._id}>{renderName(l)}</option>
                                ))}
                            </Input>
                        </FormGroup>
                    </Col>
                    <Col lg={6}>
                        <FormGroup>
                            <label className="form-control-label">Select Lead Source</label>
                            <Input
                                type="select"
                                value={source}
                                onChange={(e) => setSource(e.target.value)}
                            >
                                <option value="false"></option>
                                {lead_sources.map(l => (
                                    <option key={l._id} value={l._id}>{l.name}</option>
                                ))}
                            </Input>
                        </FormGroup>
                    </Col>

                </Row>
               </CardBody>

               <CardFooter>
                    <button onClick={onGenerate} className="btn btn-success">Generate Link</button>
               </CardFooter>
             
            </Card>

        </Container>

    )
}


const mapStateToProps = state => {
    return {
        lead_sources: state.lead_sources.lead_sources,
        users: state.users.users,
    };
};

export default connect(mapStateToProps, '')(Directory);
