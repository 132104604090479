/*
Documentation

this files renders tasks and step moving capabilities

*/

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import StepChange from './components/StepChange';
import TasksFinished from './components/TasksFinished';
import TasksUnfinished from './components/TasksUnfinished';
import TasksCompleteBeforeMove from './components/TasksCompleteBeforeMove';
import InitialMove from './components/InitialMove';
import History from './components/History';
import _case_workflows from '_functions/case_workflows'
import { io } from 'sockets';
import * as socketEvents from 'sockets/events';

import * as privileges  from '_functions/users/privileges'


class Tasks extends Component {

    state = {
        showCompleteAfterTasks: false,
        isMoving: false,
        complete_before_move: []
    };

    listenForCaseWorkflowTasksUpdated = (data) => {

        let complete_before_move = [...this.state.complete_before_move]

        if(complete_before_move.length && data.data.finished) {

            complete_before_move = complete_before_move.filter(c => c._id !== data.data._id)
            this.setState({complete_before_move})

        }
    }

    toggleIsMoving = async (movingToStep) => {

        if(movingToStep && movingToStep._id  ) {

            const complete_before_move = await _case_workflows.getStepsOnMove(movingToStep._id)
            if(complete_before_move) this.setState({complete_before_move: complete_before_move.data})

        }

        // toggle loader, then fetch complete_before_move for the step trying to move to
        this.setState({isMoving: !this.state.isMoving})

    }

    componentWillUnmount = () => {
        io.off(socketEvents.case_workflow_tasks_updated,  this.listenForCaseWorkflowTasksUpdated)
    }

    componentDidMount = () => {
        io.on(socketEvents.case_workflow_tasks_updated,  this.listenForCaseWorkflowTasksUpdated)
    }

    render() {

        if(!this.props.case_workflow || !this.props.case) return <div />

        const { isMoving } = this.state

        const case_workflow   = Object.assign({}, this.props.case_workflow)
        const _case           = Object.assign({}, this.props.case);
        const { clickedStep, forceRefresh, getCase, navPills }     = this.props;
        const step_to_show    = this.props.step_to_show
        const current_step    = case_workflow.current_step

        const { tasks_unfinished, tasks_finished, tasks_persistent } = case_workflow
        const { complete_before_move } = this.state

        return (

            <div className="tasks-view">

                {!_case.finished_by ? (
                    <div>

                        <StepChange
                            case={_case}
                            case_workflow={case_workflow}
                            step_to_show={step_to_show}
                            isMoving={isMoving}
                            toggleIsMoving={this.toggleIsMoving}
                            hasMovingTasksOpen={complete_before_move.length}
                            clickedStep={clickedStep}
                            forceRefresh={forceRefresh}
                        />

                        {isMoving ? (
                            complete_before_move.length ? (
                                <TasksCompleteBeforeMove
                                    case={_case}
                                    case_workflow={case_workflow}
                                    complete_before_move={complete_before_move}
                                    forceRefresh={forceRefresh}
                                />
                            ) : null
                        ) : null }

                        <TasksUnfinished
                            current_step={current_step}
                            case={_case}
                            case_workflow={case_workflow}
                            tasks_unfinished={tasks_unfinished}
                            tasks_persistent={tasks_persistent}
                            step_to_show={step_to_show}
                            forceRefresh={forceRefresh}
                        />

                        <TasksFinished
                            tasks_finished={tasks_finished}
                        />

                    </div>
                ) : null}

                <History
                    case={_case}
                />

                {privileges.canJumpSteps() ? (
                    <InitialMove 
                        case={_case}
                        case_workflow={case_workflow}
                        forceRefresh={forceRefresh}
                    />
                ) : null}
               

            </div>

        )

    }

}

Tasks.propTypes = {
    case: PropTypes.object.isRequired,
    case_workflow: PropTypes.object.isRequired,
    step_to_show: PropTypes.string.isRequired,
}

export default Tasks
