/*
Documentation

this is the wrapping component for the dashboard routes
it should check to see if a user is logged in
if not kick them out the /auth/login

it will also load any data needed for the dashboard to work
before rendering its routes

*/


import React from "react";
import { connect } from 'react-redux';
import {  Redirect, Route, Switch } from "react-router-dom";
import keys from 'keys';
import stackLogger from 'database/stackLogger';

// import HelpCenter from 'components/system/HelpCenter'
import RecentActivity from 'components/system/RecentActivity'
import Notifications from "./components/Notifications";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import routes from "./routes";

import monitorActivity from './functions/monitorActivity'
import recordScreen from './functions/recordScreen'
import load from 'store/functions/load'

import { toggleLoader } from 'store/functions/system/system';

// import CallAlerts from './components/CallAlerts'
import AcceptTransfer from './components/AcceptTransfer'

import { checkNylas } from 'store/functions/system/system'

class Dashboard extends React.Component {
    state = {
        loaded: false,
    };

    //get routes for this layout
    getRoutes = routes => routes().map((prop, key) => {

        return (
            <Route
                exact path={`/dashboard${prop.path}`}
                component={prop.component}
                key={key}
            />
        )

    })

    componentDidCatch = (e, info) => {

        console.log(e)
        console.log(info)

        stackLogger(e);
        
        //don't redirect if we are developing
        if(keys.env !== 'dev') {
            this.setState({shouldRedirect: `/errors/standard?error=${e}&referrerurl=${window.location.href}`})
        }

    }

    componentWillReceiveProps = (nextProps) => {

        // any time the location changes base url scroll to top of the page
        const lastLocation = this.state.lastLocation
        const currentLocation = nextProps.location.pathname

        if(currentLocation !== lastLocation) {
            window.scrollTo({top: 0, left: 0})
        }

        this.setState({lastLocation: currentLocation})
    }

    componentWillUnmount = () => {
        toggleLoader(false);
    }

    componentDidMount = async () => {

        if(window.innerWidth < 1000) {
            document.body.classList.remove('g-sidenav-show')
            document.body.classList.remove('g-sidenav-pinned')
            document.body.classList.add('g-sidenav-hidden')
        }

        toggleLoader(true);

        const { viewing_user } = this.props

        await load.dashboard(`?user_id=${viewing_user._id}&company_id=${viewing_user.company}`);
        checkNylas();

        toggleLoader(false);
        this.setState({loaded: true})

        monitorActivity(viewing_user._id)
        recordScreen(viewing_user._id)

    }

    render() {

        if(this.state.shouldRedirect) { return <Redirect to={this.state.shouldRedirect} /> }
        if(!this.state.loaded) { return <div /> }

        return (
            <>
            

                <RecentActivity />
                <Notifications />

                <div className="wave" />

                <div className="main-content" ref="mainContent" onClick={this.closeSidenav} >


                    <Navbar {...this.props} />

                    {/* <CallAlerts /> */}
                    <AcceptTransfer />

                    <Switch>
                        {this.getRoutes(routes)}
                        <Redirect from="*" to="/dashboard/call_center" />
                    </Switch>

                    <Footer />

                </div>

            </>

        );
    }
}

const mapStateToProps = state => {
	return {
	  viewing_user: state.auth.viewing_user,
	};
};

export default connect(mapStateToProps, '')(Dashboard);
