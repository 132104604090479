import { UncontrolledTooltip } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';

const TableIcons = ({icons}) => (
    <div className="table-icons">
    {icons.map((icon, i) => {
        const id = 'x' + uuidv4();
        const tooltip = icon.tooltip ? (
            <UncontrolledTooltip
                delay={0}
                placement="top"
                target={id}
            >
                {icon.tooltip}
            </UncontrolledTooltip>
        ) : ''
        return (
            <span className="table-icon-wrapper">
                {icon.wrapper ? (
                    <icon.wrapper {...icon}>
                        <span id={id} className={`table-icon table-icon-${icon.color} ${icon.disabled ? 'table-icon-disabled' : ''}`}>
                            <i className={icon.icon} />
                        </span>
                    </icon.wrapper>
                ) : (
                    <span id={id} onClick={icon.onClick ? icon.onClick : () => {}} className={`table-icon table-icon-${icon.color} ${icon.disabled ? 'table-icon-disabled' : ''}`}>
                        <i className={icon.icon} />
                    </span>
                )}
                {icon.disabled ? '' : tooltip}
            </span>        
        )
    })}
</div>
)

export default TableIcons