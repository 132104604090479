import apiWorker from '../apiWorker'

const wikis = {

    create    : (data)      => apiWorker('post',   `/api/v1/core/wikis`, data, true),
    delete    : (_id)       => apiWorker('delete', `/api/v1/core/wikis/${_id}`, null, true),
    update    : (_id, data) => apiWorker('patch',  `/api/v1/core/wikis/${_id}`, data, true),
    findById  : (_id)       => apiWorker('get',  `/api/v1/core/wikis/${_id}`, null, true),
    search    : (data)      => apiWorker('post',  `/api/v1/core/wikis/search`, data, true),

}

export default wikis;