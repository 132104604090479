/*
Documentation

This is the top half of the navbar on the dashboard layout

*/

import keys from 'keys';
import React from "react";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Badge, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { simulateClickOfId } from 'utils/events';
import renderName from 'utils/renderName';
import { formatPhone } from 'utils/text';
import * as privileges from '_functions/users/privileges';

class UserDropdown extends React.Component {

    render() {

        const { viewing_user } = this.props
        let { my_tasks, my_messaging_center, my_appointments } = this.props

        if(my_tasks > 99) my_tasks = '99+'
        if(my_messaging_center > 99) my_messaging_center = '99+'
        if(my_appointments > 99) my_appointments = '99+'


        return (

            <UncontrolledDropdown className="nav-dropdown"  nav>

                <DropdownToggle className="nav-link " color="" tag="a">
                    <i id="archk-nav-master-toggler"  className="right-icon fas fa-plus text-white " />
                </DropdownToggle>

                <DropdownMenu className="card-navigation dropdown-menu-lg dropdown-menu-dark nav-master-menu" style={{maxHeight: '85vh', overflow: 'auto'}} right >

                    <i className="fas fa-times close" onClick={() => simulateClickOfId('archk-nav-master-toggler')} />

                    <div className="bg-dark text-center py-4">
                        <img className="w-50" src={keys.LOGO} alt="..." />
                        <h4 className="text-capitalize text-white mb-1 mt-3">{renderName(viewing_user)}</h4>
                        <h5 className="mb-0 mt-0 text-muted">{viewing_user.email}</h5>
                        <h5 className="mb-0 mt-0 text-muted">{formatPhone(viewing_user.phone)}</h5>
                    </div>

                    <ul>

                        <li onClick={() => simulateClickOfId('archk-nav-master-toggler')}>
                            <Link className="text-dark" to="/dashboard/tasks/user">
                                <i className="fas fa-tasks mr-2"></i> My Task List
                                <Badge
                                    className="float-right "
                                    color={typeof my_tasks === 'string' ? 'danger' : my_tasks === 0 ? 'success' : 'warning'}
                                >
                                    {my_tasks}
                                </Badge>
                            </Link>
                        </li>
                       
                        <li onClick={() => simulateClickOfId('archk-nav-master-toggler')}>
                            <Link className="text-dark" to="/dashboard/messenger">
                                <i className="fas fa-comments mr-2" /> Messenger
                                <Badge
                                    className="float-right "
                                    color={typeof my_messaging_center === 'string' ? 'danger' : my_messaging_center === 0 ? 'success' : 'warning'}
                                >
                                    {my_messaging_center}
                                </Badge>

                            </Link>
                        </li>

                        <li onClick={() => simulateClickOfId('archk-nav-master-toggler')}>
                            <Link className="text-dark" to="/dashboard/appointments/view/new">
                                <i className="fas fa-calendar mr-2" /> Appointments
                                <Badge
                                    className="float-right "
                                    color={typeof my_appointments === 'string' ? 'danger' : my_appointments === 0 ? 'success' : 'warning'}
                                >
                                    {my_appointments}
                                </Badge>
                            </Link>
                        </li>

                        {privileges.canViewCases() && (
                            <li onClick={() => simulateClickOfId('archk-nav-master-toggler')}>
                                <Link className="text-dark" to="/dashboard/cases/create">
                                    <i className="fas fa-gavel mr-2" /> Cases
                                </Link>
                            </li>
                        )}

                        <li onClick={() => simulateClickOfId('archk-nav-master-toggler')}>
                            <Link className="text-dark" to="/dashboard/contacts/create">
                                <i className="fas fa-users mr-2" /> Contacts
                            </Link>
                        </li>
                        <li onClick={() => simulateClickOfId('archk-nav-master-toggler')}>
                            <Link className="text-dark" to="/dashboard/consultations/create">
                                <i className="fas fa-calendar mr-2" /> Consultation
                            </Link>
                        </li>
                        <li onClick={() => simulateClickOfId('archk-nav-master-toggler')}>
                            <Link className="text-dark" to="/dashboard/me">
                                <i className="fas fa-calendar mr-2" /> Queue & Consults
                            </Link>
                        </li>

                        {/* <li onClick={() => simulateClickOfId('archk-nav-master-toggler')}>
                            <Link className="text-dark" to="/dashboard/team/stats">
                                <i className="fas fa-chart-line mr-2" />Team Stats
                            </Link>
                        </li> */}
                      
                    </ul>


                </DropdownMenu>

            </UncontrolledDropdown>

        )

    }

}


const mapStateToProps = state => {
    return {
      viewing_user: state.auth.viewing_user,
    };
};

export default connect(mapStateToProps, '')(UserDropdown);
