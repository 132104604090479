

import apiWorker from '../apiWorker'

const questionnaire_answers = {
    save : (
        questionnaire_id, 
        contact_id, 
        case_id,
        data
    ) => apiWorker('post',   `/api/v1/core/questionnaire_answers/${questionnaire_id}/contact/${contact_id}?case_id=${case_id}&internal=true`, data, true),

    findByContact : (
        questionnaire_id, 
        contact_id
    ) => apiWorker('get',   `/api/v1/core/questionnaire_answers/${questionnaire_id}/contact/${contact_id}`, null, true),

}

export default questionnaire_answers