import { useEffect, useState } from 'react';

import { Card, CardHeader, CardBody, CardTitle, Row, Col, FormGroup, Input } from 'reactstrap';

import _cases from '_functions/cases';

import Circle from 'components/markup/loading/Circle'
import A from 'components/markup/links/A'

import { toggleStandardLoader, toggleAlertBS } from 'store/functions/system/system';
toggleStandardLoader(false);
toggleAlertBS(false, '');

const Packages = (props) => {

    const _case = props.case

    const [packages, setPackages] = useState(null);
    const [link, setLink] = useState(_case.final_package_link ? _case.final_package_link : '')

    const onSaveFinalPackage = async () => {
        if(!link || link === 'false') return;

        toggleStandardLoader(true);
        const updated = await _cases.update(_case._id, { final_package_link: link })
        toggleStandardLoader(false);

        if(updated.success) {
            toggleAlertBS(false, 'Final Report Link Updated Successfully');
        } else {
            toggleAlertBS(true, `Something went wrong, please try again.`);
        }

    }

    useEffect(() => {
        const get = async () => {
            const data = await _cases.getPackages(_case._id)
            setPackages(data.data ? data.data : [])
        }
        get()
    }, [_case._id])

    const packageLink = link && link !== false && packages ? packages.find(p => p.url === link) : ''

    return (

        <Card>
            <CardHeader>
                <CardTitle className="mb-0">Final Package</CardTitle>
                <p className="text-sm mb-0">The final package selected below will be shown to clients to download and sign within the client portal.</p>
            </CardHeader>
            
            {!packages ? <Circle /> : packages.length ? (
                <CardBody>
                    <Row>
                        <Col >
                            <FormGroup>
                                <Input 
                                    type="select"
                                    value={link}
                                    onChange={(e) => setLink(e.target.value)}
                                >
                                    <option value="false"></option>
                                    {packages.map(p => (
                                        <option key={p.id} value={p.url}>{p.label}</option>
                                    ))}
                                </Input>
                            </FormGroup>

                            {packageLink ? (
                                <p className="text-sm mb-0"><A href={packageLink.url}>{packageLink.url}</A></p>
                            ) : ''}
                        </Col>

                        <div className="col-auto"  style={{width: 190}}>
                            <button disabled={!link || link === 'false'} onClick={onSaveFinalPackage} className="btn btn-success btn-block" style={{paddingTop: 7, paddingBottom: 7}}>Save Link</button>
                        </div>
                    </Row>
                </CardBody>
            ) : (
                <CardBody>
                    <p className="text-sm mb-0">No Final Packages Were Found For This Case.</p>
                </CardBody>
            )}

        </Card>
    )
}

export default Packages;