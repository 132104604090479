/*
Documentation

Renders the keypad users can use to dial out specific numbers

*/

import PropTypes from 'prop-types'
import React, { Component } from 'react';
import { connect } from 'react-redux';
import renderName from 'utils/renderName';
import _contacts from '_functions/contacts';
import { Input } from 'reactstrap'
import { formatPhone } from 'utils/text'

import * as ANALYTIC_EVENTS from '_settings/analytic_events';

class Keypad extends Component {

    state = {
        number: ''
    };

    buttonClicked = false;

    createCall = async (contact) => {

        if(this.buttonClicked === false) {

            // prevent double clicks
            this.buttonClicked = true;
            setTimeout(() => { this.buttonClicked = false }, 300)

            this.props.setOnCall({ user: this.props.viewing_user._id, phone: '+1' + this.state.number }, ANALYTIC_EVENTS.DIAL_MANUAL);
            
        }

    }

    // removes number by button click
    removeNumber = () => {

        let number = this.state.number;

        if(!number || number.length <= 1) {
            number = null
        } else {
            number = number.slice(0, number.length - 1)
        }

        this.setState({number})

    }

    // adds number by button click
    addNumber = async (number) => {

        let newNumber = this.state.number ? this.state.number + number : number
        this.setState({number: newNumber})

        let name;

        newNumber = '+1' + newNumber

        if(newNumber && newNumber.length === 12) {

            const foundContact = await _contacts.findByPhone(newNumber)

            name = renderName(foundContact.data)

            if(name === 'Not Found') {
                name = 'Unknown Contact'
            }

        } else  {

            name = null

        }

        this.setState({name})

    }

    // shows the formatted number, returns a string
    renderNumber = (number) => {

        if(!number) return ''

        const length = number.length

        let toSet;

        let string1
        let string2
        let string3

        if(length <= 3) {

            toSet = `(${number})`

        } else if(length <= 6) {

            string1 = number.slice(0, 3)
            string2 = number.slice(3, number.length)

            toSet = `(${string1}) ${string2}`

        } else {

            string1 = number.slice(0, 3)
            string2 = number.slice(3, 6)
            string3 = number.slice(6, number.length)

            toSet = `(${string1}) ${string2}-${string3}`

        }

        toSet = '+1 ' +  toSet



        return toSet

    }

    // handles typing into the input
    onKeypadChange = (e) => {

        let number = e.target.value;

        if(number && number.includes('(') && !number.includes(')')) number = number.slice(0, number.length - 1)
        if(number && number.slice(0,3) === '+1 ') number = number.slice(2, number.length)

        if(number)  number = number.replace(/[^\d.-]/g, '').replace(/-/g, '')

        if(number && number.length > 10) {
            // number = number.slice(0, 10)
        }

        this.setState({number})

    }

    // when we paste try to format what was pasted in to be a real phone number
    onPaste = (e) => {

        e.preventDefault()

        let text = e.clipboardData.getData('Text')
        if(text) { 

            text = text.replace(/\D/g,'');

            if(text.length === 11 && text.slice(0,1) === '1') {
                this.onKeypadChange({target: { value: formatPhone(text.slice(1, text.length))} })
            } else {
                this.onKeypadChange({target: { value: formatPhone(text)} })
            }

        }

    }

    render() {

        const { number, name } = this.state
        const { toggleKeypad } = this.props

        return (

            <div className="keypad">

                <div className="keypad-background" onClick={toggleKeypad} />

                <div className="keypad-content rounded">

                    <div className="header">
                        <Input
                            id="cc-keypad-input"
                            type="text"
                            onPaste={this.onPaste}
                            placeholder="Phone Number"
                            onChange={(e) => this.onKeypadChange(e)}
                            value={this.renderNumber(number)}
                            style={{ textAlign: 'center', fontSize: '1.6rem', border: 'none', }}
                        />
                        {name ? <p className="text-sm mb-0 text-capitalize">{name}</p> : null}
                    </div>

                    <span className="block first" onClick={() => this.addNumber('1')}>
                        1 <span className="letters placeholder">-</span>
                    </span>

                    <span className="block second" onClick={() => this.addNumber('2')}>
                        2 <span className="letters">ABC</span>
                    </span>

                    <span className="block third" onClick={() => this.addNumber('3')}>
                        3 <span className="letters">DEF</span>
                    </span>

                    <span className="block first" onClick={() => this.addNumber('4')}>
                        4 <span className="letters">GHI</span>
                    </span>

                    <span className="block second" onClick={() => this.addNumber('5')}>
                        5 <span className="letters">JKL</span>
                    </span>

                    <span className="block third" onClick={() => this.addNumber('6')}>
                        6 <span className="letters">MNO</span>
                    </span>

                    <span className="block first" onClick={() => this.addNumber('7')}>
                        7 <span className="letters">PQRS</span>
                    </span>

                    <span className="block second" onClick={() => this.addNumber('8')}>
                        8 <span className="letters">TUV</span>
                    </span>

                    <span className="block third" onClick={() => this.addNumber('9')}>
                        9 <span className="letters">WXYZ</span>
                    </span>

                    <span className="block first last">
                        * <span className="letters placeholder">-</span>
                    </span>

                    <span className="block second last" onClick={() => this.addNumber('0')}>
                        0 <span className="letters last">+</span>
                    </span>

                    <span className="block third last backspace" onClick={this.removeNumber}>
                        <i className="fas fa-backspace" /> <span className="letters placeholder">-</span>
                    </span>

                   {number && number.length >= 10 ? (
                        <button
                            onClick={number.length > 10 ? null : this.createCall}
                            disabled={number.length > 10}
                            className="btn btn-block btn-lg btn-success"
                        >
                            <i className="fas fa-phone" /> Call
                        </button>
                   ) : null}

                </div>
            </div>

        )

    }

}


const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,
    };
};

Keypad.propTypes = {
    setOnCall:      PropTypes.func.isRequired,
    toggleKeypad:   PropTypes.func.isRequired
}

export default connect(mapStateToProps, '')(Keypad);
