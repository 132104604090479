import { useEffect, useState, useCallback } from "react"
import { Card, CardTitle, CardBody, Badge, Row, Col, CardFooter, CardHeader } from 'reactstrap';

import Circle from 'components/markup/loading/Circle';

import _cases from '_functions/cases'

import { capitalize } from "utils/text";

import ImageRender from 'components/functional/images/ImageRenderer'
import _documents from '_functions/documents';
import ReactSelect from 'components/functional/inputs/ReactSelect';

const BadgeTrue = () => (
    <Badge color="success"><i className="fas fa-check mr-2  " /> Yes</Badge>
)

const Table = ({title, rows}) => (
    <>

    <CardBody className="pb-0">
        <CardTitle className="mb-0">{title}</CardTitle>

        <div className="table-responsive ">
            <table className="table rounded border">
                <thead>
                    <tr>
                        <th style={{width: 300, borderTop: 'none'}}>Field</th>
                        <th style={{borderTop: 'none'}}>Answer</th>
                    </tr>
                </thead>
                <tbody>
                    {rows.map((row, i) => (
                        <tr key={i}>
                            <td style={{whiteSpace: 'pre-line'}}>{row.field}</td>
                            <td>{row.value === '' || row.value === null || row.value === undefined ? <Badge color="warning">Not Answered</Badge> : row.value}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    </CardBody>
    </>
)

const Checklist = ({_case, setNav}) => {

    const missing_docs = _case.missing_docs
    const [err, setErr] = useState(false);
    const [documents, setDocuments] = useState(false);

    const fetchDocuments = useCallback(async () => {
        const data = await _documents.findByCase(_case._id)
        if(data.success) setDocuments(data.data)
    }, [_case._id])

    useEffect((fetchDocuments) => {
        document.body.classList.add('modal-can-scroll')

        return () => {
            document.body.classList.remove('modal-can-scroll')
        }

    }, [fetchDocuments])

    if(err) return (
        <div className="alert alert-warning"><i className="fas fa-exclamation-triangle mr-2 " /> Failed to fetch case, please refresh your page.</div>
    )

    return (
        <div>
            <Card className="pb-4">

                <CardHeader className="text-right">
                    <button style={{width: 'auto'}} onClick={() => setNav(3)} className="btn btn-warning"><i className="fas fa-arrow-left " /> Back To Docs</button>
                </CardHeader>

                <Row>
                    <Col lg={6}>
                        <Table 
                            title="Company Info"
                            rows={[
                                { field: 'Company Name', value: _case.company_name },
                                { field: 'Employee Identification Number (EIN)', value: _case.company_ein },
                                { field: 'DBA / Trade Name', value: _case.company_dba },
                                { field: 'Mailing Address', value: _case.company_address },
                                { field: 'Mailing City', value: _case.company_city },
                                { field: 'Mailing State', value: _case.company_state },
                                { field: 'Mailing Zip', value: _case.company_zip },
                            ]}
                        />

                        <Table 
                            title="Employees"
                            rows={[
                                { field: 'Employee Count - Q4 2020', value: _case.employees_quarter_4_2020 },
                                { field: 'Employee Count - Q1 2021', value: _case.employees_quarter_1_2021 },
                                { field: 'Employee Count - Q2 2021', value: _case.employees_quarter_2_2021 },
                                { field: 'Employee Count - Q3 2021', value: _case.employees_quarter_3_2021 },
                            ]}
                        />


                        <Table 
                            title="Social Security Wages"
                            rows={[
                                { field: 'SS Wages - Q4 2020', value: _case.social_security_wages_quarter_4_2020 },
                                { field: 'SS Wages - Q1 2021', value: _case.social_security_wages_quarter_1_2021 },
                                { field: 'SS Wages - Q2 2021', value: _case.social_security_wages_quarter_2_2021 },
                                { field: 'SS Wages - Q3 2021', value: _case.social_security_wages_quarter_3_2021 },
                            ]}
                        />

                        <Table 
                            title=" Gross Revenue"
                            rows={[
                                { field: 'Gross Revenue - Q1 2019', value: _case.gross_revenue_quarter_1_2019 },
                                { field: 'Gross Revenue - Q2 2019', value: _case.gross_revenue_quarter_2_2019 },
                                { field: 'Gross Revenue - Q3 2019', value: _case.gross_revenue_quarter_3_2019 },
                                { field: 'Gross Revenue - Q4 2019', value: _case.gross_revenue_quarter_4_2019 },
                                { field: 'Gross Revenue - Q1 2020', value: _case.gross_revenue_quarter_1_2020 },
                                { field: 'Gross Revenue - Q2 2020', value: _case.gross_revenue_quarter_2_2020 },
                                { field: 'Gross Revenue - Q3 2020', value: _case.gross_revenue_quarter_3_2020 },
                                { field: 'Gross Revenue - Q4 2020', value: _case.gross_revenue_quarter_4_2020 },
                                { field: 'Gross Revenue - Q1 2021', value: _case.gross_revenue_quarter_1_2021 },
                                { field: 'Gross Revenue - Q2 2021', value: _case.gross_revenue_quarter_2_2021 },
                                { field: 'Gross Revenue - Q3 2021', value: _case.gross_revenue_quarter_3_2021 },
                                { field: 'Gross Revenue - Q4 2021', value: _case.gross_revenue_quarter_4_2021 },
                            ]}
                        />

                        <Table 
                            title="PPP Round 1"
                            rows={[
                                { field: 'Received First Round PPP', value: _case.ppp_round_1 ? <BadgeTrue /> : '-' },
                                { field: 'PPP Loan Amount', value: _case.ppp_round_1 ?  _case.ppp_round_1_loan_amount : ''},
                                { field: 'Amount of Loan Spent on Payroll Costs', value: _case.ppp_round_1 ?  _case.ppp_round_1_loan_amount_spent : ''},
                                { field: 'Covered Period Start Date', value: _case.ppp_round_1 ?  _case.ppp_round_1_start : ''},
                                { field: 'Covered Period Weeks', value: _case.ppp_round_1 ?  _case.ppp_round_1_weeks : ''},
                            ]}
                        />
                    
                        <Table 
                            title="PPP Round 2"
                            rows={[
                                { field: 'Received Second Round PPP', value: _case.ppp_round_2 ? <BadgeTrue /> : '-' },
                                { field: 'PPP Loan Amount', value: _case.ppp_round_2 ?  _case.ppp_round_2_loan_amount : ''},
                                { field: 'Amount of Loan Spent on Payroll Costs', value: _case.ppp_round_2 ?  _case.ppp_round_2_loan_amount_spent : ''},
                                { field: 'Covered Period Start Date', value: _case.ppp_round_2 ?  _case.ppp_round_2_start : ''},
                                { field: 'Covered Period Weeks', value: _case.ppp_round_2 ?  _case.ppp_round_2_weeks : ''},
                            ]}
                        />

                        <Table 
                            title="Rep Info"
                            rows={[
                                { field: 'Representative Name', value: _case.rep_name },
                                { field: 'Representative Title', value: _case.rep_title },
                                { field: 'Representative Email', value: _case.rep_email },
                                { field: 'Representative Phone', value: _case.rep_phone },
                            ]}
                        />

                        <Table 
                            title=" Business Impact"
                            rows={[
                                { field: 'Partial or full shutdown per government mandates', value: _case.government_shutdown ? <BadgeTrue /> : '-' },
                                { field: 'Restrictions on number of people in room or building', value: _case.restricted_people_in_building ? <BadgeTrue /> : '-' },
                                { field: 'Inability to attend normal networking events and functions such as professional conferences', value: _case.inability_to_attend_events ? <BadgeTrue /> : '-' },
                                { field: 'Disruption to sales force\'s ability to function normally', value: _case.disruption_to_sales ? <BadgeTrue /> : '-' },
                                { field: 'Supply Chain Interruptions', value: _case.supply_chain_interruptions ? <BadgeTrue /> : '-' },
                                { field: 'Inability to access equipment', value: _case.inability_to_access_equipment ? <BadgeTrue /> : '-' },
                                { field: 'Limited Capacity To Operate', value: _case.limited_capacity_to_operate ? <BadgeTrue /> : '-' },
                                { field: 'Inability to work with vendors', value: _case.inability_to_work_for_vendors ? <BadgeTrue /> : '-' },
                                { field: 'Reduction in services or goods offered to customers', value: _case.reduction_in_customer_offerings ? <BadgeTrue /> : '-' },
                                { field: 'Cut down in hours of operation', value: _case.lowered_hours_of_operation ? <BadgeTrue /> : '-' },
                                { field: 'Shifting hours to increase sanitation of facility', value: _case.shifting_hours_to_sanitize ? <BadgeTrue /> : '-' },
                                { field: 'Projects were canceled or delayed to COVID-related disruptions', value: _case.canceled_projects ? <BadgeTrue /> : '-' },
                                { field: 'Delayed production timelines caused by supply chain interruptions.', value: _case.delayed_by_supply_chain ? <BadgeTrue /> : '-' },
                                { field: 'Other', value: _case.other_impact ? _case.other_impact : '-' },
                            ]}
                        />
                  
                        <Table 
                            title="Business Owners"
                            rows={[
                                { field: 'Business Owners', value: _case.business_owners },
                                { field: 'W2 Employees related to business owners', value: _case.business_owners_w2 },
                            ]}
                        />
                    
                    
                    </Col>

                    <Col lg={6}>
                        <CardBody className="borde" style={{position: 'sticky', top: 80}}>


                                <CardTitle className="mb-0">Missing Docs</CardTitle>


                            <div className="table-responsive border" style={{overflow: 'visible'}}>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th style={{width: 300}}>Doc Name</th>
                                            <th>Uploaded</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {missing_docs && missing_docs.length ? missing_docs.map((doc, i) => (
                                            <tr key={doc._id ? doc._id : i}>
                                                <td style={{verticalAlign: 'top', whiteSpace: 'pre-line'}}>
                                                    <span >
                                                        {doc.name ? capitalize(doc.name.replace(/\_/g, ' ')) : doc.name}
                                                    </span>
                                                </td>
                                                <td style={{verticalAlign: 'top'}}>
                                                    {doc.documents && doc.documents.length ? doc.documents.map((document, i) => {

                                                        if(!document._id) {

                                                            // somehow document.find can give a not found error, check that documents are an array here before
                                                            // running the find function documents
                                                            const foundDoc = documents && documents.length && documents.find(d => d._id === document);
                                                            if(foundDoc) {
                                                                document = foundDoc;
                                                            } else {
                                                                // don't show any documents that cannot be found
                                                                return '';
                                                            }

                                                        }

                                                        return (
                                                            <div key={document._id + i}>
                                                                <span className="border-bottom">

                                                                        <ImageRender 
                                                                            maxNameLength={20}
                                                                            doc={document}
                                                                            freeflow={true}
                                                                        />


                                                                </span>  
                                                            </div>
                                                        )
                                                    }) : '-'}
                                                </td>
                                            </tr>
                                            )) : (
                                            <tr>
                                                <td>This case has no missing documents.</td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                        )}
                                    </tbody>

                                </table>
                            </div>
                        </CardBody>
                    </Col>
                </Row>
                
                <CardFooter className="text-right">
                    <button onClick={() => setNav(3)} className="btn btn-warning"><i className="fas fa-arrow-left " /> Back To Docs</button>
                </CardFooter>
             
            </Card>
        </div>
    )

}

export default Checklist