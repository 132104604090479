const types = [
    "accounting",
    "airlines/aviation",
    "alternative medicine",
    "apparel & fashion",
    "architecture & planning",
    "arts and crafts",
    "automotive",
    "aviation & aerospace",
    "banking",
    "biotechnology",
    "broadcast media",
    "building materials",
    "business supplies and equipment",
    "capital markets",
    "chemicals",
    "civic & social organization",
    "civil engineering",
    "commercial real estate",
    "computer & network security",
    "computer games",
    "computer hardware",
    "computer networking",
    "computer software",
    "construction",
    "consumer electronics",
    "consumer goods",
    "consumer services",
    "cosmetics",
    "dairy",
    "defense & space",
    "design",
    "e-learning",
    "education management",
    "electrical/electronic manufacturing",
    "entertainment",
    "environmental services",
    "events services",
    "executive office",
    "facilities services",
    "farming",
    "financial services",
    "fine art",
    "fishery",
    "food & beverages",
    "food production",
    "fund-raising",
    "furniture",
    "glass, ceramics & concrete",
    "government administration",
    "government relations",
    "graphic design",
    "health, wellness and fitness",
    "higher education",
    "hospital & health care",
    "hospitality",
    "human resources",
    "import and export",
    "individual & family services",
    "industrial automation",
    "information services",
    "information technology and services",
    "insurance",
    "international affairs",
    "international trade and development",
    "internet",
    "investment banking",
    "investment management",
    "law enforcement",
    "law practice",
    "legal services",
    "leisure, travel & tourism",
    "libraries",
    "logistics and supply chain",
    "luxury goods & jewelry",
    "machinery",
    "management consulting",
    "maritime",
    "market research",
    "marketing and advertising",
    "mechanical or industrial engineering",
    "media production",
    "medical devices",
    "medical practice",
    "mental health care",
    "military",
    "mining & metals",
    "motion pictures and film",
    "museums and institutions",
    "music",
    "nanotechnology",
    "newspapers",
    "non-profit organization management",
    "oil & energy",
    "online media",
    "package/freight delivery",
    "packaging and containers",
    "paper & forest products",
    "performing arts",
    "pharmaceuticals",
    "philanthropy",
    "photography",
    "plastics",
    "political organization",
    "primary/secondary education",
    "printing",
    "professional training & coaching",
    "program development",
    "public policy",
    "public relations and communications",
    "public safety",
    "publishing",
    "railroad manufacture",
    "ranching",
    "real estate",
    "recreational facilities and services",
    "religious institutions",
    "renewables & environment",
    "research",
    "restaurants",
    "retail",
    "security and investigations",
    "semiconductors",
    "sporting goods",
    "sports",
    "staffing and recruiting",
    "supermarkets",
    "telecommunications",
    "textiles",
    "think tanks",
    "tobacco",
    "translation and localization",
    "transportation/trucking/railroad",
    "utilities",
    "venture capital & private equity",
    "veterinary",
    "warehousing",
    "wholesale",
    "wine and spirits",
    "wireless",
    "writing and editing",
]

module.exports = types;
