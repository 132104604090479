import apiWorker from '../apiWorker'
import actionsGenerate from './actions//generate';

const template_word_docs = {

    generate: actionsGenerate,
    create:   (data, hideError)                 => apiWorker('post',   `/api/v1/core/template_word_docs/create`, data, hideError),
    update:   (doc_id, data)                    => apiWorker('post',   `/api/v1/core/template_word_docs/update/${doc_id}`, data),
    delete:   (doc_id)                          => apiWorker('delete', `/api/v1/core/template_word_docs/delete/${doc_id}`),
    find:      (query)                          => apiWorker('get',    `/api/v1/core/template_word_docs/find${query}`),
    findById:  (_id)                            => apiWorker('get',    `/api/v1/core/template_word_docs/${_id}`),
    fields:  (template_word_doc_id, case_id)    => apiWorker('post',   `/api/v1/core/template_word_docs/fields/${template_word_doc_id}/${case_id}`),

}

export default template_word_docs;