

import apiWorker from '../apiWorker'

const emails = {
    send: (data) => apiWorker('post',   `/v1/emails/send`, data, true),
    getThread: (_id) => apiWorker('get',   `/v1/emails/${_id}/thread`, null, true),
    getMessage: (_id, message_id) => apiWorker('get',   `/v1/emails/${_id}/message/${message_id}`, null, true),
}

export default emails;