/*
Documentation

This file renders a modal to update case details, name, chapter, etc

*/

import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import StandardFormGroup from 'components/functional/inputs/StandardFormGroup';

import React, { Component } from 'react';
import { Modal, Row, Col } from 'reactstrap';

import _cases from '_functions/cases';

import { formatMoney } from 'utils/currency'

import { toggleStandardLoader, toggleAlertBS } from 'store/functions/system/system';

const numberIsValid = (percent) => {
    return percent !== undefined && percent !== null && percent !== '' ? true : false
}
class CaseOverviewModalUpdate extends Component {

    state = {
        newCase: {},
    };

    toggleModal = () => {
        this.setState({newCase: Object.assign({}, this.props.case)});
        this.props.toggleModal()
    }

    onSaveCase = async () => {

        const { newCase } = this.state

        if(!newCase.name) return

        let round_1_q4_refund_amount = undefined;
        let round_2_q1_refund_amount = undefined;
        let round_2_q2_refund_amount = undefined;
        let round_2_q3_refund_amount = undefined;
        
        let round_1_q4_paid = undefined;
        let round_2_q1_paid = undefined;
        let round_2_q2_paid = undefined;
        let round_2_q3_paid = undefined;
       
        if(numberIsValid(newCase.round_1_q4_refund_amount)) round_1_q4_refund_amount = parseFloat(newCase.round_1_q4_refund_amount)
        if(numberIsValid(newCase.round_2_q1_refund_amount)) round_2_q1_refund_amount = parseFloat(newCase.round_2_q1_refund_amount)
        if(numberIsValid(newCase.round_2_q2_refund_amount)) round_2_q2_refund_amount = parseFloat(newCase.round_2_q2_refund_amount)
        if(numberIsValid(newCase.round_2_q3_refund_amount)) round_2_q3_refund_amount = parseFloat(newCase.round_2_q3_refund_amount)
        
        if(numberIsValid(newCase.round_1_q4_paid)) round_1_q4_paid = parseFloat(newCase.round_1_q4_paid)
        if(numberIsValid(newCase.round_2_q1_paid)) round_2_q1_paid = parseFloat(newCase.round_2_q1_paid)
        if(numberIsValid(newCase.round_2_q2_paid)) round_2_q2_paid = parseFloat(newCase.round_2_q2_paid)
        if(numberIsValid(newCase.round_2_q3_paid)) round_2_q3_paid = parseFloat(newCase.round_2_q3_paid)

        toggleStandardLoader(true);
        const updated = await _cases.update(this.props.case._id, {
            round_1_q4_refund_amount,
            round_2_q1_refund_amount,
            round_2_q2_refund_amount,
            round_2_q3_refund_amount,
            round_1_q4_paid,
            round_2_q1_paid,
            round_2_q2_paid,
            round_2_q3_paid,
        }, true)
        toggleStandardLoader(false);

        if(updated.success) {
            this.toggleModal()
        } else {
            toggleAlertBS('info', 'Make sure that all percents set are a 0 or greater');
        }

    }

    componentWillReceiveProps = (nextProps) => {

        if(!this.props.showModal && nextProps.showModal) {
            this.setState({newCase: Object.assign({}, nextProps.case)})
        }

    }

    render() {


        let { newCase } = this.state;
        const { showModal } = this.props;

        let totalERTC = 0;
        let total = newCase.total_ertc;
        if(numberIsValid(newCase.round_1_q4_refund_amount)) totalERTC += parseFloat(newCase.round_1_q4_refund_amount)
        if(numberIsValid(newCase.round_2_q1_refund_amount)) totalERTC += parseFloat(newCase.round_2_q1_refund_amount)
        if(numberIsValid(newCase.round_2_q2_refund_amount)) totalERTC += parseFloat(newCase.round_2_q2_refund_amount)
        if(numberIsValid(newCase.round_2_q3_refund_amount)) totalERTC += parseFloat(newCase.round_2_q3_refund_amount)
      
        let totalERTCPaid = 0;
        let totalPaid = newCase.total_ertc_paid;
        if(numberIsValid(newCase.round_1_q4_paid)) totalERTCPaid += parseFloat(newCase.round_1_q4_paid)
        if(numberIsValid(newCase.round_2_q1_paid)) totalERTCPaid += parseFloat(newCase.round_2_q1_paid)
        if(numberIsValid(newCase.round_2_q2_paid)) totalERTCPaid += parseFloat(newCase.round_2_q2_paid)
        if(numberIsValid(newCase.round_2_q3_paid)) totalERTCPaid += parseFloat(newCase.round_2_q3_paid)

        if(totalERTC && totalERTC !== total) total = totalERTC;
        if(totalERTCPaid && totalERTCPaid !== totalPaid) totalPaid = totalERTCPaid;

        return (

            <Modal
                className="modal-dialog-centered"
                isOpen={showModal}
                toggle={this.toggleModal}
                size="lg"
            >
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Edit Case Payments</h5>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={this.toggleModal}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>

                <div className="modal-body">
                    <Row>
                        <Col lg={6}>
                            <h3>Total ERTC: {formatMoney(total)}</h3>
                        </Col>
                        <Col lg={6}>
                            <h3>Total PAID: {formatMoney(totalPaid)}</h3>
                        </Col>
                    </Row>
                </div>

                <div className="modal-body border-top border-bottom bg-secondary">
                    <p className="text-sm mb-0">These details should automatically pull in from Patriot after their webhook sync goes live but can be manually entered below. The total ERTC value will be calculated from the sum of each quarter below. In order to not overwrite the total ERTC value before quarters were introduced, contact John should the total ERTC for each quarter need to be set to $0 across each quarter.</p>
                </div>

                <div className="modal-body">

                    <Row>
                        <Col lg={6}>
                            <StandardFormGroup
                                obj={newCase}
                                objName='newCase'
                                onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                                type="number"
                                field="round_1_q4_refund_amount"
                                title={`Round 1 Quarter 4 - Amount`}
                            />
                        </Col>
                        <Col lg={6}>
                            <StandardFormGroup
                                obj={newCase}
                                objName='newCase'
                                onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                                type="number"
                                field="round_1_q4_paid"
                                title={`Round 1 Quarter 4 - PAID`}
                            />
                        </Col>
                    </Row>
                   
                    <Row>
                        <Col lg={6}>
                            <StandardFormGroup
                                obj={newCase}
                                objName='newCase'
                                onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                                type="number"
                                field="round_2_q1_refund_amount"
                                title={`Round 2 Quarter 1 - Amount`}
                            />
                        </Col>
                        <Col lg={6}>
                            <StandardFormGroup
                                obj={newCase}
                                objName='newCase'
                                onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                                type="number"
                                field="round_2_q1_paid"
                                title={`Round 2 Quarter 1 - PAID`}
                            />
                        </Col>
                    </Row>
                   
    
                   
                    <Row>
                        <Col lg={6}>
                            <StandardFormGroup
                                obj={newCase}
                                objName='newCase'
                                onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                                type="number"
                                field="round_2_q2_refund_amount"
                                title={`Round 2 Quarter 2 - Amount`}
                            />
                        </Col>
                        <Col lg={6}>
                            <StandardFormGroup
                                obj={newCase}
                                objName='newCase'
                                onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                                type="number"
                                field="round_2_q2_paid"
                                title={`Round 2 Quarter 2 - PAID`}
                            />
                        </Col>
                    </Row>
                   
    
                   
                    <Row>
                        <Col lg={6}>
                            <StandardFormGroup
                                obj={newCase}
                                objName='newCase'
                                onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                                type="number"
                                field="round_2_q3_refund_amount"
                                title={`Round 2 Quarter 3 - Amount`}
                            />
                        </Col>
                        <Col lg={6}>
                            <StandardFormGroup
                                obj={newCase}
                                objName='newCase'
                                onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                                type="number"
                                field="round_2_q3_paid"
                                title={`Round 2 Quarter 3 - PAID`}
                            />
                        </Col>
                    </Row>
                   
    
                
                </div>

                <div className="modal-footer">
                    <button onClick={this.onSaveCase}  className="btn btn-success">Save Case</button>
                </div>

            </Modal>

        )

    }

}

CaseOverviewModalUpdate.proptTypes = {
    showModal   : PropTypes.bool.isRequired,
    toggleModal : PropTypes.func.isRequired,
    case        : PropTypes.object.isRequired,
}


const mapStateToProps = state => {
    return {
    	users: state.users.users,
    	call_centers: state.call_centers.call_centers,
    	lead_sources: state.lead_sources.lead_sources,
    	brokers: state.brokers.brokers,
    };
};

export default connect(mapStateToProps, '')(CaseOverviewModalUpdate);
