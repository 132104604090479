/*
Documentation

This file renders a modal to update case details, name, chapter, etc

*/

import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import StandardFormGroup from 'components/functional/inputs/StandardFormGroup';

import React, { Component } from 'react';
import { Modal, Row, Col, Input, FormGroup } from 'reactstrap';

import _cases from '_functions/cases';

import { toggleStandardLoader, toggleAlertBS } from 'store/functions/system/system';
import renderName from 'utils/renderName';
import * as privileges  from '_functions/users/privileges'
import renderCaseName from 'utils/renderCaseName'

class CaseOverviewModalUpdate extends Component {

    state = {
        newCase: {},
        dataProvider: this.props.case.data_provider_1
    };

    toggleModal = () => {
        this.setState({newCase: Object.assign({}, this.props.case)});
        this.props.toggleModal()
    }

    onSaveCase = async () => {

        const { newCase } = this.state

        if(!newCase.name) return

        let percent_fee = undefined;
        let percent_marketing_reserve = undefined;
        // let total_ertc = undefined;

        let broker_1 = newCase.broker_1 === 'false' ? null : newCase.broker_1;
        let broker_2 = newCase.broker_2  === 'false' ? null : newCase.broker_2;
        let broker_3 = newCase.broker_3  === 'false' ? null : newCase.broker_3;
       
        let attorney_1 = newCase.attorney_1  === 'false' ? null : newCase.attorney_1;;
        let attorney_2 = newCase.attorney_2  === 'false' ? null : newCase.attorney_2;;
        let attorney_3 = newCase.attorney_3  === 'false' ? null : newCase.attorney_3;;
        
        let data_provider_1 = newCase.data_provider_1  === 'false' ? null : newCase.data_provider_1;
        let data_provider_2 = newCase.data_provider_2  === 'false' ? null : newCase.data_provider_2;
        let data_provider_3 = newCase.data_provider_3  === 'false' ? null : newCase.data_provider_3;
    
        let call_center_1 = newCase.call_center_1  === 'false' ? null : newCase.call_center_1;
        let call_center_2 = newCase.call_center_2  === 'false' ? null : newCase.call_center_2;
        let call_center_3 = newCase.call_center_3  === 'false' ? null : newCase.call_center_3;

        let percent_broker_1 = undefined
        let percent_broker_2 = undefined
        let percent_broker_3 = undefined
        
        let percent_attorney_1 = undefined
        let percent_attorney_2 = undefined
        let percent_attorney_3 = undefined
        
        let percent_data_provider_1 = undefined
        let percent_data_provider_2 = undefined
        let percent_data_provider_3 = undefined
     
        let percent_call_center_1 = undefined
        let percent_call_center_2 = undefined
        let percent_call_center_3 = undefined

        const percentIsValid = (percent) => {
            return percent !== undefined && percent !== null && percent !== '' ? true : false
        }

        if(percentIsValid(newCase.percent_fee)) percent_fee = parseFloat(newCase.percent_fee)
        if(percentIsValid(newCase.percent_marketing_reserve)) percent_marketing_reserve = parseFloat(newCase.percent_marketing_reserve)
        // // // if(percentIsValid(newCase.total_ertc)) total_ertc = parseFloat(newCase.total_ertc)

        if(percentIsValid(newCase.percent_broker_1)) percent_broker_1 = parseFloat(newCase.percent_broker_1)
        if(percentIsValid(newCase.percent_broker_2)) percent_broker_2 = parseFloat(newCase.percent_broker_2)
        if(percentIsValid(newCase.percent_broker_3)) percent_broker_3 = parseFloat(newCase.percent_broker_3)
       
        if(percentIsValid(newCase.percent_attorney_1)) percent_attorney_1 = parseFloat(newCase.percent_attorney_1)
        if(percentIsValid(newCase.percent_attorney_2)) percent_attorney_2 = parseFloat(newCase.percent_attorney_2)
        if(percentIsValid(newCase.percent_attorney_3)) percent_attorney_3 = parseFloat(newCase.percent_attorney_3)
   
        if(percentIsValid(newCase.percent_data_provider_1)) percent_data_provider_1 = parseFloat(newCase.percent_data_provider_1)
        if(percentIsValid(newCase.percent_data_provider_2)) percent_data_provider_2 = parseFloat(newCase.percent_data_provider_2)
        if(percentIsValid(newCase.percent_data_provider_3)) percent_data_provider_3 = parseFloat(newCase.percent_data_provider_3)
   
        if(percentIsValid(newCase.percent_call_center_1)) percent_call_center_1 = parseFloat(newCase.percent_call_center_1)
        if(percentIsValid(newCase.percent_call_center_2)) percent_call_center_2 = parseFloat(newCase.percent_call_center_2)
        if(percentIsValid(newCase.percent_call_center_3)) percent_call_center_3 = parseFloat(newCase.percent_call_center_3)

        if(this.state.dataProvider !== data_provider_1) {

        }

        toggleStandardLoader(true);
        const updated = await _cases.update(this.props.case._id, {
            name                        : newCase.name,
            case_number                 : newCase.case_number,
            company_size                : newCase.company_size,
            has_onboarding                : newCase.has_onboarding,
            percent_fee,
            percent_marketing_reserve,
            // total_ertc,

            changedSource: this.state.dataProvider !== data_provider_1 ? true : false,

            broker_1,
            broker_2,
            broker_3,

            attorney_1,
            attorney_2,
            attorney_3,

            data_provider_1,
            data_provider_2,
            data_provider_3,

            call_center_1,
            call_center_2,
            call_center_3,

            percent_broker_1,
            percent_broker_2,
            percent_broker_3,

            percent_attorney_1,
            percent_attorney_2,
            percent_attorney_3,

            percent_data_provider_1,
            percent_data_provider_2,
            percent_data_provider_3,

            percent_call_center_1,
            percent_call_center_2,
            percent_call_center_3,

            percent_data_provider_1_is_gross: newCase.percent_data_provider_1_is_gross ? true : false,
            percent_data_provider_2_is_gross: newCase.percent_data_provider_2_is_gross ? true : false,
            percent_data_provider_3_is_gross: newCase.percent_data_provider_3_is_gross ? true : false,
          
            percent_broker_1_is_gross: newCase.percent_broker_1_is_gross ? true : false,
            percent_broker_2_is_gross: newCase.percent_broker_3_is_gross ? true : false,
            percent_broker_3_is_gross: newCase.percent_broker_4_is_gross ? true : false,
            
            percent_call_center_1_is_gross: newCase.percent_call_center_1_is_gross ? true : false,
            percent_call_center_2_is_gross: newCase.percent_call_center_2_is_gross ? true : false,
            percent_call_center_3_is_gross: newCase.percent_call_center_3_is_gross ? true : false,
            
            percent_attorney_1_is_gross: newCase.percent_attorney_1_is_gross ? true : false,
            percent_attorney_2_is_gross: newCase.percent_attorney_2_is_gross ? true : false,
            percent_attorney_3_is_gross: newCase.percent_attorney_3_is_gross ? true : false,

        }, true)
        toggleStandardLoader(false);

        if(updated.success) {
            this.toggleModal()
        } else {
            toggleAlertBS('info', 'Make sure that all percents set are a number between 0 and 1. Example: 25% = .25');
        }

    }

    componentWillReceiveProps = (nextProps) => {

        if(!this.props.showModal && nextProps.showModal) {
            const _case = Object.assign({}, nextProps.case);
            _case.name = renderCaseName(_case.name)
            if(_case.display_name) _case.name = renderCaseName(_case.display_name);
            this.setState({newCase: Object.assign({}, _case)})
        }

    }

    render() {


        let { newCase } = this.state;
        const { showModal } = this.props

        return (

            <Modal
                className="modal-dialog-centered"
                isOpen={showModal}
                toggle={this.toggleModal}
                size="md"
            >
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Edit Case Details</h5>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={this.toggleModal}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>

                <div className="modal-body">



                    <StandardFormGroup
                        obj={newCase}
                        objName='newCase'
                        onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                        type="text"
                        field="name"
                        title="Case Name"
                    />
                   
                    <StandardFormGroup
                        obj={newCase}
                        objName='newCase'
                        onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                        type="number"
                        field="company_size"
                        title={`Company Size ${newCase.company_size_estimated ? ' (Estimated Between: ' + newCase.company_size_estimated + ')' : '' }`}
                    />

                    <FormGroup>
                        <label className="form-control-label">Onboarding Confirmed</label>
                        <Input
                            obj={newCase}
                            objName='newCase'
                            value={newCase.has_onboarding === true || newCase.has_onboarding === 'true' ? true : 'false'}
                            onChange={e => {
                                let val = e.target.value === 'false' ? false : true;
                                this.setState({newCase: { ...newCase, has_onboarding: val }})
                            }}
                            type="select"
                            field="has_onboarding"
                            title={`Onboarding Confirmed`}
                        >
                            <option value="false">No</option>
                            <option value="true">Yes</option>
                        </Input>

                    </FormGroup>
                 
                  
                    <hr />

                    {/* <StandardFormGroup
                        obj={newCase}
                        objName='newCase'
                        onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                        type="number"
                        field="total_ertc"
                        title={`Total ERTC Value ${formatMoney(newCase.total_ertc)}`}
                    />
                    */}
                    {privileges.canEditCasePayments() ? (
                        <div>

                            <StandardFormGroup
                                obj={newCase}
                                objName='newCase'
                                onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                                type="number"
                                field="percent_fee"
                                title={`Fee Percent: ${!Number.isNaN(parseFloat(newCase.percent_fee)) ? (parseFloat(newCase.percent_fee) * 100).toFixed(2) + '%' : '0%'}`}
                            />
                    
                            <StandardFormGroup
                                obj={newCase}
                                objName='newCase'
                                onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                                type="number"
                                field="percent_marketing_reserve"
                                title={`Marketing Reserve Percent: ${!Number.isNaN(parseFloat(newCase.percent_marketing_reserve)) ? (parseFloat(newCase.percent_marketing_reserve) * 100).toFixed(2) + '%' : '0%'}`}

                            />
                        
                            <hr />

                            <Row>
                                <Col lg={6}>
                                    <StandardFormGroup
                                        obj={newCase}
                                        objName='newCase'
                                        onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                                        type="select"
                                        field="data_provider_1"
                                        title="Data Provider #1"
                                    >
                                        <option value="false"></option>
                                        {this.props.lead_sources.map(b => (
                                            <option key={b._id} value={b._id}>{b.name}</option>
                                        ))}
                                    </StandardFormGroup>
                                </Col>
                                <Col lg={6}>
                                    <div className="position-relative">
                                        <StandardFormGroup
                                            obj={newCase}
                                            objName='newCase'
                                            onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                                            type="number"
                                            field="percent_data_provider_1"
                                            title={`Percent: ${!Number.isNaN(parseFloat(newCase.percent_data_provider_1)) ? (parseFloat(newCase.percent_data_provider_1) * 100).toFixed(2) + '%' : '0%'}`}
                                        />

                                        <div style={{position: 'absolute', top: 0, right: 0}}>
                                            <div className="custom-control custom-checkbox">
                                                <input
                                                    className="custom-control-input"
                                                    type="checkbox"
                                                    id="percent_data_provider_1_is_gross"
                                                    value={newCase.percent_data_provider_1_is_gross}
                                                    checked={newCase.percent_data_provider_1_is_gross}
                                                    onChange={(e) => this.setState({newCase: { ...newCase, percent_data_provider_1_is_gross: !newCase.percent_data_provider_1_is_gross }})}
                                                />
                                                <label className="custom-control-label" htmlFor="percent_data_provider_1_is_gross">
                                                    Is Gross
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6}>
                                    <StandardFormGroup
                                        obj={newCase}
                                        objName='newCase'
                                        onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                                        type="select"
                                        field="data_provider_2"
                                        title="Data Provider #2"
                                    >
                                        <option value="false"></option>
                                        {this.props.lead_sources.map(b => (
                                            <option key={b._id} value={b._id}>{b.name}</option>
                                        ))}
                                    </StandardFormGroup>
                                </Col>
                                <Col lg={6}>
                                    <div className="position-relative">
                                        <StandardFormGroup
                                            obj={newCase}
                                            objName='newCase'
                                            onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                                            type="number"
                                            field="percent_data_provider_2"
                                            title={`Percent: ${!Number.isNaN(parseFloat(newCase.percent_data_provider_2)) ? (parseFloat(newCase.percent_data_provider_2) * 100).toFixed(2) + '%' : '0%'}`}
                                        />

                                        <div style={{position: 'absolute', top: 0, right: 0}}>
                                            <div className="custom-control custom-checkbox">
                                                <input
                                                    className="custom-control-input"
                                                    type="checkbox"
                                                    id="percent_data_provider_2_is_gross"
                                                    value={newCase.percent_data_provider_2_is_gross}
                                                    checked={newCase.percent_data_provider_2_is_gross}
                                                    onChange={(e) => this.setState({newCase: { ...newCase, percent_data_provider_2_is_gross: !newCase.percent_data_provider_2_is_gross }})}
                                                />
                                                <label className="custom-control-label" htmlFor="percent_data_provider_2_is_gross">
                                                    Is Gross
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6}>
                                    <StandardFormGroup
                                        obj={newCase}
                                        objName='newCase'
                                        onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                                        type="select"
                                        field="data_provider_3"
                                        title="Data Provider #3"
                                    >
                                        <option value="false"></option>
                                        {this.props.lead_sources.map(b => (
                                            <option key={b._id} value={b._id}>{b.name}</option>
                                        ))}
                                    </StandardFormGroup>
                                </Col>
                                <Col lg={6}>
                                    <div className="position-relative">
                                        <StandardFormGroup
                                            obj={newCase}
                                            objName='newCase'
                                            onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                                            type="number"
                                            field="percent_data_provider_3"
                                            title={`Percent: ${!Number.isNaN(parseFloat(newCase.percent_data_provider_3)) ? (parseFloat(newCase.percent_data_provider_3) * 100).toFixed(2) + '%' : '0%'}`}
                                        />

                                        <div style={{position: 'absolute', top: 0, right: 0}}>
                                            <div className="custom-control custom-checkbox">
                                                <input
                                                    className="custom-control-input"
                                                    type="checkbox"
                                                    id="percent_data_provider_3_is_gross"
                                                    value={newCase.percent_data_provider_3_is_gross}
                                                    checked={newCase.percent_data_provider_3_is_gross}
                                                    onChange={(e) => this.setState({newCase: { ...newCase, percent_data_provider_3_is_gross: !newCase.percent_data_provider_3_is_gross }})}
                                                />
                                                <label className="custom-control-label" htmlFor="percent_data_provider_3_is_gross">
                                                    Is Gross
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                            <hr />

                            <Row>
                                <Col lg={6}>
                                    <StandardFormGroup
                                        obj={newCase}
                                        objName='newCase'
                                        onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                                        type="select"
                                        field="attorney_1"
                                        title="Attorney #1"
                                    >
                                        <option value="false"></option>
                                        {this.props.users.map(b => (
                                            <option key={b._id} value={b._id}>{renderName(b)}</option>
                                        ))}
                                    </StandardFormGroup>
                                </Col>
                                <Col lg={6}>
                                     <div className="position-relative">
                                        <StandardFormGroup
                                            obj={newCase}
                                            objName='newCase'
                                            onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                                            type="number"
                                            field="percent_attorney_1"
                                            title={`Percent: ${!Number.isNaN(parseFloat(newCase.percent_attorney_1)) ? (parseFloat(newCase.percent_attorney_1) * 100).toFixed(2) + '%' : '0%'}`}
                                        />

                                        <div style={{position: 'absolute', top: 0, right: 0}}>
                                            <div className="custom-control custom-checkbox">
                                                <input
                                                    className="custom-control-input"
                                                    type="checkbox"
                                                    id="percent_attorney_1_is_gross"
                                                    value={newCase.percent_attorney_1_is_gross}
                                                    checked={newCase.percent_attorney_1_is_gross}
                                                    onChange={(e) => this.setState({newCase: { ...newCase, percent_attorney_1_is_gross: !newCase.percent_attorney_1_is_gross }})}
                                                />
                                                <label className="custom-control-label" htmlFor="percent_attorney_1_is_gross">
                                                    Is Gross
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6}>
                                    <StandardFormGroup
                                        obj={newCase}
                                        objName='newCase'
                                        onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                                        type="select"
                                        field="attorney_2"
                                        title="Attorney #2"
                                    >
                                        <option value="false"></option>
                                        {this.props.users.map(b => (
                                            <option key={b._id} value={b._id}>{renderName(b)}</option>
                                        ))}
                                    </StandardFormGroup>
                                </Col>
                                <Col lg={6}>
                                     <div className="position-relative">
                                        <StandardFormGroup
                                            obj={newCase}
                                            objName='newCase'
                                            onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                                            type="number"
                                            field="percent_attorney_2"
                                            title={`Percent: ${!Number.isNaN(parseFloat(newCase.percent_attorney_2)) ? (parseFloat(newCase.percent_attorney_2) * 100).toFixed(2) + '%' : '0%'}`}
                                        />

                                        <div style={{position: 'absolute', top: 0, right: 0}}>
                                            <div className="custom-control custom-checkbox">
                                                <input
                                                    className="custom-control-input"
                                                    type="checkbox"
                                                    id="percent_attorney_2_is_gross"
                                                    value={newCase.percent_attorney_2_is_gross}
                                                    checked={newCase.percent_attorney_2_is_gross}
                                                    onChange={(e) => this.setState({newCase: { ...newCase, percent_attorney_2_is_gross: !newCase.percent_attorney_2_is_gross }})}
                                                />
                                                <label className="custom-control-label" htmlFor="percent_attorney_2_is_gross">
                                                    Is Gross
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>

                                <Col lg={6}>
                                    <StandardFormGroup
                                        obj={newCase}
                                        objName='newCase'
                                        onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                                        type="select"
                                        field="attorney_3"
                                        title="Attorney #3"
                                    >
                                        <option value="false"></option>
                                        {this.props.users.map(b => (
                                            <option key={b._id} value={b._id}>{renderName(b)}</option>
                                        ))}
                                    </StandardFormGroup>
                                </Col>
                                <Col lg={6}>
                                     <div className="position-relative">
                                        <StandardFormGroup
                                            obj={newCase}
                                            objName='newCase'
                                            onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                                            type="number"
                                            field="percent_attorney_3"
                                            title={`Percent: ${!Number.isNaN(parseFloat(newCase.percent_attorney_3)) ? (parseFloat(newCase.percent_attorney_3) * 100).toFixed(2) + '%' : '0%'}`}
                                        />

                                        <div style={{position: 'absolute', top: 0, right: 0}}>
                                            <div className="custom-control custom-checkbox">
                                                <input
                                                    className="custom-control-input"
                                                    type="checkbox"
                                                    id="percent_attorney_3_is_gross"
                                                    value={newCase.percent_attorney_3_is_gross}
                                                    checked={newCase.percent_attorney_3_is_gross}
                                                    onChange={(e) => this.setState({newCase: { ...newCase, percent_attorney_3_is_gross: !newCase.percent_attorney_3_is_gross }})}
                                                />
                                                <label className="custom-control-label" htmlFor="percent_attorney_3_is_gross">
                                                    Is Gross
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                            <hr />

                            <Row>
                                <Col lg={6}>
                                    <StandardFormGroup
                                        obj={newCase}
                                        objName='newCase'
                                        onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                                        type="select"
                                        field="broker_1"
                                        title="Broker #1"
                                    >
                                        <option value="false"></option>
                                        {this.props.brokers.map(b => (
                                            <option key={b._id} value={b._id}>{b.name}</option>
                                        ))}
                                    </StandardFormGroup>
                                </Col>
                                <Col lg={6}>
                                    <div className="position-relative">
                                        <StandardFormGroup
                                            obj={newCase}
                                            objName='newCase'
                                            onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                                            type="number"
                                            field="percent_broker_1"
                                            title={`Percent: ${!Number.isNaN(parseFloat(newCase.percent_broker_1)) ? (parseFloat(newCase.percent_broker_1) * 100).toFixed(2) + '%' : '0%'}`}
                                        />

                                        <div style={{position: 'absolute', top: 0, right: 0}}>
                                            <div className="custom-control custom-checkbox">
                                                <input
                                                    className="custom-control-input"
                                                    type="checkbox"
                                                    id="percent_broker_1_is_gross"
                                                    value={newCase.percent_broker_1_is_gross}
                                                    checked={newCase.percent_broker_1_is_gross}
                                                    onChange={(e) => this.setState({newCase: { ...newCase, percent_broker_1_is_gross: !newCase.percent_broker_1_is_gross }})}
                                                />
                                                <label className="custom-control-label" htmlFor="percent_broker_1_is_gross">
                                                    Is Gross
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6}>
                                    <StandardFormGroup
                                        obj={newCase}
                                        objName='newCase'
                                        onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                                        type="select"
                                        field="broker_2"
                                        title="Broker #2"
                                    >
                                        <option value="false"></option>
                                        {this.props.brokers.map(b => (
                                            <option key={b._id} value={b._id}>{b.name}</option>
                                        ))}
                                    </StandardFormGroup>
                                </Col>
                                <Col lg={6}>
                                    <div className="position-relative">
                                        <StandardFormGroup
                                            obj={newCase}
                                            objName='newCase'
                                            onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                                            type="number"
                                            field="percent_broker_2"
                                            title={`Percent: ${!Number.isNaN(parseFloat(newCase.percent_broker_2)) ? (parseFloat(newCase.percent_broker_2) * 100).toFixed(2) + '%' : '0%'}`}
                                        />

                                        <div style={{position: 'absolute', top: 0, right: 0}}>
                                            <div className="custom-control custom-checkbox">
                                                <input
                                                    className="custom-control-input"
                                                    type="checkbox"
                                                    id="percent_broker_2_is_gross"
                                                    value={newCase.percent_broker_2_is_gross}
                                                    checked={newCase.percent_broker_2_is_gross}
                                                    onChange={(e) => this.setState({newCase: { ...newCase, percent_broker_2_is_gross: !newCase.percent_broker_2_is_gross }})}
                                                />
                                                <label className="custom-control-label" htmlFor="percent_broker_2_is_gross">
                                                    Is Gross
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6}>
                                    <StandardFormGroup
                                        obj={newCase}
                                        objName='newCase'
                                        onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                                        type="select"
                                        field="broker_3"
                                        title="Broker #3"
                                    >
                                        <option value="false"></option>
                                        {this.props.brokers.map(b => (
                                            <option key={b._id} value={b._id}>{b.name}</option>
                                        ))}
                                    </StandardFormGroup>
                                </Col>
                                <Col lg={6}>
                                     <div className="position-relative">
                                        <StandardFormGroup
                                            obj={newCase}
                                            objName='newCase'
                                            onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                                            type="number"
                                            field="percent_broker_3"
                                            title={`Percent: ${!Number.isNaN(parseFloat(newCase.percent_broker_3)) ? (parseFloat(newCase.percent_broker_3) * 100).toFixed(2) + '%' : '0%'}`}
                                        />

                                        <div style={{position: 'absolute', top: 0, right: 0}}>
                                            <div className="custom-control custom-checkbox">
                                                <input
                                                    className="custom-control-input"
                                                    type="checkbox"
                                                    id="percent_broker_3_is_gross"
                                                    value={newCase.percent_broker_3_is_gross}
                                                    checked={newCase.percent_broker_3_is_gross}
                                                    onChange={(e) => this.setState({newCase: { ...newCase, percent_broker_3_is_gross: !newCase.percent_broker_3_is_gross }})}
                                                />
                                                <label className="custom-control-label" htmlFor="percent_broker_3_is_gross">
                                                    Is Gross
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                            <hr />

                            <Row>
                                <Col lg={6}>
                                    <StandardFormGroup
                                        obj={newCase}
                                        objName='newCase'
                                        onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                                        type="select"
                                        field="call_center_1"
                                        title="Call Center #1"
                                    >
                                        <option value="false"></option>
                                        {this.props.call_centers.map(b => (
                                            <option key={b._id} value={b._id}>{b.name}</option>
                                        ))}
                                    </StandardFormGroup>
                                </Col>
                                <Col lg={6}>
                                     <div className="position-relative">
                                        <StandardFormGroup
                                            obj={newCase}
                                            objName='newCase'
                                            onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                                            type="number"
                                            field="percent_call_center_1"
                                            title={`Percent: ${!Number.isNaN(parseFloat(newCase.percent_call_center_1)) ? (parseFloat(newCase.percent_call_center_1) * 100).toFixed(2) + '%' : '0%'}`}
                                        />

                                        <div style={{position: 'absolute', top: 0, right: 0}}>
                                            <div className="custom-control custom-checkbox">
                                                <input
                                                    className="custom-control-input"
                                                    type="checkbox"
                                                    id="percent_call_center_1_is_gross"
                                                    value={newCase.percent_call_center_1_is_gross}
                                                    checked={newCase.percent_call_center_1_is_gross}
                                                    onChange={(e) => this.setState({newCase: { ...newCase, percent_call_center_1_is_gross: !newCase.percent_call_center_1_is_gross }})}
                                                />
                                                <label className="custom-control-label" htmlFor="percent_call_center_1_is_gross">
                                                    Is Gross
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6}>
                                    <StandardFormGroup
                                        obj={newCase}
                                        objName='newCase'
                                        onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                                        type="select"
                                        field="call_center_2"
                                        title="Call Center #2"
                                    >
                                        <option value="false"></option>
                                        {this.props.call_centers.map(b => (
                                            <option key={b._id} value={b._id}>{b.name}</option>
                                        ))}
                                    </StandardFormGroup>
                                </Col>
                                <Col lg={6}>
                                     <div className="position-relative">
                                        <StandardFormGroup
                                            obj={newCase}
                                            objName='newCase'
                                            onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                                            type="number"
                                            field="percent_call_center_2"
                                            title={`Percent: ${!Number.isNaN(parseFloat(newCase.percent_call_center_2)) ? (parseFloat(newCase.percent_call_center_2) * 100).toFixed(2) + '%' : '0%'}`}
                                        />

                                        <div style={{position: 'absolute', top: 0, right: 0}}>
                                            <div className="custom-control custom-checkbox">
                                                <input
                                                    className="custom-control-input"
                                                    type="checkbox"
                                                    id="percent_call_center_2_is_gross"
                                                    value={newCase.percent_call_center_2_is_gross}
                                                    checked={newCase.percent_call_center_2_is_gross}
                                                    onChange={(e) => this.setState({newCase: { ...newCase, percent_call_center_2_is_gross: !newCase.percent_call_center_2_is_gross }})}
                                                />
                                                <label className="custom-control-label" htmlFor="percent_call_center_2_is_gross">
                                                    Is Gross
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6}>
                                    <StandardFormGroup
                                        obj={newCase}
                                        objName='newCase'
                                        onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                                        type="select"
                                        field="call_center_3"
                                        title="Call Center #3"
                                    >
                                        <option value="false"></option>
                                        {this.props.call_centers.map(b => (
                                            <option key={b._id} value={b._id}>{b.name}</option>
                                        ))}
                                    </StandardFormGroup>
                                </Col>
                                <Col lg={6}>
                                     <div className="position-relative">
                                        <StandardFormGroup
                                            obj={newCase}
                                            objName='newCase'
                                            onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                                            type="number"
                                            field="percent_call_center_3"
                                            title={`Percent: ${!Number.isNaN(parseFloat(newCase.percent_call_center_3)) ? (parseFloat(newCase.percent_call_center_3) * 100).toFixed(2) + '%' : '0%'}`}
                                        />

                                        <div style={{position: 'absolute', top: 0, right: 0}}>
                                            <div className="custom-control custom-checkbox">
                                                <input
                                                    className="custom-control-input"
                                                    type="checkbox"
                                                    id="percent_call_center_3_is_gross"
                                                    value={newCase.percent_call_center_3_is_gross}
                                                    checked={newCase.percent_call_center_3_is_gross}
                                                    onChange={(e) => this.setState({newCase: { ...newCase, percent_call_center_3_is_gross: !newCase.percent_call_center_3_is_gross }})}
                                                />
                                                <label className="custom-control-label" htmlFor="percent_call_center_3_is_gross">
                                                    Is Gross
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                        </div>
                    ) : null}
                   
                
                </div>

                <div className="modal-footer">
                    <button onClick={this.onSaveCase}  className="btn btn-success">Save Case</button>
                </div>

            </Modal>

        )

    }

}

CaseOverviewModalUpdate.proptTypes = {
    showModal   : PropTypes.bool.isRequired,
    toggleModal : PropTypes.func.isRequired,
    case        : PropTypes.object.isRequired,
}


const mapStateToProps = state => {
    return {
    	users: state.users.users,
    	call_centers: state.call_centers.call_centers,
    	lead_sources: state.lead_sources.lead_sources,
    	brokers: state.brokers.brokers,
    };
};

export default connect(mapStateToProps, '')(CaseOverviewModalUpdate);
