/*
Documentation

this page shows the inputs and markup to create a client

*/

import CustomDataFormGroup from 'components/functional/inputs/CustomDataFormGroup';
import StandardFormGroup from 'components/functional/inputs/StandardFormGroup';
import Countries from 'components/markup/inputs/Countries';
import States from 'components/markup/inputs/States';
import Dots from 'components/markup/loading/Dots';
import PropTypes from 'prop-types';
import React from "react";
import { connect } from 'react-redux';
import { Col, FormGroup, Input, Modal, Row } from "reactstrap";
import { toggleStandardLoader } from 'store/functions/system/system';
import { validatePhoneNumber } from 'utils/validation';
import _contacts from '_functions/contacts';
import _contact_forms from '_functions/contact_forms';
import * as privileges from '_functions/users/privileges';
import contact_types from '_settings/contact_types';
import ContactSearch from 'views/dashboard/pages/_components/ContactSearch'
import TagsUpdater from 'views/dashboard/pages/_components/TagsUpdater';
import EmailValidator from 'email-validator'
import { formatPhone } from 'utils/text'

import * as ANALYTIC_EVENTS from '_settings/analytic_events';
import _analytics from '_functions/analytics';


const required_form_fields = [
    // 'email',
]

class ContactsCrud extends React.Component {

    state = {
        analytics_custom_data: [],
        contact: {
            relationships: [],
            country: 'USA',
            billing_country: 'USA',
            is_lead: true
        },
        contact_forms: null,
        possibleDuplicates: null,
        is_related: false,
        canSave: true,
        showRelationModal: false,
        relationToAssociate: {}
    }

    onTagChange = (tags) => {

        const contact = Object.assign({}, this.state.contact)
        contact.tags = tags

        this.setState({contact})

    }


    onAssignOffice = (office) => {

        const contact = Object.assign({}, this.state.contact)
        contact.office = office

        this.setState({contact})

    }

    validateEmail =  async (email) => {
        return new Promise (async resolve => {

            if(!email) return resolve(true)

            this.setState({validatingEmail: true})

            let emailAlert = null

            email = email.trim()

            if(!EmailValidator.validate(email)) {

                emailAlert = (
                    <div className="alert alert-danger py-2 px-3">
                        {email} is not a valid email address.
                    </div>
                )

            }

            const emailInSystem = await _contacts.findByEmail(email)

            if(emailInSystem.success && emailInSystem.data && !emailAlert) {

                const url = `/dashboard/contacts/view/${emailInSystem.data._id}`

                // set the error message if we need it
                let errorMessage = (
                    <a href={url} target="_blank" rel="noopener noreferrer">
                        <div className="alert alert-danger py-2 px-3">
                            {emailInSystem.data.deleted ?
                                ` There is already a contact with the email "${email}". NOTE THAT THIS CONTACT HAS BEEN DELETED. CLICK HERE TO VIEW THEM.` :
                                ` There is already a contact with the email "${email}". CLICK HERE TO VIEW THEM.`}
                        </div>
                    </a>
                )

                 // if the contact is in the system but we are creating a contact we have an error
                 if(!this.props.contact) {

                    emailAlert = errorMessage

                // if the contact is in the system but we are updating a contact don't show an error for the same email address
                } else if(this.props.contact._id !== emailInSystem.data._id) {

                    emailAlert = errorMessage

                }

            }

            this.setState({validatingEmail: false, emailAlert})
            resolve(emailAlert ? false : true)


        })
    }

    validatePhone = async (phone) => {
        return new Promise (async resolve => {

            if(!phone) return resolve(true)

            phone = phone.trim()

            this.setState({validatingPhone: true})

            let formattedPhone = validatePhoneNumber(phone)

            if(formattedPhone) {
                phone = formattedPhone
            } else {
                const phoneAlert = (
                    <div className="alert alert-danger py-2 px-3">"{phone}" is not a valid phone number.</div>
                )
                this.setState({validatingPhone: false, phoneAlert})
                return resolve(false)
            }

            // see if this phone is in the system
            const phoneInSystem = await _contacts.findByPhone(phone)
            let phoneAlert = null;

            if(phoneInSystem.success && phoneInSystem.data) {

                const url = `/dashboard/contacts/view/${phoneInSystem.data._id}`

                // set the error message if we need it
                let errorMessage = (
                    <a href={url} target="_blank" rel="noopener noreferrer">

                        <div className="alert alert-danger py-2 px-3">
                            {phoneInSystem.data.deleted ?
                                ` There is already a contact with the phone number "${phone}". NOTE THAT THIS CONTACT HAS BEEN DELETED. CLICK HERE TO VIEW THEM.` :
                                ` There is already a contact with the phone number "${phone}". CLICK HERE TO VIEW THEM.`}
                        </div>
                    </a>
                )

                // if the contact is in the system but we are creating a contact we have an error
                if(!this.props.contact) {
                    phoneAlert = errorMessage

                // if the contact is in the system but we are updating a contact don't show an error for the same email address
                } else if(this.props.contact._id !== phoneInSystem.data._id) {
                    phoneAlert = errorMessage
                }

            }

            this.setState({validatingPhone: false, phoneAlert})
            resolve(phoneAlert ? false : true)

        })
    }

    removeRelationship = (_id) => {

        let identifiers = this.state.contact.relationships

        identifiers = identifiers.filter(i => i._id.toString() !== _id.toString() )

        this.setState({
            contact: {
                ...this.state.contact,
                relationships: identifiers
            }
        })

    }

    fetchContactForms = async () => {

        const forms = await _contact_forms.findByContact(this.props.contact._id)

        if(forms.success) {
            this.setState({contact_forms: forms.data ? forms.data.values : {}})
        }

    }

    getContactRelations = () => {

        const relationships = this.state.contact.relationships
        const canUpdate = privileges.canUpdateContacts()

        let markup;

        if(relationships && relationships.length) {
            markup = relationships.map(i => (
                <tr key={i._id}>
                    <td>{i.name}</td>
                    <td>{i.value}</td>
                    <td
                        onClick={canUpdate ? () => this.removeRelationship(i._id) : null}
                        className="text-right"
                    >
                        <button disabled={!canUpdate} className="btn btn-danger btn-sm">Remove</button>
                    </td>
                </tr>
            ))
        } else {

            markup = (
                <tr>
                    <td>No current associations.</td>
                    <td></td>
                    <td></td>
                </tr>
            )
        }

        return markup

    }

    setRelationModal = (contact) => {

        this.setState({
            relationToAssociate: contact,
            showRelationModal: true,
            association: null
        })

    }

    setAssociation = () => {

        if(this.state.association) {

            let relationships = [...this.state.contact.relationships];


            const relationToAssociate = this.state.relationToAssociate

            relationships.push({
                _id: relationToAssociate._id,
                email: relationToAssociate.email,
                name: this.getContactName(relationToAssociate), //for use on this page delete when sending to the api
                value: this.state.association
            })

            this.setState({
                contact: {
                    ...this.state.contact,
                    relationships,
                },
                showRelationModal: false
            })

        }

    }

    renderContactTypes = () => {

        if(!contact_types) {
            return 'There are no contact types set for this system.'
        }

        let columns = [[],[],[]];

        contact_types.forEach((c, i) => {

            //make sure type is not empty
            let is_checked = this.state.contact.type && this.state.contact.type.includes(c)

            const markup = (
                <div key={c}>
                    <input
                        id={c}
                        onChange={() => {

                            const type = this.state.contact.type || []

                            if(type.includes(c)) {
                                const index = type.indexOf(c);
                                if (index > -1) {
                                    type.splice(index, 1);
                                  }
                            } else {
                                type.push(c)
                            }

                            this.setState({
                                contact: {
                                    ...this.state.contact, type
                                }
                            })

                        }}
                        checked={is_checked || ''}
                        type="checkbox"
                        className="mr-2"
                    />
                    <label htmlFor={c} >{c}</label>
                </div>
            )

            //push markup to one of three columns
            if      (i < (contact_types.length / 3))        { columns[0].push(markup) }
            else if (i < ((contact_types.length / 3) * 2))  { columns[1].push(markup) }
            else                                            { columns[2].push(markup) }

        })

        return (
            <Row>
                <Col md={4}>{columns[0]}</Col>
                <Col md={4}>{columns[1]}</Col>
                <Col md={4}>{columns[2]}</Col>
            </Row>
        )

    }



    onCreate = async  (newState) => {

        toggleStandardLoader(true)

        // dont create a callback or a show a new contact has been created if
        // they were created through the system
        const created = await _contacts.create({...newState, HIDE_SHOW_NOTIFICATION: true})

        if(created.success) {

            //if we have an onCreate property run it with the data of the contact created
            if(this.props.onCreate && typeof this.props.onCreate === 'function') {
                this.props.onCreate(created.data)
            }

            _analytics.events.create({event: ANALYTIC_EVENTS.CONTACTS_CREATED});

        } else {

            //if we have an onCreate property run it with the data of the contact created
            if(this.props.onError && typeof this.props.onError === 'function') {
                this.props.onError(created)
            }

        }

        toggleStandardLoader(false)

    }

    onSave = async () => {

        this.setState({canSave: false, fieldError: false, emailAlert: false, phoneAlert: false, invalidPhone: false})
        //copy state
        let newState = Object.assign({}, this.state.contact);
        //assume we have no errors
        let errors = 0;

        required_form_fields.forEach((field) => {
            //check each required field
            if(newState[field]) {
                newState[field + "State"] = 'valid';
            } else {
                newState[field + "State"] = 'invalid';
                errors++
            }

        })

        this.setState({ contact: newState });

        if(!errors) {

            toggleStandardLoader(true)

            Promise.all([
                this.validateEmail(newState.email),
                this.validatePhone(newState.phone)
            ]).then(async values => {

                if(values[0] === false || values[1] === false) {
                    return toggleStandardLoader(false)
                }

                if(newState.phone) {

                    let formattedPhone = validatePhoneNumber(newState.phone)

                    if(!newState.phone) return this.setState({phoneAlert: `${newState.phone} is not a valid phone number`})

                    newState.phone = formattedPhone;


                }

                toggleStandardLoader(false)

                const possibleDuplicates = await _contacts.duplicate(newState)

                if(possibleDuplicates.success && possibleDuplicates.data.length) {
                    this.setState({possibleDuplicates: possibleDuplicates.data})
                } else {
                    this.setState({possibleDuplicates: null})
                    this.onCreate(newState)
                }

                toggleStandardLoader(false)

            })



        } else {

            this.setState({fieldError: true})

        }

        this.setState({canSave: true})

    }

    onUpdate = async () => {

        this.setState({canSave: false, emailAlert: false, phoneAlert: false})
        //copy state
        let newState = Object.assign({}, this.state.contact);

        //assume we have no errors
        let errors = 0;

        required_form_fields.forEach((field) => {
            //check each required field
            if(newState[field]) {
                newState[field + "State"] = 'valid';
            } else {
                newState[field + "State"] = 'invalid';
                errors++
            }

        })

        this.setState({ contact: newState });

        if(!errors) {

            toggleStandardLoader(true)

            Promise.all([
                this.validateEmail(newState.email),
                this.validatePhone(newState.phone)
            ]).then(async values => {

                if(values[0] === false || values[1] === false) {
                    return toggleStandardLoader(false)
                }

                let formattedPhone = validatePhoneNumber(newState.phone)
                newState.phone = formattedPhone !== false ? formattedPhone : '';

                if(!newState.email) newState.email = '';

                if(!newState.default_account_vault_id) delete newState.default_account_vault_id

                // remove these properties, this is most likely causing some contacts to remain
                // with the on call status after a call ends if this function is called
                delete newState.call_on_hold;
                delete newState.call_status;
                delete newState.call_tried_last;

                const updated = await _contacts.update(newState._id, newState)

                if(updated.success) {

                    //if we have an onCreate property run it with the data of the contact created
                    if(this.props.onUpdate && typeof this.props.onUpdate === 'function') {
                        this.props.onUpdate(updated.data)
                    }

                } else {

                    //if we have an onCreate property run it with the data of the contact created
                    if(this.props.onError && typeof this.props.onError === 'function') {
                        this.props.onError(updated)
                    }

                }

                toggleStandardLoader(false)


            })


        }

        this.setState({canSave: true})

    }

    getContactName = (contact) => {

        if(contact.given_name && contact.family_name) {
            return contact.given_name + ' ' + contact.family_name
        } else if (contact.given_name) {
            return contact.given_name
        } else if (contact.family_name) {
            return contact.family_name
        }

        return 'Name Not Found'

    }

    getCustomDataTypes = () => {

        let analytics_custom_data = this.props.analytics_data_types;
        analytics_custom_data = analytics_custom_data.filter(a => a.collection_name === 'contacts');

        this.setState({analytics_custom_data})

    }

    // when we paste try to format what was pasted in to be a real phone number
    onPastePhone = (e, key) => {

        e.preventDefault()

        const contact = Object.assign({}, this.state.contact);

        let text = e.clipboardData.getData('Text')

        if(text) { 

            text = text.replace(/\D/g,'');

            this.setState({contact: {...contact, [key]: text, [`${key}State`]: text ? 'valid' : 'invalid'}})

        }

    }

    //when we mount if a contact is added in props we should update them
    componentDidMount = async () => {

        const c = this.props.contact

        if(c) {

            this.getCustomDataTypes()
            this.setState({
                contact: {
                    ...c,
                    type: c.type ? c.type : [],
                    relationships: c.relationships ? c.relationships : [],
                    country: c.country ? c.country : 'USA',
                    billing_country: c.billing_country ? c.billing_country : 'USA',

                },
                is_related: c.relationships && c.relationships ? true : false
            })

        }

    }

    render() {

        const { contact, relationToAssociate, possibleDuplicates, verifiedNonDuplicate, validatingEmail, validatingPhone, fieldError, analytics_custom_data } = this.state

        const canUpdate = privileges.canUpdateContacts();
        const { lead_sources } = this.props



        return (
            <>

                {!canUpdate ? (
                    <div className="alert alert-danger"><i className="fas fa-info-circle" /> NOTE: Your account only has access to view a client, not update or create them.</div>
                ) : null}


                {/* <StandardFormGroup
                    disabled={!canUpdate}
                    obj={contact}
                    objName="contact"
                    onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}, possibleDuplicates: null})}
                    type="select"
                    field="is_lead"
                    formatter={(e) => e === true || e === "true" ? "true" : "false"}
                    title={(<><i className="fas fa-exclamation-triangle text-warning mr-2 font-weight-bold" /> Contact Is A Lead</>)}
                    formGroupClasses="w-50"
                >
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                </StandardFormGroup> */}

                {privileges.canSeeLeadSources() ? (
                    <>
                    <h3 className="mb-4">Client Status</h3>

                    <StandardFormGroup
                        disabled={!canUpdate}
                        obj={contact}
                        objName="contact"
                        onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}, possibleDuplicates: null})}
                        type="select"
                        field="lead_source"
                        title="Lead Source"
                        formGroupClasses="w-50"
                    >
                        <option value=""></option>
                        {lead_sources.length ? 
                            lead_sources.map(source => <option key={source._id} value={source._id}>{source.name}</option>)
                        : (
                            <option value="Other">Other</option>
                        )}
                    </StandardFormGroup>
                    <hr />

                    </>
                ): null }
{/* 
                <StandardFormGroup
                    disabled={!canUpdate}
                    obj={contact}
                    objName="contact"
                    onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}, possibleDuplicates: null})}
                    type="select"
                    field="important"
                    formatter={(e) => e === true || e === "true" ? "true" : "false"}
                    title={(<>Priority Contact</>)}
                    formGroupClasses="w-50"
                >
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                </StandardFormGroup> */}

              
                <h3 className="mb-4">Identity</h3>
                <Row>

                    <Col lg="4">
                        <StandardFormGroup
                            disabled={!canUpdate}
                            obj={contact}
                            objName="contact"
                            onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}, possibleDuplicates: null})}
                            field="given_name"
                            title="First Name *"
                        />
                    </Col>

                    <Col lg="4">
                        <StandardFormGroup
                            disabled={!canUpdate}
                            obj={contact}
                            objName="contact"
                            onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}, possibleDuplicates: null})}
                            field="middle_name"
                            title="Middle Name"
                        />
                    </Col>

                    <Col lg="4">
                        <StandardFormGroup
                            disabled={!canUpdate}
                            obj={contact}
                            objName="contact"
                            onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}, possibleDuplicates: null})}
                            field="family_name"
                            title="Last Name *"
                        />
                    </Col>

                </Row>

                <hr />
                <h3 className="mb-4">Contact Information</h3>

                <Row>
                    <Col lg={6}>

                        <StandardFormGroup
                            disabled={!canUpdate}
                            obj={contact}
                            objName="contact"
                            onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}, possibleDuplicates: null})}
                            field="email"
                            title="Primary Email *"
                        />

                        <StandardFormGroup
                            disabled={!canUpdate}
                            obj={contact}
                            objName="contact"
                            onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}, possibleDuplicates: null})}
                            field="email_2"
                            title="Email 2"
                        />

                        <StandardFormGroup
                            disabled={!canUpdate}
                            obj={contact}
                            objName="contact"
                            onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}, possibleDuplicates: null})}
                            field="email_3"
                            title="Email 3"
                        />

                    </Col>

                    <Col lg={6}>

                        <StandardFormGroup
                            disabled={!canUpdate}
                            obj={contact}
                            objName="contact"
                            onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}, possibleDuplicates: null})}
                            field="phone"
                            title="Phone"
                            type="number"
                            onPaste={(e) => this.onPastePhone(e, 'phone')}
                        />


                        <StandardFormGroup
                            disabled={!canUpdate}
                            obj={contact}
                            objName="contact"
                            onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}, possibleDuplicates: null})}
                            field="phone_2"
                            title="Phone 2"
                            type="number"
                            onPaste={(e) => this.onPastePhone(e, 'phone_2')}
                        />

                        <StandardFormGroup
                            disabled={!canUpdate}
                            obj={contact}
                            objName="contact"
                            onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}, possibleDuplicates: null})}
                            field="phone_3"
                            title="Phone 3"
                            type="number"
                            onPaste={(e) => this.onPastePhone(e, 'phone_3')}
                        />

                    </Col>

                </Row>

                <StandardFormGroup
                    disabled={!canUpdate}
                    obj={contact}
                    objName="contact"
                    onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}, possibleDuplicates: null})}
                    field="fax_number"
                    title="Fax Number"
                    type="tel"
                />

                <Row>
                    <Col lg={6}>

                        <hr />
                        <h3 className="mb-4">Address</h3>

                        <StandardFormGroup
                            disabled={!canUpdate}
                            obj={contact}
                            objName="contact"
                            onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}, possibleDuplicates: null})}
                            field="address_line_1"
                            title="Address Line 1 "
                        />

                        <StandardFormGroup
                            disabled={!canUpdate}
                            obj={contact}
                            objName="contact"
                            onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}, possibleDuplicates: null})}
                            field="address_line_2"
                            title="Address Line 2 "
                        />

                        <StandardFormGroup
                            disabled={!canUpdate}
                            obj={contact}
                            objName="contact"
                            onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}, possibleDuplicates: null})}
                            field="city"
                            title="City"
                        />

                        <StandardFormGroup
                            disabled={!canUpdate}
                            obj={contact}
                            objName="contact"
                            onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}, possibleDuplicates: null})}
                            field="state"
                            title="State"
                            type="select"
                        >
                            <option value="">Select State</option>
                            <States />
                        </StandardFormGroup>

                        <StandardFormGroup
                            disabled={!canUpdate}
                            obj={contact}
                            objName="contact"
                            onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}, possibleDuplicates: null})}
                            field="postal_code"
                            title="Postal code "
                        />

                        <StandardFormGroup
                            disabled={!canUpdate}
                            obj={contact}
                            objName="contact"
                            onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}, possibleDuplicates: null})}
                            type="select"
                            field="country"
                            title="Country "
                        >
                            <option></option>
                            <Countries />
                        </StandardFormGroup>
                    </Col>

                    <Col lg={6}>
                          
                        <hr />
                        <h3 className="mb-4">Personal Information</h3>

                        <StandardFormGroup
                            disabled={!canUpdate}
                            obj={contact}
                            objName="contact"
                            onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}, possibleDuplicates: null})}
                            type="text"
                            field="job_title"
                            title="Job Title"
                        />
                        
                        <StandardFormGroup
                            disabled={!canUpdate}
                            obj={contact}
                            objName="contact"
                            onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}, possibleDuplicates: null})}
                            type="date"
                            field="dob"
                            title="Date Of Birth"
                        />

                        <StandardFormGroup
                            disabled={!canUpdate}
                            obj={contact}
                            objName="contact"
                            onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}, possibleDuplicates: null})}
                            type="select"
                            field="marital_status"
                            title="Marital Status"
                        >
                            <option></option>
                            <option value="not married">Not Married</option>
                            <option value="married">Married</option>
                            <option value="divorced">Divorced</option>
                            <option value="separated">separated</option>
                            <option value="widow(er)">Widow(er)</option>
                            <option value="domestic partners">Domestic Partners</option>
                        </StandardFormGroup>

                        <StandardFormGroup
                            disabled={!canUpdate}
                            obj={contact}
                            objName="contact"
                            onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}, possibleDuplicates: null})}
                            type="select"
                            field="gender"
                            title="Gender"
                        >
                            <option></option>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                        </StandardFormGroup>
                    </Col>
                </Row>

                <hr />
                {/* <h3 className="mb-4">Tags</h3>

                <TagsUpdater
                    keyValue={contact._id}
                    current_tags={contact.tags ? contact.tags : []}
                    onChange={this.onTagChange}
                    hideSaveButton={true}
                /> */}

                <hr />
     

                {this.state.phoneAlert}
                {this.state.emailAlert}

                {possibleDuplicates && possibleDuplicates.length ? (
                    <div className="bg-secondary p-4">

                        <p className="text-center text-danger font-weight-bold mb-0">The contact you are creating may be a duplicate. Verify the contacts is indeed unique below</p>

                        <p className="text-center text-sm">
                            <span className="mr-2">
                                {contact.given_name && contact.family_name ? contact.given_name + ' ' + contact.family_name : 'Name Not Found'}
                            </span>
                            <span className="mr-2">
                                &mdash; {' '}
                                {contact.email ? contact.email : 'Email Not Found'}
                            </span>
                            <span className="mr-2">
                                &mdash; {' '}
                                {contact.phone ? formatPhone(contact.phone) : 'Phone Not Found'}
                            </span>
                        </p>

                        {possibleDuplicates.map(p => (
                            <React.Fragment key={p._id}>
                                <Row>
                                    <Col sm={4}>
                                        {p.given_name && p.family_name ? p.given_name + ' ' + p.family_name : 'No Name Found'}
                                    </Col>
                                    <Col sm={4} className="text-center">{p.email ? p.email : 'No Email Found'}</Col>
                                    <Col sm={4} className="text-right">{p.phone ? formatPhone(p.phone) : 'No Phone Found'}</Col>
                                </Row>
                                <hr className="my-2" />
                            </React.Fragment>
                        ))}


                        <div className="text-center">
                            <label htmlFor="verifiedNonDuplicate" className="text-sm my-3 d-block">
                                <Input
                                    id="verifiedNonDuplicate"
                                    onChange={() => this.setState({verifiedNonDuplicate: !verifiedNonDuplicate})}
                                    type="checkbox"
                                />
                                I have verified that this contact does not match any stated above.
                            </label>
                            <button
                                disabled={!verifiedNonDuplicate}
                                className="btn btn-success"
                                onClick={() => this.onCreate(this.state.contact)}
                            >
                                Create Contact
                            </button>
                        </div>


                    </div>
                ) : (
                    this.props.contact && this.props.contact._id ? (

                        <div className="text-right">
                                <button disabled={!canUpdate} className="btn btn-success" onClick={canUpdate ? this.onUpdate : null}>
                                Update Contact
                            </button>
                        </div>

                    ) : (

                        <>

                        <div className="text-right">

                            {fieldError ? (
                                <p className="text-sm text-danger">Before creating a contact make sure all required fields (highlighted in red) are filled out.</p>
                            ) : null }

                            <button
                                disabled={!canUpdate ?
                                    true : validatingEmail || validatingPhone ?
                                    true : false
                                }
                                className="btn btn-success"
                                onClick={!canUpdate ?
                                    null : validatingEmail || validatingPhone ?
                                    null : this.onSave
                                }

                            >
                                Create Contact
                            </button>
                        </div>

                        </>

                    )
                )}

                {validatingEmail ? (
                   <div className="text-right"> <Dots />validating email</div>
                ) : null}

                {validatingPhone ? (
                   <div className="text-right"> <Dots />validating phone</div>
                ) : null}



                <Modal
                    className="modal-dialog-centered"
                    isOpen={this.state.showRelationModal}
                    toggle={() => this.setState({showRelationModal: false}) }
                    size="md"
                >
                    <div className="modal-header">

                        <h5 className="modal-title" id="exampleModalLabel">
                            Set {relationToAssociate.given_name} {relationToAssociate.family_name}'s Association
                        </h5>

                        <button
                            aria-label="Close"
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => this.setState({showRelationModal: false})}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>

                    </div>

                    <div className="modal-body">
                        <FormGroup>
                            <label>Set Association</label>
                            <Input
                                value={this.state.association || ''}
                                type="text"
                                onChange={(e) => this.setState({association: e.target.value})}
                            />
                        </FormGroup>
                    </div>

                    <div className="modal-footer">
                        <button onClick={this.setAssociation} className="btn btn-success btn-sm">Set Association</button>
                    </div>

                </Modal>

            </>
        );
    }
}



const mapStateToProps = state => {
    return {
        analytics_data_types    : state.analytics_data_types.analytics_data_types,
        lead_sources            : state.lead_sources.lead_sources,
    };
};

ContactsCrud.propTypes = {
    onCreate: PropTypes.func,
    onUpdate: PropTypes.func,
    onError: PropTypes.func,
}

export default connect(mapStateToProps, '')(ContactsCrud);
