/*

*/

import React, { Component } from 'react';
import {FormGroup, Input, } from 'reactstrap'
import DatePicker from 'react-datepicker';
import moment from 'moment';

class CustomDataFormGroup extends Component {

    state = {

    };

    render() {


        const {field, obj, description, title, type, placeholder, objName, values} = this.props

        const custom_data = obj.custom_data ?  obj.custom_data : {};

        // set value to the custom_data object sent in
        let value = custom_data[field] || ''

        // if value has changed set it to the new value
        if(obj[`__cd__${field}`] !== undefined) {
            value = obj[`__cd__${field}`]
        }

        const err = this.props.hideErr ? null : obj[`${field}State`]


        return (

            <FormGroup className={"mb-3 " + this.props.formGroupClasses}>
                {title ? <label className="form-control-label mb-0">{title}</label> : null}
                {description ? <p className="text-sm mb-2">{description}</p> : null}

                {type === 'date' ? (
                    <div className="date-picker-wrapper">
                        <DatePicker
                            selected={value && moment(value).toDate() !== 'Invalid Date' ? moment(value).toDate() : undefined}
                            onChange={date => {
                                if(date && date !== 'Invalid Date') {
                                    this.props.onChange(objName, `__cd__${field}`, moment(date).format('MM/DD/YYYY'))
                                }
                            }}
                        />
                    </div>
                ) : (

                    <Input
                        id={this.props.inputId ? this.props.inputId : null}

                        disabled={this.props.disabled ? this.props.disabled : false}

                        onChange={e => {

                            let value = e.target.value;

                            if(this.props.type === 'number') { value = parseInt(value) }

                            this.props.onChange(objName, `__cd__${field}`, value, e)

                        }}
                        type={type === 'yes/no' || type === 'one of' ? 'select' : type}

                        value={value || ''}
                        placeholder={placeholder}

                        valid={ err === "valid" }
                        invalid={ err === "invalid" }

                        onKeyDown={this.props.onKeyDown ? (e) =>  this.props.onKeyDown(e) : null}
                        onBlur={(e) => this.props.onBlur ? this.props.onBlur(e) : () => {}}

                        className={this.props.inputGroupClasses}
                        style={this.props.style ? this.props.style : {}}
                    >
                        {type === 'yes/no' ? (
                            <>
                                <option></option>
                                <option value="yes">Yes</option>
                                <option value="no">No</option>
                            </>
                        ) : type === 'one of' ? (
                            <>
                                <option></option>
                                {values && values.length ? values.map((val, i) => (
                                    <option key={i} value={val.toLowerCase()}>{val}</option>
                                )) : null}
                            </>
                        ) : null}
                    </Input>
                )}
            </FormGroup>

        )

    }

}

export default CustomDataFormGroup;
