/*
Documentation

decline and accept call buttons have event listeners set up in store/functions/state

*/
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';

import renderName from 'utils/renderName';
import { formatPhone } from 'utils/text';

const OnCall = ({incoming_call, divisions}) => {

    if(!incoming_call) return <></>;

    return (
        <div className="archk-incoming-call z-depth-2">
            <div className="archk-incoming-call-wrapper">

                <Row>
                    <Col xs={5}>
                        <h4 className="mb-0">Incoming Call</h4>
                    </Col>
                    <Col xs={7} className="text-right">
                        <h4 className="mb-0"><i className="fas fa-headset mr-2 text-info " /> {formatPhone(incoming_call.call_phone_number)}</h4>
                    </Col>
                </Row>
            </div>

            <div className="archk-incoming-call-wrapper border-top">
                <Row>
                    <Col xs={6} className="align-self-center">
                        <h4 className="mb-0 text-capitalize">{incoming_call.contact_identifier}</h4>
                        {incoming_call.contact_identifier !== incoming_call.contact_phone ? <h6 className="mb-0">{formatPhone(incoming_call.contact_phone)}</h6> : '' }
                        {incoming_call.case_name ? <h6 className="mb-0 text-capitalize">{incoming_call.case_name}</h6> : ''}
                    </Col>
                </Row>
            </div>

            <div className="archk-incoming-call-wrapper border-top">
                <div>
                    <Row>
                        <Col xs={6}>
                            <button id="archk-decline-call" className="btn btn-danger btn-block">Decline</button>
                        </Col>
                        <Col xs={6}>
                            <button id="archk-accept-call" className="btn btn-success btn-block">Accept</button>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    )

}


const mapStateToProps = state => {
    return {
        incoming_call: state.state.incoming_call,
        divisions: state.divisions,
    };
};

export default connect(mapStateToProps, '')(OnCall);