/*
Documentation

This page shows a list of all cases
via api cases can be searched by case name or _id

*/

import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Card, CardHeader, CardTitle, Container } from "reactstrap";

import _zendesk from '_functions/zendesk';

import Circle from 'components/markup/loading/Circle';

import moment from 'moment';

const Directory = ({lead_sources, users}) => {

    const [tickets, setTickets] = useState('')
    const [show, setShow] = useState('unassigned')

    const fetchData = useCallback(async () => {
        const data = await _zendesk.tickets();
        if(data.data) setTickets(data.data)
    }, [])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    if(!tickets) return <Circle />

   
    return (
        <Container fluid>

            <Helmet>
                <title>{`Directory`}</title>
                <meta name="description" content="Directory" />
            </Helmet>

            <DashHeaderOpen
                icon="fas fa-home"
                icon_link="/analytics"
                title={<span >Directory</span>} breadcrumb_1="All"
            />

            <Card className="card-color card-primary">

                <CardHeader>
                    <CardTitle className="mb-0">
                        <span 
                            onClick={() => setShow('unassigned')} 
                            className={show === 'unassigned' ? 'text-success' : ''}
                            style={{paddingRight: 30, cursor: 'pointer'}}
                        >
                                Unassigned ({tickets.unassigned.count})
                            </span>
                        <span 
                            onClick={() => setShow('diana')} 
                            className={show === 'diana' ? 'text-success' : ''}
                            style={{paddingRight: 15, paddingLeft: 15, cursor: 'pointer'}}
                        >
                                Diana ({tickets.diana.count})
                            </span>
                        <span 
                            onClick={() => setShow('shirley')} 
                            className={show === 'shirley' ? 'text-success' : ''}
                            style={{paddingRight: 15, paddingLeft: 15, cursor: 'pointer'}}
                        >
                                Shirley ({tickets.shirley.count})
                            </span>
                        <span 
                            onClick={() => setShow('kevin')} 
                            className={show === 'kevin' ? 'text-success' : ''}
                            style={{paddingRight: 15, paddingLeft: 15, cursor: 'pointer'}}
                        >
                                Kevin ({tickets.kevin.count})
                            </span>
                        <span 
                            onClick={() => setShow('michael')} 
                            className={show === 'michael' ? 'text-success' : ''}
                            style={{paddingRight: 15, paddingLeft: 15, cursor: 'pointer'}}
                        >
                                Michael ({tickets.michael.count})
                            </span>
                    </CardTitle>
                </CardHeader>

                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Status</th>
                                <th>Subject</th>
                                <th>Description</th>
                                <th>Updated</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>

                            {tickets[show].results.map(t => (
                                <tr key={t.id}>
                                    <td className='text-uppercase'>{t.status}</td>
                                    <td>{t.raw_subject && t.raw_subject.length > 20 ? t.raw_subject.slice(0, 20) + '...' : t.raw_subject}</td>
                                    <td>{t.description && t.description.length > 40 ? t.description.slice(0, 40) + '...' : t.description}</td>
                                    <td>{moment(t.updated_at).format("MMM Do, YYYY h:mm A")}</td>
                                    <td className="text-right"><a href={`https://bizheadlaw.zendesk.com/agent/tickets/${t.id}`} target="_blank" rel="noopener noreferrer" className='btn btn-success'>Open</a></td>
                                </tr>
                            ))}

                        </tbody>
                    </table>
                </div>

            </Card>

        </Container>

    )
}


const mapStateToProps = state => {
    return {
        lead_sources: state.lead_sources.lead_sources,
        users: state.users.users,
    };
};

export default connect(mapStateToProps, '')(Directory);
