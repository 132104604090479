/*
Documentation

This page shows a list of all contacts
can search by name, email, phone, or _id

*/

import NotificationDelete from 'components/functional/notifications/Delete';
import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider  from "react-bootstrap-table2-toolkit";
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Card,  CardHeader, Container, CardTitle, Badge, Input, UncontrolledTooltip } from "reactstrap";
import renderName from 'utils/renderName';
import _contacts from '_functions/contacts';
import Circle from 'components/markup/loading/Circle';
import * as _ from 'underscore';
import NameCircle from 'components/markup/misc/NameCircle';
import AvatarImage from 'components/functional/images/AvatarImage';
import * as privileges  from '_functions/users/privileges'
import { formatPhone } from 'utils/text'
import { toggleStartCallModal } from 'store/functions/call_center/call_center'
import RenderEmailWithSend from 'components/system/RenderEmailWithSend'

import moment from 'moment';

class ContactsAll extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            searchText: "",
            page: 1,
            sizePerPage: 25,
            total_documents: 0,
            data: null,
            contactToDelete: null
        }
       this.queryTableDataThrottled = _.throttle(this.queryTableData, 750)
    }

    columns = [
        {
            dataField: "given_name",
            text: "Name",
            formatter: (cell, row) => (
                <div>
                    <Link className="text-capitalize" to={`/dashboard/contacts/view/${row._id}`}>
                        <NameCircle width={27} contact={row} style={{marginRight: 5}} />{' '}
                        {renderName(row)}
                    </Link>

                </div>
            ),
        },
        {
            dataField: "email",
            text: "Contact Info",
            formatter: (cell, row) => {

                let markup = []
                const blacklistID = `blacklist-tooltip-${row._id}`;

                if(row.email) {
                    markup.push((
                        <div key={row._id}><i className="text-muted fas fa-envelope mr-2" /><RenderEmailWithSend contact={row} /></div>
                    ))
                }

                if(row.phone) {
                    markup.push((
                        <div>
                            <span 
                                onClick={() => toggleStartCallModal(true, row)} 
                                key={row._id + row.phone}
                                className={row.blacklisted ? 'text-danger cursor-pointer' : 'cursor-pointer'}
                                id={blacklistID}
                            >
                                <i   className={row.blacklisted ? 'text-danger fas fa-exclamation-triangle mr-3' : 'text-muted fas fa-phone mr-3'} /> 
                                {formatPhone(row.phone)}
                            </span>
                            {row.blacklisted ? (
                                <UncontrolledTooltip  key={row.created_at} delay={0} placement="bottom" target={blacklistID}>
                                    Blacklisted As Spam
                                </UncontrolledTooltip>
                            ) : null}
                            
                        </div>
                    ))
                }
                
                return markup.length ? markup : ''

            }
        },
        {
            dataField: "assigned_user",
            text: "Assigned User",
            formatter: (cell, row) => (
                row.assigned_user ? (
                <div className="text-capitalize">
                    <AvatarImage src={row.assigned_user.avatar} className="mr-2" />
                    {renderName(row.assigned_user)}
                </div>
                ) : (
                    '-'
                )
            )
        },
        ...privileges.canSeeLeadSources() ? [{
            dataField: "lead_source_name",
            text: "Lead Source / Status",
            formatter: (cell, row) => {

                let status = '-';
                if(row.lead_source_entry && row.lead_source_entry.status) {
                    if(row.lead_source_entry.status === 'not contacted') {
                        status = 'Potential'
                    } else {
                        status = row.lead_source_entry.status
                    }
                }

                return (
                    <div className="text-capitalize">
                        <div className="font-weight-bold text-muted">{row.lead_source_name ? row.lead_source_name : 'Other'}</div>
                        <div>{status}</div>
                    </div>
                )
            }
        }] : [], 
        {
            dataField: "text_opt_out",
            text: "Communication",
            formatter: (cell, row) => (
                <div>

                    <Badge style={{width: 45, display: 'inline-block', }} color={row.opt_out_text ? 'danger': 'success'}>
                        <i className="fas fa-mobile mr-2" />
                    </Badge>
                    <Badge style={{width: 45, display: 'inline-block', }} color={row.opt_out_email ? 'danger': 'success'}>
                        <i className="fas fa-envelope mr-2" />
                    </Badge>

                </div>
            ),
        },
        {
            dataField: "creted_at",
            text: "Created",
            formatter: (cell, row) => {

                const date = moment.unix(row.created_at); 

                return (
                    <div>
                        <div>{date.format('MMM Do, YYYY')}</div>
                        <div>{date.format('h:mm A')}</div>
                    </div>
                )
            },
        },
        {
            dataField: "x",
            text: "Actions",
            headerStyle: {textAlign: 'right'},
            formatter: (cell, row) => {
                return (
                    <div className="text-right">
                        <Link to={`/dashboard/contacts/view/${row._id}`}>
                            <i className="fas fa-edit text-success mr-2" />
                        </Link>
                        {privileges.canDeleteContacts() ? (
                            <i 
                                onClick={() => this.setState({contactToDelete: row})} 
                                className="fas fa-trash text-danger cursor-pointer" 
                            />
                        ) : null}
                    </div>
                )
            }
        },
    ]

    onTableChange = async (type, newState) => {

        const { searchText } = this.state

        if(type === 'search') {

            this.queryTableDataThrottled(searchText, true)


        } else {

            this.setState({ page: newState.page, sizePerPage: newState.sizePerPage }, () => {
                this.queryTableDataThrottled(searchText)
            })

        }

    }

    onDeleteContact = (contact) => {
        this.onTableChange(null, this.state)
    }

    getQueryParams = (search, skip) => {

        const { opt_out_text, opt_out_email, start_date, end_date, lead_source, blacklisted } = this.props;

        return {
            opt_out_text    : opt_out_text  ? opt_out_text  : undefined,
            opt_out_email   : opt_out_email ? opt_out_email : undefined,
            start_date      : start_date    ? start_date    : undefined,
            end_date        : end_date      ? end_date      : undefined,
            lead_source     : lead_source   ? lead_source   : undefined,
            blacklisted     : blacklisted   ? blacklisted   : undefined,
            search          : search,
            skip            : skip,
            limit           : this.state.sizePerPage,
        }


    }

    queryTableData = async (searchText, setPage1) => {

        this.setState({loading: true});

        let page = this.state.page
        if(setPage1) {
            this.setState({page: 1})
            page = 1
        }

        const skip = (page - 1) * this.state.sizePerPage;

        const contacts = await _contacts.search(this.getQueryParams(searchText, skip))
        this.setState({ data: contacts.data, total_documents: contacts.total_documents, loading: false })

    }


    componentDidMount = async () => {

        const contacts = await _contacts.search(this.getQueryParams(undefined, 0))
        this.setState({data: contacts.data, total_documents: contacts.total_documents})

    }

    render() {

        const { data, loading, contactToDelete } = this.state;
        const { hideHeader, cardTitle } = this.props;
        return (
            <>

            {contactToDelete && (
                <NotificationDelete
                    deletionURL={`/api/v1/core/contacts/delete/${contactToDelete._id}`}
                    confirmBtnText="Delete Contact"
                    title={<span className="text-capitalize">{`Delete ${renderName(contactToDelete)}`}</span>}
                    text="Deleting this contact will remove all cases associated with them. Proceed With Caution!"
                    onClose={() => { this.setState({contactToDelete: null}) }}
                    onSuccess={this.onDeleteContact}
                />
            )}

            <Container fluid>

                {!hideHeader ? (
                    <>
                    <Helmet>
                        <title>{`Contacts (${this.state.total_documents}) `}</title>
                        <meta name="description" content="Contacts" />
                    </Helmet>

                    <DashHeaderOpen
                        icon="fas fa-home"
                        icon_link="/dashboard"
                        title={<span >Contacts</span>} breadcrumb_1="All"
                        actionComponent={privileges.canUpdateContacts() ? (
                            <Link  to="/dashboard/contacts/create" className="btn btn-success">Create Contact</Link>
                        ): null}
                    />
                    </>
                ) : null}
               

                <Card className="card-color card-primary">

                    <CardHeader>
                        <CardTitle className="mb-0 float-left">
                            {cardTitle ? cardTitle : 'All Contacts'}
                        </CardTitle>
                    </CardHeader>

                    {data ? (
                            <ToolkitProvider
                                data={data.length ? data : []}
                                keyField="_id"
                                columns={this.columns}
                                search
                            >
                                {props => (
                                    <>
                                        <div className="dataTables_filter pr-4 pb pt-3 pb-2 position-relative" >
                                            <label>
                                                Search:
                                                <Input
                                                    className="form-control-sm"
                                                    value={this.state.searchText}
                                                    placeholder="Search..."
                                                    style={{width: 190}}
                                                    onChange={(e) => {
                                                        this.setState({searchText: e.target.value})
                                                        this.queryTableDataThrottled(e.target.value, true)
                                                    }}
                                                />
                                                 {loading ? (<Circle style={{display: 'inline-block', top: 1, position: 'absolute'}} /> ) : null}

                                            </label>
                                        </div>

                                        <div className="table-not-fixed table-responsive table-vertical-align">
                                            <BootstrapTable
                                                pagination={paginationFactory({
                                                    totalSize: this.state.total_documents,
                                                    page: this.state.page,
                                                    sizePerPage: this.state.sizePerPage,
                                                    alwaysShowAllBtns: true,
                                                    showTotal: true,
                                                    withFirstAndLast: true,
                                                    sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
                                                        <div className="dataTables_length" id="datatable-basic_length">
                                                            <label>
                                                                Show{" "}
                                                                {
                                                                <select
                                                                    value={currSizePerPage}
                                                                    name="datatable-basic_length"
                                                                    aria-controls="datatable-basic"
                                                                    className="form-control form-control-sm"
                                                                    onChange={e => onSizePerPageChange(e.target.value)}
                                                                >
                                                                    <option value="10">10</option>
                                                                    <option value="25">25</option>
                                                                    <option value="50">50</option>
                                                                    <option value="100">100</option>
                                                                </select>
                                                                }{" "}
                                                                entries.
                                                            </label>
                                                        </div>
                                                    )
                                                })}
                                                {...props.baseProps}
                                                bootstrap4={true}
                                                bordered={false}
                                                remote={{
                                                    search: true,
                                                    pagination: true,
                                                    sort: false,
                                                    cellEdit: false
                                                }}
                                                onTableChange={this.onTableChange}
                                                rowClasses={(row, rowIndex) => {
                                                    if(row.blacklisted) {
                                                        return 'bg-secondary' 
                                                    }
                                                    return '';
                                                }}
                                            />
                                        </div>
                                    </>
                                )}
                            </ToolkitProvider>
                    ) : (
                       <Circle />
                    )}

                </Card>

            </Container>

            </>
        );
    }
}

export default ContactsAll
