import { useCallback, useState } from 'react'
import PropTypes from 'prop-types'

import { Modal, FormGroup, Input } from "reactstrap";

import { toggleStandardLoader } from 'store/functions/system/system';
import moment from 'moment';

import _automations from '_functions/automations'
import { toggleAlertBS } from 'store/functions/system/system';

const ModalUpdateRunDates = ({showModal, toggleModal, contactId, getAutomations}) => {

    const [errs, setErrs] = useState([]);
    const [runDate, setRunDate] = useState('');

    const onSave = useCallback(async () => {

        const _errs = [];

        if(!runDate) _errs.push('runDate');

        setErrs(_errs);
        if(_errs.length) return;

        toggleStandardLoader(true);
        const updated = await _automations.assigned.updateRunDates(contactId, { next_run_date: parseInt(moment(runDate).format('X')) })
        if(updated.success) {
            getAutomations(contactId)
            toggleModal()
            toggleAlertBS(false, 'Next run date updated successfully')
            setRunDate('')
        } else {
            toggleAlertBS(true, `Something went wrong, please try again`)
        }

        toggleStandardLoader(false);

    }, [runDate, contactId, getAutomations, toggleModal])

    return (
        <Modal
            className="modal-dialog-centered"
            isOpen={showModal}
            toggle={toggleModal}
            size="md"
        >

            <div className="modal-header">
                <h5 className="modal-title">Update Next Automation Date</h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModal}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>

            <div className="modal-body">

                <FormGroup>
                    <label className="form-control-label">Next Run Date*</label>
                    <p className="text-sm mb-0">By updating the next run date here, all automations assigned to this cases contact will be set to run next on the day specified below.</p>
                    <Input 
                        type="date"
                        value={runDate}
                        onChange={(e) => setRunDate(e.target.value)}
                        invalid={errs.includes('runDate')}
                    />
                </FormGroup>

            </div>

            <div className="modal-footer text-right">
                <button className="btn btn-success" onClick={onSave}>
                    <i className="fas fa-save mr-2" />
                    Save
                </button>
            </div>

        </Modal>

    )

}

ModalUpdateRunDates.propTypes = {
    toggleModal   : PropTypes.func.isRequired,
    showModal     : PropTypes.bool.isRequired,
}

export default ModalUpdateRunDates
