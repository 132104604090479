import States from 'components/markup/inputs/States';
import React from "react";
import { connect } from 'react-redux';
import { Button, Card, CardHeader, CardTitle, CardFooter, Col, FormGroup, Input, Row } from "reactstrap";
import { setViewingUser } from 'store/functions/auth/auth';
import { toggleAlertBS } from 'store/functions/system/system'
import { toggleStandardLoader } from 'store/functions/system/system'
import keys from 'keys';
import { formatPhone } from 'utils/text'

import validator from 'utils/validator';

import _users from  '_functions/users';
import { CopyToClipboard } from "react-copy-to-clipboard";

const required_fields = [
    'given_name',
    'family_name',
    'email',
    'phone',
    'address_line_1',
    'city',
    'state',
    'postal_code',
    'call_phone_default',
]

class AccountDetails extends React.Component {

    state = {
        user: Object.assign({}, this.props.viewing_user),
        canSave: true
    }

    // give an error if the email or phone that have been given are in the system
    validateEmailAndPhone = () => {
        return new Promise (async resolve => {

            const user = this.state.user

            // query database to get any users with the same email or phone number
            const values = await Promise.all([
                _users.find(`?filter=phone__${user.phone}`),
                _users.find(`?filter=email__${user.email}`),
            ])

            const emailExists = values[1]
            const phoneExists = values[0]

            // if we have an error with the database send an error, we don't want duplicates
            let emailError = !emailExists.success;
            let phoneError = !phoneExists.success;

            if(emailExists.success) {

                // loop through all users and let a match pass if the email found was for the current user
                emailExists.data.forEach(user => {
                    if(user._id.toString() !== this.props.viewing_user._id.toString()) { emailError = true }
                })

            }

            if(phoneExists.success) {

                // loop through all users and let a match pass if the phone found was for the current user
                phoneExists.data.forEach(user => {
                    if(user._id.toString() !== this.props.viewing_user._id.toString()) { phoneError = true }
                })

            }

            // set state if we have an error
            if(emailError) { this.setState({emailExists: true, user: {...this.state.user, emailState: 'invalid'} }); }
            if(phoneError) { this.setState({phoneExists: true, user: {...this.state.user, phoneState: 'invalid'} }); }

            // return true if we have any errors
            resolve(emailError || phoneError ? true : false)


        })
    }

    onSave = async () => {

        toggleStandardLoader(true)

        const validateFields = validator.requiredFields('user', required_fields, this)
        const uniqueError = await this.validateEmailAndPhone()

        const newState = validateFields.state

        //delete fields we do not want to send
        delete newState.new_password
        delete newState.new_password_confirm
        delete newState.picture_url

        if(validateFields.success && !uniqueError) {

            const updated = await _users.update(this.props.viewing_user._id, newState)

            if(updated.success) {
                toggleAlertBS(false, `Your profile has been updated successfully.`)
                setViewingUser()
            }

        }

        toggleStandardLoader(false)

    }

    onInputChange = (e, stateField) => {
        validator.onInputChange(e.target.value, 'user', stateField, this)
    }

    render() {

        const { emailExists, phoneExists, user } = this.state
        const bookLink = `${keys.BOOK_URL}?u=${user._id}`

        return (

            <>

            <div className="alert alert-info">
                Link To Book Me By Consultation: {bookLink} 
                <CopyToClipboard
                    text={bookLink}
                    onCopy={() => {
                        this.setState({ copiedText: true }, () => {
                            setTimeout(() => { this.setState({ copiedText: false }) }, 1000)
                        })
                    }}
                >
                    <i className="fas fa-clipboard text-white ml-3 cursor-pointer" />
                </CopyToClipboard>
                {this.state.copiedText ? ' COPIED!' : ''}
            </div>

            <Card>

                <CardHeader>
                    <CardTitle className="mb-0">Details</CardTitle>
                </CardHeader>

                <CardHeader>

                    <h6 className="heading-small text-muted mb-4">User information</h6>

                    <Row>

                        <Col lg="6">
                            <FormGroup>
                                <label className="form-control-label" htmlFor="given_name" >
                                    First name
                                </label>
                                <Input
                                    id="given_name"
                                    value={user.given_name || ''}
                                    placeholder="First name"
                                    type="text"
                                    valid={ user.given_nameState === "valid" }
                                    invalid={ user.given_nameState === "invalid" }
                                    onChange={e => this.onInputChange(e, "given_name") }
                                />
                                <div className="valid-feedback">Looks good!</div>
                            </FormGroup>
                        </Col>

                        <Col lg="6">
                            <FormGroup>
                                <label className="form-control-label" htmlFor="family_name" >
                                    Last name
                                </label>
                                <Input
                                    id="family_name"
                                    value={user.family_name || ''}
                                    placeholder="Last name"
                                    type="text"
                                    valid={ user.family_nameState === "valid" }
                                    invalid={ user.family_nameState === "invalid" }
                                    onChange={e => this.onInputChange(e, "family_name") }
                                />
                                <div className="valid-feedback">Looks good!</div>
                            </FormGroup>
                        </Col>

                    </Row>

                    <Row>

                        <Col lg="6">
                            <FormGroup>
                                <label className="form-control-label" htmlFor="email">
                                    Email address
                                </label>
                                <Input
                                    id="email"
                                    value={user.email || ''}
                                    placeholder="email@example.com"
                                    type="email"
                                    valid={ user.emailState === "valid" }
                                    invalid={ user.emailState === "invalid" }
                                    onChange={e => this.onInputChange(e, "email") }
                                />
                                <div className="valid-feedback">Looks good!</div>
                            </FormGroup>
                        </Col>

                        <Col lg="6">
                            <FormGroup>
                                <label className="form-control-label" htmlFor="phone">
                                    Phone
                                </label>
                                <Input
                                    id="phone"
                                    value={user.phone || ''}
                                    placeholder="555-555-5555"
                                    type="tel"
                                    valid={ user.phoneState === "valid" }
                                    invalid={ user.phoneState === "invalid" }
                                    onChange={e => this.onInputChange(e, "phone") }

                                />
                                <div className="valid-feedback">Looks good!</div>
                            </FormGroup>
                        </Col>

                    </Row>

                </CardHeader>

                <CardHeader>

                    <h6 className="heading-small text-muted mb-4">Address</h6>

                    <Row>

                        <Col md="6">
                            <FormGroup>
                                <label className="form-control-label" htmlFor="address_line_1" >
                                    Address Line 1
                                </label>
                                <Input
                                    id="address_line_1"
                                    value={user.address_line_1 || ''}
                                    placeholder="198 Manhattan Ave"
                                    type="text"
                                    valid={ user.address_line_1State === "valid" }
                                    invalid={ user.address_line_1State === "invalid" }
                                    onChange={e => this.onInputChange(e, "address_line_1") }
                                />
                                <div className="valid-feedback">Looks good!</div>
                            </FormGroup>
                        </Col>

                        <Col md="6">
                            <FormGroup>
                                <label className="form-control-label" htmlFor="address_line_2" >
                                    Address Line 2
                                </label>
                                <Input
                                    id="address_line_2"
                                    value={user.address_line_2 || ''}
                                    placeholder="Apt D"
                                    type="text"
                                    valid={ user.address_line_2State === "valid" }
                                    invalid={ user.address_line_2State === "invalid" }
                                    onChange={e => this.onInputChange(e, "address_line_2") }
                                />
                                <div className="valid-feedback">Looks good!</div>
                            </FormGroup>
                        </Col>

                    </Row>

                    <Row>

                        <Col lg="4">
                            <FormGroup>
                                <label className="form-control-label" htmlFor="city" >
                                    City
                                </label>
                                <Input
                                    id="city"
                                    value={user.city || ''}
                                    placeholder="Manhattan"
                                    type="text"
                                    valid={ user.cityState === "valid" }
                                    invalid={ user.cityState === "invalid" }
                                    onChange={e => this.onInputChange(e, "city") }
                                />
                                <div className="valid-feedback">Looks good!</div>
                            </FormGroup>
                        </Col>

                        <Col lg="4">
                            <FormGroup>
                                <label className="form-control-label" htmlFor="state" >
                                    State
                                </label>
                                <Input
                                    id="state"
                                    type="select"
                                    value={user.state || ''}
                                    valid={ user.stateState === "valid" }
                                    invalid={ user.stateState === "invalid" }
                                    onChange={e => this.onInputChange(e, "state") }
                                >
                                    <option value="">Select State</option>
                                    <States />
                                </Input>
                                <div className="valid-feedback">Looks good!</div>
                            </FormGroup>
                        </Col>

                        <Col lg="4">
                            <FormGroup>
                                <label className="form-control-label" htmlFor="postal_code" >
                                    Postal code
                                </label>
                                <Input
                                    id="postal_code"
                                    value={user.postal_code || ''}
                                    placeholder="Apt D"
                                    type="text"
                                    valid={ user.postal_codeState === "valid" }
                                    invalid={ user.postal_codeState === "invalid" }
                                    onChange={e => this.onInputChange(e, "postal_code") }
                                />
                                <div className="valid-feedback">Looks good!</div>
                            </FormGroup>
                        </Col>

                    </Row>

                    <h6 className="heading-small text-muted mb-4">Call Center</h6>

                    <FormGroup>
                        <label className="form-control-label" htmlFor="call_phone_default" >
                            Calling Type
                        </label>
                        <Input
                            id="call_phone_default"
                            value={user.call_phone_default || ''}
                            placeholder="198 Manhattan Ave"
                            type="select"
                            valid={ user.call_phone_defaultState === "valid" }
                            invalid={ user.call_phone_defaultState === "invalid" }
                            onChange={e => this.onInputChange(e, "call_phone_default") }
                        >
                            <option value="phone">Phone</option>
                            <option value="browser">Browser</option>
                        </Input>
                        <div className="valid-feedback">Looks good!</div>
                    </FormGroup>

                    {emailExists ? (<div className="alert alert-danger mt-3">Unable to update your account. An account already exists with the email: {user.email}.</div> ) : null}
                    {phoneExists ? (<div className="alert alert-danger mb-0">Unable to update your account. An account already exists with the phone number: {formatPhone(user.phone)}.</div> ) : null}

                </CardHeader>

                <CardFooter className="text-right">
                    <Button
                        color="success"
                        onClick={this.onSave}
                        disabled={this.state.canSave ? false : true}
                    >
                        Save Profile
                    </Button>
                </CardFooter>

            </Card>

            </>

        );
    }
}

const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,
    };
};

export default connect(mapStateToProps, '')(AccountDetails);
