/*
Documentation

this component renders the card showing all missing documents on the case

*/

import ReactSelect from 'components/functional/inputs/ReactSelect';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from "react";
import { Card, CardFooter, CardHeader, CardTitle, UncontrolledTooltip, Row, Col, CardBody, Input, FormGroup } from "reactstrap";

import ModalSubmitToPatriot from './modals/ModalSubmitToPatriot'
import ModalMissingDocs from './modals/ModalMissingDocs'
import ModalAddMissingDocs from './modals/ModalAddMissingDocs'
import ModalSaveMissingDocs from './modals/ModalSaveMissingDocs'
import _cases from '_functions/cases';
import * as privileges from '_functions/users/privileges'
import ImageRender from 'components/functional/images/ImageRenderer'
import { io } from 'sockets';
import * as socketEvents from 'sockets/events';
import A from 'components/markup/links/A'
import { capitalize } from 'utils/text'

import Packages from './Packages'

import { toggleStandardLoader, toggleAlertBS } from 'store/functions/system/system';

import SendEmail from 'components/system/SendEmail';
import SendEmailModalWrapper from 'components/system/SendEmail/ModalWrapper';

class DocumentsMissing extends React.Component {

    state = {
        final_package_link: this.props.case.final_package_link ? this.props.case.final_package_link : '',
        missing_docs: null,
        missing_doc: null,
        showModalSubmitToPatriot: false,
        showModal: false,
        showModalAddMissingDoc: false,
        showModalSaveMissingDocsDoc: false,
        showModalEmail: false,
    }

    onSuccessAddMissingDoc = (doc) => {

        const missing_docs = this.state.missing_docs ? [...this.state.missing_docs] : []
        missing_docs.push(doc)

        this.setState({missing_docs})

    }


    // get everything after . or return "Media Object"
    getDocumentName = (link) => {

        if(!link) return 'Misc Document'

        const arr = link.split('/')

        return arr ? arr[arr.length -1] : 'Misc Document'

    }

    toggleModal = (missing_doc, bool) => this.setState({showModal: bool ? bool : !this.state.showModal, missing_doc})
    toggleModalAddMissingDoc = () => this.setState({showModalAddMissingDoc: !this.state.showModalAddMissingDoc})
    toggleModalSubmitToPatriot = () => this.setState({showModalSubmitToPatriot: !this.state.showModalSubmitToPatriot})
    toggleModalSaveMissingDocsDoc = () => this.setState({showModalSaveMissingDocsDoc: !this.state.showModalSaveMissingDocsDoc})
    toggleModalEmail = () => this.setState({showModalEmail: !this.state.showModalEmail})


    onRemoveMissingDoc = (missing_doc, document) => {

        let missing_docs = this.state.missing_docs ? [...this.state.missing_docs] : []

        const found_doc = missing_docs.find(doc => doc._id === missing_doc._id)

        if(found_doc) {

            let documents = found_doc.documents ? found_doc.documents : []
            documents = documents.filter(d => {

                const doc_id = d._id ? d._id : d;
                return doc_id !== document._id
            })

            found_doc.documents = documents

            this.setState({missing_docs})
            this.onSaveMissingDocs()

        }
        
    }

    onSaveFinalPackage = async (sendEmail) => {

        toggleStandardLoader(true);
        const updated = await _cases.update(this.props.case._id, { final_package_link: this.state.final_package_link })
        toggleStandardLoader(false);

        if(updated.success) {
            toggleAlertBS(false, 'Final Report Link Updated Successfully');
        } else {
            toggleAlertBS(true, `Something went wrong, please try again.`);
        }

    }
    onSaveMissingDocs = async (sendEmail) => {

        toggleStandardLoader(true);
        const updated = await _cases.update(this.props.case._id, { missing_docs: this.state.missing_docs })
        toggleStandardLoader(false);


        if(updated.success) {
            if(sendEmail) {
                this.toggleModalEmail()
            } else {
                toggleAlertBS(false, 'Docs Updated Successfully');
            }
        } else {
            toggleAlertBS(true, `Something went wrong, please try again.`);
        }

    }

    onMissingDocChange = (missing_doc_id, values) => {

        let missing_docs = this.state.missing_docs ? [...this.state.missing_docs] : []
        const doc = missing_docs[missing_docs.findIndex(doc => doc._id === missing_doc_id)]

        doc.status_marked_at = Math.floor(new Date() / 1000);
        doc.status_marked_by = this.props.viewing_user._id;
        doc.status = values.value

        this.setState({missing_docs})

    }

    onDocsAssigned = (missing_docs) => {
        this.setState({missing_docs})

    }

    componentWillReceiveProps = (nextProps) => {

        if(this.props.lastDocsAssigned && this.props.lastDocsAssigned.timestamp !== nextProps.lastDocsAssigned.timestamp) {
            this.setState({missing_docs: nextProps.lastDocsAssigned.missing_docs})
        }

    }

    onTemplateDocAdded = (data) => {

        if(data.data.document.case === this.props.case._id) {

            const missing_docs = [...this.props.case.missing_docs];
            const foundDoc = missing_docs.find(d => d.name === data.data.templateDocName);

            if(foundDoc) {
                foundDoc.documents.push(data.data.document);
                this.setState({missing_docs})
            }

        }

    }

    componentWillUnmount = () => {
        
        io.off(socketEvents.case_template_doc_added, this.onTemplateDocAdded)

    }

    componentDidMount = () => {

        this.setState({missing_docs: this.props.case.missing_docs});

        io.on(socketEvents.case_template_doc_added, this.onTemplateDocAdded)

    }

    render() {

        const canUpdate = privileges.canUpdateCases()

        const { missing_doc, missing_docs, showModal, showModalAddMissingDoc, showModalSubmitToPatriot, showModalSaveMissingDocsDoc, showModalEmail } = this.state
        const { documents, setNav } = this.props;
        const _case = this.props.case

        const isMobile = this.props.device.info.isMobile;

        return (

            <>

            <Card>

                <CardHeader>
                    <CardTitle className="mb-0">
                        <Row>

                            <Col xs={6}>
                                Missing Docs
                            </Col>

                            <Col xs={6} className="text-right">
                                <button
                                    style={{width: 160}}
                                    onClick={canUpdate ? this.toggleModalAddMissingDoc : null }
                                    disabled={!canUpdate}
                                    className="btn btn-outline-success"
                                >
                                    Add Missing Doc
                                </button>
                            </Col>

                        </Row>
                    </CardTitle>
                </CardHeader>

                <div className="table-responsive" style={{overflow: isMobile ? 'auto': 'visible'}}>
                    <table className="table">
                        <thead>
                            <tr>
                                <th style={{width: 300}}>Doc Name</th>
                                <th>Uploaded</th>
                                <th className="text-right" style={{width: 150}}>Add Document</th>
                                <th className="text-right" style={{width: 200, minWidth: 200}}>Status</th>
                            </tr>
                        </thead>

                        <tbody>
                            {missing_docs && missing_docs.length ? missing_docs.map((doc, i) => (
                                <tr key={doc._id ? doc._id : i}>
                                    <td style={{verticalAlign: 'top', whiteSpace: 'pre-line'}}>
                                        <span id={'missing-doc-description-' + doc._id } className={doc.description ? 'cursor-pointer' : ''}>
                                            {doc.description && (
                                                <i className="fas fa-info-circle text-info-original mr-2" />
                                            )}
                                            {doc.name ? capitalize(doc.name.replace(/\_/g, ' ')) : doc.name}
                                        </span>
                                        {doc.description && (
                                            <UncontrolledTooltip
                                                delay={0}
                                                placement="top"
                                                target={'missing-doc-description-' + doc._id}
                                            >
                                            {doc.description}
                                            </UncontrolledTooltip>
                                        )}
                                    </td>
                                    <td style={{verticalAlign: 'top'}}>
                                        {doc.documents && doc.documents.length ? doc.documents.map((document, i) => {

                                            if(!document._id) {

                                                // somehow document.find can give a not found error, check that documents are an array here before
                                                // running the find function documents
                                                const foundDoc = documents && documents.length && documents.find(d => d._id === document);
                                                if(foundDoc) {
                                                    document = foundDoc;
                                                } else {
                                                    // don't show any documents that cannot be found
                                                    return '';
                                                }

                                            }

                                            return (
                                                <div key={document._id + i}>
                                                    <span className="border-bottom">
                                                        <span style={{display: 'inline-block', width: 230}}>
                                                            <ImageRender 
                                                                maxNameLength={20}
                                                                doc={document}
                                                            />
                                                        </span>
                                                        <i 
                                                            onClick={() => this.onRemoveMissingDoc(doc, document)} 
                                                            className="fas fa-trash ml-3 text-danger cursor-pointer" 
                                                        />
                                                    </span>  
                                                </div>
                                            )
                                        }) : '-'}
                                    </td>
                                    <td style={{verticalAlign: 'top'}}  className="text-right">
                                        {doc.name === 'Completed Financial Information Checklist' ? (
                                            <button 
                                                style={{height: 38}} 
                                                onClick={() => setNav(20)} 
                                                className="btn btn-outline-info ml-4"
                                            >
                                                <i className="fas fa-eye mr-4" /> View
                                            </button>
                                        ) : (
                                            <button 
                                                style={{height: 38}} 
                                                onClick={() => this.toggleModal(doc)} 
                                                className="btn btn-outline-success ml-4"
                                            >
                                                <i className="fas fa-plus" /> Upload
                                            </button>
                                        )}
                                        
                                    </td>
                                    <td style={{verticalAlign: 'top'}}>
                                        <ReactSelect
                                            defaultValue={{
                                                label: doc.status === 'n/a' ? 'N/A' :
                                                    doc.status === 'completed' ? <span><i className="fas fa-check mr-3 text-success" />Completed </span> :
                                                    doc.status === 'pending' ? <span><i className="fas fa-info text-info-original mr-3 text-success" />Pending </span> :
                                                    <span><i className="fas fa-times mr-3 text-danger" />Missing</span>,
                                                value: doc.status
                                            }}
                                            onChange={(values) => this.onMissingDocChange(doc._id, values)}
                                            options={[
                                                {value: 'n/a', label: 'N/A' },
                                                {value: 'missing', label: <span><i className="fas fa-times mr-3 text-danger" />Missing</span> },
                                                {value: 'pending', label: <span><i className="fas fa-info mr-3 text-info-original" />Pending</span> },
                                                {value: 'completed', label: <span><i className="fas fa-check mr-3 text-success" />Completed </span> },
                                            ]}
                                        />
                                    </td>
                                </tr>
                                )) : (
                                <tr>
                                    <td>This case has no missing documents.</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            )}
                        </tbody>

                    </table>
                </div>

                {missing_docs && missing_docs.length ? (
                    <CardFooter className="text-right">

                        {_case.final_package_link ? (
                            <A href={_case.final_package_link} className="btn btn-info"><i className="fas fa-download mr-2 " /> Final Report</A>
                        ) : null}
                        
                        <button className="btn btn-outline-warning" onClick={this.toggleModalSubmitToPatriot}>
                            Submit To Patriot
                        </button>

                        <button
                            onClick={this.toggleModalSaveMissingDocsDoc}
                            className="btn btn-success"
                            disabled={!canUpdate}
                        >
                            Save Missing
                        </button>
                    </CardFooter>
                ) : null}

            </Card>

            <Packages 
                case={_case}
            />

            <ModalSubmitToPatriot 
                case_id={_case._id}
                showModal={showModalSubmitToPatriot}
                toggleModal={this.toggleModalSubmitToPatriot}
                setNav={setNav}

            />

            <ModalMissingDocs
                case={_case}
                missing_doc={missing_doc}
                showModal={showModal}
                toggleModal={() => this.toggleModal(undefined, false)}
            />

            <ModalAddMissingDocs
                case={_case}
                showModal={showModalAddMissingDoc}
                toggleModal={this.toggleModalAddMissingDoc}
                onSuccess={this.onSuccessAddMissingDoc}
            />

            <ModalSaveMissingDocs 
               showModal={showModalSaveMissingDocsDoc}
               toggleModal={this.toggleModalSaveMissingDocsDoc}
               onSaveMissingDocs={this.onSaveMissingDocs}
            />

            <SendEmailModalWrapper
                showModal={showModalEmail}
                toggleModal={this.toggleModalEmail}
            >
                <SendEmail
                    template_email="Documents Updated"
                    onSendingStart={this.toggleModalEmail}
                    onError={() => toggleAlertBS(true, 'Your email was not sent, please try again or send it from your Office 365 account.')}
                    subject="Biz Head Law"
                    to={_case.contact && _case.contact.email ? [_case.contact.email]   : []}
                    contact={_case.contact ? _case.contact._id : undefined}
                    case={_case ? _case._id : undefined}
                    division={_case ? _case.division : undefined}
                />
            </SendEmailModalWrapper>


            </>

        );
    }
}

DocumentsMissing.propTypes = {
    case: PropTypes.object.isRequired
}


const mapStateToProps = state => {
    return {
    	viewing_user: state.auth.viewing_user,
    	device: state.device,
    };
};

export default connect(mapStateToProps, '')(DocumentsMissing);
