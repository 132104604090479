/*
Documentation

toggleAlert params

1 - boolean
this is either true or false. True will show the alert false will hide it

2 - type
one of: success, danger, warning, info

3 - text
this is either a react component or a string of text to show in the alert

4 - time
the time in miliseconds to keep the alert up
default is 5000
if is string "infinite" the alert will never go away

*/

import { architeckCall } from 'database';
import * as actionTypes from '../../actions';
import store from 'store';
import * as callCenterActions from 'store/functions/call_center/call_center'

import { Device } from 'twilio-client';
import renderName from 'utils/renderName'

import _nylas from '_functions/nylas'
let timeout;

export const showStepsToPetitionPrep = (payload) => {
    store.dispatch({ type: actionTypes.SET_STEPS_TO_PETITION_PREP, payload, });
}


export const setDocumentLoading = (payload) => {
    store.dispatch({ type: actionTypes.SET_DOCUMENT_LOADING, payload, });
}


export const setOnlineStatus = (bool) => {
    store.dispatch({ type: actionTypes.SET_ONLINE_STATUS, payload: bool, });
}


// @param - array of document objects
export const setDocumentsForMerge = (payload) => {
    store.dispatch({ type: actionTypes.SET_DOCUMENTS_FOR_MERGE, payload, });
}

export const toggleStandardLoader = (boolean) => {

    if(boolean === true) {
        window.onbeforeunload = (event) => {
            event.preventDefault();
            return 'You content is still loading, if you leave this page you may have to refresh your window and progress may not be saved.';
        };
        window.onpopstate = (event) => {
            event.preventDefault();
            return 'You content is still loading, if you leave this page you may have to refresh your window and progress may not be saved.';
        };
        // window.addEventListener('popstate', unsavedProgress);
    } else {
        window.onbeforeunload = null;
        window.onpopstate = null;

    }

    store.dispatch({ type: actionTypes.TOGGLE_STANDARD_LOADER, payload: boolean, });
}

export const toggleAlertBS = (error, text) => {
    store.dispatch({ type: actionTypes.TOGGLE_SYSTEM_ALERT_BS, payload: { error, text } });
}

export const toggleHelpCenter = (body, footer) => {
    store.dispatch({ type: actionTypes.TOGGLE_HELP_CENTER, payload: { body, footer } });
}

export const toggleLoader = (boolean) => {
    store.dispatch({ type: actionTypes.TOGGLE_SYSTEM_LOADER, payload: boolean });
}

export const toggleDevTickets = (boolean) => {
    store.dispatch({ type: actionTypes.TOGGLE_DEV_TICKETS, payload: boolean }); 
}

export const toggleRecentActivity = (show) => {

    if(show) {
        document.body.classList.add('noScroll')
    } else {
        document.body.classList.remove('noScroll')
    }

    store.dispatch({ type: actionTypes.TOGGLE_RECENT_ACTIVITY, payload: { show } });

}

export const setIncomingCall = (payload) => {
    store.dispatch({
        type: actionTypes.SET_INCOMING_CALL,
        payload: payload
    });
}

export const setOffCall = () => {
    document.body.classList.remove('archk-bg-on-call')

    store.dispatch({
        type: actionTypes.SET_STATE,
        payload: {
            on_call: {}
        }
    });
}


// for params see fetchConnectionParams
export const setOnCall = (params) => {

    document.body.classList.add('archk-bg-on-call')
 
    store.dispatch({
        type: actionTypes.SET_STATE,
        payload: {
            on_call: params
        }
    });

}

const fetchConnectionParams = (conn) => {
    return {
        call_phone_number_id        : conn.customParameters.get('call_phone_number_id'),
        call_phone_number           : conn.customParameters.get('call_phone_number'),
        company_id                  : conn.customParameters.get('company_id'),
        company_name                : conn.customParameters.get('company_name'),
        division_id                 : conn.customParameters.get('division_id'),
        division_name               : conn.customParameters.get('division_name'),
        lead_source_id              : conn.customParameters.get('lead_source_id'),
        lead_source_name            : conn.customParameters.get('lead_source_name'),
        case_id                     : conn.customParameters.get('case_id'),
        case_name                   : conn.customParameters.get('case_name'),
        contact_id                  : conn.customParameters.get('_id') ? conn.customParameters.get('_id') : conn.customParameters.get('contact_id'),
        contact_identifier          : conn.customParameters.get('contact_identifier') ? conn.customParameters.get('contact_identifier') : conn.customParameters.get('given_name') + ' ' +conn.customParameters.get('family_name'),
        contact_given_name          : conn.customParameters.get('given_name'),
        contact_family_name         : conn.customParameters.get('family_name'),
        contact_phone               : conn.customParameters.get('contact_phone'),
        contact_phone_2             : conn.customParameters.get('contact_phone_2'),
        contact_phone_3             : conn.customParameters.get('contact_phone_3'),
        contact_email               : conn.customParameters.get('contact_email'),
        case_manager_id             : conn.customParameters.get('case_manager_id'),
        case_manager_identifier     : conn.customParameters.get('case_manager_identifier'),
        case_manager_given_name     : conn.customParameters.get('case_manager_given_name'),
        case_manager_family_name    : conn.customParameters.get('case_manager_family_name'),
        case_manager_phone          : conn.customParameters.get('case_manager_phone'),
        case_manager_email          : conn.customParameters.get('case_manager_email'),
        is_new                      : conn.customParameters.get('is_new'),
        type                        : conn.customParameters.get('type'),
    }
}

export const toggleAlert = (boolean, type, text, time) => {

    clearTimeout(timeout)

    //set the payload to dispatch the notification
    const payload =  { boolean, text, type }

    //dispatch the store
    store.dispatch({ type: actionTypes.TOGGLE_SYSTEM_ALERT, payload });

    //if we are showing and not hiding the alert
    if(boolean === true) {

        //if second parameter is not true lets set a timeout to hide this alert
        //default is 5 seconds to hide
        if(time !== 'infinite') {

            //set timeout so it can be cleared later
            //useful if another toggleAlert is called while this is still up.
            timeout = setTimeout(() => {
                store.dispatch({ type: actionTypes.TOGGLE_SYSTEM_ALERT, payload: { ...payload, boolean: false, } });
            }, time ? time : 5000);

        }

    }

}


const addOnCallListeners = (conn) => {
    const addListeners = (tries) => {
        if(!tries > 100) return;
        const transfer = document.getElementById('archk-transfer');
        const toggleMute = document.getElementById('archk-toggle-mute');
        const toggleHold = document.getElementById('archk-toggle-hold');
        const endCall = document.getElementById('archk-end-call');

        if(!transfer || !toggleMute || !toggleHold || !endCall) return setTimeout(() => {
            addListeners(tries + 1)
        }, 200)

        // transfer.addEventListener('click', async (e) => {
        //     const transfer = await _call_center.transfer({
        //         call_sid: conn.parameters.CallSid, 
        //         user_id: e.target.innerText,
        //         ...fetchConnectionParams(conn),
        //     })
        //     if(!transfer.success) return toggleAlertBS('info', 'Please try again.')

        //     const transferId = transfer.data.transferId
            
        //     store.dispatch({
        //         type: actionTypes.SET_TRANSFER,
        //         payload: transferId
        //     });

        // })

        toggleMute.addEventListener('click', async () => {
            const state = store.getState();
            const mute = state.state.on_call.mute ? false : true
            Device.activeConnection().mute(state.state.on_call.mute ? false : true);

            store.dispatch({
                type: actionTypes.SET_STATE,
                payload: {
                    on_call: {
                        ...state.state.on_call,
                        mute
                    }
                }
            });

        })

        // toggleHold.addEventListener('click', async () => {
        //     const state = store.getState();
        //     const setOnHold = await _call_center.toggleHold({
        //         callSid: conn.parameters.CallSid, 
        //         contact_id: conn.customParameters.get('contact_id'),
        //         hold: state.state.on_call.hold ? false : true
        //     })

        //     if(setOnHold.data) {
        //         store.dispatch({
        //             type: actionTypes.SET_STATE,
        //             payload: {
        //                 on_call: {
        //                     ...state.state.on_call,
        //                     hold: setOnHold.data.hold
        //                 }
        //             }
        //         });
        //     }
            
        // })

        endCall.addEventListener('click', () => {
            Device.disconnectAll();
            setOffCall()
        })
    }
    addListeners(0)
}


// this function will create a device with twilio allowing the user to dial via browser if they have that as their call setting
export const setBrowserDialing = async (user_id) => {
    return new Promise (async resolve => {

        const generateToken = await architeckCall({
            shouldNotLogAndShowError: true,
            method: 'post',
            url: '/api/v1/call_center/actions/tokens/generate',
            data: {
                user_id,
            }
        })

        try {

            // console.log('connect')
            // Device.setup(generateToken.data, {
            //     "iceServers": [ { "url": "turn:global.turn.twilio.com:443?transport=tcp" } ] 
            // });

            Device.setup(generateToken.data, {
                codecPreferences: ["opus", "pcmu"],
                fakeLocalDTMF: true,
                enableRingingState: true,
                sounds: {
                    incoming: '/Ringtone.m4a',
                    // kill the connected sound by placing a bad url here
                    outgoing: 'test'
                }
            });

        } catch(e) {

            console.log(e)
            store.dispatch({type: actionTypes.SET_BROWSER_DIALING_STATUS, payload: { status: 'error'}, });
            return resolve(false)

        }

        // wont fire on login but fires every other time a user refreshes the page
        Device.on('ready', () => {

            store.dispatch({ type: actionTypes.SET_BROWSER_DIALING_STATUS, payload: { status: 'ready'}, });
            return resolve(true)

        })

         Device.on('disconnect', (conn) => {
            setIncomingCall(false);
            setOffCall();
        })
        
        // fired if call not picked up
        Device.on('cancel', (conn) => {
            setIncomingCall(false);
            setOffCall();
        })


        Device.on('connect', (conn) => {   
            const status = document.getElementById('archk-call-status')
            const circle = document.getElementById('archk-call-status-circle')
            if(status) status.innerText = 'Connected'
            if(circle) circle.classList.add('Answered')

            const type = conn.customParameters.get('type')
            const contact_id = conn.customParameters.get('contact_id')

        })
        
        Device.on('incoming', (conn) => {
            setIncomingCall(fetchConnectionParams(conn));

     
            const initListeners = (tries) => {
                if(!tries > 100) return;
                const accept = document.getElementById('archk-accept-call');
                const decline = document.getElementById('archk-decline-call');

                if(!accept || !decline) return setTimeout(() => {
                    initListeners(tries + 1)
                }, 200)

                accept.addEventListener('click', () => {
                    setOnCall({...fetchConnectionParams(conn), isIncoming: true})
                    conn.accept()
                    addOnCallListeners(conn)
                    setIncomingCall(false);
                })

                decline.addEventListener('click', () => {
                    conn.reject()
                    setIncomingCall(false);
                })

            }
            initListeners(0)
        })


        // onReady does not fire on login, believed to be a twilio error as the device is ready after 2 seconds here, needs some testing on other devices
        // #MonkeyPatch
        setTimeout(() => {

            if(Device.status() === 'ready');

            store.dispatch({ type: actionTypes.SET_BROWSER_DIALING_STATUS, payload: { status: 'ready'}, });

            return resolve(true)

        }, 2000)

        Device.on('connect', (conn) => {
            // console.log(conn)

    //         return {
    //     call_phone_number_id        : conn.customParameters.get('call_phone_number_id'),
    //     call_phone_number           : conn.customParameters.get('call_phone_number'),
    //     company_id                  : conn.customParameters.get('company_id'),
    //     company_name                : conn.customParameters.get('company_name'),
    //     division_id                 : conn.customParameters.get('division_id'),
    //     division_name               : conn.customParameters.get('division_name'),
    //     lead_source_id              : conn.customParameters.get('lead_source_id'),
    //     lead_source_name            : conn.customParameters.get('lead_source_name'),
    //     case_id                     : conn.customParameters.get('case_id'),
    //     case_name                   : conn.customParameters.get('case_name'),
    //     contact_id                  : conn.customParameters.get('contact_id'),
    //     contact_identifier          : conn.customParameters.get('contact_identifier'),
    //     contact_given_name          : conn.customParameters.get('contact_given_name'),
    //     contact_family_name         : conn.customParameters.get('contact_family_name'),
    //     contact_phone               : conn.customParameters.get('contact_phone'),
    //     contact_phone_2             : conn.customParameters.get('contact_phone_2'),
    //     contact_phone_3             : conn.customParameters.get('contact_phone_3'),
    //     contact_email               : conn.customParameters.get('contact_email'),
    //     case_manager_id             : conn.customParameters.get('case_manager_id'),
    //     case_manager_identifier     : conn.customParameters.get('case_manager_identifier'),
    //     case_manager_given_name     : conn.customParameters.get('case_manager_given_name'),
    //     case_manager_family_name    : conn.customParameters.get('case_manager_family_name'),
    //     case_manager_phone          : conn.customParameters.get('case_manager_phone'),
    //     case_manager_email          : conn.customParameters.get('case_manager_email'),
    //     is_new                      : conn.customParameters.get('is_new'),
    //     type                        : conn.customParameters.get('type'),
    // }

            if(conn.customParameters.get('_id')) {
                callCenterActions.setOnCall({
                    isIncoming: conn._direction === "INCOMING" ? true : false,
                    contact: conn.customParameters.get('_id'),
                    contact_name: renderName({
                        given_name: conn.customParameters.get('given_name'),
                        family_name: conn.customParameters.get('family_name'),
                    }),
                    contact_phone: conn.customParameters.get('phone'),
                    contact_email: conn.customParameters.get('email'),
                    finishCall: () => {},
                })
            }
           
        })

        // log any device errors
        Device.on('error', async (err) => {

            // if there is an error try to refresh the token
            const refreshedToken = await architeckCall({
                shouldNotLogAndShowError: true,
                method: 'post',
                url: '/api/v1/call_center/actions/tokens/generate',
                data: {
                    user_id,
                }
            })

            try {

                Device.setup(refreshedToken.data);
                console.log('successfully refreshed browser dialing token')

            } catch(e) {

                console.log(err)
                store.dispatch({type: actionTypes.SET_BROWSER_DIALING_STATUS, payload: { status: 'error'}, });
                console.log(e)


            }

        })

    })
}

export const checkNylas = async () => {
    const value = await _nylas.accountStatus()
    const state = store.getState()

    const mayNeedLogin = state.auth.viewing_user.privileges.includes("can_give_attorney_consultation")

    if(mayNeedLogin && value.success && value.data && value.data.status === 'invalid') {
        store.dispatch({ type: actionTypes.SET_NYLAS_INVALID, payload: true });
    }
}