/*
Documentation

this component shows only emails inside the case feed
it also allows emails to be sent

*/

import SendEmail from 'components/system/SendEmail';
import SendEmailModalWrapper from 'components/system/SendEmail/ModalWrapper';

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { CardHeader } from "reactstrap";
import { toggleAlertBS } from 'store/functions/system/system';
import RenderEmail from './Render/Email';

class Feed extends Component {

    state = {
        feedEmailEntry:     {
            to: this.props.case.contact.email,
            attachments: []
        },
        reactQuillText:     "",
        showModal: false
    };

    toggleModal = () => this.setState({showModal: !this.state.showModal})

    handleReactQuillChange = value => {
        this.setState({
            reactQuillText: value,
            feedEmailEntry: { ...this.state.feedEmailEntry, value }
        });
    };

    onEmailSendError = () => toggleAlertBS(true, 'Your email was not sent, please make sure that your account is synced by going to your account settings. If this issue continues try re-authenticating your email account.')

    //this function takes the state feed array and filters it by type equal to the filter value
    filterFeed = (feed ) => {

        if(feed && feed.length ) { feed = feed.filter(f => f.type === 'contact_email') }
        return feed

    }

    render() {

        if(this.props.view !== 'contact_emails') return null

        let { feed, showFeedEntries, isFullScreen, toggleFullScreen, renderNameAndInitial } = this.props
        const { showModal } = this.state
        const _case = this.props.case

        if(!feed) feed = []

        feed = this.filterFeed(feed)
        feed = feed.slice(0, showFeedEntries)

        return (

            <>

                <CardHeader>

                    <p className="font-weight-bold mb-0 text-muted">
                        <i className="fas fa-envelope " />
                        <span className="float-right">
                            <i
                                style={{cursor: 'pointer'}}
                                className={!isFullScreen ? "fas fa-plus text-success pl-4" : "fas fa-minus text-warning ml-3 pl-4"}
                                onClick={toggleFullScreen}
                            />
                        </span>
                    </p>

                    <button onClick={this.toggleModal} className=" mt-2 btn btn-block btn-outline-success">Send Email</button>

                </CardHeader>

                <div id="feed-wrapper" className="feed-wrapper" style={{overflowY: 'auto'}}>
                    <table className="table">
                        <tbody>

                        { feed && feed.length ? (
                            feed.map((entry, i) => (
                                <RenderEmail 
                                    key={i}
                                    entry={entry}
                                    _case={_case}
                                    renderNameAndInitial={renderNameAndInitial}
                                />
                            ) )
                        ) : feed ? (

                            <tr>
                                <td style={{whiteSpace: 'normal'}}>
                                    <div className="item-wrapper">
                                        <h6 className="text-sm mb-0 font-weight-bold">
                                            SYSTEM
                                        </h6>
                                        There are no emails associated with this contact.
                                    </div>
                                </td>
                            </tr>

                        ) : null}

                        </tbody>
                    </table>

                </div>

                <SendEmailModalWrapper
                    showModal={showModal}
                    toggleModal={this.toggleModal}
                >
                    <SendEmail
                        onSendingStart={this.toggleModal}
                        onError={this.onEmailSendError}
                        subject="Biz Head Law"
                        to={_case.contact && _case.contact.email ? [_case.contact.email]   : []}
                        contact={_case.contact ? _case.contact._id : undefined}
                        case={_case ? _case._id : undefined}
                        division={_case ? _case.division : undefined}
                    />
                </SendEmailModalWrapper>

            </>
        )

    }

}

const mapStateToProps = state => {
    return {
    	viewing_user: state.auth.viewing_user,
    	company: state.companies.company,
    };
};

Feed.propTypes = {
    feed                : PropTypes.array.isRequired,
    case                : PropTypes.object.isRequired,
    toggleFullScreen    : PropTypes.func.isRequired,
    isFullScreen        : PropTypes.bool.isRequired,
    showFeedEntries     : PropTypes.number.isRequired,
    view                : PropTypes.string.isRequired,
}

export default connect(mapStateToProps, '')(Feed);
