/*
Documentation

This renders the modal for completing unfinished tasks

*/

import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactDatetime from "react-datetime";
import { connect } from 'react-redux';
import { Col, FormGroup, Input, Modal, Row } from "reactstrap";

import { toggleAlert } from 'store/functions/system/system';
import _case_workflow_tasks from '_functions/case_workflow_tasks';

import renderName from 'utils/renderName'

import FormTypesAppointments from './formTypes/Appointments';
import FormTypesText from './formTypes/Text';
import FormTypesUpload from './formTypes/Upload';
import FormTypesContact from './formTypes/Contact';
import FormTypesCustomData from './formTypes/CustomData';
import FormTypesSetCaseRole from './formTypes/SetCaseRole';
import FormTypesSetChapter from './formTypes/SetChapter';
import FormTypesSetAssignedUser from './formTypes/SetAssignedUser';
import FormTypesTagQuestion from './formTypes/TagQuestion';
import FormTypesSetCaseNumber from './formTypes/SetCaseNumber';
import FormTypesSendTemplateDoc from './formTypes/SendTemplateDoc';
import FormTypesSetCourt from './formTypes/SetCourt';
import FormTypesSetOffice from './formTypes/SetOffice';
import FormTypesSelect from './formTypes/Select';
import FormTypesSetFeePercent from './formTypes/SetFeePercent';
import FormTypesSetMarketingReservePercent from './formTypes/SetMarketingReservePercent';
import FormTypesSetTotalERTC from './formTypes/SetTotalERTC';

const oneDay = 86400;
const warningInterval = 1 //number of days to show warning for

class ModalUnfinished extends Component {

    state = {
        filesNumberToUpload: 0,
        filesUploadedForTask: [],
        shouldFireUploads: false,
        time_now: Math.floor(new Date() / 1000),
        editing_task: {},
        documents_uploaded: 0,
        documents_total: 0,
        assigned_to: null,
        canSave: true,
        appointment: null
    };

    onDateChange = (value) => {

        const date = moment.unix(value._d).format('X') / 1000

        this.setState({
            has_new_date: true,
            editing_task: { ...this.state.editing_task, date }
        })
    }

    onUpdateTaskDueDate = () => {

        const case_workflow_task_id = this.state.editing_task._id;
        const date = this.state.editing_task.date

        _case_workflow_tasks.update(case_workflow_task_id, { date}  )

        this.setState({ has_new_date: false })

    }

    onAssignedToChange = (value) => this.setState({assigned_to: value});

    assignUserToTask = (type, user_id) => {

        let assigned_to = [...this.state.editing_task.assigned_to];

        //this code block is used to updated the state and reflect changes
        if(type === 'patch') {

            if(!this.state.assigned_to) return

            let found_user = this.props.users.find(u => u._id === this.state.assigned_to);

            if(found_user) {

                assigned_to.push(found_user)

            } else {

                return toggleAlert(true, 'warning', 'You must select a valid user to assign.', 5000)

            }

        } else {

            if(!user_id) { return toggleAlert(true, 'warning', 'You must select a valid user to assign.', 5000) }
            assigned_to = assigned_to.filter(u => u._id !== user_id)

        }

        this.setState({ assigned_to: null })

        const case_workflow_task_id = this.state.editing_task._id

        if(type === 'patch') {

            _case_workflow_tasks.update(case_workflow_task_id, { assign_user: this.state.assigned_to, user: this.props.viewing_user._id })

        } else {

            _case_workflow_tasks.update(case_workflow_task_id, { remove_user: user_id, user: this.props.viewing_user._id })

        }

    }

    renderDueText = (task, time_now) => {

        let color = '';
        let overdue = false

        if(task.finished)   { return <span className="font-weight-bold text-success">Finished</span> }
        if(!task.date)      { return 'No Due Date ' }

        if(task.date < time_now) {
            color = 'text-danger text-uppercase text-undrline';
            overdue = true
        }

        if(task.date > time_now && task.date < (time_now + (oneDay * warningInterval))) {
            color = 'text-warning';
        }

        return  (
            <span className={`font-weight-bold ${color}`}>Task Due: {moment.unix(task.date).fromNow()} {overdue ? '!!!' : ''} </span>
        )

    }

    // look through user props and get users that we have the ability to assign to the task
    getUsersToAssign = () => {

        const _case = this.props.case;
        const editing_task = this.state.editing_task || {}

        let users = [];

        editing_task.assigned_to = editing_task.assigned_to ? editing_task.assigned_to : []

        if(_case.roles && _case.roles.length) {

            _case.roles.forEach(r => {

                if(editing_task.assigned_to && r.user) {

                    if(!editing_task.assigned_to.find(a => a._id === r.user._id)) {
                        users.push(r.user)
                    }

                }

            })

        }

        if(users.length) {
            return users.map((u, i) => <option value={u._id} key={u._id + i}>{u.given_name} {u.family_name}</option> )
        } else {
            return <option value="false">No Users To Assign</option>
        }

    }

    componentDidMount = () => {
        this.setState({editing_task: Object.assign({}, this.props.editing_task)})
    }

    componentWillReceiveProps = (nextProps) => {
        const currentTask = this.state.editing_task;
        const nextTask = nextProps.editing_task;
        if(currentTask && nextTask && currentTask._id !== nextTask._id) {
            this.setState({editing_task: Object.assign({}, nextProps.editing_task)})
        }
    }

    render() {

        const time_now = this.state.time_now
        const _case = this.props.case;
        const editing_task = this.state.editing_task || {}
        const { showModal, case_workflow_id, forceRefresh } = this.props

        return (

            <>

            <Modal
                className="modal-dialog-centered modal-unfinished-tasks"
                isOpen={showModal}
                size="lg"
            >

                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                        Update Task ( {this.renderDueText(editing_task, time_now)}) {editing_task.required && <span className="text-danger"><i className="fas fa-exclamation-triangle mr-2 " />REQUIRED</span>}
                    </h5>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => {
                            this.props.toggleModal();
                            this.setState({ has_new_date: false});
                        }}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>

                <div className="modal-body">

                    {editing_task.description ? (
                        <p className="text-sm"><i className="fas fa-info-circle mr-2 text-info-original" />{editing_task.description}</p>
                    ) : null}

                    <Row>

                        <Col md={8}>
                            <FormTypesText            forceRefresh={forceRefresh} case_workflow_id={case_workflow_id} editing_task={editing_task} toggleModal={this.props.toggleModal} />
                            <FormTypesUpload          forceRefresh={forceRefresh} case_workflow_id={case_workflow_id} editing_task={editing_task} toggleModal={this.props.toggleModal} case={_case} />
                            <FormTypesAppointments    forceRefresh={forceRefresh} case_workflow_id={case_workflow_id} editing_task={editing_task} toggleModal={this.props.toggleModal} case={_case}/>
                            <FormTypesContact         forceRefresh={forceRefresh} case_workflow_id={case_workflow_id} editing_task={editing_task} toggleModal={this.props.toggleModal}/>
                            <FormTypesCustomData      forceRefresh={forceRefresh} case_workflow_id={case_workflow_id} editing_task={editing_task} toggleModal={this.props.toggleModal}/>
                            <FormTypesSetCaseRole     forceRefresh={forceRefresh} case_workflow_id={case_workflow_id} editing_task={editing_task} toggleModal={this.props.toggleModal}/>
                            <FormTypesSetChapter      forceRefresh={forceRefresh} case_workflow_id={case_workflow_id} editing_task={editing_task} toggleModal={this.props.toggleModal}/>
                            <FormTypesSetAssignedUser forceRefresh={forceRefresh} case_workflow_id={case_workflow_id} editing_task={editing_task} toggleModal={this.props.toggleModal} case={_case} />
                            <FormTypesTagQuestion     forceRefresh={forceRefresh} case_workflow_id={case_workflow_id} editing_task={editing_task} toggleModal={this.props.toggleModal} />
                            <FormTypesSetCaseNumber   forceRefresh={forceRefresh} case_workflow_id={case_workflow_id} editing_task={editing_task} toggleModal={this.props.toggleModal} />
                            <FormTypesSendTemplateDoc forceRefresh={forceRefresh} case_workflow_id={case_workflow_id} editing_task={editing_task} toggleModal={this.props.toggleModal} />
                            <FormTypesSetCourt        forceRefresh={forceRefresh} case_workflow_id={case_workflow_id} editing_task={editing_task} toggleModal={this.props.toggleModal} />
                            <FormTypesSetOffice       forceRefresh={forceRefresh} case_workflow_id={case_workflow_id} editing_task={editing_task} toggleModal={this.props.toggleModal} />
                            <FormTypesSelect          forceRefresh={forceRefresh} case_workflow_id={case_workflow_id} editing_task={editing_task} toggleModal={this.props.toggleModal} />
                            <FormTypesSetFeePercent          forceRefresh={forceRefresh} case_workflow_id={case_workflow_id} editing_task={editing_task} toggleModal={this.props.toggleModal} />
                            <FormTypesSetMarketingReservePercent          forceRefresh={forceRefresh} case_workflow_id={case_workflow_id} editing_task={editing_task} toggleModal={this.props.toggleModal} />
                            <FormTypesSetTotalERTC          forceRefresh={forceRefresh} case_workflow_id={case_workflow_id} editing_task={editing_task} toggleModal={this.props.toggleModal} />
                        </Col>

                        <Col md={4} className="right-column">

                            <FormGroup>
                                <label className="form-control-label" > Due Date </label>
                                <ReactDatetime
                                    defaultValue={editing_task.date ? moment.unix(editing_task.date).format('MM/DD/YYYY hh:mm A')   : ''}
                                    inputProps={{placeholder: "Select Date"}}
                                    timeFormat={true}
                                    input={true}
                                    onChange={this.onDateChange}
                                />
                            </FormGroup>

                            <div className="text-right">
                                <button
                                    disabled={this.state.has_new_date ? false : true}
                                    className={this.state.has_new_date ? "btn btn-s btn-success mt-3" : "btn btn-s btn-neutral mt-3"}
                                    onClick={this.onUpdateTaskDueDate}
                                >
                                    Update Due Date
                                </button>
                            </div>

                            <FormGroup>
                                <label className="form-control-label" > Assign To </label>
                                <Input
                                    value={this.state.assigned_to || ''}
                                    type="select"
                                    onChange={e => this.onAssignedToChange(e.target.value) }
                                >
                                    <option></option>
                                    {this.getUsersToAssign()}
                                </Input>

                                <div className="text-right">
                                    <button
                                        disabled={this.state.assigned_to ? false : true}
                                        className={this.state.assigned_to ? "btn btn-s btn-success mt-3" : "btn btn-s btn-primary mt-3"}
                                        onClick={() => this.assignUserToTask('patch')}
                                    >
                                        Assign user
                                    </button>
                                </div>

                            </FormGroup>

                            <div  className="table-responsive table-no-margin mt-2">
                                <table className="table">

                                    <thead>
                                        <tr className="font-weight-bold">
                                            <th>Assigned To</th>
                                            <th className="text-right">Actions</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {editing_task.assigned_to && editing_task.assigned_to.length ? editing_task.assigned_to.map((e, i) => (
                                            <tr key={e._id + i}>
                                                <td className="text-capitalize">{renderName(e)}</td>
                                                <td className="text-right">
                                                    <button className="btn btn-s btn-danger my-2 pt-1" onClick={() => this.assignUserToTask('delete', e._id)}>
                                                    <i className="fas fa-trash text-white mr-2" />
                                                    Remove
                                                </button>
                                                </td>
                                            </tr>
                                        )): (
                                            <tr>
                                                <td className="text-danger font-weight-bold"><i className="fas fa-exclamation-triangle mr-2 " /> No User Assigned</td>
                                                <td></td>
                                            </tr>
                                        )}
                                    </tbody>

                                </table>
                            </div>

                        </Col>

                    </Row>

                </div>

            </Modal>

            </>

        )

    }

}

ModalUnfinished.propTypes = {
    case                : PropTypes.object.isRequired,
    editing_task        : PropTypes.object.isRequired,
    showModal           : PropTypes.bool.isRequired,
    toggleModal         : PropTypes.func.isRequired,
    case_workflow_id    : PropTypes.string.isRequired,
}

const mapStateToProps = state => {
    return {
    	viewing_user: state.auth.viewing_user,
    	users: state.users.users,
    };
};

export default connect(mapStateToProps, '')(ModalUnfinished);
