import { CardFooter, FormGroup, Input } from "reactstrap";

const getWeekInputs = () => {
    let markup = [];

    for (let i = 1; i < 200; i++) {
        markup.push(<option key={i} value={i}>{i}</option>)   
    }
    return markup
}

const InfoRep = ({newCase, onSetState}) => (
    <CardFooter id="archk-details-ppp">

        <h3 className="mb-2">Round 1</h3>

        <FormGroup>
            <div className="custom-control custom-checkbox">
                <input
                    className="custom-control-input"
                    id={`archk_checklist_ppp_round_1`}
                    type="checkbox"
                    checked={newCase.ppp_round_1 === true}
                    onChange={(e) => onSetState({}, 'ppp_round_1', !newCase.ppp_round_1)}
                />
                <label className="custom-control-label" htmlFor={`archk_checklist_ppp_round_1`}>
                    I received the FIRST round PPP loan
                </label>
            </div>
        </FormGroup>

        {newCase.ppp_round_1 ? (
            <div className="pl-7">
                <FormGroup>
                    <label className="form-control-label">PPP Loan Amount (See A Above)*</label>
                    <Input 
                        type="text"
                        value={newCase.ppp_round_1_loan_amount}
                        onChange={(e) => onSetState({}, 'ppp_round_1_loan_amount', e.target.value)}

                    />
                </FormGroup> 
                <FormGroup>
                    <label className="form-control-label">Amount of Loan Spent on Payroll Costs (See C Above)*</label>
                    <Input 
                        type="text"
                        value={newCase.ppp_round_1_loan_amount_spent}
                        onChange={(e) => onSetState({}, 'ppp_round_1_loan_amount_spent', e.target.value)}

                    />
                </FormGroup> 
                <FormGroup>
                    <label className="form-control-label">Covered Period Start Date (See C Above)*</label>
                    <Input 
                        type="date"
                        value={newCase.ppp_round_1_start}
                        onChange={(e) => onSetState({}, 'ppp_round_1_start', e.target.value)}
                    />
                </FormGroup> 
                <FormGroup>
                    <label className="form-control-label">How many weeks did you receive the PPP round 1? (See C Above)*</label>
                    <Input 
                        type="select"
                        value={newCase.ppp_round_1_weeks ? newCase.ppp_round_1_weeks.toString() : newCase.ppp_round_1_weeks}
                        onChange={(e) => onSetState({}, 'ppp_round_1_weeks', e.target.value)}
                    >
                        <option value="false"></option>
                        {getWeekInputs()}
                    </Input>

                </FormGroup> 
            </div>
        ) : null}

        <h3 className="mb-2">Round 2</h3>

        <FormGroup>
            <div className="custom-control custom-checkbox">
                <input
                    className="custom-control-input"
                    id={`archk_checklist_ppp_round_2`}
                    type="checkbox"
                    checked={newCase.ppp_round_2 === true}
                    onChange={(e) => onSetState({}, 'ppp_round_2', !newCase.ppp_round_2)}

                />
                <label className="custom-control-label" htmlFor={`archk_checklist_ppp_round_2`}>
                    I received the SECOND round PPP loan
                </label>
            </div>
        </FormGroup>

        {newCase.ppp_round_2 ? (
            <div className="pl-7">
                <FormGroup>
                    <label className="form-control-label">PPP Loan Amount (See A Above)*</label>
                    <Input 
                        type="text"
                        value={newCase.ppp_round_2_loan_amount}
                        onChange={(e) => onSetState({}, 'ppp_round_2_loan_amount', e.target.value)}
                    />
                </FormGroup> 
                <FormGroup>
                    <label className="form-control-label">Amount of Loan Spent on Payroll Costs (See C Above)*</label>
                    <Input 
                        type="text"
                        value={newCase.ppp_round_2_loan_amount_spent}
                        onChange={(e) => onSetState({}, 'ppp_round_2_loan_amount_spent', e.target.value)}
                    />
                </FormGroup> 
                <FormGroup>
                    <label className="form-control-label">Covered Period Start Date (See C Above)*</label>
                    <Input 
                        type="date"
                        value={newCase.ppp_round_2_start}
                        onChange={(e) => onSetState({}, 'ppp_round_2_start', e.target.value)}
                    />
                </FormGroup> 

                <FormGroup>
                    <label className="form-control-label">How many weeks did you receive the PPP round 2? (See C Above)*</label>
                    <Input 
                        type="select"
                        value={newCase.ppp_round_2_weeks ? newCase.ppp_round_2_weeks.toString() : newCase.ppp_round_2_weeks}
                        onChange={(e) => onSetState({}, 'ppp_round_2_weeks', e.target.value)}
                    >
                        <option value="false"></option>
                        {getWeekInputs()}
                    </Input>
                </FormGroup> 
            </div>
        ) : null}
       
        
    </CardFooter>
)

export default InfoRep