import React, {useEffect} from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'

import moment from 'moment';
import renderName from 'utils/renderName';
import renderNameAndInitial from 'utils/renderNameAndInitial';

import _analytics from '_functions/analytics'

import QueryCreator from 'components/system/QueryCreator';

import * as privileges from '_functions/users/privileges';

const QueryAppointments = ({offices, users, divisions, groupings}) => {

    useEffect(() => {
        const callAlerts = document.getElementById('archk-call-alerts')
        const footer = document.querySelector('footer');
        if(callAlerts) callAlerts.style.display = 'none'
        if(footer) footer.style.display = 'none'
        return () => {
            if(callAlerts) callAlerts.style.display = 'block'
            if(footer) footer.style.display = 'block'
        }

    }, [])

    return (
        <div className="dashboard-query-creator">
            <QueryCreator 
                title="Reminder Reports"
                hideSearch={true}
                actionComponent={(
                    <Link  to="/dashboard/appointments/view/new" className="btn btn-success" style={{lineHeight: 'inherit'}}>Create Reminder</Link>
                )}

                runQuery={async (query, cb) => new Promise (async resolve => {
                    // set payments to default to today only at first
                    if(!query.hasRun) {

                        const date = moment().startOf('day');
                        query.filter.date = {
                            $gte: parseInt(date.format('X')),
                            $lt: parseInt(date.format('X')) + 86400,
                        }
                    }
                    const result = await _analytics.query.appointments(query)
                    return resolve(result.data)
                })}
                inputs={[
                    { type: 'ArchkDates', field: 'date', label: '', defaultValue: new Date() },


                    {
                        type: 'ArchkSelectSimple', field: 'division', label: 'Division',
                        data: divisions.map(i => { return {value: i._id, text: i.name} })
                    },
                    {
                        type: 'ArchkSelectSimple', field: 'office', label: 'Office',
                        data: offices.map(o => { return {value: o._id, text: o.name} })
                    },
                    {
                        type: 'ArchkInArray', field: 'assigned_to', label: 'Assigned To', query: 'in',
                        data: users.map(t => { return {value: t._id, text: renderName(t)} })
                    },
                    {
                        type: 'ArchkInArray', field: 'updated_by', label: 'Last Update By', query: 'in',
                        data: users.map(t => { return {value: t._id, text: renderName(t)} })
                    },
                    {
                        type: 'ArchkGroupings', field: 'template_appointment', label: 'Grouping', query: 'in',
                        data: groupings.map(t => { return {_id: t._id, value: t.documents, text: t.name} })
                    },
                    {
                        type: 'ArchkSelectSimple', field: 'type', label: 'Type',
                        data: [
                            {value: 'attorney', text: 'attorney'},
                            {value: 'client liaison', text: 'client liaison'},
                            {value: 'paralegal', text: 'paralegal'},
                        ]
                    },
                    { type: 'ArchkNull', field: 'confirmed', label: 'Confirmed', },
                
                ]}
                columns={[
                    {
                        dataField: "date",
                        text: "Date",
                        formatter: (cell, row) => (
                            <div>
                                <div>{moment.unix(row.date).format("M/D/YYYY")}</div>
                                <div><span>{moment.unix(row.date).format("h:mm")} - {moment.unix(row.date_end).format("h:mm A")}</span></div>
                            </div>
                        )
                    },
                    {
                        dataField: "name",
                        text: "Reminder",
                        formatter: (cell, row) => (
                            <div style={{whiteSpace: 'pre-line', maxWidth: 275}}>
                                <div>{row.name}</div>
                                {row.updated_by ? <div className="text-capitalize"><b>Updated By:</b> {renderNameAndInitial(row.updated_by)}</div> : '-'}
                            </div>
                        ),
                    },
                  
                    {
                        dataField: "assigned_to",
                        text: "Assigned To",
                        formatter: (cell, row) => {
                            if(row.assigned_to && row.assigned_to.length) {

                                return row.assigned_to.map((u, i)=> (
                                    <span className="text-capitalize" key={u._id} style={{display: 'block', width: 160,}}>
                    
                                        <i className="fas fa-user mr-2 text-info-original" />
                                        {renderName(u)}
                                    </span>
                                ))
                    
                            }
                            return '-'

                        }
                    },
                    {
                        dataField: "_id",
                        text: "Actions",
                        formatter: (cell, row) =>  privileges.canModerateAppointments() ? (
                            <div className="text-right">
                                <Link to={`/dashboard/appointments/view/${row._id}`}><i className="fas fa-edit text-success mr-2"></i></Link>
                                {/* <i onClick={() => this.setState({appointmentToDelete: row})} className="fas fa-trash text-danger"></i> */}
                            </div>
                        ) : null
                    },
                

                ]}
            
            />
        </div>
    )

}



const mapStateToProps = state => {
    return {
        offices: state.offices.offices,
        users: state.users.users,
        divisions: state.divisions.divisions,
        groupings: state.groupings.groupings.filter(g => g.collection_name === 'template_appointments')
    };
};

export default connect(mapStateToProps, '')(QueryAppointments);