import React from "react";
import { Card, CardBody, CardHeader, Col, Row, CardTitle } from "reactstrap";
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

import { connect } from 'react-redux';
import _cases from '_functions/cases';
import _case_parties from '_functions/case_parties';

class Cases extends React.Component {

    state = {
        contact: {},
        appointments: [],
        canSave: true,
    }

    setClientCases = async () => {

        const client_cases = await _cases.find(`?filter=contact__${this.props.contact._id}|deleted__false{bool}`)
        
        if(client_cases.data && !this.props.viewing_user.privileges.includes('can_view_all_cases')) {
            client_cases.data = client_cases.data.filter(c => {
                return c.roles && c.roles.some(r => {
                    return r.user && r.user._id === this.props.viewing_user._id
                }) ? true : false
            })
        }
        if(client_cases.success) this.setState({client_cases: client_cases.data})

    }

    setCaseParties = async () => {

        const case_parties = await _case_parties.find(`?filter=contact__${this.props.contact._id}|deleted__false{bool}&populate=case`)
        if(case_parties.success) this.setState({case_parties: case_parties.data})

    }

    componentDidMount = async () => {

        this.setClientCases()
        this.setCaseParties()

    }

    render() {

        const { client_cases, case_parties } = this.state
        const { navPills, createCaseURL, contact } = this.props

        return (
            navPills === 1 ? (
                <>

                <Card className="card-color card-primary">

                    <CardHeader>
                        <Row>

                            <Col lg={6} className="align-self-center">
                                <CardTitle className="mb-0">Client Cases</CardTitle>
                            </Col>

                            <Col lg={6} className="align-self-center text-right">
                                <Link className="btn btn-success" to={createCaseURL(contact._id)}><i className="fas fa-plus " /> Add</Link>
                            </Col>

                        </Row>
                        
                        <p className="text-sm mb-0">This row shows all cases in which this contact is a client</p>


                    </CardHeader>

                    <CardBody>
                        <Row>

                        {client_cases && client_cases.length ? client_cases.map((client_case, i) => (
                            
                            <Col md={4} key={i}>
                                <Link to={`/dashboard/cases/view/${client_case._id}`}>
                                    <Card
                                        style={{opacity: client_case.deleted ? .5 : 1}}
                                        className="cursor-pointer text-center bg-gradient-success"
                                    >

                                        <CardBody>
                                            <h2 className="my-0 py-0 text-white text-capitalize">{client_case.name}</h2>
                                            <p  className="text-sm text-white">CLIENT</p>
                                        </CardBody>

                                    </Card>
                                </Link>
                            </Col>
                        )) : (
                            <Col xs={12}>
                                <p className="text-sm text-warning font-weight-bold mb-0">
                                    There are no found cases for this contact.
                                </p>
                            </Col>
                        ) }

                        </Row>
                    </CardBody>
                </Card>

                {/* <Card className="card-color card-primary">

                    <CardHeader>
                        <CardTitle className="mb-0">Associated Cases</CardTitle>
                        <p className="text-sm mb-0">This row shows all cases in which this contact has a role within the case</p>
                    </CardHeader>

                    <CardBody>
                        <Row>

                        {case_parties && case_parties.length ? case_parties.map((case_party, i) => (
                            case_party.case ? (
                            <Col md={4} key={i}>
                                <Link to={`/dashboard/cases/view/${case_party.case._id}`}>
                                    <Card className="cursor-pointer text-center bg-gradient-warning ">

                                        <CardBody>
                                            <h2 className="my-0 py-0 text-white text-capitalize">{case_party.case.name}</h2>
                                            <p  className="text-sm text-white">{case_party.title}</p>
                                        </CardBody>

                                    </Card>
                                </Link>
                            </Col>
                            ): null
                        )) : (
                            <Col xs="12">
                                <p className="text-sm text-warning font-weight-bold mb-0">
                                    There are no cases where this contact is associated.
                                </p>
                            </Col>
                        ) }

                        </Row>
                    </CardBody>
                </Card> */}

                </>

            ) : null
        );
    }
}

Cases.propTypes = {
    contact   : PropTypes.object.isRequired,
    navPills  : PropTypes.number.isRequired
}


const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,
    };
};

export default connect(mapStateToProps, '')(Cases);
