import apiWorker from '../apiWorker'

const appointments = {

    update:                (appointment_id, data)           =>  apiWorker('patch',  `/api/v1/core/appointments/update/${appointment_id}`, data),
    delete:                (appointment_id, data)           =>  apiWorker('delete', `/api/v1/core/appointments/delete/${appointment_id}`, data),
    finish:                (appointment_id, data, hideErr)  =>  apiWorker('post',   `/api/v1/core/appointments/finish/${appointment_id}`, data, hideErr),
    create:                (data)                           =>  apiWorker('post',   `/api/v1/core/appointments/create`, data),

    find:                  (query)                          =>  apiWorker('get', `/api/v1/core/appointments/find/${query}`),
    findById:              (appointment_id, hideError)      =>  apiWorker('get', `/api/v1/core/appointments/find/${appointment_id}`, null, hideError ),
    findFinishedById:      (finished_id, hideError)         =>  apiWorker('get', `/api/v1/core/appointments/findFinished/${finished_id}`, null, hideError ),
    findFinishedByContact: (finished_id)                    =>  apiWorker('get', `/api/v1/core/appointments/findFinishedByContact/${finished_id}` ),
    findByAssignedTo:      (user_id, query)                 =>  apiWorker('get', `/api/v1/core/appointments/findByAssignedTo/${user_id}${query}`, null ),

}

export default appointments;