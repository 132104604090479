import { useCallback, useState, useEffect } from "react";
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';

import { Card, Container, CardBody, CardHeader, CardTitle, CardFooter, Badge, Row, Col,   DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown, } from "reactstrap";
// import moment from 'moment';

// import Table, { downloadCSV } from 'components/functional/tables/Standard';
// import NameCircle from 'components/markup/misc/NameCircle';

// import ModalToggler from 'components/functional/modals/Toggler'
import A from 'components/markup/links/A'

import HeaderNavigation from 'components/markup/layout/HeaderNavigation'

import TableIcons from 'components/markup/icons/TableIcons'

import _cases from '_functions/cases'

import Circle from 'components/markup/loading/Circle'
import moment from "moment";
import renderName from "utils/renderName";

import { formatPhone } from 'utils/text'

import renderCaseName from 'utils/renderCaseName'

import SendEmail from 'components/system/SendEmail';
import SendEmailModalWrapper from 'components/system/SendEmail/ModalWrapper';

import SendText from 'components/system/SendText';
import SendTextModalWrapper from 'components/system/SendText/ModalWrapper';

import { toggleAlertBS } from "store/functions/system/system";
               
const PipelineCol = ({children, header}) => (
    <Col className="archk-pipeline-col">
        {header}
        <div className="archk-pipeline-col-wrapper border-left border-right bg-secondary ">
        {/* <div className="archk-pipeline-col-wrapper  "> */}
            <div className="archk-pipeline-col-inner">
                <div className="pt-3">
                    {children}
                </div>
            </div>
        </div>
    </Col>
)

const PipelineCardEmpty = () => (
    <div className="pb-3">
        <Card className={`archk-pipeline-card mb-0 } `}>
            <CardHeader className=" text-cente">
                <CardTitle className="mb--2 text-dark">
                    <Link to={`/matters/6383911d94be017545077847`}>No Cases Found</Link>
                </CardTitle>
            </CardHeader>
            <CardBody className="py-2 bg-secondar">
                <p className="text-sm mb-0 text-dark">
                    No Cases Found For This Step 
                </p>
            </CardBody>
        </Card>
    </div>
)

const PipelineCard = ({hideActions, hideLastNote, _case, sort}) => {
    const [showModal, setShowModal] = useState(false);
    const [showModalText, setShowModalText] = useState(false);

    const date = sort && sort.includes('Created') ? _case.created_at : _case.current_step_start;

    return (
        <div className="pb-3">
            <Card className={`archk-pipeline-card mb-0 } `}>
                <CardHeader className=" text-cente">
                    <Row>
                        <Col className="align-self-center">
                            <CardTitle className="mb-3 text-dark">
                                <Link className="title" style={{whiteSpace: 'pre-line'}} to={`/dashboard/cases/view/${_case._id}`}>
                                    {_case.company_name ? renderCaseName(_case.company_name) : renderCaseName(_case.name)}
                                </Link>
                            </CardTitle>

                            {_case.company_dba ? (
                                <p className="text-sm text-muted mb--1 mt-3">DBA: {_case.company_dba}</p>
                            ) : ''}
                            <p className="text-sm mb-0 text-muted"><span>
                               {/* <small> {sort && sort.includes('Created') ? 'Created' : 'Moved'}{':  '}</small> */}
                                
                                <div className="mt-2" style={{whiteSpace: 'pre-line', lineHeight: '14px'}}>
                                {moment.unix(date).format("MMM Do, YYYY")}{' - '}
                                    {moment.unix(date).fromNow()}</div>
                            </span></p>
                            
                            <p className="text-sm mb--2 text-dark font-weight-bold">Employee Count: {_case.company_size ? _case.company_size : 'Unknown'}</p>
                            <p className="text-sm mb-0 text-dark font-weight-bold">CL: {_case.cl}</p>
                            <p className="text-sm mb-0">Docs: {_case.document_percentage}%</p>
                            </Col>
                    </Row>
                    
                </CardHeader>
                <CardBody className="py-2 bg-secondar">
                    <p className="text-sm mb-0 text-dark text-capitalize">
                        {_case.contact ? renderName(_case.contact) : 'Contact Not Found'} 
                    </p>
                    {_case.contact && _case.contact.phone ? <p className="text-dark text-sm mt--2 mb-0">{formatPhone(_case.contact.phone)}</p>  : null }
                    {hideLastNote ? '' : <p className="text-sm mb-0 text-muted" style={{whiteSpace: 'pre-line', lineHeight: '14px'}}>{_case.last_note_preview}</p>}
                </CardBody>

                {hideActions ? '' : (
                    <CardFooter className=" text-centr">
                        <TableIcons
                            icons={[
                                { 
                                    icon: 'fas fa-mobile',
                                    color: 'info', 
                                    doc: document,
                                    disabled: _case.contact && _case.contact.phone ? false : true,
                                    onClick: () => setShowModalText(true),
                                    // tooltip: <span><i className="fas fa-mobile mr-2 " /> Text Client</span>
                                },
                                {  
                                    icon: 'fas fa-envelope', 
                                    color: 'success',
                                    disabled: _case.contact && _case.contact.email ? false : true,
                                    onClick: () => setShowModal(true),
                                    // tooltip: <span><i className="fas fa-paper-plane mr-2 " /> Email Client</span>,
                                },
                                { 
                                    icon: 'fas fa-link',
                                    color: 'warning', 
                                    wrapper: A,
                                    href: `/dashboard/cases/view/${_case._id}`,
                                    // tooltip: 'View Case'
                                },
                            ]}
                        />
                    </CardFooter>  
                )}
              
                <SendEmailModalWrapper
                    showModal={showModal}
                    toggleModal={() => setShowModal(false)}
                >
                    <SendEmail
                        onSendingStart={() => setShowModal(false)}
                        onError={() => toggleAlertBS('info', 'Email Not Sent, Please Try Again')}
                        subject="Biz Head Law"
                        to={_case.contact && _case.contact.email ? [_case.contact.email]   : []}
                        contact={_case.contact ? _case.contact._id : undefined}
                        case={_case ? _case._id : undefined}
                        case_id={_case ? _case._id : undefined}
                        division={_case ? _case.division : undefined}
                    />
                </SendEmailModalWrapper>

                <SendTextModalWrapper
                    toggleModal={() => setShowModalText(false)}
                    showModal={showModalText}
                >
                    <SendText
                        onSendingStart= {() => setShowModalText(false)}
                        onError={() => toggleAlertBS('info', 'Text Not Sent, Please Try Again')}
                        contact=        {_case && _case.contact ? _case.contact._id : undefined}
                    />
                </SendTextModalWrapper>

            </Card>
        </div>
    )
}

const ContactsAll = ({viewing_user}) => {

    const [justMine, setJustMine] = useState(false);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(false);
    const [hideActions, setHideActions] = useState(false);
    const [hideLastNote, setHideLastNote] = useState(false);
    
    const [sort, setSort] = useState('Step Move Date (Newest)');

    const onSetSort = useCallback((_sort) => {
        const _data = JSON.parse(JSON.stringify(data));
        Object.keys(data).forEach(key => {
            _data[key].sort((a, b) => {
                if(_sort === 'Step Move Date (Newest)') {
                    return a.current_step_start >= b.current_step_start ? -1 : 1
                } else if(_sort === 'Step Move Date (Oldeest)') {
                    return a.current_step_start <= b.current_step_start ? -1 : 1
                } else if(_sort === 'Created Date (Newest)') {
                    return a.created_at >= b.created_at ? -1 : 1
                } else if(_sort === 'Created Date (Newest)') {
                    return a.created_at <= b.created_at ? -1 : 1
                } else if(_sort === 'Company Size (Largest)') {
                    if(!a.company_size) return 1
                    return a.company_size >= b.company_size ? -1 : 1
                } else if(_sort === 'Company Size (Smallest)') {
                    if(!a.company_size) return 1
                    return a.company_size <= b.company_size ? -1 : 1
                } else if(_sort === 'Docs Percentage (Smallest)') {
                    if(!a.document_percentage) return -1
                    return a.document_percentage <= b.document_percentage ? -1 : 1
                } else if(_sort === 'Docs Percentage (Largest)') {
                    // if(!a.document_percentage) return 1
                    return a.document_percentage >= b.document_percentage ? -1 : 1
                }
                return -1;
            })
        })

        setSort(_sort)
        setData(_data);
    }, [data])

    const fetchData = useCallback(async () => {
        setLoading(true)
        const _data = await _cases.pipeline(justMine);
        setLoading(false)
        if(_data.data) setData(_data.data);
    }, [justMine])

    const onDownload = useCallback(async (type) => {
        const _data = await _cases.pipeline(null, type);
        console.log(_data)
        if(_data.data) {
            var hiddenElement = document.createElement('a');
            hiddenElement.href = 'data:text/csv;charset=utf-8,' + _data.data;
            hiddenElement.target = '_blank';
            hiddenElement.download = `export - ${type}.csv`;
            hiddenElement.click();
        }
    }, [])

    useEffect(() => {
        fetchData()
        document.body.classList.add('bg-secondary')
        document.body.classList.add('noScroll')

        const footer = document.getElementById('footer');
        if(footer) footer.style.display = 'none'

        return () => {
            document.body.classList.remove('bg-secondary')
            document.body.classList.remove('noScroll')
            if(footer) footer.style.display = 'block'
        }
    }, [fetchData])

    if(!data || loading) return <div className="py-6"><Circle /></div>

    console.log(data)

    return (

        <>

        <HeaderNavigation 
            title="Pipeline"
            // description={`Division`}
            background="bg-white"
            leftColSize={4}
            actionComponent={(
                <div >
                    <UncontrolledDropdown>
                        <DropdownToggle color="info">
                            {sort ? sort : 'Sort'}  <i className="fas fa-sort-amount-down ml-2" />
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem onClick={e => onSetSort('Step Move Date (Newest)')}>Step Move Date (Newest)</DropdownItem>
                            <DropdownItem onClick={e => onSetSort('Step Move Date (Oldest)')}>Step Move Date (Oldest)</DropdownItem>
                            <DropdownItem onClick={e => onSetSort('Created Date (Newest)')}>Created Date (Newest)</DropdownItem>
                            <DropdownItem onClick={e => onSetSort('Created Date (Oldest)')}>Created Date (Oldest)</DropdownItem>
                            <DropdownItem onClick={e => onSetSort('Company Size (Largest)')}>Company Size (Largest)</DropdownItem>
                            <DropdownItem onClick={e => onSetSort('Company Size (Smallest)')}>Company Size (Smallest)</DropdownItem>
                            <DropdownItem onClick={e => onSetSort('Docs Percentage (Smallest)')}>Docs Percentage (Smallest)</DropdownItem>
                            <DropdownItem onClick={e => onSetSort('Docs Percentage (Largest)')}>Docs Percentage (Largest)</DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                   
                   {viewing_user.privileges.includes('can_view_all_cases') ? (
                    <UncontrolledDropdown>
                        <DropdownToggle color={"success"}>
                            {justMine ? 'Assigned To Me' : 'All Cases'}  <i className="fas fa-filter ml-2" />
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem onClick={e => setJustMine(false)}>All Cases</DropdownItem>
                            <DropdownItem onClick={e => setJustMine(true)}>Assigned To Me</DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                   ) : null}
                  
                   
                    <UncontrolledDropdown>
                        <DropdownToggle color="outline-warning">
                            Settings  <i className="fas fa-cogs ml-2" />
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem onClick={e => setHideActions(!hideActions)}>{hideActions ? 'Show Quick Actions' : 'Hide Quick Actions'}</DropdownItem>
                            <DropdownItem onClick={e => setHideLastNote(!hideLastNote)}>{hideLastNote ? 'Show Last Note' : 'Hide Last Note'}</DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>

                    {/* <button className="btn btn-outline-success">Display <i className="fas fa-laptop ml-2 " /></button> */}
                </div>
            )}
        />

        <Container className="ful  bg-white mt--4 pt-4" fluid>
    
            <Helmet>
                <title>{`Pipeline`}</title>
                <meta name="description" content="Pipeline" />
            </Helmet>

            <div className="archk-pipeline">

                <Row className="archk-pipeline-row">

                    <PipelineCol
                        header={(
                            <div className="bg-warning  px-4 py-2">
                                <h4 className="text-white pt-1">Call Scheduled ({data.callScheduled.length}) <i className="fas fa-download float-right cursor-pointer " onClick={()  => onDownload('callScheduled')} /></h4>
                            </div>
                        )}
                    >
                        {data.callScheduled.length ? data.callScheduled.map((_case, i) => (
                              <PipelineCard key={_case._id} sort={sort} _case={_case} hideActions={hideActions} hideLastNote={hideLastNote} />
                        )) : (
                            <PipelineCardEmpty />
                        )}
                    </PipelineCol>

                    <PipelineCol
                        header={(
                            <div className="bg-danger  px-4 py-2">
                                <h4 className="text-white pt-1">No Hire ({data.noShowNoHire.length}) <i className="fas fa-download float-right cursor-pointer " onClick={()  => onDownload('noShowNoHire')} /></h4>
                            </div>
                        )}
                    >
                        {data.noShowNoHire.length ? data.noShowNoHire.map((_case, i) => (
                              <PipelineCard  key={_case._id}  sort={sort} _case={_case} hideActions={hideActions} hideLastNote={hideLastNote} />
                        )) : (
                            <PipelineCardEmpty />
                        )}
                    </PipelineCol>
                   
                    <PipelineCol
                        header={(
                            <div className="bg-danger  px-4 py-2">
                                <h4 className="text-white pt-1">Follow Up ({data.noShowFollowUp.length}) <i className="fas fa-download float-right cursor-pointer " onClick={()  => onDownload('noShowFollowUp')} /></h4>
                            </div>
                        )}
                    >
                        {data.noShowFollowUp.length ? data.noShowFollowUp.map((_case, i) => (
                              <PipelineCard  key={_case._id}  sort={sort} _case={_case} hideActions={hideActions} hideLastNote={hideLastNote} />
                        )) : (
                            <PipelineCardEmpty />
                        )}
                    </PipelineCol>
                    
                    <PipelineCol
                        header={(
                            <div className="bg-info-original  px-4 py-2">
                                <h4 className="text-white pt-1">Agreement Sent ({data.sentRetainer.length}) <i className="fas fa-download float-right cursor-pointer " onClick={()  => onDownload('sentRetainer')} /></h4>
                            </div>
                        )}
                    >
                        {data.sentRetainer.length ? data.sentRetainer.map((_case, i) => (
                              <PipelineCard  key={_case._id}  sort={sort} _case={_case} hideActions={hideActions} hideLastNote={hideLastNote} />
                        )) : (
                            <PipelineCardEmpty />
                        )}
                    </PipelineCol>
                   
                    <PipelineCol
                        header={(
                            <div className="bg-info-original  px-4 py-2">
                                <h4 className="text-white pt-1">Signed - Needs Onboard ({data.signedRetainerNotOnboarded.length}) <i className="fas fa-download float-right cursor-pointer " onClick={()  => onDownload('signedRetainerNotOnboarded')} /></h4>
                            </div>
                        )}
                    >
                        {data.signedRetainerNotOnboarded.length ? data.signedRetainerNotOnboarded.map((_case, i) => (
                              <PipelineCard  key={_case._id}  sort={sort} _case={_case} hideActions={hideActions} hideLastNote={hideLastNote} />
                        )) : (
                            <PipelineCardEmpty />
                        )}
                    </PipelineCol>

                    <PipelineCol
                        header={(
                            <div className="bg-info-original  px-4 py-2">
                                <h4 className="text-white pt-1">Signed - Needs Docs ({data.signedRetainer.length}) <i className="fas fa-download float-right cursor-pointer " onClick={()  => onDownload('signedRetainer')} /></h4>
                            </div>
                        )}
                    >
                        {data.signedRetainer.length ? data.signedRetainer.map((_case, i) => (
                              <PipelineCard  key={_case._id}  sort={sort} _case={_case} hideActions={hideActions} hideLastNote={hideLastNote} />
                        )) : (
                            <PipelineCardEmpty />
                        )}
                    </PipelineCol>
                   
                    <PipelineCol
                        header={(
                            <div className="bg-purple  px-4 py-2">
                                <h4 className="text-white pt-1">Submitted to Patriot ({data.submitted.length}) <i className="fas fa-download float-right cursor-pointer " onClick={()  => onDownload('submitted')} /></h4>
                            </div>
                        )}
                    >
                        {data.submitted.length ? data.submitted.map((_case, i) => (
                              <PipelineCard  key={_case._id}  sort={sort} _case={_case} hideActions={hideActions} hideLastNote={hideLastNote} />
                        )) : (
                            <PipelineCardEmpty />
                        )}
                    </PipelineCol>
                    
                    <PipelineCol
                        header={(
                            <div className="bg-purple  px-4 py-2">
                                <h4 className="text-white pt-1">Needs Final Signature ({data.signature.length}) <i className="fas fa-download float-right cursor-pointer " onClick={()  => onDownload('signature')} /></h4>
                            </div>
                        )}
                    >
                        {data.signature.length ? data.signature.map((_case, i) => (
                              <PipelineCard  key={_case._id}  sort={sort} _case={_case} hideActions={hideActions} hideLastNote={hideLastNote} />
                        )) : (
                            <PipelineCardEmpty />
                        )}
                    </PipelineCol>
             
                    <PipelineCol
                        header={(
                            <div className="bg-success  px-4 py-2">
                                <h4 className="text-white pt-1">IRS ({data.irs.length}) <i className="fas fa-download float-right cursor-pointer " onClick={()  => onDownload('irs')} /></h4>
                            </div>
                        )}
                    >
                        {data.irs.length ? data.irs.map((_case, i) => (
                              <PipelineCard  key={_case._id}  sort={sort} _case={_case} hideActions={hideActions} hideLastNote={hideLastNote} />
                        )) : (
                            <PipelineCardEmpty />
                        )}
                    </PipelineCol>
                   
                    <PipelineCol
                        header={(
                            <div className="bg-success  px-4 py-2">
                                <h4 className="text-white pt-1">Payment+ ({data.payment.length}) <i className="fas fa-download float-right cursor-pointer " onClick={()  => onDownload('payment')} /></h4>
                            </div>
                        )}
                    >
                        {data.payment.length ? data.payment.map((_case, i) => (
                              <PipelineCard  key={_case._id}  sort={sort} _case={_case} hideActions={hideActions} hideLastNote={hideLastNote} />
                        )) : (
                            <PipelineCardEmpty />
                        )}
                    </PipelineCol>


                </Row>
            </div>

       
        </Container>

        </>
    )

}

const mapStateToProps = state => {
	return {
        viewing_user: state.auth.viewing_user,
	};
};

export default connect(mapStateToProps, '')(ContactsAll);
