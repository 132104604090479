import PropTypes from 'prop-types';
import React from 'react';
import { Modal } from "reactstrap";
import { capitalize } from 'utils/text'

const ModalPreviewImage = ({showModal, toggleModal, doc, src}) => {

    const name = doc.friendly_name ? capitalize(doc.friendly_name.replace(/\_/g, ' ')) : doc.friendly_name
    return (
        <Modal
            className="modal-dialog-centered"
            isOpen={showModal}
            toggle={toggleModal}
            size="lg"
        >
            <div className="modal-header">
                <h5 className="modal-title">{name}</h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModal}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>
    
            <div className="modal-body text-center">
    
                <img style={{maxWidth: '100%'}} alt={name} src={src} />
    
            </div>
    
            <div className="modal-footer">
                <button onClick={toggleModal} className="btn btn-warning" >
                    Close
                </button>
            </div>
    
        </Modal>
    )
}


ModalPreviewImage.propTypes = {
    showModal   : PropTypes.bool.isRequired,
    toggleModal : PropTypes.func.isRequired,
    doc         : PropTypes.object.isRequired,
}

export default ModalPreviewImage
