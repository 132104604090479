import { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { Modal } from "reactstrap";
import { Redirect } from 'react-router-dom';

import { toggleStandardLoader } from 'store/functions/system/system';
import _contacts from '_functions/contacts';
import _leads from '_functions/leads';

import Circle from 'components/markup/loading/Circle';
import { toggleAlertBS } from 'store/functions/system/system';

const ModalMerge = ({showModal, toggleModal, lead}) => {

    const [data, setData] = useState({})
    const [redirect, setRedirect] = useState(false)
    const [loading, setLoading] = useState({})

    const onMerge = useCallback(async () => {
        toggleStandardLoader(true)
        const data = await _leads.merge({lead_id: lead._id})
        toggleStandardLoader(false)

        if(data.data && data.data.case && data.data.case._id) {
            setRedirect(`/dashboard/cases/view/${data.data.case._id}`)
        } else {
            toggleAlertBS('info', 'Lead could not be merged, please try again or refresh your page.')
        }
    }, [lead._id])
    
    const fetchData = useCallback(async () => {

        const promiseArr = [];

        if(lead.email) promiseArr.push(_contacts.findByEmail(lead.email))
        if(lead.phone) promiseArr.push(_contacts.findByPhone(lead.phone))

        if(!promiseArr.length) return;

        setLoading(true);
        const values = await Promise.all(promiseArr)
        setLoading(false);

        if(values[0] && values[0].data) return setData(values[0].data)
        if(values[1] && values[1].data) return setData(values[1].data)

    }, [lead.email, lead.phone])

    useEffect(() => {

        if(showModal) {
            fetchData();
        } else {
            setData({});
        }
    }, [showModal, fetchData])

    if(redirect) return <Redirect to={redirect} />

    return (
        <Modal
            className="modal-dialog-centered"
            isOpen={showModal}
            toggle={toggleModal}
            size="lg"
        >

            <div className="modal-header">
                <h5 className="modal-title">Merge Lead To Contact</h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModal}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>

            <div className="modal-body">
                {loading ? <Circle /> : (
                    <div>
                        {data._id ? (
                            <p className="text-sm mb-0">A contact was found with a matching identifier to this lead: Email: {data.email ? data.email : '-'} Phone: {data.phone ? data.phone : '-'}. Merge not allowed.</p>
                        ) : (
                            <p className="text-sm mb-0">This lead can be merged in as a contact and will have an ERTC case associated with them. Click the button below to proceed.</p>
                        )}
                    </div>
                )}
            </div>

            <div className="modal-footer text-right">
                <button disabled={loading || data._id ? true : false} className="btn btn-success" onClick={() => onMerge()}>
                    Merge Contact
                </button>
            </div>

        </Modal>

    )

}

ModalMerge.propTypes = {
    toggleModal   : PropTypes.func.isRequired,
    showModal     : PropTypes.bool.isRequired,
}

export default ModalMerge
