import { useState } from 'react';

import { toggleAlertBS } from 'store/functions/system/system';

import SendEmail from 'components/system/SendEmail';
import SendEmailModalWrapper from 'components/system/SendEmail/ModalWrapper';

const SendReminder = ({contact}) => {

    const [showModal, setShowModal] = useState(false);
    if(!contact.email) return '';
    return (
       <>
        <span className="cursor-pointer" onClick={() => setShowModal(true)}>
            {contact.email}
        </span>

        <SendEmailModalWrapper
            showModal={showModal}
            toggleModal={() => setShowModal(false)}
        >
            <SendEmail
                onSendingStart={() => setShowModal(false)}
                onError={() => toggleAlertBS(true, 'Your email was not sent, please try again or send it from your Office 365 account.')}
                subject="Biz Head Law"
                to={[contact.email]}
                contact={contact._id}
            />
        </SendEmailModalWrapper>
        </>
    )
}

export default SendReminder