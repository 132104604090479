import { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { Modal, FormGroup, Input } from "reactstrap";

import _cases from '_functions/cases'

import Circle from 'components/markup/loading/Circle'

import HasData from './HasData'

import { toggleStandardLoader } from 'store/functions/system/system';
import A from 'components/markup/links/A'

const ModalSubmitToPatriot = ({showModal, toggleModal, case_id, setNav}) => {
    const [data, setData] = useState(null);
    const [errs, setErrs] = useState([]);
    const [id, setId] = useState('');
    const [confirmed, setConfirmed] = useState(false);
    const [possibleTimeout, setPossibleTimeout] = useState(false);

    const onSubmit = useCallback(async () => {
        if(!confirmed) return;

        toggleStandardLoader(true)
        const data = await _cases.submitToPatriot(case_id);
        toggleStandardLoader(false);


        if(data.data) {
            setId(data.data)
        } else {
            setPossibleTimeout(true)
            if(Array.isArray(data.message)) {
                setErrs(data.message)
            } else {
                setErrs(['Internal Error, Please Try Again'])
            }
        }
    }, [case_id, confirmed])

    const fetchData = useCallback(async () => {

        const data = await _cases.submitToPatriot(case_id, true);
        if(data.data) {
            setData(data.data)
        } else {
            if(Array.isArray(data.message)) {
                setErrs(data.message)
            } else {
                setErrs(['Internal Error, Please Try Again'])
            }
        }

    }, [case_id])

    useEffect(() => {
        if(showModal) {
            fetchData()
        } else {
            setData(null);
            setErrs([])
        }

    }, [showModal, fetchData])

    return (
        <Modal
            className="modal-dialog-centered"
            isOpen={showModal}
            toggle={toggleModal}
            size="lg"
        >

            <div className="modal-header">
                <h5 className="modal-title">Submit To Patriot</h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModal}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>

            <div className="modal-body">

                {id ? (   
                    <div className="py-5 text-center">
                        <p className="text-sm"><i className="fas fa-check-double text-success mr-2 " /> Information was successfully submitted to Patriot. You may view the submitted information inside the Patriot Dashboard at the following link</p>
                        <A href={`https://patriotdataprocessing.com/clients#${id}`}>https://patriotdataprocessing.com/clients#{id}</A>
                    </div>
                ) : errs.length ? (
                    <div>
                        <h4 className="mb-4"><i className="fas fa-exclamation-triangle mr-2 text-danger " /> Information Not Submitted To Patriot</h4>
                        {possibleTimeout ? (
                            <p className="text-warning font-weight-bold">While an error message appears here, it is possible data is still syncing to patriot and the final report may be downloaded after a few minutes by refreshing this page.</p>
                        ) : null}
                        {errs.map((err, i) => (
                            <div key={i}>
                                {err.includes('is not marked') ? (
                                    <i className="fas fa-file text-purple mr-2 " />
                                ) : err.includes('is missing') ? (
                                    <i className="fas fa-edit text-yellow mr-2 " />
                                ) : (
                                    <i className="fas fa-info-circle text-info-original mr-2 " />
                                )} 
                                {' '}
                                <span
                                    className="cursor-pointer"
                                    onClick={() => {
                                        if(err.includes('is not marked')) {
                                            toggleModal() 
                                            window.scrollTo(0,0)
                                        } else if(err.includes('is missing')) {
                                            setNav(9)
                                            toggleModal()
                                            window.scrollTo(0,0)
                                        }
                                        return;
                                    }}
                                >
                                    {err}
                                </span>
                                
                            </div>
                        ))}
                        <p className="text-sm mt-3 mb-0">
                            If you just updated the documents section of this case make sure your to hit save before trying to submit the data to Patriot. 
                            <b 
                                className="text-underline cursor-pointer" 
                                onClick={() => {
                                    setNav(9)
                                    toggleModal()
                                    window.scrollTo(0,0)
                                }}
                            >
                                To update checklist info click here
                            </b>
                        </p>
                    </div>
                ) : data ? (
                    <div>
                        <HasData data={data} />

                        <div className='bg-dark rounded px-4 py-3'>
                            <div className="custom-control custom-checkbox">
                                <input
                                    className="custom-control-input"
                                    type="checkbox"
                                    id="confirm-patriot-firm"
                                    value={confirmed}
                                    checked={confirmed}
                                    onChange={(e) => setConfirmed(!confirmed)}
                                />
                                {console.log(confirmed)}
                                <label className="custom-control-label" htmlFor="confirm-patriot-firm">
                                    <i className="fas fa-exclamation-triangle text-warning mr-2" /> 
                                    <span className='text-white' style={{position: 'relative', top: -0}}>I have confirmed that this case was sent a <span style={{top: 0}} className='text-info-original font-weight-bold'> {data && data.case ? data.case.tax_attorney : ''}</span> Agreement</span>
                                    <i className="fas fa-exclamation-triangle text-warning ml-2" /> 
                                </label>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="py-6"><Circle /></div>
                )}


            </div>

            <div className="modal-footer text-right">
                {errs.length || id ? (
                    <button className="btn btn-warning" onClick={toggleModal}>
                        <i className="fas fa-arrow-left mr-2" />
                        Close
                    </button>
                ) : data ? (
                    <button className="btn btn-success" onClick={onSubmit}>
                        <i className="fas fa-upload mr-2" />
                        Submit
                    </button>
                ) : null}
              
            </div>

        </Modal>

    )

}

ModalSubmitToPatriot.propTypes = {
    toggleModal   : PropTypes.func.isRequired,
    showModal     : PropTypes.bool.isRequired,
}

export default ModalSubmitToPatriot
