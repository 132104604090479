import React, { useState, useCallback } from 'react';
import { Input, FormGroup } from 'reactstrap';

const ArchkGroupings = ({label, data, onChange}) => {

    const [selected, setSelected] = useState('');

    const onInputChange = useCallback((e) => {
        const value = e.target.value;

        const found = data.find(d => d._id === value )

        if(!found) {
            setSelected('__false__')
            return onChange('__delete__')
        }

        onChange({$in: found.value});
        setSelected(found._id)

    }, [selected, onChange])

 
    return (
        <FormGroup>
            <label className="form-control-label mb-0">{label}</label>
            <Input
                value={selected}
                onChange={onInputChange}
                type="select"
            >
                <option value="__false__"></option>
                {data.map((d, i) => <option key={i} value={d._id}>{d.text}</option>)}
            </Input>

        </FormGroup>
    )

}

export default ArchkGroupings;
