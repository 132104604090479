/*
Documentation

this reducer holds all the actions we can dispatch for our global user object

*** make sure to declare all action types in actions.js one folder level back

*/

import * as actionTypes from '../../actions';

const initialState = {
    selected_division: {},
    on_call: {},
    email_editors: [],
    incoming_call: false,
    call_review: {},
    transfers: [],
}

const reducer = (state = initialState, action) => {

    switch (action.type) {
        case actionTypes.SET_STATE:

            return {
                ...state,
                ...action.payload
            }
      
        case actionTypes.SET_BROWSER_DIALING_STATUS:
            return {
                ...state,
                browser_dialing_status: action.payload.status
            }
            
        case actionTypes.SET_INCOMING_CALL:
            return {
                ...state,
                incoming_call: action.payload
            }
            
    
        case actionTypes.SET_CALL_REVIEW:
            return {
                ...state,
                call_review: action.payload
            }
   
        
        case actionTypes.SET_TRANSFER:
            let transferState = JSON.parse(JSON.stringify(state));
            transferState.transfers.push(action.payload)

            return transferState

        default:
            return state;

    }

}

export default reducer;
