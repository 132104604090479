import { useCallback, useState, useEffect } from "react";
import { Card, CardBody, CardFooter, Nav, NavItem, NavLink, CardHeader } from "reactstrap";
import { toggleStandardLoader } from 'store/functions/system/system';

import { toggleAlertBS } from 'store/functions/system/system';
import _cases from '_functions/cases';
import * as privileges  from '_functions/users/privileges'


import Impact from './Impact';
import Info from './Info';
import People from './People';
import PPP from './PPP';
import Revenue from './Revenue';
import SS from './SS';
import LeadInfo from './LeadInfo';

const tabs = [
    'Info',
    'People',
    'Revenue',
    'Impact',
    'PPP',
    'Social Security',
    'Lead Info'
]

const ContactsCrud = ({navPills, _case, setNav}) => {

    const [newCase, setNewCase] = useState(JSON.parse(JSON.stringify(_case)));
    const [tab, setTab] = useState('Info');
    const [editingEnabled, setEditingEnabled] = useState(false);

   const onSetState = useCallback((o, f, v) => {
        const _newCase = JSON.parse(JSON.stringify(newCase));
        _newCase[f] = v;
        setNewCase(_newCase)
    }, [newCase])

    const onScroll = (id) => {
        var el = document.getElementById(id);   
        window.scrollTo(el.offsetLeft,el.offsetTop); 

        const highlighted = document.querySelectorAll('.archk-highlighted')
        for (let i = 0; i < highlighted.length; i++) {
            const element = highlighted[i];
            element.style.border = '';
            element.classList.remove('.archk-highlighted')
            element.classList.remove('z-depth-5')

        }

        el.style.border = 'solid 6px #11cdef'
        el.classList.add('archk-highlighted')
        el.classList.add('z-depth-5')
    }

    const onSave = useCallback(async () => {
        toggleStandardLoader(true)

        const setNumber = (f) => {
            if(newCase[f] || newCase[f] === 0)  {
                newCase[f] = parseFloat(newCase[f])
            }
        }

        setNumber('social_security_wages_quarter_4_2020');
        setNumber('social_security_wages_quarter_1_2021');
        setNumber('social_security_wages_quarter_2_2021');
        setNumber('social_security_wages_quarter_3_2021');
        
        setNumber('gross_revenue_quarter_1_2019');
        setNumber('gross_revenue_quarter_2_2019');
        setNumber('gross_revenue_quarter_3_2019');
        setNumber('gross_revenue_quarter_4_2019');
        setNumber('gross_revenue_quarter_1_2020');
        setNumber('gross_revenue_quarter_2_2020');
        setNumber('gross_revenue_quarter_3_2020');
        setNumber('gross_revenue_quarter_4_2020');
        setNumber('gross_revenue_quarter_1_2021');
        setNumber('gross_revenue_quarter_2_2021');
        setNumber('gross_revenue_quarter_3_2021');
        setNumber('gross_revenue_quarter_4_2021');
        
        setNumber('ppp_round_1_loan_amount');
        setNumber('ppp_round_1_loan_amount_spent');
        setNumber('ppp_round_1_weeks');
        setNumber('ppp_round_2_loan_amount');
        setNumber('ppp_round_2_loan_amount_spent');
        setNumber('ppp_round_2_weeks');
        

        const saved = await _cases.saveChecklist(newCase._id, newCase)
        toggleStandardLoader(false)

        if(saved.success) {
            toggleAlertBS(false, 'Case Details Updated Successfully')
        } else {
            toggleAlertBS(true, 'Something went wrong, please try again')
        }

    }, [newCase])

    useEffect(() => {
        if(navPills !== 9) {
            setEditingEnabled(false)
        }
    }, [navPills])

    if(navPills !== 9) return <></>

    return (
        <Card>

{/* <CardHeader className="text-right">
                    <button style={{width: 'auto'}} onClick={() => setNav(3)} className="btn btn-warning"><i className="fas fa-arrow-left " /> Back To Docs</button>
                </CardHeader> */}

            <CardBody>
                <Nav className="nav-fill flex-column flex-sm-row my-0" id="tabs-text" pills role="tablist" >
                    {tabs.map((t, i) => (
                        <NavItem key={i}>
                            <NavLink
                                aria-selected={tab === t}
                                className={`mb-sm-3 mb-md-0 ${tab === t ? 'active' : ''}`}
                                onClick={e => {
                                    if(t === 'Social Security') {
                                        onScroll('archk-details-ss')
                                    } else if(t === 'PPP') {
                                        onScroll('archk-details-ppp')
                                    } else if(t === 'Impact') {
                                        onScroll('archk-details-impact')
                                    } else if(t === 'Revenue') {
                                        onScroll('archk-details-revenue')
                                    } else if(t === 'People') {
                                        onScroll('archk-details-people')
                                    } else if(t === 'Info') {
                                        onScroll('archk-details-info')
                                    } else if(t === 'Lead Info') {
                                        onScroll('archk-details-lead-info')
                                    }
                                }}
                                href="#pablo"
                                role="tab"
                            >
                                {t}
                            </NavLink>
                        </NavItem>
                    ))}
                
                </Nav>
            </CardBody>

            <div style={{position: 'relative'}}>
                {!privileges.canEditCaseDetails() ? (
                    <div style={{position: 'absolute', top: '0', right: '0', bottom: 0, left: 0, background: 'rgba(0,0,0,.1)', zIndex: 10}} />
                ) : (
                    !editingEnabled ? (
                        <div style={{position: 'absolute', top: '0', right: '0', bottom: 0, left: 0, background: 'rgba(0,0,0,.3)', zIndex: 10}} >
                            <div onClick={() => setEditingEnabled(true)} style={{position: 'absolute', top: '2%', left: 0, right: 0, background: 'rgba(0,0,0,.6)'}} className="cursor-pointer text-center p-3 text-white">
                                Click To Edit
                            </div>
                            <div onClick={() => setEditingEnabled(true)} style={{position: 'absolute', top: '50%', left: 0, right: 0, background: 'rgba(0,0,0,.6)'}} className="cursor-pointer text-center p-3 text-white">
                                Click To Edit
                            </div>
                            <div onClick={() => setEditingEnabled(true)} style={{position: 'absolute', top: '98%', left: 0, right: 0, background: 'rgba(0,0,0,.6)'}} className="cursor-pointer text-center p-3 text-white">
                                Click To Edit
                            </div>
                        </div>
                    ) : null
                )}
                
                <Info newCase={newCase} onSetState={onSetState} />
                <People newCase={newCase} onSetState={onSetState} />
                <Revenue newCase={newCase} onSetState={onSetState} />
                <Impact newCase={newCase} onSetState={onSetState} />
                <PPP newCase={newCase} onSetState={onSetState} />
                <SS newCase={newCase} onSetState={onSetState} />
                <LeadInfo newCase={newCase} onSetState={onSetState} />

                {/* {tab === 'Info' ? (
                    <Info newCase={newCase} onSetState={onSetState} />
                ) : tab === 'People' ? (
                    <People newCase={newCase} onSetState={onSetState} />
                ) : tab === 'Revenue' ? (
                    <Revenue newCase={newCase} onSetState={onSetState} />
                ) : tab === 'Impact' ? (
                    <Impact newCase={newCase} onSetState={onSetState} />
                ) : tab === 'PPP' ? (
                    <PPP newCase={newCase} onSetState={onSetState} />
                ) : tab === 'Social Security' ? (
                    <SS newCase={newCase} onSetState={onSetState} />
                ) : null} */}
            </div>
            
            {privileges.canEditCaseDetails() && editingEnabled ? (
                <>
                <CardFooter className="text-right">
                    <button onClick={() => setNav(3)} className="btn btn-warning"><i className="fas fa-arrow-left " /> Back To Docs</button>
                    <button onClick={onSave} className="btn btn-success">Save</button>
                </CardFooter>
                </>
            ) : (
                <CardFooter className="text-right">
                    <button onClick={() => setNav(3)} className="btn btn-warning"><i className="fas fa-arrow-left " /> Back To Docs</button>
                </CardFooter>
            )}

        </Card>
    )

}

export default ContactsCrud;