/*

this modal should show up whenever a call has been ended

note finishCase is the case id that corresponds to finishOptions
it is not any indication of actually "finishing" the case


*/

import StandardFormGroup from 'components/functional/inputs/StandardFormGroup';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {  Modal, FormGroup } from "reactstrap";
import _appointments from '_functions/appointments';
import _users from '_functions/users';
import _call_center from '_functions/call_center'
import _contact_notes from '_functions/contact_notes'
import { toggleStandardLoader } from 'store/functions/system/system'
import ReactQuill from "react-quill";
import * as callCenterActions from 'store/functions/call_center/call_center'

import * as ANALYTIC_EVENTS from '_settings/analytic_events';

import _analytics from '_functions/analytics';
import _lead_sources from '_functions/lead_sources';
import _contacts from '_functions/contacts';

import renderName from 'utils/renderName'
import { formatPhone } from 'utils/text'

import _recent_activity from '_functions/recent_activity';

import { io } from 'sockets';
import * as socketEvents from 'sockets/events';

const possibleConsultingOutcomes = ['Client Answered - Completed Appointment']

class ModalEndCall extends Component {

    state = {
        contact                     : null,
        finishOptions               : null,
        lead_source_entry           : null,
        reactQuillInvalid           : false,
        reactQuillText              : "",
        finished                    : {},
        contact_note                : {},
        call_appointment_previous   : {},
    };

    requiresLeadSource = () => {
        const { contact } = this.state;
        if(contact && (!contact.lead_source_name || contact.lead_source_name === 'Other')) return true;

        return false;
    }

    // sets contact note value and then sets state for the reactQuillText
    handleReactQuillChange = value => {
        this.setState({
            reactQuillInvalid: false,
            reactQuillText: value,
            contact_note: { ...this.state.contact_note, value }
        });
    };

    shouldConsult = () => {
        const { contact, finished, lead_source_entry } = this.state;
        if(lead_source_entry && lead_source_entry.status_consulted) return false;

        return contact && contact.is_lead && possibleConsultingOutcomes.includes(finished.outcome) ? true : false;
    }

    // if we have a selected appointment from props
    // this triggers a call to the db to "finish" it by
    // the api route
    finishAppointment = async (outcome) => {

        if(this.state.call_appointment_previous && this.state.call_appointment_previous._id) {
            _appointments.finish(this.state.call_appointment_previous._id, {
                outcome,
                finished_by: this.props.viewing_user._id,
                action: 'delete'
            }, true)
        }

        const entry = this.state.lead_source_entry;
        const contact_id = this.props.viewing_user.call_contact_previous;
        const { consulted } = this.state.finished;

        const status_consulted = entry && entry.status_consulted && entry.status_consulted !== 'null' ? true : false;
        
        // update firest call status and if client was consulted with if needed
        if(entry && (!entry.status_first_call || entry.status_first_call === 'unknown' || !status_consulted)) {
            _lead_sources.entry.setCallStatus(contact_id, {
                status_consulted: consulted === 'true' ? true : false,
                status_first_call: outcome
            });
        }

    }

    // function fires when a user is trying to finish the after call pop up modal
    // we check here if the contact is not assigned a lead source besides "other"
    // and if they are not we ask the user to provide the lead source of the contact
    onFinishReview = async () => {

        const { contact_note } = this.state
        const { outcome, lead_source, consulted } = this.state.finished;
        const contact_id = this.props.viewing_user.call_contact_previous;

        if(!contact_note.value) return this.setState({reactQuillInvalid: true})
        if(!outcome) return this.setState({finished: {...this.state.finished, outcomeState: 'invalid'}})

        if(this.requiresLeadSource() && (!lead_source || lead_source === 'false')) {
            return this.setState({finished: {...this.state.finished, lead_sourceState: 'invalid'}})
        }

        if(this.shouldConsult() && (!consulted || consulted === 'false')) {
            return this.setState({finished: {...this.state.finished, consultedState: 'invalid'}})
        }

        if(lead_source && lead_source !== 'I Am Unsure') {
            _contacts.update(contact_id, { lead_source })
        }
       
        _contact_notes.create({
            value: contact_note.value,
            contact: contact_id,
            user: this.props.viewing_user._id,
        }, true);

        _analytics.events.create({event: ANALYTIC_EVENTS.NOTES_CREATED});

        this.finishAppointment(outcome)
        this.onEnd()

    }

    // creates a call to the db to update the user saying they are no long on call
    // we then try to close the modal and refresh the queue for the viewing user via props
    onEnd = async () => {

        await _users.update(this.props.viewing_user._id, {
            fireCallNoteLeftSocket: true,
            call_status: 'open',
            call_start: null,
            call_contact: null,
            call_appointment: null,
            call_appointment_previous: null,
            call_must_review: false,
            call_appointment_in_person: null
        })

        const newState = {
            finished: {},
            contact_note: {},
            outcome: null,
            reactQuillText: ''
        }

        this.setState(newState, () => callCenterActions.toggleEndCallModal(false))

    }

    setAppointmentFound = async () => {
        return new Promise (async resolve => {

            const { call_appointment_previous } = this.props.viewing_user;

            const found_appointment = await _appointments.findById(call_appointment_previous, true)
            const appointment = found_appointment.data ? found_appointment.data : {}

            this.setState({call_appointment_previous: appointment}, resolve)

        })
    }
    setAppointmentOptions = async () => {
        return new Promise (async resolve => {

            const contact_id = this.props.viewing_user.call_contact_previous;

            const options = await _call_center.getFinishOptions(contact_id)

            const finishCase = options && options.data && options.data.case ? options.data.case : null
            const finishOptions = options && options.data && options.data.options && options.data.options.length ? options.data.options : null

            this.setState({finishCase, finishOptions, contact_id}, resolve)

        })
    }

    checkLastAppointment = async (nextProps) => {

        if(!nextProps.viewing_user) return

        const { call_must_review, call_conference, call_appointment_previous, call_appointment_in_person } = nextProps.viewing_user;

        if(call_must_review && !call_conference && !call_appointment_in_person) {

            const found_appointment = await _appointments.findById(call_appointment_previous, true)
            const appointment = found_appointment.data ? found_appointment.data : {}

            this.setState({call_appointment_previous: appointment})
            callCenterActions.toggleEndCallModal(true)

        }

    }

    fetchContact = async (props) => {

        const contact = await _contacts.findById(props.viewing_user.call_contact_previous, true);
        if(contact.data) this.setState({contact: contact.data})

    }
    
    fetchRecentLeadSource = async (props) => {

        if(!props.viewing_user.call_contact_previous) return;

        const lead_source_entry = await _lead_sources.entry.findByContact(props.viewing_user.call_contact_previous);
        if(lead_source_entry.data) this.setState({lead_source_entry: lead_source_entry.data})

    }
    

    // if opening the modal we try to see if we have custom options for the
    // call based on whether or not the associated contact is on a step
    // inside a case with custom answers. we also try to grab the last
    // users appointment to pre fill fields
    componentWillReceiveProps = async (nextProps) => {

        if(!this.props.viewing_user && nextProps.viewing_user) {

            this.checkLastAppointment(nextProps);

        } else if(!this.props.end_call_modal.show && nextProps.end_call_modal.show) {

            toggleStandardLoader(true)

            // reset values here
            this.setState({finished: {}, contact_note: {} })

            await Promise.all([
                this.setAppointmentFound(),
                this.setAppointmentOptions(),
                this.fetchContact(nextProps),
                this.fetchRecentLeadSource(nextProps),
            ])

            toggleStandardLoader(false);

        }

    }

    listenForCallNoteLeft = (data) => {
        if(data && data.data && data.data._id === this.props.viewing_user._id) {
            callCenterActions.toggleEndCallModal(false)
        }
    }


    componentWillUnmount = () => {

        io.off(socketEvents.user_call_note_left, this.listenForCallNoteLeft)

        const { reactQuillText } = this.state;
        if(reactQuillText && reactQuillText !== '<p><br></p>') {
            _recent_activity.update({
                title: 'Left Note As Draft', 
                value: reactQuillText
            })
        }

    }

    componentDidMount = () => {

        io.on(socketEvents.user_call_note_left, this.listenForCallNoteLeft)

        // try to let software load then if a user needs to
        // give outcome of the last appointment do so.
        // not perfectly in sync with load but good enough for now
        setTimeout(() => {
            this.checkLastAppointment(this.props);
            this.fetchContact(this.props);
            this.fetchRecentLeadSource(this.props);
        }, 1000 )

    }

    render() {

        const { finished, finishOptions  } = this.state
        const { reactQuillText, reactQuillInvalid, call_appointment_previous, contact } = this.state
        const { show } = this.props.end_call_modal;
        const { lead_sources, SETTINGS } = this.props;

        return (

            <>
                <Modal
                    id="end-call-modal"
                    className="modal-dialog-centered"
                    isOpen={show}
                    toggle={null}
                    size="lg"
                    zIndex={100000}
                >
                    <div className="modal-header mb-0 font-weight-bold text-capitalize">
                        {call_appointment_previous.name ? call_appointment_previous.name : (
                            <span className="text-capitalize">
                                {contact && (contact.given_name || contact.family_name) ? renderName(contact) : contact && contact.phone ? formatPhone(contact.phone) : ''} {' - '}
                                My Last Call
                            </span>
                        )}
                    </div>

                    <div className="modal-body">

                        <FormGroup>
                            <label className="form-control-label">Call Notes *</label>
                            <div className={reactQuillInvalid ? "react-quill-toolbar-hide react-quill-invalid" : "react-quill-toolbar-hide"}>
                                <ReactQuill
                                    value={reactQuillText}
                                    onChange={this.handleReactQuillChange}
                                    placeholder=""
                                    theme="snow"
                                    modules={{toolbar: []}}
                                />
                            </div>
                        </FormGroup>

                        {finishOptions ? (
                            <StandardFormGroup
                                obj={finished}
                                objName="finished"
                                onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                                type="select"
                                field="outcome"
                                title="What was the outcome of this call?"
                            >
                                <option></option>
                                {finishOptions.map((option, i) => <option key={i} value={option.step}>{option.name}</option> )}
                            </StandardFormGroup>
                        ) : (
                            <StandardFormGroup
                                obj={finished}
                                objName="finished"
                                onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                                type="select"
                                field="outcome"
                                title="What was the outcome of this call? *"
                            >
                                <option></option>
                                {SETTINGS.call_center.outcomes.map((outcome, i) => <option key={i} value={outcome}>{outcome}</option> )}
                            </StandardFormGroup>
                        )}

                        {this.shouldConsult() ? (
                            <StandardFormGroup
                                obj={finished}
                                objName="finished"
                                onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                                type="select"
                                field="consulted"
                                title="Were you able to do a full consultation with this contact or has one been done for them before? *"
                            >
                                <option value="false"></option>
                                <option value="null">No</option>
                                <option value="true">Yes</option>
                            </StandardFormGroup>
                        ) : null}

                    </div>

                    {this.requiresLeadSource() ? (
                        <div className="modal-body bg-dark">
                              <StandardFormGroup
                                obj={finished}
                                objName="finished"
                                onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                                type="select"
                                field="lead_source"
                                title={<span className="text-white"><i className="fas fa-poll mr-2 text-success" /> How did {contact && contact.given_name ? <span className="text-capitalize text-underline">{contact.given_name}</span> : 'this contact'} hear about us?</span>}
                                description={<span className="text-muted">If you do not know where they heard about us from select: <span className="font-weight-bold  text-warning">I Am Unsure</span></span>}
                            >
                                <option value="false"></option>
                                {lead_sources.length ? 
                                    <>
                                        {lead_sources.map(source => source.name === 'Other' ? null : <option key={source._id} value={source._id}>{source.name}</option>)}
                                        <option disabled>──────────</option>
                                        <option value="I Am Unsure">I Am Unsure</option>
                                    </>  
                                : (
                                    <option value="Other">Other</option>
                                )}
                            </StandardFormGroup>

                            {finished.lead_source === 'I Am Unsure' ? (
                                <div className="alert alert-warning mb-0"><i className="fas fa-exclamation mr-2 " /> Please ask <span className="text-capitalize">{contact.given_name ? contact.given_name : 'the contact'}</span> where they heard about us next time you speak with them.</div>
                            ) : null}
                        </div>
                    ) : null}

                    <div className="modal-footer">
                        <button onClick={this.onFinishReview} className="btn btn-success">
                            <i className="fas fa-check mr-2" /> Finish Call Review
                        </button>
                    </div>

                </Modal>
            </>

        )

    }

}

const mapStateToProps = state => {
    return {
        viewing_user    : state.auth.viewing_user,
        end_call_modal  : state.call_center.end_call_modal,
        lead_sources    : state.lead_sources.lead_sources,
        SETTINGS        : state.system.SETTINGS
    };
};

export default connect(mapStateToProps, '')(ModalEndCall);
