import React, { Component } from 'react';
import { Modal } from "reactstrap";

class SendEmailModalWrapper extends Component {

    render() {

        let { showModal, toggleModal, children } = this.props

        if(!showModal) return <></>

        return (

            <Modal
                className="modal-dialog-centered archk-send-email-modal"
                isOpen={showModal}
                size="lg"
                fade={false}
            >

                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Send Email</h5>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={toggleModal}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>

                {children}

            </Modal>

        )

    }

}

export default SendEmailModalWrapper
