import React, {useEffect} from 'react';
import { connect } from 'react-redux';

import moment from 'moment';
import renderName from 'utils/renderName';

import _analytics from '_functions/analytics'

import A from 'components/markup/links/A'
import QueryCreator from 'components/system/QueryCreator';

import keys from 'keys';

const QueryConsultations = ({viewing_user, users, lead_sources, groupings}) => {

    useEffect(() => {
        const callAlerts = document.getElementById('archk-call-alerts')
        const footer = document.querySelector('footer');
        if(callAlerts) callAlerts.style.display = 'none'
        if(footer) footer.style.display = 'none'
        return () => {
            if(callAlerts) callAlerts.style.display = 'block'
            if(footer) footer.style.display = 'block'
        }

    }, [])

    return (
        <div className="dashboard-query-creator">
             <QueryCreator 
                title="Consultations"
                hideSearch={true}
                runQuery={async (query, cb) => new Promise (async resolve => {
                    // set payments to default to today only at first
                    if(!query.hasRun) {

                        const date = moment().startOf('day');
                        query.filter.start = {
                            $gte: parseInt(date.format('X')),
                            $lt: parseInt(date.format('X')) + 86400,
                        }
                    }
                    const result = await _analytics.query.consultations(query)
                    return resolve(result.data)
                })}
                inputs={[
                    { type: 'ArchkDates', field: 'start', label: '', defaultValue: new Date() },

                    {
                        type: 'ArchkSelectSimple', field: 'user', label: 'Consulting User',
                        data: users.map(u => {
                            return { value: u._id, text: renderName(u) }
                        })
                    },
                    {
                        type: 'ArchkSelectSimple', field: 'booking_user', label: 'Booking User',
                        data: users.map(u => {
                            return { value: u._id, text: renderName(u) }
                        })
                    },
                    {
                        type: 'ArchkSelectSimple', field: 'lead_source', label: 'Lead Source',
                        data: lead_sources.map(u => {
                            return { value: u._id, text: u.name }
                        })
                    },
                    {
                        type: 'ArchkSelectSimple', field: 'company_size', label: 'Company Size',
                        data: [
                            {value: '1 - 10', text: '1 - 10'},
                            {value: '11 - 20', text: '11 - 20'},
                            {value: '21 - 30', text: '21 - 30'},
                            {value: '31 - 50', text: '31 - 50'},
                            {value: '51 - 100', text: '51 -100'},
                            {value: '100+', text: '100+'},
                        ]
                    },
                    {
                        type: 'ArchkInArray', field: 'outcome', label: 'Outcomes', query: 'in',
                        data: [
                            {value: 'rescheduled', text: 'Rescheduled'},
                            {value: 'No Show - follow up needed', text: 'No Show - follow up needed'},
                            {value: 'No Show - cancelled - no follow up needed', text: 'No Show - cancelled - no follow up needed'},
                            {value: 'Showed - DNH - needs for information', text: 'Showed - DNH - needs for information'},
                            {value: 'Showed - Needs Agreement 20%', text: 'Showed - Needs Agreement 20%'},
                            {value: 'Showed - Needs Agreement 18%', text: 'Showed - Needs Agreement 18%'},
                            {value: 'Showed - Needs Agreement 17.5%', text: 'Showed - Needs Agreement 17.5%'},
                            {value: 'Showed - Needs Agreement 15%', text: 'Showed - Needs Agreement 2015'},
                            {value: 'Showed - needs follow up call with senior lawyer', text: 'Showed - needs follow up call with senior lawyer'},
                            {value: 'Showed - DNQ (not impacted)', text: 'Showed - DNQ (not impacted)'},
                            {value: 'Showed - DNQ (only W2 is owner/family member)', text: 'Showed - DNQ (only W2 is owner/family member)'},
                            {value: 'Showed - DNQ (not interested)', text: 'Showed - DNQ (not interested)'},
                            {value: 'Showed - DNQ (already applied)', text: 'Showed - DNQ (already applied)'},
                            {value: 'Manually Cancelled', text: 'Manually Cancelled'},
                        ]
                    },
                    { type: 'ArchkGreaterThan0', field: 'finished_on', label: 'Finished', defaultValue: '' },

                ]}
                columns={[
                    {
                        dataField: "company_name",
                        text: 'Company Name',
                        formatter: (cell, row) => (
                            <div>
                                <div>{row.company_name}</div>
                                <div>
                                    {!row.outcome ? (
                                        <div>
                                            <A href={`${keys.BOOK_URL}?reschedule=${row._id}&u=${viewing_user._id}&l=${row.lead_source ? row.lead_source : ''}`}>
                                                <i className="fas fa-calendar text-info-original " />
                                            </A>
                                            <A href={`${keys.BOOK_URL}/outcome/${row._id}`}>
                                                <i className="fas fa-edit ml-3 text-success " />
                                            </A>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        ),
                    },
                    {
                        dataField: "outcome",
                        text: "Outcome",
                        formatter: (cell, row) => (
                            <span className="text-capitalize">{row.outcome ? row.outcome : '-'}</span>
                        )
                    },
                    {
                        dataField: "cases",
                        text: 'Cases',
                        formatter: (cell, row) => (
                            row.cases && row.cases.length ? (
                                row.cases.map(c => {
                                    const step = row.steps ? row.steps.find(s => s._id === c.original_current_step) : ''
                                    return (
                                        <div className="rounded bg-secondary p-2 mb-2 text-capitalize border">
                                            {c.name}
                                            <div>{step && step.name ? step.name.replace('Call Completed ', '') : ''}</div>
                                        </div>
                                    )
                                })
                            ) : '-'
                        )
                    },
                    
                    {
                        dataField: "user",
                        text: "Contact / User",
                        formatter: (cell, row) => (
                            row.user && row.contact ? (
                                <div>
                                    <span className="text-capitalize">{renderName(row.user)}</span>
                                    <div>
                                        <A href={`/dashboard/contacts/view/${row._id}`}>
                                            <span className="text-capitalize">{renderName(row.contact)}</span>
                                        </A>
                                    </div>
                                </div>
                            ) : '-'
                        
                        ),
                    },

                    
                    {
                        dataField: "company_size",
                        text: "Company Size",
                    },
                    {
                        dataField: "created_at",
                        text: "Consultation Date",
                        formatter: (cell, row) => (
                            <div className="text-right">
                                <div>{moment.unix(row.start).format("MM/DD/YYYY")}</div>
                                <div>{ moment.unix(row.start).format("hh:mm A")}</div>
                            </div>
                        )
                    },

                ]}
            
            />
        </div>
    )

}



const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,
        offices: state.offices.offices,
        users: state.users.users,
        lead_sources: state.lead_sources.lead_sources,
        groupings: state.groupings.groupings.filter(g => g.collection_name === 'template_appointments')
    };
};

export default connect(mapStateToProps, '')(QueryConsultations);