import apiWorker from '../apiWorker'

const calendar = {

    query: {

        query: (ids, query) => apiWorker('get',  `/api/v1/core/calendar/query/${ids}${query}`),

        byUsers: (ids, query) => apiWorker('get',  `/api/v1/core/calendar/query/byUsers/${ids}${query}`),
        byContacts: (ids, query) => apiWorker('get',  `/api/v1/core/calendar/query/byContacts/${ids}${query}`),

    }

}

export default calendar;