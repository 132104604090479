/*
Documentation

This file holds all the socket events to listen for within this system

*/


export const hold_updated                     = 'hold_updated';
export const muted_updated                    = 'muted_updated';
export const activity_updated                 = 'activity_updated';

export const analytics_data_types_added       = 'analytics_data_types_added';
export const analytics_data_types_updated     = 'analytics_data_types_updated';
export const analytics_data_types_deleted     = 'analytics_data_types_deleted';

export const appointments_added               = 'appointments_added';
export const appointments_updated             = 'appointments_updated';
export const appointments_deleted             = 'appointments_deleted';
export const appointments_not_available       = 'appointments_not_available';
export const appointments_available           = 'appointments_available';

export const automations_added                = 'automations_added';
export const automations_updated              = 'automations_updated';
export const automations_deleted              = 'automations_deleted';

export const call_queue_deleted               = 'call_queue_deleted';
export const call_queue_added                 = 'call_queue_added';

export const cases_added                      = 'cases_added';
export const cases_updated                    = 'cases_updated';
export const cases_deleted                    = 'cases_deleted';

export const case_alerts_added                = 'case_alerts_added';
export const case_alerts_updated              = 'case_alerts_updated';
export const case_alerts_deleted              = 'case_alerts_deleted';

export const case_feed_added                  = 'case_feed_added';
export const case_finished                    = 'case_finished';

export const case_template_doc_added          = 'case_template_doc_added';

export const document_merged                  = 'document_merged';
export const document_uploaded                = 'document_uploaded';

export const email_parser_logs_added          = 'email_parser_logs_added';

export const email_senders_added              = 'email_senders_added';
export const email_senders_updated            = 'email_senders_updated';
export const email_senders_deleted            = 'email_senders_deleted';

export const location_courts_added            = 'location_courts_added';
export const location_courts_updated          = 'location_courts_updated';
export const location_courts_deleted          = 'location_courts_deleted';

export const lead_sources_added               = 'lead_sources_added';
export const lead_sources_updated             = 'lead_sources_updated';
export const lead_sources_deleted             = 'lead_sources_deleted';

export const logout                           = 'logout';

export const case_workflow_tasks_updated      = 'case_workflow_tasks_updated';
export const case_workflow_tasks_added        = 'case_workflow_tasks_added';

export const case_workflows_updated           = 'case_workflows_updated';

export const case_parties_added               = 'case_parties_added';
export const case_parties_updated             = 'case_parties_updated';
export const case_parties_deleted             = 'case_parties_deleted';

export const case_templates_added             = 'case_templates_added';
export const case_templates_deleted           = 'case_templates_deleted';

export const case_alert_templates_added       = 'case_alert_templates_added';
export const case_alert_templates_deleted     = 'case_alert_templates_deleted';

export const companies_added                  = 'companies_added';
export const companies_updated                = 'companies_updated';
export const companies_deleted                = 'companies_deleted';

export const contacts_added                   = 'contacts_added';
export const contacts_updated                 = 'contacts_updated';
export const contacts_deleted                 = 'contacts_deleted';

export const contact_notes_added              = 'contact_notes_added';
export const contact_notes_updated            = 'contact_notes_updated';
export const contact_notes_deleted            = 'contact_notes_deleted';

export const contact_texts_added              = 'contact_texts_added';
export const contact_texts_updated            = 'contact_texts_updated';
export const contact_texts_deleted            = 'contact_texts_deleted';

export const contact_emails_added             = 'contact_emails_added';
export const contact_emails_updated           = 'contact_emails_updated';
export const contact_emails_deleted           = 'contact_emails_deleted';

export const contact_removed_from_hold        = 'contact_removed_from_hold';
export const contact_put_on_hold              = 'contact_put_on_hold';

export const documents_added                  = 'documents_added';
export const documents_updated                = 'documents_updated';
export const documents_deleted                = 'documents_deleted';

export const finished_appointments_added      = 'finished_appointments_added';

export const forms_added                      = 'forms_added';
export const forms_updated                    = 'forms_updated';
export const forms_deleted                    = 'forms_deleted';

export const groupings_added                  = 'groupings_added';
export const groupings_deleted                = 'groupings_deleted';
export const groupings_updated                = 'groupings_updated';

export const invoices_added                   = 'invoices_added';
export const invoices_updated                 = 'invoices_updated';
export const invoices_deleted                 = 'invoices_deleted';

export const missing_docs_added               = 'missing_docs_added';
export const missing_docs_updated             = 'missing_docs_updated';
export const missing_docs_deleted             = 'missing_docs_deleted';

export const microsoft_email_sync             = 'microsoft_email_sync';

export const offices_added                    = 'offices_added';
export const offices_updated                  = 'offices_updated';
export const offices_deleted                  = 'offices_deleted';

export const questionnaires_added             = 'questionnaires_added';
export const questionnaires_updated           = 'questionnaires_updated';

export const recent_activity_updated          = 'recent_activity_updated';

export const twilio_conference_start          = 'twilio_conference_start';
export const twilio_conference_end            = 'twilio_conference_end';
export const twilio_browser_transfer          = 'twilio_browser_transfer';
export const twilio_browser_transfer_denied   = 'twilio_browser_transfer_denied';

export const template_appointments_added      = 'template_appointments_added';
export const template_appointments_updated    = 'template_appointments_updated';
export const template_appointments_deleted    = 'template_appointments_deleted';

export const template_emails_added            = 'template_emails_added';
export const template_emails_updated          = 'template_emails_updated';
export const template_emails_deleted          = 'template_emails_deleted';

export const template_texts_added             = 'template_texts_added';
export const template_texts_updated           = 'template_texts_updated';
export const template_texts_deleted           = 'template_texts_deleted';

export const user_notifications_added         = 'user_notifications_added';
export const user_notifications_deleted       = 'user_notifications_deleted';

export const user_statuses_added              = 'user_statuses_added';
export const user_statuses_updated            = 'user_statuses_updated';
export const user_statuses_deleted            = 'user_statuses_deleted';

export const screen_watch_updated             = 'screen_watch_updated';
export const screen_watch_set                 = 'screen_watch_set';
export const screen_watch_cancel_user_events  = 'screen_watch_cancel_user_events';

export const tags_added                       = 'tags_added';
export const tags_updated                     = 'tags_updated';
export const tags_deleted                     = 'tags_deleted';

export const template_docs_added              = 'template_docs_added';
export const template_docs_updated            = 'template_docs_updated';
export const template_docs_deleted            = 'template_docs_deleted';

export const template_word_docs_added         = 'template_word_docs_added';
export const template_word_docs_updated       = 'template_word_docs_updated';
export const template_word_docs_deleted       = 'template_word_docs_deleted';

export const transfer_did_not_answer          = 'transfer_did_not_answer';
export const transfer_answered                = 'transfer_answered';

export const trust_logs_added                 = 'trust_logs_added';

export const twilio_browser_call_started      = 'twilio_browser_call_started';
export const twilio_browser_call_ended        = 'twilio_browser_call_ended';

export const users_added                      = 'users_added';
export const users_updated                    = 'users_updated';
export const users_deleted                    = 'users_deleted';
export const user_call_note_left              = 'user_call_note_left';

export const user_entered_call                = 'user_entered_call';
export const user_exited_call                 = 'user_exited_call';
export const user_ended_call                  = 'user_ended_call';

export const viewing_user_updated             = 'viewing_user_updated';

export const voicemail_updated                = 'voicemail_updated';
export const voicemail_added                  = 'voicemail_added';

export const workflows_added                  = 'workflows_added';
export const workflows_updated                = 'workflows_updated';
export const workflows_deleted                = 'workflows_deleted';
