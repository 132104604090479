
import StandardFormGroup from 'components/functional/inputs/StandardFormGroup';
import { CardFooter, Col, Row } from "reactstrap";

const InfoRep = ({newCase, onSetState}) => (
    <CardFooter id="archk-details-people">

        <Row>

            <Col lg={6}>

                <h3 className="mb-4">Representative</h3>

                <StandardFormGroup
                    obj={newCase}
                    objName="newCase"
                    onChange={onSetState}
                    type="text"
                    field="rep_name"
                    title="Name"
                />
                <StandardFormGroup
                    obj={newCase}
                    objName="newCase"
                    onChange={onSetState}
                    type="text"
                    field="rep_title"
                    title="Title"
                />
                <StandardFormGroup
                    obj={newCase}
                    objName="newCase"
                    onChange={onSetState}
                    type="text"
                    field="rep_email"
                    title="Email"
                />
                <StandardFormGroup
                    obj={newCase}
                    objName="newCase"
                    onChange={onSetState}
                    type="text"
                    field="rep_phone"
                    title="Phone"
                />
            </Col>

            <Col lg={6}>

                <h3 className="mb-4">Employees</h3>

                <StandardFormGroup
                    obj={newCase}
                    objName="newCase"
                    onChange={(o, f, v) => onSetState(o, f, v ? parseFloat(v) : v)}
                    type="number"
                    field="employees_quarter_4_2020"
                    title="Employees Q4 - 2020"
                />
                <StandardFormGroup
                    obj={newCase}
                    objName="newCase"
                    onChange={(o, f, v) => onSetState(o, f, v ? parseFloat(v) : v)}
                    type="number"
                    field="employees_quarter_1_2021"
                    title="Employees Q1 - 2021"
                />
                <StandardFormGroup
                    obj={newCase}
                    objName="newCase"
                    onChange={(o, f, v) => onSetState(o, f, v ? parseFloat(v) : v)}
                    type="number"
                    field="employees_quarter_2_2021"
                    title="Employees Q2 - 2021"
                />
                <StandardFormGroup
                    obj={newCase}
                    objName="newCase"
                    onChange={(o, f, v) => onSetState(o, f, v ? parseFloat(v) : v)}
                    type="number"
                    field="employees_quarter_3_2021"
                    title="Employees Q3 - 2021"
                />
            </Col>

        </Row>

        <hr />

        <h3 className="mb-4">Business Owners</h3>

        <StandardFormGroup
            obj={newCase}
            objName="newCase"
            onChange={onSetState}
            type="textarea"
            field="business_owners"
            title="Please list the names of all owners of the business below* (1 name per line)"
        />


        <h3 className="mb-4">W2 Employees</h3>

        <StandardFormGroup
            obj={newCase}
            objName="newCase"
            onChange={onSetState}
            type="textarea"
            field="business_owners_w2"
            title="If any W2 employees of the business are related to any of the business owners list the names of those employees below (1 name per line)"
        />
        
    </CardFooter>
)

export default InfoRep