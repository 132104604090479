/*
Documentation

This file holds the master component to render the view for creating a new case

It will save the data in the url whenever the view of the case creator is moved
it pulls this data back should the page be refreshed in the future

*/

import StepTracker from 'components/functional/progress/StepTracker';
import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import React from "react";
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardHeader, CardTitle, Container } from "reactstrap";
import _contacts from '_functions/contacts';
import _cases from '_functions/cases';
import { getUrlParameter } from 'utils/urls'

import SelectWorkflow from './components/SelectWorkflow';
import AssociateContact from './components/AssociateContact';
import AssignUsers from './components/AssignUsers';
import FinalizeDetails from './components/FinalizeDetails';

class CasesCreate extends React.Component {

    state = {
        step: 1,
        selected_contact: {},
        selected_workflow: {},
        selected_roles: [],
        totalCases: 0
    }

    setUrl = (selected_workflow, selected_contact, selected_roles) => {

        const url =
        window.location.pathname +
        '?selected_workflow=' + selected_workflow +
        '&selected_contact=' + selected_contact +
        '&selected_roles=' + selected_roles +
        '&name=' + getUrlParameter('name') +
        '&user=' + getUrlParameter('user') +
        '&court=' + getUrlParameter('court');

        window.history.pushState(null, null, url);

    }

    onSelectWorkflow = (workflow) => {

        this.setState({selected_workflow: workflow, step: 2})

        let selected_workflow = workflow._id
        let selected_contact = getUrlParameter('selected_contact')
        let selected_roles = getUrlParameter('selected_roles')

        this.setUrl(selected_workflow, selected_contact, selected_roles)

    }

    onSelectContact = async (contact) => {

        this.setState({selected_contact: contact, step: 3})

        let selected_workflow = getUrlParameter('selected_workflow') || this.state.selected_workflow
        let selected_contact = contact._id
        let selected_roles = getUrlParameter('selected_roles')

        this.setUrl(selected_workflow, selected_contact, selected_roles)


        const cases = await _cases.find(`?filter=contact__${contact._id}|deleted__false{bool}|original_workflow__${selected_workflow && selected_workflow._id ? selected_workflow._id : selected_workflow}&limit=1`)
        if(cases.total_documents) this.setState({totalCases: cases.total_documents})

    }

    onAssignUsers = (roles) => {

        this.setState({selected_roles: roles, step: 4})

        //set url parameters
        let selected_workflow = getUrlParameter('selected_workflow')
        let selected_contact = getUrlParameter('selected_contact')
        let selected_roles = [];
        let selected_role_string

        if(roles.length ) {

            roles.forEach((r, i) => {

                if(r.user) {

                    let pair = r.role + '__' + r.user._id

                    selected_role_string = i > 0 ? '|' + pair : pair

                    if(selected_roles) {
                        selected_roles = selected_roles + selected_role_string
                    } else {
                        selected_roles = selected_role_string
                    }

                }

            })

        }

        this.setUrl(selected_workflow, selected_contact, selected_roles)

    }

    onChangeStep = (step) => {

        let passesStepRoles = false
        const selected_contact = this.state.selected_contact

        const {selected_workflow, selected_roles} = this.state

        if(selected_workflow && selected_workflow.roles && selected_workflow.roles.length && selected_roles && selected_roles.length) {
            passesStepRoles = true
        }

        let maxStep

        if(passesStepRoles && selected_contact._id && this.state.selected_workflow) { maxStep = 4} else
        if(selected_contact._id && this.state.selected_workflow) { maxStep = 3} else
        if(this.state.selected_workflow) { maxStep = 2}

        if(step <= maxStep) {

            this.setState({step})

        } else {

            //show an alert that a user can not go to a new step without finishing the one they are on
            this.setState({showStepViewAlert: true}, () => {
                setTimeout(() => {
                    this.setState({showStepViewAlert: false})
                }, 2000)
            })

        }

    }

    getSelectedRolesFromQueryString = () => {

        const selected_roles = getUrlParameter('selected_roles');
        let selected_roles_to_push = []

        if(selected_roles) {

            const selected_roles_array = selected_roles.split('|')

            selected_roles_array.forEach(s => {

                let pair = s.split('__')

                let user = this.props.users.find(u =>  u._id === pair[1] )

                if(user) selected_roles_to_push.push({ role: pair[0], user })

            })

        }

        return selected_roles_to_push

    }

    //when we mount if we have url params lets get them to adjust the step and state data
    componentWillMount = async () => {

        let found_workflow = {};
        let found_contact = {};

        let hide_contact = getUrlParameter('hide_contact')
        let selected_workflow = getUrlParameter('selected_workflow')
        let selected_contact = getUrlParameter('selected_contact')
        let selected_roles = this.getSelectedRolesFromQueryString()

        if(this.props.selectedContact) selected_contact = this.props.selectedContact

        //find the workflow if the url param is passed in
        if(selected_workflow) {
            found_workflow = this.props.workflows.workflows.find(x => x._id === selected_workflow) || {};
        }

        if(!found_workflow._id && this.props.workflows.workflows.length === 1) {
            found_workflow = this.props.workflows.workflows[0]
        }

        //find the contact if the url param is passed in
        if(selected_contact && hide_contact !== 'true') {
            let query = await _contacts.findById(selected_contact)
            if(query.data) found_contact = query.data
        }

        let step = 1;

        // if this is a template start at the beginning
        if(!getUrlParameter('is_template')) {

            if(selected_roles && selected_roles.length && found_contact._id && found_workflow._id) {
                step = 4;
            } else if(found_contact._id && found_workflow._id && getUrlParameter('selected_contact') === 'none') {
                step = 4;
            } else if(found_contact._id && found_workflow._id) {
                step = 3;
            } else if(found_workflow._id) {
                step = 2;
            }

        }

        this.setState({
            selected_workflow: found_workflow,
            selected_contact: found_contact,
            selected_roles: selected_roles,
            step
        })

    }

    render() {

        const { step, selected_workflow, selected_contact, selected_roles, totalCases, showStepViewAlert } = this.state;
        const { isMobile } = this.props.device.info;

        const { hideNavigation, onCaseCreated } = this.props

        return (
            <>

            <Container fluid>

                <Helmet>
                    <title>Cases Create</title>
                    <meta name="description" content="Cases Create" />
                </Helmet>

                {!hideNavigation ? (
                    <DashHeaderOpen
                        icon="fas fa-home"
                        icon_link="/dashboard"
                        title={<span>Cases</span>} breadcrumb_1="Create"
                        actionComponent={(
                            <>
                            <Link to="/dashboard/case_templates/all" className="btn btn-info d-none d-md-inline-block">Create From Template</Link>
                            <Link  to="/dashboard/cases/all" className="btn btn-success"><i className="fas fa-arrow-left" /> All Cases</Link>
                            </>
                        )}
                    />
                ) : null}
              

                {showStepViewAlert ? (<div className="alert alert-warning">You must finish your current step before moving on.</div> ) : null}

                <Card className="card-color card-success">

                    <CardHeader>
                        <CardTitle className="mb-0 float-left">Create A Case</CardTitle>
                    </CardHeader>

                    <CardBody>

                        <StepTracker
                            current={step}
                            onChangeStep={(step) => {this.onChangeStep(step)}}
                            steps={[
                                {name: isMobile ? 'Workflow' : 'Add Workflow'},
                                {name: isMobile ? 'Contact'  : 'Associate Contact'},
                                {name: isMobile ? 'Users'    : 'Add Users'},
                                {name: isMobile ? 'Finalize' : 'Finalize Details'},
                            ]}
                        />

                    </CardBody>

                </Card>

                {totalCases ? (
                    <Link to={selected_contact ? `/dashboard/contacts/view/${selected_contact._id}?&nav=1` : 'null'}>
                        <div className="alert alert-warning cursor-pointer">
                            <i className="fas fa-gavel mr-2 " /> This contact currently has {totalCases} case(s) open with this workflow type. Click here to view them.
                        </div>
                    </Link>
                ) : null}

                {step === 1 ? (
                    <SelectWorkflow
                        selected_workflow={selected_workflow}
                        onSelectWorkflow={(workflow) => this.onSelectWorkflow(workflow)}
                    />
                ) : null}

                {step === 2 ? (
                    <AssociateContact
                        onChangeStep={(step) => {this.onChangeStep(step)}}
                        selected_contact={selected_contact}
                        onSelectContact={(contact) => this.onSelectContact(contact)}
                    />
                ) : null}

                {step === 3 ? (
                    <AssignUsers
                        selected_workflow={selected_workflow}
                        onAssignUsers={(roles) => this.onAssignUsers(roles)}
                        selected_roles={selected_roles}

                    />
                ) : null}

                {step === 4 ? (
                    <FinalizeDetails
                        selected_workflow={selected_workflow}
                        selected_contact={selected_contact}
                        selected_roles={selected_roles}
                        onCaseCreated={onCaseCreated}
                        hideNavigation={hideNavigation}
                    />
                ) : null}

            </Container>

            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        users: state.users.users,
        workflows: state.workflows,
        device: state.device
    };
};

export default connect(mapStateToProps, '')(CasesCreate);
