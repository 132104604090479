import { Modal } from "reactstrap";

const ModalSaveMissingDocs = ({showModal, toggleModal, onSaveMissingDocs}) => (
    <Modal
        className="modal-dialog-centered"
        isOpen={showModal}
        toggle={toggleModal}
        size="sm"
    >

        <div className="modal-header">
            <h5 className="modal-title">Notify Client</h5>
            <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={toggleModal}
            >
                <span aria-hidden={true}>×</span>
            </button>
        </div>

        <div className="modal-body">
            <p className="text-sm mb-0">Do you want to send out an email notifying the client their documents are updated and further action is required?</p>
        </div>

        <div className="modal-footer text-right">
            <button 
                className="btn btn-warning" 
                onClick={() => {
                    onSaveMissingDocs(false)
                    toggleModal()
                }}
            >
                No, Just Save
            </button>
            <button 
                className="btn btn-success" 
                onClick={() => {
                    onSaveMissingDocs(true)
                    toggleModal()
                }}
            >
                Send Email & Save
            </button>
        </div>

    </Modal>
)

export default ModalSaveMissingDocs
