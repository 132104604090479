import apiWorker from '../apiWorker'

const case_parties = {

    find:   (query)         => apiWorker('get',    `/api/v1/core/case_parties/find${query}`),

    create:   (data)        => apiWorker('post',   `/api/v1/core/case_parties/create`, data),
    update:   (_id, data)   => apiWorker('patch',  `/api/v1/core/case_parties/update/${_id}`, data),
    delete:   (_id)         => apiWorker('delete', `/api/v1/core/case_parties/delete/${_id}`),

}

export default case_parties;