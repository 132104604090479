import apiWorker from '../apiWorker'

const template_texts = {

    find        : (query)       => apiWorker('get',     `/api/v1/automations/template_texts/find${query}`),

    create      : (data)        => apiWorker('post',    `/api/v1/automations/template_texts/create`, data),
    update      : (_id, data)   => apiWorker('patch',   `/api/v1/automations/template_texts/update/${_id}`, data),
    delete      : (_id)         => apiWorker('delete',  `/api/v1/automations/template_texts/delete/${_id}`),
    generate    : (data)        => apiWorker('post',   `/api/v1/automations/templates/generate`, data),

}

export default template_texts;