/*
Documentation

This file creates a court

*/

import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import React, { useState, useEffect } from "react";

import { Helmet } from 'react-helmet';
import { Redirect } from 'react-router-dom';
import { Button, Card, CardBody, CardHeader, Col, Container, Row, CardTitle, CardFooter, FormGroup, Input  } from "reactstrap";
import { Link } from 'react-router-dom'
import _consultations from '_functions/consultations'
import { toggleAlertBS } from 'store/functions/system/system'

import { connect } from 'react-redux';

import ContactSearchInput from '../_components/ContactSearchInput';

import MinuteOptions15 from 'components/markup/dates/MinuteOptions15';
import DatePicker from 'react-datepicker';

import moment from 'moment';
import { toggleStandardLoader } from 'store/functions/system/system';
import renderName from 'utils/renderName';

const CreateConsultation = ({lead_sources, users}) => {

    const [redirect, setRedirect] = useState(null);
    const [contact, setContact] = useState(null);
    const [user, setUser] = useState(null);
    const [leadSource, setLeadSource] = useState(null);
    const [companyName, setCompanyName] = useState('');

    const [day, setDay] = useState(null);
    const [start, setStart] = useState(null);

    const onSave = async () => {

        if(!contact || (contact && !contact._id)) return toggleAlertBS('info', 'Make sure to fill out all fields.')
        if(!user) return toggleAlertBS('info', 'Make sure to fill out all fields.')
        if(!companyName) return toggleAlertBS('info', 'Make sure to fill out all fields.')
        if(!day) return toggleAlertBS('info', 'Make sure to fill out all fields.')
        if(!start) return toggleAlertBS('info', 'Make sure to fill out all fields.')

        const foundDay    = parseInt(moment(day).startOf('day').format('X'))
        const timeStart   = parseInt(start)

        const compiledDate = foundDay + timeStart;

        toggleStandardLoader(true)
        const data = await _consultations.create({
            contact: contact._id,
            user,
            company_name: companyName,
            lead_source: leadSource,
            date: compiledDate
        })
        toggleStandardLoader(false)

        if(data.data) {
            setRedirect('/dashboard/full_redirect?redirect=/dashboard/consultations/create')
            toggleAlertBS(false, 'Consultation imported successfully')
        } else {
            toggleAlertBS(true, 'Please try again')

        }

    }

    if(redirect) return <Redirect to={redirect} />

    return (
        <Container fluid>

        <Helmet>
            <title>Consultations Create</title>
            <meta name="description" content="Cases Create" />
        </Helmet>

        <DashHeaderOpen
            title={<span><i className="fas fa-database mr-2 text-success " /> System</span>}
            breadcrumb_1="Consultations"
            breadcrumb_2="Create"
            actionComponent={(
                <Link to="/analytics/consultations/query" className="btn btn-outline-warning"><i className="fas fa-arrow-left" /> All</Link>
            )}
        />

        <Card  className="card-color card-success">

            <CardHeader>
                <CardTitle className=" ">Create A Consultation</CardTitle>
                <p className="text-sm mb-0"><i className="fas fa-exclamation-triangle text-danger " /> This page should only be used to import an existing consultation that has already been scheduled in a calendar.</p>
                <p className="text-sm mb-0"> Creating a consultation here will <b>NOT</b> notify a contact or create a calendar event but WILL send out disposition text messages and emails at the time of the consultations. </p>
                <p className="text-sm mb-0"> To create a consultation that sends out invites and text reminders use your calendar booking link.</p>
            </CardHeader>

            <CardBody>

            <Row>

                <Col lg={6}>
                    <FormGroup>
                        <label className="form-control-label">Consultation Date</label>
                        <div
                            className={'date-picker-wrapper'}
                        >
                            <DatePicker
                                selected={day ? day : undefined}
                                onChange={day => setDay(day)}
                            />
                        </div>
                    </FormGroup>
                </Col>

                <Col lg={6}>
                    <FormGroup>
                    <label className="form-control-label">Consultation Time</label>

                        <Input
                            type="select"
                            value={start}
                            onChange={(e) => setStart(e.target.value === 'false' ? null : e.target.value)}
                            disabled={!day}
                        >
                            <option value="false"></option>
                            <MinuteOptions15
                                val={start}
                                date={day}
                            />
                        </Input>
                    </FormGroup>
                </Col>

            </Row>

            <FormGroup>
                <label className="form-control-label">Company Name</label>
                <Input 
                    type="text"
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                />
            </FormGroup>
          
    
            <FormGroup>
                <label className="form-control-label">User</label>
                <Input 
                    type="select"
                    value={user}
                    onChange={(e) => setUser(e.target.value === 'false' ? null : e.target.value)}
                >
                    <option value="false"></option>
                    {users.map(u => (
                        <option key={u._id} value={u._id}>{renderName(u)}</option>
                    ))}
                </Input>
            </FormGroup>

            <FormGroup>
                <label className="form-control-label">Lead Source</label>
                <Input 
                    type="select"
                    value={leadSource}
                    onChange={(e) => setLeadSource(e.target.value === 'false' ? null : e.target.value)}
                >
                    <option value="false"></option>
                    {lead_sources.map(source => (
                        <option key={source._id} value={source._id}>{source.name}</option>
                    ))}
                </Input>
            </FormGroup>

            <FormGroup className="p-2">
                <h4>Associate Contact</h4>
                <ContactSearchInput
                    value={contact ? contact : {}}
                    onSelect={(contact) => setContact(contact)}
                />
            </FormGroup>
            
            </CardBody>

            <CardFooter className="text-right">
                <Button
                    color="success"
                    onClick={onSave}
                >
                Create
                </Button>
            </CardFooter>

        </Card>

    </Container>
    )
}

const mapStateToProps = state => {
    return {
        lead_sources: state.lead_sources.lead_sources,
        users: state.users.users,
    };
};

export default connect(mapStateToProps, '')(CreateConsultation);
