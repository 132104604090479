/*
Documentation

this modal adds a party to the case.
note that it only adds, deleting is done in ./index in the rendered table

*/

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormGroup, Input, Modal } from "reactstrap";
import ContactSearchInput from 'views/dashboard/pages/_components/ContactSearchInput';
import _case_parties from '_functions/case_parties';
import _cases from '_functions/cases';
import ModalToggler from 'components/functional/modals/Toggler'

import ModalContactsCrud from '../../../../../contacts/components/ModalContactsCrud'

class ModalUpdateParties extends Component {

    state = {
        contact: {},
        title: '',
        error: false
    };

    onAddContact = async () => {

        this.setState({error: false})

        let { contact, title } = this.state

        title = title ? title.trim() : title;
        if(!title || !contact._id) return this.setState({error: true})

        const data = {
            contact: contact._id,
            case: this.props.case._id,
            title
        }

        await _case_parties.create(data)
        this.props.fetchParties()

        this.props.toggleModal()
        this.setState({contact: {}, title: ''})

    }

    render() {

        const { showModal, toggleModal } = this.props
        const { error, contact, title } = this.state
 
        return (

            <Modal
                className="modal-dialog-centered"
                isOpen={showModal}
                toggle={toggleModal}
                size="md"
            >
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Add Party</h5>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={toggleModal}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>

                <div className="modal-body">

                    <p className="text-sm">Assigning parties to case allows for quick relationships between all contacts involved. </p>

                    <ContactSearchInput
                        value={contact ? contact : null}
                        onSelect={(contact) => this.setState({contact}) }
                    />

                    <FormGroup>
                        <label className="form-control-label">Party Name</label>
                        <Input
                            type="text"
                            value={title || ''}
                            onChange={(e) => this.setState({title: e.target.value})}
                        />
                    </FormGroup>

                    {error && <p className="text-sm font-weight-bold text-danger mb-0">A contact and party must be specified before proceeding.</p>}

                </div>

                <div className="modal-footer">
                    <ModalToggler component={ModalContactsCrud} onSave={() => {}}>
                        <button className="btn btn-primary">
                            Add Contact
                        </button>
                    </ModalToggler>
     
                    <button onClick={this.onAddContact}  className="btn btn-success">
                        Add Party
                    </button>
                </div>

            </Modal>

        )

    }

}

ModalUpdateParties.propTypes = {
    case        : PropTypes.object.isRequired,
    showModal   : PropTypes.bool.isRequired,
    toggleModal : PropTypes.func.isRequired,
}

export default ModalUpdateParties
