/*
Documentation

This is the navbar for the dashboard layout

*/

import React from "react";
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import NavbarBottom from './NavbarBottom';
import NavbarTop from './NavbarTop';
import NavbarMobile from './NavbarMobile';


class LayoutNavbar extends React.Component {

	state = {
		shouldRedirect: null
	}

	onRedirect = () => {

		let redirect = this.state.shouldRedirect

		if(redirect) {
			this.setState({shouldRedirect: null})
			return <Redirect to={redirect} />
		}
	}

	render() {

		if(this.state.shouldRedirect) return this.onRedirect()

        const location = window.location.href;

    	return (

			<>

                <NavbarTop />

                <NavbarBottom
                    location={location}
                />

                <NavbarMobile
                    location={location}

                />


			</>

    	);
  	}
}

const mapStateToProps = state => {
  	return {
        viewing_user: state.auth.viewing_user,
  	};
};

export default connect(mapStateToProps, '')(LayoutNavbar);
