import React, { Component } from 'react';
import ReactQuill from "react-quill";
import PropTypes from 'prop-types'

class SendEmailBody extends Component {

    render() {

        const { body, onBodyChange } = this.props

        

        return (

            <ReactQuill
                defaultValue={body}
                value={body}
                onChange={onBodyChange}
                theme="snow"
                modules={{ toolbar: '#toolbar' }}
            />

        )

    }

}

SendEmailBody.propTypes = {
    body            : PropTypes.string,
    onBodyChange    : PropTypes.func.isRequired,
}

export default SendEmailBody
