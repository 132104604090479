/*
Documentation

This page shows a list of all cases
via api cases can be searched by case name or _id

*/

import NotificationDelete from 'components/functional/notifications/Delete';
import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardHeader, Container, CardTitle, Input } from "reactstrap";
import renderName from 'utils/renderName';
import _cases from '_functions/cases';
import Circle from 'components/markup/loading/Circle';
import * as _ from 'underscore';
import moment from 'moment'
import RenderEmailWithSend from 'components/system/RenderEmailWithSend'

import * as privileges from '_functions/users/privileges'
import { formatMoney } from 'utils/currency';

import { formatPhone } from 'utils/text'
import { toggleStartCallModal } from 'store/functions/call_center/call_center'

import { connect } from 'react-redux';

class CasesAll extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            sizePerPage: 25,
            total_documents: 0,
            data: null,
            caseToDelete: null,
            original_current_step: ''
        }
       this.queryTableDataThrottled = _.throttle(this.queryTableData, 750)
    }

    columns = [
        {
            dataField: "case name",
            text: "Name",
            formatter: (cell, row) => (
                <div>
                    <Link 
                        className="text-capitalize text-primary font-weight-bold" 
                        to={`/dashboard/cases/view/${row._id}`}
                    >
                        {row.name && row.name.length > 35 ? row.name.slice(0, 35) + '...' : row.name} 
                        {row.company_dba ? <div>{row.company_dba}</div> : null}

                        <div className="p-2 rounded bg-secondary border text-capitalize">
                            {row.roles.map(role => (
                                <div>{role.role}: {renderName(this.props.users.find(u => u._id === role.user))}</div>
                            ))}
                        </div>
                    </Link>

                  
                </div>
            ),
        },
        {
            dataField: "_id",
            text: "Company Size",
            formatter: (cell, row) => row.company_size ? row.company_size : row.company_size_estimated
        },
        {
            dataField: "contact",
            text: "Contact",
            formatter: (cell, row) => (
                <div>
                    {row.contact ? (
                        <div>
                            <Link 
                                className="text-capitalize text-primary font-weight-bold" 
                                to={`/dashboard/contacts/view/${row.contact._id}`}
                            > 
                                {renderName(row.contact)} 
                            </Link>
                            {row.contact.email ? <div><RenderEmailWithSend contact={row.contact} /></div> : null}
                            {row.contact.phone ? <div>{<span className="cursor-pointer" onClick={() => toggleStartCallModal(true, row)}>{formatPhone(row.contact.phone)}</span>}</div> : null}
                        </div>
                    ) : 'NOT FOUND'}
                </div>
            ),
        },
        {
            dataField: "current_step",
            text: "Workflow / Step",
            formatter: (cell, row) => {
                if(!row.current_step) return '-'
                const name = row.current_step.name;

                const color =  name === 'Email Sent' ? 'yellow' :
                    name === 'Call Scheduled' ? 'lightblue' :
                    name.includes('Call Completed') ? 'lightgray' :
                    name === 'Retainer Signed - Awaiting Documents' ? 'lightgreen' :
                    name === 'Documents Submitted' ? '#A569BD' :
                    name === 'Pending Signed Report' ? 'orange' :
                    name === 'IRS Submission' ? 'green' :
                    name === 'Case Finished - Payment Received' || name === 'Case Finished - Payment Received' ? 'neongreen' :
                    'red'
                return (
                    <div style={{background: color, width: 200, wordBreak: 'break-word', whiteSpace: 'pre-line'}} className="py-2 px-4 text-dark border rounded text-center">
                        <div>{row.current_step ? row.current_step.name : <span className="text-warning font-weight-bold">No Assigned Step</span>}</div>
                    </div>
                )
            }
        },
        // {
        //     dataField: "updated_at",
        //     text: "Roles",
        //     formatter: (cell, row) => (
        //         <div>
        //             {row.roles.map(role => (
        //                 <div>{role.role}: {renderName(this.props.users.find(u => u._id === role.user))}</div>
        //             ))}
        //         </div>
        //     )
        // },
        {
            dataField: "last_note_preview",
            text: "Last Note",
            formatter: (cell, row) => (
                <div>
                    <div>{row.last_note_preview ? row.last_note_preview : ''}</div>
                </div>
            )
        },
        {
            dataField: "created_at",
            text: "Submitted At",
            formatter: (cell, row) => (
                <div>
                    {console.log(row)}
                    <div>{row.documents_submitted_at ? moment.unix(row.documents_submitted_at).format('MMM Do, YYYY') : ''}</div>
                </div>
            )
        },
        {
            dataField: "document_percentage",
            text: "Doc% / ERTC Amount",
            formatter: (cell, row) => (
                <div className="font-weight-bold">
                    {row.document_percentage ? (
                        <span className={row.document_percentage >= 100 ? 'text-success' : row.document_percentage >= 0 ? 'text-info-original' : 'text-warning'}>
                            {row.document_percentage.toFixed(2)}%
                        </span>
                    )
                    :  <span className="text-danger">0.00%</span>}
                    <div>{row.total_ertc ? formatMoney(row.total_ertc) : '$0.00'}</div>
                </div>
            )
        },
        {
            dataField: "percent_fee",
            text: "Fee Percent",
            formatter: (cell, row) => (
                <div>
                    <div>{row.percent_fee ? (row.percent_fee * 100) + '%'  : ''}</div>
                </div>
            )
        },
        {
            dataField: "_id",
            text: "Total Fee",
            formatter: (cell, row) => (
                <div>
                    <div>{row.total_ertc && row.percent_fee ? formatMoney(row.total_ertc * row.percent_fee) : '-'}</div>
                </div>
            )
        },
        {
            dataField: "created_at",
            text: "Step Last Moved",
            formatter: (cell, row) => (
                <div>
                    <div>{moment.unix(row.current_step_start).format('MMM Do, YYYY')}</div>
                </div>
            )
        },
        // "services_agreement_sent_at": 1,
        // "retainer_signed_at": 1,
        // "documents_submitted_at": 1,
        // "report_sent_for_signature_at": 1,
        // "submitted_to_irs_at": 1,
        // "payment_received_at": 1,
       
       
       
        {
            dataField: "updated_at",
            text: "Actions",
            headerStyle: {textAlign: 'right'},
            formatter: (cell, row) => {
                return (
                    <div className="text-right">
                        <Link to={`/dashboard/cases/view/${row._id}`}><i className="fas fa-edit text-success mr-2"></i></Link>
                        {privileges.canDeleteCases() && (
                            <i onClick={() => this.setState({caseToDelete: row})} className="fas fa-trash text-danger"></i>
                        )}
                    </div>
                )
            }
        },

    ]

    queryTableData = async (searchText, setPage1) => {

        this.setState({loading: true});
        if(setPage1) this.setState({page: 1})

        const cases = await _cases.search({
            filter: {
                original_current_step: this.state.original_current_step
            },
            search: searchText,
            limit: this.state.sizePerPage,
            skip: (this.state.page - 1) * this.state.sizePerPage
        })
       
        this.setState({
            data: cases.data,
            total_documents: cases.total_documents,
            canRun: false,
            loading: false,

        })

    }

    onTableChange = async (type, newState) => {

        const { searchText } = newState

        if(type === 'search') {

            this.queryTableDataThrottled(searchText, true)

        } else {

            this.setState({ page: newState.page, sizePerPage: newState.sizePerPage }, () => {
                this.queryTableDataThrottled(searchText)
            })

        }

    }

    onDeleteCase = (_case) => this.onTableChange(null, this.state)

    // load first set of cases on load
    componentDidMount = async () => {

        const now = Math.floor(new Date() / 1)

        const cases = await _cases.search({
            search: undefined,
            limit: this.state.sizePerPage
        })

        this.setState({data: cases.data, total_documents: cases.total_documents})

    }

    render() {

        const caseToDelete = this.state.caseToDelete;
        let { data, loading, searchText} = this.state;

        return (
            <>

            {caseToDelete && (
                <NotificationDelete
                    deletionURL={`/api/v1/core/cases/delete/${caseToDelete._id}`}
                    confirmBtnText="Delete Case"
                    title={<span className="text-capitalize">{`Delete ${caseToDelete.name}`}</span>}
                    text="Deleting this case will remove it from being able to be accessed again. Proceed With Caution!"
                    onClose={() => { this.setState({caseToDelete: null}) }}
                    onSuccess={this.onDeleteCase}
                />
            )}

            <Container fluid>

                <Helmet>
                    <title>{`Cases (${this.state.total_documents}) `}</title>
                    <meta name="description" content="Cases" />
                </Helmet>

                <DashHeaderOpen
                    icon="fas fa-home"
                    icon_link="/dashboard"
                    title={<span >Cases</span>} breadcrumb_1="All"
                    actionComponent={privileges.canUpdateCases() ? (
                        <Link  to="/dashboard/cases/create" className="btn btn-success">Create Case</Link>
                    ): null}
                />

                <Card className="card-color card-primary">

                    <CardHeader>
                        <CardTitle className="mb-0 float-left">All Cases</CardTitle>
                    </CardHeader>

                    {data ? (
                        data.length || searchText !== undefined ? (
                            <ToolkitProvider
                                data={data}
                                keyField="_id"
                                columns={this.columns}
                                search
                            >
                                {props => (
                                    <>
                                        <div className="dataTables_filter pr-4 pb pt-3 pb-2 position-relative" >
                                            <label>
                                                <Input
                                                    className="form-control-sm"
                                                    value={this.state.searchText || ''}
                                                    placeholder="Search..."
                                                    onChange={(e) => {
                                                        this.setState({searchText: e.target.value})
                                                        this.queryTableDataThrottled(e.target.value)
                                                    }}
                                                />
                                                <Input
                                                    className="form-control-sm"
                                                    value={this.state.original_current_step}
                                                    type="select"
                                                    style={{display: 'inline-block', marginLeft: 10, maxWidth: 200}}
                                                    onChange={(e) => {
                                                        this.setState({original_current_step: e.target.value === 'false' ? '' : e.target.value}, () => {
                                                            this.queryTableDataThrottled(searchText)
                                                        })
                                                        
                                                    }}
                                                >
                                                    <option value="false">SELECT STEP</option>
                                                    <option value="emailed">Emailed</option>
                                                    <option value="callScheduled">Call Scheduled</option>
                                                    <option value="callConducted">Call Conducted</option>
                                                    <option value="sentRetainer">Sent Retainer</option>
                                                    <option value="signedRetainer">Signed Retainer</option>
                                                    <option value="submitted">Submitted</option>
                                                    <option value="signature">Signature</option>
                                                    <option value="irs">IRS</option>
                                                    <option value="dead">Dead</option>
                                                    <option value="noShow">No Show</option>

                                                </Input>
                                                 {loading ? (<Circle style={{display: 'inline-block', top: 1, position: 'absolute'}} /> ) : null}

                                            </label>
                                        </div>

                                        <div className="table-not-fixed table-responsive table-vertical-align">
                                            <BootstrapTable
                                                pagination={paginationFactory({
                                                    totalSize: this.state.total_documents,
                                                    page: this.state.page,
                                                    sizePerPage: this.state.sizePerPage,
                                                    alwaysShowAllBtns: true,
                                                    showTotal: true,
                                                    withFirstAndLast: true,
                                                    sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
                                                        <div className="dataTables_length" id="datatable-basic_length">
                                                            <label>
                                                                Show{" "}
                                                                {
                                                                <select
                                                                    value={currSizePerPage}
                                                                    name="datatable-basic_length"
                                                                    aria-controls="datatable-basic"
                                                                    className="form-control form-control-sm"
                                                                    onChange={e => onSizePerPageChange(e.target.value)}
                                                                >
                                                                    <option value="10">10</option>
                                                                    <option value="25">25</option>
                                                                    <option value="50">50</option>
                                                                    <option value="100">100</option>
                                                                </select>
                                                                }{" "}
                                                                entries.
                                                            </label>
                                                        </div>
                                                    )
                                                })}
                                                {...props.baseProps}
                                                bootstrap4={true}
                                                bordered={false}
                                                remote={{
                                                    search: true,
                                                    pagination: true,
                                                    sort: false,
                                                    cellEdit: false
                                                }}
                                                onTableChange={this.onTableChange}

                                            />
                                        </div>
                                    </>
                                )}
                            </ToolkitProvider>
                        ): (
                            <CardBody>
                                <p className="text-sm mb-0">
                                    No cases have been created yet.
                                    <Link to="/dashboard/cases/create">
                                        <b className="text-warning"> Click here to create one.</b>
                                    </Link>
                                </p>
                            </CardBody>
                        )
                    ) : (
                       <Circle />
                    )}

                </Card>

            </Container>

            </>
        );
    }
}


const mapStateToProps = state => {
    return {
    	users: state.users.users,
    };
};

export default connect(mapStateToProps, '')(CasesAll);
