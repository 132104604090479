/*------------------------------------------------*/
/* LIBRARIES
/*------------------------------------------------*/
import throttle from 'lodash/throttle';

/*------------------------------------------------*/
/* INTERNAL DEPENDENCIES
/*------------------------------------------------*/
const  MAIN_APP_SIDEBAR_ID = 'merge-container-master';

/*------------------------------------------------*/
/* CONSTANTS
/*------------------------------------------------*/
const OFFSET = 25;
const PX_DIFF = 12;

/*------------------------------------------------*/
/* GLOBAL VARIABLES
/*------------------------------------------------*/
let scrollIncrement = 0;
let isScrolling = false;
let sidebarElement = null;

/*------------------------------------------------*/
/* METHODS
/*------------------------------------------------*/

/**
 * Scroll up in the sidebar.
 */
const goUp = () => {
  scrollIncrement -= PX_DIFF;
  sidebarElement.scrollTop = scrollIncrement;

  if (isScrolling && scrollIncrement >= 0) {
    window.requestAnimationFrame(goUp);
  }
};

/**
 * Scroll down in the sidebar.
 */
const goDown = () => {
  scrollIncrement += PX_DIFF;
  sidebarElement.scrollTop = scrollIncrement;

  if (isScrolling && scrollIncrement <= sidebarElement.scrollHeight) {
    window.requestAnimationFrame(goDown);
  }
};

const onDragOver = (event) => {
  const clientRect = sidebarElement.getBoundingClientRect();
  const isMouseOnTop = (
    scrollIncrement >= 0 && event.clientY > clientRect.top
    && event.clientY < (clientRect.top + OFFSET)
  );
  const isMouseOnBottom = (
    scrollIncrement <= sidebarElement.scrollHeight
    && event.clientY > (window.innerHeight - OFFSET)
    && event.clientY <= window.innerHeight
  );

  if (!isScrolling && (isMouseOnTop || isMouseOnBottom)) {
    isScrolling = true;
    scrollIncrement = sidebarElement.scrollTop;

    if (isMouseOnTop) {
      window.requestAnimationFrame(goUp);
    }
    else {
      window.requestAnimationFrame(goDown);
    }
  }
  else if (!isMouseOnTop && !isMouseOnBottom) {
    isScrolling = false;
  }
};

/**
 * The "throttle" method prevents executing the same function SO MANY times.
 */
const throttleOnDragOver = throttle(onDragOver, 300);

// IMPORTANT: CALL THIS METHOD IN: beginDrag!!!
const addEventListenerForSidebar = () => {
  sidebarElement = document.getElementById(MAIN_APP_SIDEBAR_ID);
  sidebarElement.addEventListener('dragover', throttleOnDragOver);
};

// IMPORTANT: CALL THIS METHOD IN: endDrag!!!
const removeEventListenerForSidebar = () => {
  sidebarElement.removeEventListener('dragover', throttleOnDragOver);
  isScrolling = false;
};

/*------------------------------------------------*/
/* EXPORTS
/*------------------------------------------------*/
// eslint-disable-next-line
export default {
  addEventListenerForSidebar,
  removeEventListenerForSidebar
};