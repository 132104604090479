
import apiWorker from '../apiWorker'

const consultations = {
    find: (contact) => apiWorker('get', `/api/v1/core/consultations/find/${contact}`),
    create: (data) => apiWorker('post', `/api/v1/core/consultations/create`, data),
    me: (data) => apiWorker('get', `/api/v1/core/consultations/me`, data),
    today: (query) => apiWorker('get', `/api/v1/core/consultations/today${query}`),

}

export default consultations;