import { CardFooter, Col, Row, FormGroup, Input } from "reactstrap";
import { formatMoney } from 'utils/currency';

const InfoRep = ({newCase, onSetState}) => (
    <CardFooter id="archk-details-revenue">

        <h3 className="mb-2">Gross Revenue 2019</h3>
        <Row>
            <Col lg={3}>
                <FormGroup>
                    <label className="form-control-label">Q1 - {formatMoney(newCase.gross_revenue_quarter_1_2019)}</label>
                    <Input 
                        type="number"
                        value={newCase.gross_revenue_quarter_1_2019}
                        onChange={(e) => onSetState({}, 'gross_revenue_quarter_1_2019', e.target.value)}
                    />
                </FormGroup>
            </Col>
            <Col lg={3}>
                <FormGroup>
                    <label className="form-control-label">Q2 - {formatMoney(newCase.gross_revenue_quarter_2_2019)}</label>
                    <Input 
                        type="number"
                        value={newCase.gross_revenue_quarter_2_2019}
                        onChange={(e) => onSetState({}, 'gross_revenue_quarter_2_2019', e.target.value)}
                    />
                </FormGroup>
            </Col>
            <Col lg={3}>
                <FormGroup>
                    <label className="form-control-label">Q3 - {formatMoney(newCase.gross_revenue_quarter_3_2019)}</label>
                    <Input 
                        type="number"
                        value={newCase.gross_revenue_quarter_3_2019}
                        onChange={(e) => onSetState({}, 'gross_revenue_quarter_3_2019', e.target.value)}
                    />
                </FormGroup>
            </Col>
            <Col lg={3}>
                <FormGroup>
                    <label className="form-control-label">Q4 - {formatMoney(newCase.gross_revenue_quarter_4_2019)}</label>
                    <Input 
                        type="number"
                        value={newCase.gross_revenue_quarter_4_2019}
                        onChange={(e) => onSetState({}, 'gross_revenue_quarter_4_2019', e.target.value)}
                    />
                </FormGroup>
            </Col>
        </Row>

        <hr />
        <h3 className="mb-2">Gross Revenue 2020</h3>

        <Row>
            <Col lg={3}>
                <FormGroup>
                    <label className="form-control-label">Q1 - {formatMoney(newCase.gross_revenue_quarter_1_2020)}</label>
                    <Input 
                        type="number"
                        value={newCase.gross_revenue_quarter_1_2020}
                        onChange={(e) => onSetState({}, 'gross_revenue_quarter_1_2020', e.target.value)}
                    />
                </FormGroup>
            </Col>
            <Col lg={3}>
                <FormGroup>
                    <label className="form-control-label">Q2 - {formatMoney(newCase.gross_revenue_quarter_2_2020)}</label>
                    <Input 
                        type="number"
                        value={newCase.gross_revenue_quarter_2_2020}
                        onChange={(e) => onSetState({}, 'gross_revenue_quarter_2_2020', e.target.value)}
                    />
                </FormGroup>
            </Col>
            <Col lg={3}>
                <FormGroup>
                    <label className="form-control-label">Q3 - {formatMoney(newCase.gross_revenue_quarter_3_2020)}</label>
                    <Input 
                        type="number"
                        value={newCase.gross_revenue_quarter_3_2020}
                        onChange={(e) => onSetState({}, 'gross_revenue_quarter_3_2020', e.target.value)}
                    />
                </FormGroup>
            </Col>
            <Col lg={3}>
                <FormGroup>
                    <label className="form-control-label">Q4 - {formatMoney(newCase.gross_revenue_quarter_4_2020)}</label>
                    <Input 
                        type="number"
                        value={newCase.gross_revenue_quarter_4_2020}
                        onChange={(e) => onSetState({}, 'gross_revenue_quarter_4_2020', e.target.value)}
                    />
                </FormGroup>
            </Col>
        </Row>
       
        <hr />
        <h3 className="mb-2">Gross Revenue 2021</h3>

        <Row>
            <Col lg={3}>
                <FormGroup>
                    <label className="form-control-label">Q1 - {formatMoney(newCase.gross_revenue_quarter_1_2021)}</label>
                    <Input 
                        type="number"
                        value={newCase.gross_revenue_quarter_1_2021}
                        onChange={(e) => onSetState({}, 'gross_revenue_quarter_1_2021', e.target.value)}
                    />
                </FormGroup>
            </Col>
            <Col lg={3}>
                <FormGroup>
                    <label className="form-control-label">Q2 - {formatMoney(newCase.gross_revenue_quarter_2_2021)}</label>
                    <Input 
                        type="number"
                        value={newCase.gross_revenue_quarter_2_2021}
                        onChange={(e) => onSetState({}, 'gross_revenue_quarter_2_2021', e.target.value)}
                    />
                </FormGroup>
            </Col>
            <Col lg={3}>
                <FormGroup>
                    <label className="form-control-label">Q3 - {formatMoney(newCase.gross_revenue_quarter_3_2021)}</label>
                    <Input 
                        type="number"
                        value={newCase.gross_revenue_quarter_3_2021}
                        onChange={(e) => onSetState({}, 'gross_revenue_quarter_3_2021', e.target.value)}
                    />
                </FormGroup>
            </Col>
            <Col lg={3}>
                <FormGroup>
                    <label className="form-control-label">Q4 - {formatMoney(newCase.gross_revenue_quarter_4_2021)}</label>
                    <Input 
                        type="number"
                        value={newCase.gross_revenue_quarter_4_2021}
                        onChange={(e) => onSetState({}, 'gross_revenue_quarter_4_2021', e.target.value)}
                    />
                </FormGroup>
            </Col>
        </Row>
       
        
    </CardFooter>
)

export default InfoRep