import apiWorker from '../apiWorker'

const auth = {

    security_code: {
        create:     (data, hideError)  =>  apiWorker('post',  `/api/v1/auth/security_code/create`, data, hideError),
        validate:   (data, hideError)  =>  apiWorker('post',  `/api/v1/auth/security_code/validate`, data, hideError),
    },

    password: {
        validate:   (data)  =>  apiWorker('post',  `/api/v1/auth/password_validate`, data, true),
        update:     (data)  =>  apiWorker('post',  `/api/v1/auth/password_update`, data, true),
    },

    status: {
        logout:     ()      =>  apiWorker('post',  `/api/v1/auth/logout`),
        isLoggedIn: ()      =>  apiWorker('get',   `/api/v1/auth/viewing_user`),
    }

}

export default auth;