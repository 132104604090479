/*
Documentation

uploads a document via the drag and drop editor
note that when documents are uploaded they will be refreshed on the screen through a socket update, no state change needed here on upload

*/

import DragAndDrop from 'components/functional/uploads/DragAndDrop';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Card, CardBody, CardHeader, CardTitle } from "reactstrap";
import { canUpdateCases } from '_functions/users/privileges';

import mergeFinal from '_functions/mergeFinal'
import fileDownloader from 'js-file-download';

import merge from '_functions/mergeFinal'

class DocumentsUpload extends Component {

    format8821 = async () => {
        const result = await merge.merge2({
            case: this.props.case._id, contact: this.props.case.contact._id
        })

        if(result.data) {
            var a = document.createElement("a"); //Create <a>
            a.href = "data:application/pdf;base64," + result.data; //Image Base64 Goes here
            a.download = "8821.pdf"; //File name Here
            a.click(); //Downloaded file
        }

        console.log(result)
    }

    render() {

        const canUpdate = canUpdateCases();

        return (

            <>

            <Card className="card-color card-warning">

                <CardHeader>
                    <CardTitle className="mb-0">Merge Final Documents</CardTitle>
                </CardHeader>

                <CardBody>
                    {canUpdate ? (
                        <DragAndDrop
                            zone_id="documents_213123123123"
                            url={`/api/merge_final`}
                            onUpload={(data) => {
                                var a = document.createElement("a"); //Create <a>
                                a.href = "data:application/pdf;base64," + data; //Image Base64 Goes here
                                a.download = "Final Report.pdf"; //File name Here
                                a.click(); //Downloaded file

                            }}
                            onError={(e) => console.log(e)}
                            // defaultValue={this.props.case.name ? this.props.case.name.replace(' - ertc', '') + ' - ' + missing_doc.name : missing_doc.name}
                            extraData={{case: this.props.case._id, contact: this.props.case.contact._id}}
                        />
                    ) : (
                        <p className="text-sm"><i className="text-warning fas fa-exclamation-triangle mr-2" /> Document upload disabled.</p>
                    )}

                </CardBody>

            </Card>
           
            <Card className="card-color card-warning">

                <CardHeader>
                    <CardTitle className="mb-0">Merge 8821</CardTitle>
                </CardHeader>

                <CardBody>
                    {/* {canUpdate ? (
                        <DragAndDrop
                            zone_id="documents_2131231231222222"
                            url={`/api/merge_final2`}
                            onUpload={(data) => {
                                var a = document.createElement("a"); //Create <a>
                                a.href = "data:application/pdf;base64," + data; //Image Base64 Goes here
                                a.download = "8821.pdf"; //File name Here
                                a.click(); //Downloaded file

                            }}
                            onError={(e) => console.log(e)}
                            // defaultValue={this.props.case.name ? this.props.case.name.replace(' - ertc', '') + ' - ' + missing_doc.name : missing_doc.name}
                            extraData={{case: this.props.case._id, contact: this.props.case.contact._id}}
                        />
                    ) : (
                        <p className="text-sm"><i className="text-warning fas fa-exclamation-triangle mr-2" /> Document upload disabled.</p>
                    )} */}
                    <button onClick={this.format8821} className='btn btn-block btn-success'>Download</button>
                </CardBody>

            </Card>

            </>

        )

    }

}

DocumentsUpload.propTypes = {
    case: PropTypes.object.isRequired,
}

export default DocumentsUpload
