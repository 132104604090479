
import { useEffect, useState, useCallback } from 'react'
import { Row, Col, Card, CardBody, CardHeader, CardTitle, CardFooter } from 'reactstrap';
import Circle from 'components/markup/loading/Circle'
import moment from 'moment'
import _case_workflow_history from '_functions/case_workflow_history';

import { toggleAlertBS } from 'store/functions/system/system';

import SendEmail from 'components/system/SendEmail';
import SendEmailModalWrapper from 'components/system/SendEmail/ModalWrapper';

import { formatMoney } from 'utils/currency';
import A from 'components/markup/links/A'

const Overview = ({getCase, case_workflow, navPills, _case}) => {

    const [history, setHistory] = useState(null)
    const [showModal, setShowModal] = useState(null)

    const fetchData = useCallback(async () => {
        const history = await _case_workflow_history.findByCase(_case._id)
        if(history.success) setHistory(history.data && history.data.history ? history.data.history : [])
    }, [_case._id])

    useEffect(() => {
        if(navPills === 1) {
            fetchData()
        }
    }, [fetchData, navPills])

    if(navPills !== 1 && navPills !== 2 ) return ''

    if(!history) return (
        <Card>
            <CardBody>
                <div className="py-6"><Circle /></div>
            </CardBody>
        </Card>
    )

    return (
        <div>
            <Card>
                <CardHeader>
                    <Row>
                        <Col lg={8}>
                            <CardTitle className="mb-0">Case Info & Progression</CardTitle>
                        </Col>
                        <Col lg={4} className="text-right">
                            <button onClick={() => setShowModal(true)} style={{width: 'auto'}} className="btn btn-primary">Send Portal Invite</button>
                        </Col>
                    </Row>
                </CardHeader>


                <CardFooter>
                        <p className="text-sm mb-0">
                            {_case.total_ertc ? <span className="p-2 px-3 rounded border mr-2 bg-dark text-white"><b style={{color: '#ddd'}}>Total Credits:</b> {formatMoney(_case.total_ertc) }</span> : ''}
                            {_case.company_ein ? <span className="p-2 px-3 rounded border mr-2 bg-dark text-white"><b style={{color: '#ddd'}}>EIN:</b> { _case.company_ein}</span> : ''}
                            {_case.company_size && _case.company_size_estimated && _case.company_size !== 'unknown' && _case.company_size_estimated !== 'unknown' ? 
                                <span className="p-2 px-3 rounded border mr-2 bg-dark text-white"><b style={{color: '#ddd'}}>COMPANY SIZE:</b> { _case.company_size || _case.company_size_estimated}</span> : ''}
                            {/* {_case.current_step_start ? <span className="p-2 px-3 rounded border mr-2 bg-dark text-white"><b style={{color: '#ddd'}}>Step Moved:</b> {moment.unix(_case.current_step_start).fromNow()}</span> : '' } */}
                            {/* {_case.last_note_preview ? <span className="p-2 px-3 rounded border mr-2 bg-dark text-white"><b style={{color: '#ddd'}}>Last Note:</b> {_case.last_note_preview ? _case.last_note_preview : '-'}</span> : '' } */}
                            {_case.patriot_id ? (
                                <span className="p-2 px-3 rounded border mr-2 bg-dark text-white"><b style={{color: '#ddd'}}>Patriot Synced:</b> 
                                <A href={`https://patriotdataprocessing.com/clients#${_case.patriot_id}`}> <i className="cursor-pointer fas fa-link text-success ml-2" /></A>
                                </span>
                            ) : (
                                <span onClick={() => { toggleAlertBS('info', 'This will always return an X until a submission is tried on the documents tab. For existing (imported) cases the ein must match that of one in patriot before the submission is tried') }} className="p-2 px-3 cursor-pointer rounded border mr-2 bg-dark text-white"><b style={{color: '#ddd'}}>Patriot Synced:</b><i  className=" fas fa-times text-danger ml-2" /></span>
                            )}
                            {_case.final_package_link ? (
                                <span className="p-2 px-3 rounded border mr-2 bg-dark text-white"><b style={{color: '#ddd'}}>Final Package:</b> 
                                <A href={_case.final_package_link}> <i className="cursor-pointer fas fa-link text-success ml-2" /></A>
                                </span>
                            ) : ''}
                            <span className="p-2 px-3 rounded border mr-2 bg-dark text-white"><b style={{color: '#ddd'}}>Docs Percent:</b> {_case.document_percentage ? _case.document_percentage : '0'}%</span>

                            {_case.submitted_to_irs_at ? (
                                <span className="p-2 px-3 rounded border mr-2 bg-dark text-white"><b style={{color: '#ddd'}}>IRS Submitted: </b>{' '}
                                {moment.unix(_case.submitted_to_irs_at).format('MM/DD/YYYY')}
                                </span>
                            ) : ''}
                            
                            {_case.logged_in_at ? (
                                <span className="p-2 px-3 rounded border mr-2 bg-success text-white"><b style={{color: '#fff'}}>HAS LOGGED IN</b>
                                </span>
                            ) : (
                                <span className="p-2 px-3 rounded border mr-2 bg-danger text-white"><b style={{color: '#fff'}}>HAS NOT LOGGED IN</b>
                                </span>
                            )}
                            {_case.has_onboarding ? (
                                <span className="p-2 px-3 rounded border mr-2 bg-success text-white"><b style={{color: '#fff'}}>Has Onboarding</b>
                                </span>
                            ) : (
                                <span className="p-2 px-3 rounded border mr-2 bg-danger text-white"><b style={{color: '#fff'}}>Not Onboarded</b>
                                </span>
                            )}

                        </p>
                    </CardFooter>

                    
                <CardFooter>
                    {!history.length ? <p className="text-sm">This case has not moved to any steps yet</p> : (
                        history.map((h, i) => (
                            <span style={{display: 'inline-block mb-3'}}>
                                {i > 0 ? <i className="fas fa-arrow-right text-dark mr-2" style={{position: 'relative', top: -15, left: -2}} /> : ''}
                                <span 
                                    className={`text-sm mb-0 p-3 text-dark rounded rounded border d-inline-block mb-2 mr-3 ${h.step_id === _case.current_step ? 'bg-success text-white' : 'bg-secondary'}`}
                                >
                                    <div className="mb--2"><small>{h.step_id === _case.current_step ? '* ' : ''}{moment.unix(h.date).format('MMM Do, YYYY h:mm A')} </small></div>
                                    <div>{h.step_name}</div>
                                    <div className="mt--2 text-capitalize"><small>{h.user_name}</small></div>
                                </span>
                            </span>
                        ))
                    )}
                </CardFooter>
            </Card>

            <SendEmailModalWrapper
                showModal={showModal}
                toggleModal={() => setShowModal(false)}
            >
                <SendEmail
                    template_email="Portal Invite"
                    onSendingStart={() => setShowModal(false)}
                    onError={() => toggleAlertBS(true, 'Your email was not sent, please try again or send it from your Office 365 account.')}
                    subject="Biz Head Law"
                    to={_case.contact && _case.contact.email ? [_case.contact.email]   : []}
                    contact={_case.contact ? _case.contact._id : undefined}
                    case={_case ? _case._id : undefined}
                    division={_case ? _case.division : undefined}
                />
            </SendEmailModalWrapper>
        </div>
    )
}

export default Overview;