import { connect } from 'react-redux';
import { FormGroup, Input } from 'reactstrap';

const Calendar = ({timezone, setTimezone, timezones}) => (
    <FormGroup>
        <label className="form-control-label">Timezone</label>
        <Input 
            value={timezone}
            onChange={(e) => setTimezone(e.target.value)}
            type="select"
        >
            {timezones.map(tz => (
                <option key={tz.code} value={tz.code}>
                    {tz.name}
                </option>
            ))}
        </Input>
    </FormGroup>
)

const mapStateToProps = state => {
    return {
        timezones: state.system.SETTINGS.timezones,
    };
};

export default connect(mapStateToProps, '')(Calendar);