import _nylas from '_functions/nylas';
import _users from '_functions/users';

import { connect } from 'react-redux';
import { useEffect, useCallback, useState } from 'react';

import { Card, CardHeader, CardFooter, CardTitle, FormGroup, Input } from 'reactstrap';

import Circle from 'components/markup/loading/Circle'

import keys from 'keys';

import { toggleStandardLoader, toggleAlertBS } from 'store/functions/system/system';

import TimeZone from 'components/settings/TimeZone'
import DayAndTimeRange from 'components/settings/DayAndTimeRange'

const Calendar = ({viewing_user, nylas_invalid}) => {

    const [availableTimes, setDaysAvailableTimes] = useState(viewing_user.available_times || [])
    const [timezone, setTimezone] = useState(viewing_user.timezone)


    const [calendarId, setCalendarId] = useState(viewing_user.nylas_calendar_id)
    const [err, setErr] = useState(false)
    const [calendars, setCalendars] = useState(null)

    const onUpdate = useCallback(async () => {
        if(!calendarId) return;
        toggleStandardLoader(true);
        const updated = await _users.update(viewing_user._id, {
            nylas_calendar_id: calendarId,
            timezone,
            available_times: availableTimes
        })
        toggleStandardLoader(false);
        if(updated.success) {
            toggleAlertBS(false, 'Synced calendar updated successfully');
        } else {
            toggleAlertBS(true, `Something's not right, please try again`);
        }
    }, [viewing_user._id, calendarId, timezone, availableTimes])

    const fetchCalendars = useCallback(async () => {
        const data = await _nylas.fetchCalendars();
        if(data.success) {
            setCalendars(data.data)
        } else {
            setErr(true)
        }
    }, [])

    useEffect(() => {   
        fetchCalendars()
    }, [fetchCalendars])

    const link = `${keys.API_URL}/v1/integrations/nylas/auth?email=${viewing_user.email}`

    return (

        <>


        <Card>
            <CardHeader>
                <CardTitle className="mb-0">Calendar Sync Status</CardTitle>
            </CardHeader>

            {nylas_invalid ? (
                <CardFooter>
                    <i className="fas fa-exclamation-triangle text-danger mr-2 " /> Your account may require you to log in via email to sync your calendar, click the link below: <br /> <a href={link}>{link}</a>
                </CardFooter>
            ) : (
                <CardFooter>
                    <i className="fas fa-check text-success mr-2 " /> Your calendar is synced on your Biz Head account and ready to go.
                    <div>If you wish to resync your account <a href={link}>CLICK HERE</a></div>
                </CardFooter>
            )}
            
        </Card>

       
        <Card>
        
            <CardHeader>
                <CardTitle className="mb-0">Locale Timezone</CardTitle>
            </CardHeader>

            <CardHeader>
                <TimeZone 
                    timezone={timezone}
                    setTimezone={setTimezone}
                />               
            </CardHeader>
          
            <CardHeader>
                <CardTitle className="mb-0">Availability Each Day</CardTitle>
                <p className="text-sm mb-0"><i className="fas fa-info-circle mr-1 text-info-original " /> Set Start & End to 12AM to disable the day.</p>
            </CardHeader>

            <CardHeader>
                <DayAndTimeRange 
                    times={availableTimes}
                    setTimes={setDaysAvailableTimes}
                />         
            </CardHeader>

            <CardHeader>
                <CardTitle className="mb-0">Default Calendar</CardTitle>
            </CardHeader>
        
            <CardHeader>
                {err ? (
                    <p className="text-sm mb-0 text-danger">Something's not right, please refresh your page</p>
                ) : !calendars ? (
                    <Circle />
                ) : !calendars.length ? (
                    <p className="text-sm mb-0">It looks like your email account is not currently synced. Please click this link to authorize your email with Biz Head Law. <br /><a className="text-underline" href={link}>{link}</a></p>
                ) : (
                    <FormGroup>
                        <label className="form-control-label">Your Synced Calendar (Events scheduled by clients will show up here)</label>
                        <Input 
                            value={calendarId}
                            onChange={(e) => setCalendarId(e.target.value)}
                            type="select"
                        >
                            {calendars.map(cal => (
                                <option key={cal.id} value={cal.id}>
                                    {cal.name}
                                    {cal.is_primary ? ` (Your Primary Email Calendar)` : ''}
                                </option>
                            ))}
                        </Input>
                    </FormGroup>
                )}
            </CardHeader>

        
            <CardFooter className="text-right">
                <button disabled={calendars && calendars.length ? false : true} className="btn btn-success" onClick={onUpdate}>
                    <i className="fas fa-save mr-2" /> Save
                </button>
            </CardFooter>

            
    
        </Card>

        </>
    )
}


const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,
        nylas_invalid: state.system.nylas_invalid,
    };
};

export default connect(mapStateToProps, '')(Calendar);