import { useCallback, useState } from 'react'
import PropTypes from 'prop-types'

import { Modal, FormGroup, Input } from "reactstrap";

import { toggleStandardLoader } from 'store/functions/system/system';

import ContactsCRUD from './ContactsCrud'

const ModalContactsCrud = ({showModal, toggleModal, onSave, contact}) => {

    return (
        <Modal
            className="modal-dialog-centered"
            isOpen={showModal}
            toggle={toggleModal}
            size="lg"
        >

            <div className="modal-header">
                <h5 className="modal-title">{contact && contact._id ? 'Update Contact' : 'Create Contact'}</h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModal}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>

            <div className="modal-body">

                <ContactsCRUD 
                    onCreate={() => {
                        toggleModal();
                        onSave();
                    }}
                    onUpdate={() => {
                        toggleModal();
                        onSave();
                    }}
                    contact={contact}
                />

            </div>

            {/* <div className="modal-footer text-right">
                <button className="btn btn-success" onClick={toggleModal}>
                    <i className="fas fa-arrow-left mr-2" />
                    Close
                </button>
            </div> */}

        </Modal>

    )

}

ModalContactsCrud.propTypes = {
    toggleModal   : PropTypes.func.isRequired,
    showModal     : PropTypes.bool.isRequired,
}

export default ModalContactsCrud
