/*
Documentation

this modal creates a case_template for use later
It works by saving the url in the database as the url
contains all information needed to come back to

*/

import { connect } from 'react-redux';
import { useEffect, useCallback, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Modal, FormGroup, Input } from 'reactstrap';
import { toggleAlertBS } from 'store/functions/system/system';
import _cases from '_functions/cases';
import _workflows from '_functions/workflows';
import Circle from 'components/markup/loading/Circle'
import { toggleStandardLoader } from 'store/functions/system/system'

const CreateMultiple = ({showModal, toggleModal, selected_workflow, name, contact, roles, viewing_user}) => {

    const [workflow, setWorkflow] = useState(null)
    const [caseNumber, caseNumbers] = useState('2')
    const [stepId, setStepId] = useState('false')
    const [redirect, setRedirect] = useState(false)

    const onCreate = async () => {
        toggleStandardLoader(true);
        const created = await _cases.createMany({
            user: viewing_user._id,
            name: name,
            contact: contact,
            workflow: selected_workflow._id,
            roles: roles,
            caseNumber,
            stepId
        })
        toggleStandardLoader(false)

        if(!created.success) {
            toggleAlertBS('info', 'Some or all cases may not have been created, check contact cases.')
        }
        setRedirect(`/dashboard/contacts/view/${contact}`)

    }

    const fetchWorkflow = useCallback(async () => {
        if(selected_workflow._id) {
            const data = await _workflows.findById(selected_workflow._id)
        data.data.steps.sort((a, b) => a.name < b.name ? -1 : 1)
        setWorkflow(data.data)
        }
        
    }, [selected_workflow._id])

    useEffect(() => {
        fetchWorkflow()
    }, [fetchWorkflow])

    if(redirect) return <Redirect to={redirect} />

    return (
        <Modal
            className="modal-dialog-centered"
            isOpen={showModal}
            toggle={toggleModal}
            size="lg"
            fade={false}
            id="modal-invoice"
        >
            <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">Create Multiple Cases</h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModal}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>

            <div className="modal-body bg-secondary">
                {!workflow ? <Circle /> : (
                    <div>
                        <FormGroup>
                            <label className="form-control-label">Select Step To Move All Cases To</label>
                            <Input
                                type="select"
                                value={stepId}
                                onChange={(e) => setStepId(e.target.value)}
                            >
                                <option value="false"></option>
                                {workflow.steps.map(s => (
                                    <option value={s._id} key={s._id}>{s.name}</option>
                                ))}
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <label className="form-control-label">How many cases do you want to create?</label>
                            <p className="text-sm">Cases will show up as the designated name with a -1, -2, -3, etc. appended to their name. Example "{name} - 2"</p>
                            <Input
                                type="select"
                                value={caseNumber}
                                onChange={(e) => caseNumbers(e.target.value)}
                            >
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
                                <option value="11">11</option>
                                <option value="12">12</option>
                                <option value="13">13</option>
                                <option value="14">14</option>
                                <option value="15">15</option>
                                <option value="16">16</option>
                                <option value="17">17</option>
                                <option value="18">18</option>
                                <option value="19">19</option>
                                <option value="20">20</option>
                            </Input>
                        </FormGroup>
                    </div>
                )}
            </div>

            <div className="modal-body">
                <p className="text-sm mb-0"><i className="fas fa-info-circle text-info-original " /> Creating multiple cases will take between 3 and 5 seconds per case. </p>
            </div>

            <div className="modal-footer text-right">
                <button className="btn btn-success" onClick={onCreate}>Create Cases</button>
            </div>

        </Modal>

    )
}
const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,
    };
};

export default connect(mapStateToProps, '')(CreateMultiple);
