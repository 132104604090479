
import AvatarImage from 'components/functional/images/AvatarImage';
import PropTypes from 'prop-types';
import React from 'react';
import renderName from 'utils/renderName';
import renderNameInitial from 'utils/renderNameAndInitial';

import FeedWrapper from './Wrapper';
import ReactHTMLParser from 'react-html-parser';

import { getEntryDate } from './_utils';

const renderSystem = (props) => {

    const { entry, renderNameAndInitial } = props;

    let value = entry.value;

    if(value) {
        value = value.replace(/<p>/g, '<span class="d-block">')
        value = value.replace(/<\/p>/g, '</span>')
    }

    return (

        <FeedWrapper>
           <h6 className="text-sm mb-0 font-weight-bold" style={{wordBreak: 'break-word'}}>
                {entry.user ? (
                    <span className="text-capitalize">
                        <AvatarImage style={{position: 'relative', top: -2, width: 30, height: 30}} src={entry.user.avatar} className="mr-2" />
                        {renderNameAndInitial ? renderNameInitial(entry.user) : renderName(entry.user)}
                    </span>
                ) : 'System'}

                <span className="float-right">
                    <i className="fas fa-database text-purple mr-2" />
                    {getEntryDate(entry)}
                </span>
               
            </h6>

            <span style={{wordBreak: 'break-word'}}  className="text-sm mb-0">
                {ReactHTMLParser(value)}
            </span>


        </FeedWrapper>

    )

}


renderSystem.propTypes = {
    entry   : PropTypes.object.isRequired,
}

export default renderSystem
