import { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { Modal } from "reactstrap";

import { toggleStandardLoader } from 'store/functions/system/system';
import moment from 'moment';

import _flow_assignments from '_functions/flow_assignments'
import { toggleAlertBS } from 'store/functions/system/system';

const ModalUpdateRunDates = ({showModal, toggleModal, flows, fetchFlows}) => {

    const togglePause = async (flow) => {
        toggleStandardLoader(true);
        const update = await _flow_assignments.update(flow._id, { paused: !flow.paused })
        toggleStandardLoader(false);

        if(update.success) {
            fetchFlows()
        } else {
            toggleAlertBS('info', 'Please try again.')
        }

    }

    useEffect(()=> {
        if(showModal) fetchFlows()
    }, [showModal, fetchFlows])

    return (
        <Modal
            className="modal-dialog-centered"
            isOpen={showModal}
            toggle={toggleModal}
            size="md"
        >

            <div className="modal-header">
                <h5 className="modal-title">Case Flows</h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModal}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>

            <div className="modal-body">

                {flows && flows.length ? (
                    flows.map(flow => (
                        <div key={flow._id} className="mb-3">
                            <div>{flow.flow.name} - { flow.finished ? 'FINISHED' : flow.paused ? 'PAUSED'  : "ACTIVE"}</div>
                            <div className="p-3 rounded bg-secondary">
                                <p className="text-sm mb-0">Next Run Date: {flow.finished ? '-' : moment.unix(flow.next_run_date).format('MM/DD/YYYY h:mm A')}</p>
                                {flow.history && flow.history.length ? flow.history.map(h => (
                                    <p className="text-sm mb-0 text-capitalize">{h.description}: {moment.unix(h.date).format('MM/DD/YYYY h:mm A')}</p>
                                )) : null}
                                <div className="text-right">
                                    {!flow.finished ? !flow.paused ? (
                                        <button onClick={() => togglePause(flow)} className="btn btn-warning">Pause</button>
                                    ) : (
                                        <button onClick={() => togglePause(flow)} className="btn btn-success">Activate</button>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    ))
                ) : (
                    <p className="text-sm mb-0">No Flows Found For This Case</p>
                )}
{/* 
                <FormGroup>
                    <label className="form-control-label">Next Run Date*</label>
                    <p className="text-sm mb-0">By updating the next run date here, all automations assigned to this cases contact will be set to run next on the day specified below.</p>
                    <Input 
                        type="date"
                        value={runDate}
                        onChange={(e) => setRunDate(e.target.value)}
                        invalid={errs.includes('runDate')}
                    />
                </FormGroup> */}

            </div>

            {/* <div className="modal-footer text-right">
                <button className="btn btn-success" onClick={toggleModal}>
                    Close
                </button>
            </div> */}

        </Modal>

    )

}

ModalUpdateRunDates.propTypes = {
    toggleModal   : PropTypes.func.isRequired,
    showModal     : PropTypes.bool.isRequired,
}

export default ModalUpdateRunDates
