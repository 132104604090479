/*
Documentation

This is the bottom half of the navbar for the dashboard layout

*/

import { useCallback, useEffect, useState } from "react";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Container, Nav, Navbar, NavItem, NavLink } from "reactstrap";
import * as privileges from '_functions/users/privileges';

import _documents from '_functions/documents';



const NavbarBottom = ({location}) => {
    const [docs, setDocs] = useState(0)

    const getDocuments = useCallback(() => {
        const fetchDocuments = async () => {
            const documents = await _documents.findUser(`?count=true`);
            setDocs(documents.data ? documents.data : 0)
            if(documents.success) {
                setTimeout(() => {
                    getDocuments()
                }, 15000)
            }
        }

        fetchDocuments();
    }, [])

    useEffect(() => {
        getDocuments();
    }, [getDocuments])

    return (

        <div className="under-nav d-none d-md-block z-depth-1 border-bottom">
            <Navbar className="navbar-expand  navbar"  >
                <Container fluid>
                    <Nav className="align-items-center " navbar>

                        <NavLink
                            tag={Link}
                            to={privileges.canViewAdminCallCenter() ? "/dashboard/call_center_admin" : "/dashboard/call_center"}
                            className={location.includes('/dashboard/call_center') ? 'active' : ''
                        }>
                            <NavItem tag="span"  style={{cursor: 'pointer'}}>
                                <i className="fas fa-headphones mr-2" />
                                Call Center
                            </NavItem>
                        </NavLink>
                        <NavLink
                            tag={Link}
                            to="/dashboard/pipeline"
                            className={location.includes('/dashboard/pipeline/') ? 'active' : ''
                        }>
                            <NavItem tag="span"  style={{cursor: 'pointer'}}>
                                <i className="fas fa-database mr-2" />
                                Pipeline
                            </NavItem>
                        </NavLink>

                        <NavLink
                            tag={Link}
                            to="/dashboard/leads/all"
                            className={location.includes('/dashboard/leads/') ? 'active' : ''
                        }>
                            <NavItem tag="span"  style={{cursor: 'pointer'}}>
                                <i className="fas fa-user-plus mr-2" />
                                Leads
                            </NavItem>
                        </NavLink>

                        <NavLink
                            tag={Link}
                            to="/dashboard/contacts/all"
                            className={location.includes('/dashboard/contacts/') ? 'active' : ''
                        }>
                            <NavItem tag="span"  style={{cursor: 'pointer'}}>
                                <i className="fas fa-users mr-2" />
                                Contacts
                            </NavItem>
                        </NavLink>

                        {privileges.canViewCases() && (
                            <NavLink
                                tag={Link}
                                to="/dashboard/cases/all"
                                className={location.includes('/dashboard/case') ? 'active' : ''}
                            >
                                <NavItem tag="span"  style={{cursor: 'pointer'}}>
                                    <i className="fas fa-gavel mr-2" />
                                    Cases
                                </NavItem>
                            </NavLink>
                        )}

                        <NavLink
                            tag={Link}
                            to="/dashboard/appointments/all"
                            className={location.includes('/dashboard/appointments/') ? 'active' : ''
                        }>
                            <NavItem tag="span"  style={{cursor: 'pointer'}}>
                                <i className="fas fa-calendar-plus mr-2" />
                                Reminders
                            </NavItem>
                        </NavLink>

                        <NavLink
                            tag={Link}
                            to="/dashboard/consultations/all"
                            className={location.includes('/dashboard/consultations/') ? 'active' : ''
                        }>
                            <NavItem tag="span"  style={{cursor: 'pointer'}}>
                                <i className="fas fa-calendar-check mr-2" />
                                Consultations
                            </NavItem>
                        </NavLink>

                        {/* <NavLink
                            tag={Link}
                            to="/dashboard/calendar/me"
                            className={location.includes('/dashboard/calendar/') ? 'active' : ''
                        }>
                            <NavItem tag="span"  style={{cursor: 'pointer'}}>
                                <i className="ni ni-calendar-grid-58 mr-2" />
                                Calendar
                            </NavItem>
                        </NavLink> */}
                       
                        <NavLink
                            tag={Link}
                            to="/dashboard/documents"
                            className={location.includes('/dashboard/documents') ? 'active' : ''
                        }>
                            <NavItem tag="span"  style={{cursor: 'pointer'}}>
                                <i className="fas fa-file mr-2" />
                                Docs {docs ? <span className={window.location.href.includes('/dashboard/documents') ? 'text-white' : 'text-info-original'}>({docs})</span> : ''}
                            </NavItem>
                        </NavLink>
                      
                        <NavLink
                            tag={Link}
                            to="/dashboard/directory"
                            className={location.includes('/dashboard/directory') ? 'active' : ''
                        }>
                            <NavItem tag="span"  style={{cursor: 'pointer'}}>
                                <i className="fas fa-search mr-2" />
                                Directory
                            </NavItem>
                        </NavLink>

                    </Nav>
                </Container>
            </Navbar>
        </div>

    );
}

const mapStateToProps = state => {
  	return {
    	viewing_user: state.auth.viewing_user,
  	};
};

export default connect(mapStateToProps, '')(NavbarBottom);
