/*

when opened we drop down a modal to try and call a user.

*/

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Modal } from "reactstrap";
import { toggleStartCallModal } from 'store/functions/call_center/call_center';
import renderName from 'utils/renderName';
import { formatPhone } from 'utils/text';
import { toggleAlertBS } from 'store/functions/system/system';

class StartCallModal extends Component {

    state = {}

    // redirect to call center with query params, that will handle the rest
    // set the modal to not show after redirect
    onCallClient = (phone) => {

        this.setState({shouldRedirect: `/dashboard/call_center?startCall=true&phone=${phone}`}, () => {
            this.setState({shouldRedirect: false})
            toggleStartCallModal(false, null)
        })

    }

     // redirect to call center with query params, that will handle the rest
    // set the modal to not show after redirect
    onCallClientRedirect = (phone) => {

        window.open(`/dashboard/call_center?startCall=true&phone=${phone}`, '_blank')
        toggleStartCallModal(false, null)

    }

    // if trying to call a client while on call stop and show the error message
    // then toggle showing this alert back to false
    componentWillReceiveProps = (nextProps) => {

        const { viewing_user } = this.props
        let currentShow = this.props.start_call_modal.show
        let nextShow = nextProps.start_call_modal.show

        if(viewing_user.call_status === "on call" && !currentShow && nextShow ) {
            toggleAlertBS('Warning', "You may not start another call until you finish the current one you are on.")
            toggleStartCallModal(false, null)
        }
    }

    render() {

        const { viewing_user } = this.props
        let { show, contact } = this.props.start_call_modal
        if(!contact) contact = {}

        if(this.state.shouldRedirect) return <Redirect to={this.state.shouldRedirect} />

        const hasName = contact.given_name || contact.family_name;

        return (
            
            <Modal
                className="modal-dialog-centered"
                isOpen={show && viewing_user.call_status !== "on call"}
                toggle={() => toggleStartCallModal(false, null)}
                size="md"
            >
                <div className="modal-header">

                    <h5 className="modal-title" id="exampleModalLabel">
                        Call {' '}
                        {hasName ? (
                            <span className="text-capitalize">{renderName(contact)} </span>
                        ) : 'Contact'}
                    </h5>

                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => toggleStartCallModal(false, null)}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>

                <div className="modal-body">
                    <p className="text-sm mb-0 font-weight-bold">
                        {hasName ? (
                            <span>
                                Click below to dial{' '} 
                                <span className="text-capitalize text-dark font-weight-bold">{renderName(contact)}</span> 
                                {' at '}
                                <span className="text-underline">{formatPhone(contact.phone)}</span>
                            </span>
                        ) : (
                            <span>Click below to dial <span className="text-capitalize text-underline">{formatPhone(contact.phone)} </span></span>
                        )}
                    </p>
                </div>

                <div className="modal-footer">
                    <button onClick={() => toggleStartCallModal(false, null)} className="btn btn-outline-warning float-left mr-auto">
                        Cancel
                    </button>

                    <button onClick={() => this.onCallClient(contact.phone)} className="btn btn-success">
                        <i className="fas fa-headphone" /> Call Contact
                    </button>

                    <button onClick={() => this.onCallClientRedirect(contact.phone)} className="btn btn-success">
                        <i className="fas fa-headphone" /> Call In New Window
                    </button>
                </div>

            </Modal>

        )

    }

}

const mapStateToProps = state => {
    return {
        start_call_modal: state.call_center.start_call_modal,
        viewing_user: state.auth.viewing_user,
    };
};

export default connect(mapStateToProps, '')(StartCallModal);
