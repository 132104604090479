// in theory these should only fire from the backend

import apiWorker from '../apiWorker'

const user_notifications = {

    create: (data)    => apiWorker('post',  `/api/v1/core/user_notifications/create`, data),
    search: (data)    => apiWorker('post',  `/api/v1/core/user_notifications/search`, data),
    delete: (_id)     => apiWorker('delete',  `/api/v1/core/user_notifications/delete/${_id}`),
    find:  (query)    => apiWorker('get', `/api/v1/core/user_notifications/find${query}`),

}

export default user_notifications