/*
Documentation

This page shows a list of all cases
via api cases can be searched by case name or _id

*/

import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import { connect } from 'react-redux';
import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardHeader, Container, CardTitle, Input, Badge } from "reactstrap";
import renderName from 'utils/renderName';
import _leads from '_functions/leads';
import Circle from 'components/markup/loading/Circle';
import * as _ from 'underscore';
import moment from 'moment'

import NameCircle from 'components/markup/misc/NameCircle';

import { formatPhone } from 'utils/text'
import { toggleStartCallModal } from 'store/functions/call_center/call_center'

import ModalToggler from 'components/functional/modals/Toggler'
import ModalMerge from './ModalMerge'


class LeadAll extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            sizePerPage: 25,
            total_documents: 0,
            data: null,
            caseToDelete: null
        }
       this.queryTableDataThrottled = _.throttle(this.queryTableData, 750)
    }

    columns = [
        {
            dataField: "given_name",
            text: "Name",
            formatter: (cell, row) => (
                <div>
                    <Link className="text-capitalize" to={`/dashboard/contacts/view/${row._id}`}>
                        <NameCircle width={27} contact={row} style={{marginRight: 5}} />{' '}
                        {renderName(row)}
                    </Link>

                </div>
            ),
        },
        {
            dataField: "phone",
            text: "Phone",
            formatter: (cell, row) => (
                row.phone ? (
                    <span 
                        onClick={() => toggleStartCallModal(true, row)} 
                        key={row._id + row.phone}
                        className={row.blacklisted ? 'text-danger cursor-pointer' : 'cursor-pointer'}

                    >
                        <i   className={row.blacklisted ? 'text-danger fas fa-exclamation-triangle mr-3' : 'text-muted fas fa-phone mr-3'} /> 
                        {formatPhone(row.phone)}
                    </span>
                ) : '-'
            )
        },
        {
            dataField: "email",
            text: "Email",
            formatter: (cell, row) => (
                row.email ? row.email : '-'
            )
        },
        {
            dataField: "duplicate",
            text: "Duplicate",
            formatter: (cell, row) => (
                row.duplicate ? <Badge color="warning">Yes</Badge> : <Badge color="success">No</Badge>
            )
        },
        {
            dataField: "lead_source_name",
            text: "Lead Source / Status",
            formatter: (cell, row) => {
                const source = this.props.lead_sources.find(l => l._id === row.lead_source)
                return source ? source.name : '-'
            }
        },

        {
            dataField: "creted_at",
            text: "Created",
            headerStyle: { textAlign: 'right' },
            formatter: (cell, row) => {

                const date = moment.unix(row.created_at); 

                return (
                    <div className="text-right">
                        <div>{date.format('MMM Do, YYYY')}</div>
                        <div>{date.format('h:mm A')}</div>
                    </div>
                )
            },
        },
        {
            dataField: "x",
            text: "Actions",
            headerStyle: {textAlign: 'right'},
            formatter: (cell, row) => {
                return (
                    <div className="text-right">
                        <ModalToggler component={ModalMerge} lead={row}>
                            <button className="btn btn-success">
                                Sync To Contact
                            </button>
                        </ModalToggler>
                      
                    </div>
                )
            }
        },
    ]

    queryTableData = async (searchText, setPage1) => {

        this.setState({loading: true});
        if(setPage1) this.setState({page: 1})

        const leads = await _leads.search({
            searchString: searchText,
            limit: this.state.sizePerPage,
            skip: (this.state.page - 1) * this.state.sizePerPage
        })

        this.setState({
            data: leads.data.documents,
            total_documents: leads.total_documents,
            canRun: false,
            loading: false,

        })

    }

    onTableChange = async (type, newState) => {

        const { searchText } = newState

        if(type === 'search') {

            this.queryTableDataThrottled(searchText, true)

        } else {

            this.setState({ page: newState.page, sizePerPage: newState.sizePerPage }, () => {
                this.queryTableDataThrottled(searchText)
            })

        }

    }

    onDeleteCase = (_case) => this.onTableChange(null, this.state)

    // load first set of leads on load
    componentDidMount = async () => {

        const leads = await _leads.search({
            searchString: undefined,
            limit: this.state.sizePerPage
        })

        this.setState({data: leads.data.documents, total_documents: leads.data.total_documents})

    }

    render() {

        let { data, loading, searchText} = this.state;

        return (
            <>

            <Container fluid>

                <Helmet>
                    <title>{`Lead (${this.state.total_documents}) `}</title>
                    <meta name="description" content="Lead" />
                </Helmet>

                <DashHeaderOpen
                    icon="fas fa-home"
                    icon_link="/dashboard"
                    title={<span >Lead</span>} breadcrumb_1="All"
                />

                <Card className="card-color card-primary">

                    <CardHeader>
                        <CardTitle className="mb-0 float-left">All Leads</CardTitle>
                    </CardHeader>

                    {data ? (
                        data.length || searchText !== undefined ? (
                            <ToolkitProvider
                                data={data}
                                keyField="_id"
                                columns={this.columns}
                                search
                            >
                                {props => (
                                    <>
                                        <div className="dataTables_filter pr-4 pb pt-3 pb-2 position-relative" >
                                            <label>
                                                <Input
                                                    className="form-control-sm"
                                                    value={this.state.searchText || ''}
                                                    placeholder="Search..."
                                                    onChange={(e) => {
                                                        this.setState({searchText: e.target.value})
                                                        this.queryTableDataThrottled(e.target.value)
                                                    }}
                                                />
                                                 {loading ? (<Circle style={{display: 'inline-block', top: 1, position: 'absolute'}} /> ) : null}

                                            </label>
                                        </div>

                                        <div className="table-not-fixed table-responsive table-vertical-align">
                                            <BootstrapTable
                                                pagination={paginationFactory({
                                                    totalSize: this.state.total_documents,
                                                    page: this.state.page,
                                                    sizePerPage: this.state.sizePerPage,
                                                    alwaysShowAllBtns: true,
                                                    showTotal: true,
                                                    withFirstAndLast: true,
                                                    sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
                                                        <div className="dataTables_length" id="datatable-basic_length">
                                                            <label>
                                                                Show{" "}
                                                                {
                                                                <select
                                                                    value={currSizePerPage}
                                                                    name="datatable-basic_length"
                                                                    aria-controls="datatable-basic"
                                                                    className="form-control form-control-sm"
                                                                    onChange={e => onSizePerPageChange(e.target.value)}
                                                                >
                                                                    <option value="10">10</option>
                                                                    <option value="25">25</option>
                                                                    <option value="50">50</option>
                                                                    <option value="100">100</option>
                                                                </select>
                                                                }{" "}
                                                                entries.
                                                            </label>
                                                        </div>
                                                    )
                                                })}
                                                {...props.baseProps}
                                                bootstrap4={true}
                                                bordered={false}
                                                remote={{
                                                    search: true,
                                                    pagination: true,
                                                    sort: false,
                                                    cellEdit: false
                                                }}
                                                onTableChange={this.onTableChange}

                                            />
                                        </div>
                                    </>
                                )}
                            </ToolkitProvider>
                        ): (
                            <CardBody>
                                <p className="text-sm mb-0">
                                    No leads have been created yet.
                                </p>
                            </CardBody>
                        )
                    ) : (
                       <Circle />
                    )}

                </Card>

            </Container>

            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        lead_sources: state.lead_sources.lead_sources,
    };
};

export default connect(mapStateToProps, '')(LeadAll);