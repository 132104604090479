const Line = ({field, text}) => (
    <p className="mb-0">
        <span style={{display: 'inline-block', width: 225}}>{field}:</span>
        {text}
    </p>
)

const HasData = ({data}) => (
    <div>
        <h3 className="display-5 mb-0">The Following Data Will Be Submitted To Patriot.</h3>
        <p className="text-sm mb-0">Confirm it is correct before proceeding</p>
        <hr className="my-2" />

        <h4 className="mb-0">Company</h4>
        <Line field="Name" text={data.signup.trade_name} />
        <Line field="EIN" text={data.signup.ein} />
        <Line field="Address" text={`${data.signup.street} ${data.signup.city} ${data.signup.state} ${data.signup.zip}`} />
        <Line field="Rep Name" text={`${data.signup.contact_first} ${data.signup.contact_last}`} />
        <Line field="Rep Email" text={data.signup.contact_email} />

        <br />
        <h4 className="mb-0">Employees</h4>
        <Line field="Employee Count Q4 2020" text={data.employees[0].count} />
        <Line field="Employee Count Q1 2021" text={data.employees[1].count} />
        <Line field="Employee Count Q2 2021" text={data.employees[2].count} />
        <Line field="Employee Count Q3 2021" text={data.employees[3].count} />
        <br />
        
        <h4 className="mb-0">Wages</h4>
        <Line field="Wages Q4 2020" text={data.wages[0].wages} />
        <Line field="Wages Q1 2021" text={data.wages[1].wages} />
        <Line field="Wages Q2 2021" text={data.wages[2].wages} />
        <Line field="Wages Q3 2021" text={data.wages[3].wages} />

        <br />
        <h4 className="mb-0">PPP Round 1</h4>
        {data.round1.did_not_receive ? (
            <Line field="PPP Round 1" text={"Did Not Receive"} />
        ) : (
            <>
            <Line field="Amount" text={data.round1.amount} />
            <Line field="Start" text={data.round1.start} />
            <Line field="Duration" text={data.round1.duration + ' Weeks'} />
            </>

        )}
        <br />
        <h4 className="mb-0">PPP Round 1</h4>
        {data.round2.did_not_receive ? (
            <Line field="PPP Round 2" text={"Did Not Receive"} />
        ) : (
            <>
            <Line field="Amount" text={data.round2.amount} />
            <Line field="Start" text={data.round2.start} />
            <Line field="Duration" text={data.round2.duration + ' Weeks'} />
            </>
        )}

        <br />
        <h4 className="mb-0">Family</h4>
        <Line field="Related W2 Employees" text={data.family.owner_family_list ? data.family.owner_family_list : 'none'} />


        <p></p>
    </div>
)

export default HasData;