/*
Documentation

This page shows a list of all cases
via api cases can be searched by case name or _id

*/
import A from 'components/markup/links/A'
import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Card, CardHeader, CardTitle, Container } from "reactstrap";
import CountUp from 'components/markup/time/CountUp'

import renderName from 'utils/renderName';
import keys from 'keys'

import _consultations from '_functions/consultations';
import _nylas from '_functions/nylas';

import moment from 'moment';

import Circle from 'components/markup/loading/Circle';

const Me = ({viewing_user}) => {

    const [queue, setQueue] = useState('')
    const [consultations, setConsultations] = useState('')

    const fetchLiveQueue = useCallback(async() => {
        const data = await _nylas.count()
        if(data) {
            let d = data;
            // if(!viewing_user.privileges.includes('can_consult_1_10')) {
            //     d = d.filter(dd => dd.company_size !== '1 - 10')
            // }
            // if(!viewing_user.privileges.includes('can_consult_11_20')) {
            //     d = d.filter(dd => dd.company_size !== '11 - 20')
            // }
            // if(!viewing_user.privileges.includes('can_consult_21_30')) {
            //     d = d.filter(dd => dd.company_size !== '21 - 30')
            // }
            // if(!viewing_user.privileges.includes('can_consult_31_50')) {
            //     d = d.filter(dd => dd.company_size !== '31 - 50')
            // }
            // if(!viewing_user.privileges.includes('can_consult_51_100')) {
            //     d = d.filter(dd => dd.company_size !== '51 - 100')
            // }


            if(viewing_user.privileges.includes('can_consult_100_plus')) {

            } else if(viewing_user.privileges.includes('can_consult_51_100')) {
                d = d.filter(dd => dd.company_size !== '100+')
            } else if(viewing_user.privileges.includes('can_consult_31_50')) {
                d = d.filter(dd => dd.company_size !== '51 - 100')
                d = d.filter(dd => dd.company_size !== '100+')
            } else if(viewing_user.privileges.includes('can_consult_21_30')) {
                d = d.filter(dd => dd.company_size !== '31 - 50')
                d = d.filter(dd => dd.company_size !== '51 - 100')
                d = d.filter(dd => dd.company_size !== '100+')
            } else if(viewing_user.privileges.includes('can_consult_11_20')) {
                d = d.filter(dd => dd.company_size !== '21 - 30')
                d = d.filter(dd => dd.company_size !== '31 - 50')
                d = d.filter(dd => dd.company_size !== '51 - 100')
                d = d.filter(dd => dd.company_size !== '100+')
            } else if(viewing_user.privileges.includes('can_consult_1_10')) {
                d = d.filter(dd => dd.company_size !== '11 - 20')
                d = d.filter(dd => dd.company_size !== '21 - 30')
                d = d.filter(dd => dd.company_size !== '31 - 50')
                d = d.filter(dd => dd.company_size !== '51 - 100')
                d = d.filter(dd => dd.company_size !== '100+')
            } else {
                d = []
            }

            d = d.filter(dd => {
                if(dd.company_size === '1 - 10') return true;
                if(dd.company_size === '11 - 20') return true;
                if(dd.company_size === '21 - 30') return true;
                if(dd.company_size === '31 - 50') return true;
                if(dd.company_size === '51 - 100') return true;
                if(dd.company_size === '100+') return true;

                return false
            })

            setQueue(d)
        }
    }, [viewing_user.privileges])
    const fetchConsultations = useCallback(async() => {
        const data = await _consultations.me()
        if(data.data) {
            
            setConsultations(data.data)
        }
    }, [])

    useEffect(() => {
        fetchLiveQueue()
        fetchConsultations()

        let interval = setInterval(() => {
            fetchLiveQueue()
            fetchConsultations()
        }, 2000)

        return () => {
            clearInterval(interval)
        }
    }, [fetchLiveQueue, fetchConsultations])


    return (
        <Container fluid>

            <Helmet>
                <title>{`Queue & Consults`}</title>
                <meta name="description" content="Queue & Consults" />
            </Helmet>

            <DashHeaderOpen
                icon="fas fa-home"
                icon_link="/analytics"
                title={<span >Queue & Consults</span>} breadcrumb_1="All"
            />

            <Card className="card-color card-primary">

                <CardHeader>
                    <CardTitle className="mb-0">Consults Needing A Disposition</CardTitle>
                </CardHeader>

                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Company</th>
                                <th>Contact</th>
                                <th>Size</th>
                                <th>Date</th>
                                <th className='text-right'>Disposition</th>
                            </tr>
                        </thead>
                        <tbody>

                            {consultations && consultations.length ? consultations.map(l => (
                                <tr key={l._id}>
                                    <td className="text-capitalize">{l.company_name}</td>
                                    <td className="text-capitalize">{renderName(l.contact)}</td>
                                    <td className="text-capitalize">{l.company_size}</td>
                                    <td className="text-capitalize">{moment.unix(l.start).format('MM/DD/YYYY h:mm A')}</td>
                                    <td className='text-right' ><A className="btn btn-success" href={`${keys.BOOK_URL}/outcome/${l._id}`}>Disposition</A></td>
                                </tr>
                            )) : consultations ? (
                                <tr>
                                    <td>No undispositioned consults found</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            ) : (
                                <tr>
                                    <td><Circle /></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>    
            </Card>

            {viewing_user.privileges.includes('can_give_attorney_consultation') ? (
            <Card className="card-color card-primary">

                <CardHeader>
                    <CardTitle className="mb-0 float-left">Live Queue Entries</CardTitle>
                </CardHeader>
                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Take</th>
                                <th>Contact</th>
                                <th>Case</th>
                                <th>Size</th>
                                <th>Time</th>
                            </tr>
                        </thead>

                        <tbody>
                        {queue && queue.length ? queue.map(q => (
                            <tr>
                                <td className='\' ><A className="btn btn-success" href={`${keys.BOOK_URL}/live/${viewing_user._id}?_id=${q._id}`}>Take Call</A></td>
                                <td className='text-capitalize'>{renderName(q.contact)}</td>
                                <td>{q.company_name}</td>
                                <td>{q.company_size}</td>
                                <td><CountUp timestamp={q.created_at} /></td>
                            </tr>
                        )) : queue ? (
                            <tr>
                                <td>There are no live queue entries to be taken</td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                        ) : (
                            <tr>
                                <td><Circle /></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                        )}

                        </tbody>
                    </table>
                </div>

            </Card>
            ) : ''}

        </Container>

    )
}


const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,
    };
};

export default connect(mapStateToProps, '')(Me);
