import apiWorker from '../apiWorker'

const cases = {

    create              : (data)                  => apiWorker('post',   `/api/v1/core/cases/create`, data),
    createMany              : (data)                  => apiWorker('post',   `/api/v1/core/cases/createMany`, data),
    reAssignUser        : (data)                  => apiWorker('post',  `/api/v1/core/cases/reAssignUser`, data),
    assignUser          : (data)                  => apiWorker('patch',  `/api/v1/core/cases/assignUser`, data),
    removeUser          : (data)                  => apiWorker('delete', `/api/v1/core/cases/assignUser`, data),

    update              : (case_id, data, hideError)         => apiWorker('patch',  `/api/v1/core/cases/update/${case_id}`, data, hideError),
    updateContact              : (case_id, data, hideError)         => apiWorker('patch',  `/api/v1/core/cases/updateContact/${case_id}`, data, hideError),
    saveChecklist              : (case_id, data, hideError)         => apiWorker('patch',  `/api/v1/core/cases/saveChecklist/${case_id}`, data, hideError),
    updateTrust         : (case_id, data)         => apiWorker('patch',  `/api/v1/core/cases/updateTrust/${case_id}`, data, true),
    addMissingDoc       : (case_id, data)         => apiWorker('patch',  `/api/v1/core/cases/addMissingDoc/${case_id}`, data),
    updateRoles         : ( data)                 => apiWorker('patch',  `/api/v1/core/cases/updateRoles`, data),
    moveStep            : (case_id, data)         => apiWorker('patch', `/api/v1/core/cases/moveStep/${case_id}`, data),

    find                : (query, hideError)      => apiWorker('get',  `/api/v1/core/cases/find${query}`, null, hideError),
    findById            : (case_id, hideError)    => apiWorker('get',  `/api/v1/core/cases/find/${case_id}`, null,  hideError),
    findByIdWithStep    : (case_id, hideError)    => apiWorker('get',  `/api/v1/core/cases/find/${case_id}?queryCurrentStep=true`, null,  hideError),
    search              : (query)                 => apiWorker('post',  `/api/v1/core/cases/search`, query),
    submitToPatriot     : (case_id, preview)                 => apiWorker('post',  `/api/v1/core/cases/submitToPatriot/${case_id}${preview ? '?preview=true' : ''}`, null, true),
    getPackages     : (case_id) => apiWorker('get',  `/api/v1/core/cases/getPackages/${case_id}`, null, true),
    
    pipeline     : (justMine, download) => apiWorker('get',  `/api/v1/core/cases/pipeline${justMine ? "?justMine=true" : ''}${download ? "?download=" + download : ''}`, null, true),

}

export default cases;

