import apiWorker from '../apiWorker'

const case_templates = {

    create:     (data)          => apiWorker('post',  `/api/v1/core/case_templates/create`, data),
    delete:     (template_id)   => apiWorker('post',  `/api/v1/core/case_templates/delete/${template_id}`),
    find:        ()              => apiWorker('get',   `/api/v1/core/case_templates/find`),

}

export default case_templates;