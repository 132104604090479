/*
Documentation

This page shows all appointments within the system with a way to query them via AppointmentsFilter

*/

import NotificationDelete from 'components/functional/notifications/Delete';
import ArchkTableExpansion from 'components/functional/tables/ArchkTableExpansion';
import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import Circle from 'components/markup/loading/Circle';
import moment from 'moment';
import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Badge, Card, CardBody, CardHeader, CardTitle, Container, UncontrolledTooltip } from "reactstrap";
import renderName from 'utils/renderName';
import { formatPhone } from 'utils/text'
import * as privileges from '_functions/users/privileges';
import { toggleStartCallModal } from 'store/functions/call_center/call_center'
import { toggleAlertBS } from 'store/functions/system/system'

import AppointmentsFilter from './components/AppointmentsFilter'

const { SearchBar } = Search;

class AppointmentAll extends React.Component {

    state = {
        appointments: null,
    }

    onDeleteError = (err) => {

        // if appointment is already deleted remove it from state
        if(err.message && err.message[0] && err.message[0].includes("No appointment was found with id")) {

            let appointments = [...this.state.appointments];
            appointments = appointments.filter(a => a._id !== this.state.appointmentToDelete._id);
    
            this.setState({appointments})

        } else {
            toggleAlertBS(true, `Something went wrong deleting this appointment`)
        }

    }

    onDelete = (appointment) => {

        let appointments = [...this.state.appointments];
        appointments = appointments.filter(a => a._id !== appointment.data._id);

        this.setState({appointments})

    }

    renderAssignedTo = (row) => {

        if(row.assigned_to && row.assigned_to.length) {

            return row.assigned_to.map((u, i)=> (
                <span className="text-capitalize" key={u._id} style={{display: 'block', width: 160,}}>

                    <i className="fas fa-user mr-2 text-info-original" />
                    {renderName(u)}
                </span>
            ))

        }

        return "-"

    }

    renderDate = (row) => {

        const start = moment.unix(row.date);
        const end = moment.unix(row.date_end);

        return (
            <span style={{display: 'inline-block', width: 130}}>
                {start.format('MMM, Do YYYY')} <br />
                {start.format('h:mm')} - {end.format('h:mm A')}
            </span>
        )

    }

    renderContactInfo = (row) => {

        return (
            <span style={{display: 'inline-block', width: 125}}>

                <ArchkTableExpansion

                    teaser={(
                        <span className="text-capitalize" style={{whiteSpace: 'pre-line'}}>
                            {renderName(row.contact)}

                            {row.contact ? (
                                <span className="font-weight-bold text-success">
                                    <br />{formatPhone(row.contact.phone)}
                                </span>
                            ) : null }
                        </span>
                    )}

                    content={(
                        <span>
                            
                            {row.contact ?
                                <>

                            <Link  
                                to={`/dashboard/contacts/view/${row.contact._id}`} 
                                className="font-weight-bold text-capitalize"
                            >
                                {renderName(row.contact)}
                            </Link>
                            <hr className="my-2" />

                                {row.contact.phone ? (
                                <div>
                                    <span className="cursor-pointer" onClick={() => toggleStartCallModal(true, row.contact)}>
                                        <i className="fas fa-copy mr-2 cursor-pointer"  />
                                        {formatPhone(row.contact.phone)}
                                    </span>
                                </div>
                                ): null}

                                {row.contact.email ? (
                                <div>
                                    <CopyToClipboard
                                        text={row.contact.email}
                                        onCopy={() => {
                                            const text = document.getElementById(`appointments-copy-email-text${row._id}`)
                                            text.innerHTML = 'Copied!'
                                            setTimeout(() => text.innerHTML = 'Copy Email', 1500)
                                        }}
                                    >
                                        <span className="cursor-pointer" id={`appointments-copy-email${row._id}`}>
                                            <i className="fas fa-copy mr-2 cursor-pointer" />
                                            {row.contact.email}
                                        </span>
                                    </CopyToClipboard>

                                    <UncontrolledTooltip
                                        delay={0}
                                        trigger="hover focus"
                                        placement="left"
                                        target={`appointments-copy-email${row._id}`}
                                    >
                                        <span id={`appointments-copy-email-text${row._id}`} >Copy Email</span>
                                    </UncontrolledTooltip>

                                </div>
                                ): null}

                                </>
                            : '-'}
                        </span>
                    )}
                />

            </span>
        )
    }

    columns = [
        {
            dataField: "date",
            text: "Date",
            formatter: (cell, row) => (
                <Link to={`/dashboard/appointments/view/${row._id}`}>
                    {this.renderDate(row)}
                </Link>
            )
        },
        {
            dataField: "contact",
            text: "Contact",
            formatter: (cell, row) => this.renderContactInfo(row),
            filterValue: (cell, row) => row.contact ? renderName(row.contact) + row.contact.phone : renderName(row.contact)
        },
        {
            dataField: "name",
            text: "Appointment Name",
            formatter: (cell, row) => <span className="text-capitalize">{row.name}</span>,
        },
        {
            dataField: "type",
            text: "Info",
            formatter: (cell, row) => (
                <span className="text-capitalize" style={{display: 'inline-block', width: 150}}>
                    <div>
                     <span className="float-left">
                     Type:
                        </span>
                        <span className="float-right">
                            <b>
                            {' '}{row.show === 'company' ? 'Company' : row.type}
                            </b>
                        </span>
                    </div>
                    <div>
                        <span className="float-left">
                            Times Rescheduled:
                        </span>
                        <span className="float-right">
                            <b className={row.times_rescheduled ? row.times_rescheduled >= 3 ? "text-danger" : 'text-warning' : "text-success"}>
                            {' '}{row.times_rescheduled}
                            </b>
                        </span>
                    </div>
                </span>
            )
        },

        {
            dataField: "assigned_to",
            text: "Assigned To",
            formatter: (cell, row) => this.renderAssignedTo(row),
            filterValue: (cell, row) => this.renderAssignedTo(row),
        },
        {
            dataField: "confirmed",
            text: "Confirmed",
            formatter: (cell, row) => (
                row.confirmed ?
                    <Badge style={{width: 60}} color="success" className="text-sm"><i className="fas fa-check mr-2" /> Yes</Badge> :
                    <Badge style={{width: 60}} color="warning" className="text-sm"><i className="fas fa-times mr-2" /> No</Badge>
            ),
            filterValue: (cell, row) => this.renderAssignedTo(row),
        },
        {
            dataField: "x",
            text: "Actions",
            headerStyle: {textAlign: 'right'},
            formatter: (cell, row) => {
                return (
                    privileges.canModerateAppointments() ? (
                        <div className="text-right">
                            <Link to={`/dashboard/appointments/view/${row._id}`}><i className="fas fa-edit text-success mr-2"></i></Link>
                            <i onClick={() => this.setState({appointmentToDelete: row})} className="fas fa-trash text-danger"></i>
                        </div>
                    ) : null
                )
            }
        },
    ]

    pagination = paginationFactory({
        page: 1,
        alwaysShowAllBtns: true,
        showTotal: false,
        withFirstAndLast: false,
        sizePerPageRenderer: () => false
    });

    render() {

        const appointments = this.state.appointments;
        const appointmentToDelete = this.state.appointmentToDelete;

        return (
            <>

            {appointmentToDelete && (
                <NotificationDelete
                    deletionURL={`/api/v1/core/appointments/delete/${appointmentToDelete._id}`}
                    confirmBtnText="Delete Appointment"
                    title={`Delete ${appointmentToDelete.name}`}
                    text="Deleting this appointment can not be undone. Proceed with caution."
                    onClose={() => this.setState({appointmentToDelete: null})}
                    onSuccess={this.onDelete}
                    onError={this.onDeleteError}
                    hideErr={true}
                />
            )}

            <Container fluid>

                <Helmet>
                    <title>{`Appointments`}</title>
                    <meta name="description" content="Appointments" />
                </Helmet>

                <DashHeaderOpen
                    icon="fas fa-home"
                    icon_link="/dashboard"
                    title={<span>Appointments</span>} breadcrumb_1="All"
                    actionComponent={(
                        <Link  to="/dashboard/appointments/view/new" className="btn btn-success">Create Appointment</Link>
                    )}
                />

                <Card className="card-color card-primary">

                    <CardHeader>
                        <CardTitle className="mb-0">Filter Appointments</CardTitle>
                    </CardHeader>

                    <CardBody>
                        <AppointmentsFilter
                            onFilter={appointments => this.setState({appointments})}
                        />
                    </CardBody>

                </Card>

                {appointments ? (

                    <Card className="card-color card-primary">

                        <CardHeader>
                            <CardTitle className="mb-0">All Appointments</CardTitle>
                        </CardHeader>

                        {appointments.length ? (

                            <ToolkitProvider
                                data={appointments}
                                keyField="_id"
                                columns={this.columns}
                                search
                            >
                                {props => (
                                    <>
                                        <div className="dataTables_filter px-4 pb pt-3 pb-2" >
                                            <label>
                                                Search:
                                                <SearchBar
                                                    className="form-control-sm"
                                                    placeholder="Contact Name"
                                                    {...props.searchProps}
                                                />
                                            </label>
                                        </div>

                                        <div className=" table-responsive table-vertical-align">
                                            <BootstrapTable
                                                pagination={this.pagination}
                                                {...props.baseProps}
                                                bootstrap4={true}
                                                bordered={false}
                                            />
                                        </div>
                                    </>
                                )}
                            </ToolkitProvider>

                        ) : (

                            <CardBody>
                                <p className="text-sm">
                                    No appointment were found with the selected feature.

                                    <Link to="/dashboard/appointments/view/new">
                                        <b className="text-warning"> Click here to create one.</b>
                                    </Link>
                                </p>
                            </CardBody>

                        )}
                    </Card>

                ) : (
                    <div className="py-4">
                        <Circle />
                    </div>
                )}

            </Container>

            </>
        );
    }
}

export default AppointmentAll
