/*
Documentation

This file renders a modal to update case details, name, chapter, etc

*/

import React, { Component } from 'react';
import { Modal, FormGroup } from 'reactstrap';
import { Redirect } from 'react-router-dom';

import _cases from '_functions/cases';

import { toggleStandardLoader, toggleAlertBS } from 'store/functions/system/system';

import ContactSearchInput from '../../../../_components/ContactSearchInput';

class CaseOverviewModalUpdate extends Component {

    state = {
        contact: {},
        redirect: null
    };

    toggleModal = () => {
        this.setState({contact: {} });
        this.props.toggleModal()
    }

    onSaveCase = async () => {

        const { contact } = this.state
        console.log(this.state.contact)

        if(!contact._id) return



        toggleStandardLoader(true);
        const updated = await _cases.updateContact(this.props.case_id, {
            contact: contact._id
        }, true)
        toggleStandardLoader(false);

        
        if(updated.data) {
            this.setState({redirect: `/dashboard/full_redirect?redirect=/dashboard/cases/view/${this.props.case_id}`})
            toggleAlertBS(false, 'Case Contact Changed Successfully')
        } else {
            toggleAlertBS(true, 'Please try again')

        }

    }
    render() {


        let { contact, redirect } = this.state;
        const { showModal,  } = this.props;

        if(redirect) return <Redirect to={redirect} />

        return (

            <Modal
                className="modal-dialog-centered"
                isOpen={showModal}
                toggle={this.toggleModal}
                size="md"
            >
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Edit Case Contact</h5>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={this.toggleModal}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>

                <div className="modal-body">
                    <FormGroup className="p-2">
                        {/* <h4>Associate Contact</h4> */}
                        <p className="text-sm"><i className="fas fa-exclamation-triangle text-danger mr-2 " /> Changing the contact below will associate this case to a new contact. Future case emails and texts will be routed to the email and phone on file of the new client</p>
                        <ContactSearchInput
                            value={contact ? contact : {}}
                            onSelect={(contact) => this.setState({contact})}
                        />
                    </FormGroup>
                </div>

                <div className="modal-footer">
                    <button onClick={this.onSaveCase}  className="btn btn-success">Save Case</button>
                </div>

            </Modal>

        )

    }

}

export default CaseOverviewModalUpdate;