import apiWorker from '../apiWorker'

const workflows = {

    update:     (workflow_id, data) => apiWorker('patch',  `/api/v1/core/workflows/update/${workflow_id}`, data),
    create:     (data)              => apiWorker('post',   `/api/v1/core/workflows/create`, data),
    copy:      (workflow_id)       => apiWorker('post',   `/api/v1/core/workflows/copy/${workflow_id}`),
    delete:     (workflow_id)       => apiWorker('delete', `/api/v1/core/workflows/delete/${workflow_id}` ),

    analyze:    (workflow_id)             => apiWorker('get',    `/api/v1/core/workflows/analyze/${workflow_id}`),
    find:       (query)             => apiWorker('get',    `/api/v1/core/workflows/find${query}`),
    findById:   (workflow_id)       => apiWorker('get',    `/api/v1/core/workflows/find/${workflow_id}`),


}

export default workflows;