/*
Documentation

renders the almost empty page body when a user is not on call

*/

import PropTypes from 'prop-types'
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as ANALYTIC_EVENTS from '_settings/analytic_events';

class NotOnCall extends Component {

    state = {
        trying: false
    }

    isDisabled = () => {

        const { canTakeCalls } = this.props;
        const { trying } = this.state;

        if(!canTakeCalls) return true;
        if(trying) return true;

        return false;

    }

    createCall = async (contact) => {

        this.setState({trying: true}, () => {
            setTimeout(() => this.setState({trying: false}), 1000)
        })

        this.props.setOnCall({ user: this.props.viewing_user._id, contact }, ANALYTIC_EVENTS.DIAL_QUEUE)
    }

    render() {

        const { noCallsOpen } = this.props

        return (

            <div className="not-on-call">
                 <div className="flex">
                    <div className="align-self-center text-center w-100">

                        <div className="content w-100">
                            <button disabled={this.isDisabled()} onClick={() => this.createCall()} className="btn btn-success px-4">
                                <i className="fas fa-phone mr-2" /> GET CALL
                            </button>
                            {noCallsOpen ? (
                                <div>{noCallsOpen}</div>
                            ) : null}
                        </div>

                    </div>
                </div>
            </div>

        )

    }

}


const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,
    };
};

NotOnCall.propTypes = {
    setOnCall       : PropTypes.func.isRequired,
    canTakeCalls    : PropTypes.bool.isRequired,
    noCallsOpen     : PropTypes.string,

}

export default connect(mapStateToProps, '')(NotOnCall);
