import apiWorker from '../apiWorker'


const nylas = {
    fetchCalendars: () => apiWorker('get',   `/v1/integrations/nylas/calendar`),
    accountStatus: () => apiWorker('get',   `/v1/integrations/nylas/account_status`),
    count: () => apiWorker('get',   `/v1/integrations/nylas/live/counts`),

}

export default nylas;