import AvatarImage from 'components/functional/images/AvatarImage';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import renderName from 'utils/renderName';
import renderNameInitial from 'utils/renderNameAndInitial';
import { formatPhone } from 'utils/text';
import FeedWrapper from './Wrapper';

import { getEntryDate } from './_utils';

const renderText = (props) => {

    const { entry, renderNameAndInitial } = props;
    const _case = props.case;


    const contact = _case && _case.contact ? _case.contact : {}

    const isOutgoing = _case && _case.contact ? entry.to === contact.phone : false;

    return (

        <React.Fragment>

            {entry.media_urls && entry.media_urls.length ? (
            <FeedWrapper className="media-container">
                <div className={isOutgoing ? 'text-left' : 'text-right'}>
                    <p className="text-sm mb-0">{moment.unix(entry.created_at).fromNow()}</p>
                    {entry.media_urls.map((url, i) => (
                        <a key={i} href={`${url}?download=true`} download target="blank" rel="noopener noreferrer" className={i === 0 ? 'mx-3 d-inline-block' : 'mx-3 mt-3 display d-inline-block'}>
                            <img  src={url} alt="sent media" className="w-100 rounded z-depth-2 cursor-pointer" style={{maxWidth: 300}} />
                        </a>
                    ))}

                </div>
            </FeedWrapper>
            ) : null}

            {entry.value !== 'Sent Media' ? (
            <FeedWrapper className={isOutgoing ? 'incoming-text' : 'outgoing-text'}>
                <h6 className="text-sm mb-0 font-weight-bold">

                    <b className="text-capitalize">
                        {isOutgoing ? (
                            <span>
                            {entry.user && <AvatarImage style={{position: 'relative', top: -2, width: 30, height: 30}} src={entry.user.avatar} className="mr-2" /> }
                            {entry.user ? renderNameAndInitial ? renderNameInitial(entry.user) : renderName(entry.user) : 'SYSTEM'}
                            </span>
                        ): (
                            <span>From: {contact.given_name || contact.family_name ? renderName(contact) : formatPhone(entry.to)}</span>
                        )}

                        <span className="float-right">
                            <i className="fas fa-mobile text-info-original mr-2" />
                            {getEntryDate(entry)}
                        </span>
                    </b>

                </h6>

                <span style={{wordBreak: 'break-word'}}  className="text-sm mb-0">{entry.value}</span>

            </FeedWrapper>
            ) : null}

        </React.Fragment>


        

    )

}


renderText.propTypes = {
    entry   : PropTypes.object.isRequired,
    case    : PropTypes.object.isRequired,
}

export default renderText
