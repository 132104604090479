import StandardFormGroup from 'components/functional/inputs/StandardFormGroup';
import { CardFooter } from "reactstrap";

const InfoRep = ({newCase, onSetState}) => (
    <CardFooter id="archk-details-ss">
        
        <h3 className="mb-4">Social Security Wages</h3>

        <StandardFormGroup
            obj={newCase}
            objName="newCase"
            onChange={(o, f, v) => onSetState(o, f, v)}
            type="number"
            field="social_security_wages_quarter_4_2020"
            title="Q4 - 2020"
        />
        <StandardFormGroup
            obj={newCase}
            objName="newCase"
            onChange={(o, f, v) => onSetState(o, f, v)}
            type="number"
            field="social_security_wages_quarter_1_2021"
            title="Q1 - 2021"
        />
        <StandardFormGroup
            obj={newCase}
            objName="newCase"
            onChange={(o, f, v) => onSetState(o, f, v)}
            type="number"
            field="social_security_wages_quarter_2_2021"
            title="Q2 - 2021"
        />
        <StandardFormGroup
            obj={newCase}
            objName="newCase"
            onChange={(o, f, v) => onSetState(o, f, v)}
            type="number"
            field="social_security_wages_quarter_3_2021"
            title="Q3 - 2021"
        />

    </CardFooter>
)

export default InfoRep