import store from 'store';
import * as privileges from '_settings/privileges'

const findPrivilege = (privilege_name, viewing_user = store.getState().auth.viewing_user) => {

    if(viewing_user) {

        const priv = viewing_user.privileges;
        if(priv) return priv.includes(privileges[privilege_name]) ? true : false

    }

    return false;

}

export const canViewAdminDashboard            = () => findPrivilege(privileges.can_view_admin_dashboard);

export const canUpdateContacts                = () => findPrivilege(privileges.can_update_contacts);
export const canDeleteContacts                = () => findPrivilege(privileges.can_delete_contacts);

export const canModerateAppointments          = () => findPrivilege(privileges.can_moderate_appointments);

export const canViewFinances                  = () => findPrivilege(privileges.can_view_finances);
export const canUpdateFinances                = () => findPrivilege(privileges.can_update_finances);
export const canAdjustTrust                   = () => findPrivilege(privileges.can_adjust_trust);
export const canViewAdminFinances             = () => findPrivilege(privileges.can_view_admin_finances);

export const canModerateDivisions             = () => findPrivilege(privileges.can_moderate_divisions);


export const canModerateCaseTemplates         = () => findPrivilege(privileges.can_moderate_case_templates);
export const canModerateCaseAlertTemplates    = () => findPrivilege(privileges.can_moderate_case_alert_templates);


export const canModerateWorkflows             = () => findPrivilege(privileges.can_moderate_workflows);


export const canModerateLocations             = () => findPrivilege(privileges.can_moderate_locations);

export const canViewCases                     = () => findPrivilege(privileges.can_view_cases);
export const canDeleteCases                   = () => findPrivilege(privileges.can_delete_cases);
export const canUpdateCases                   = () => findPrivilege(privileges.can_update_cases);


export const canModerateStaff                 = () => findPrivilege(privileges.can_moderate_staff);


export const canModerateTemplateDocs          = () => findPrivilege(privileges.can_moderate_template_docs);

export const canModerateUserStatuses          = () => findPrivilege(privileges.can_moderate_users_statuses);

export const developer                        = () => findPrivilege(privileges.developer);

export const canLoginAsContact                = () => findPrivilege(privileges.can_login_as_contact);

export const canModerateArchives              = () => findPrivilege(privileges.can_moderate_archives);

export const canModerateOffices               = () => findPrivilege(privileges.can_moderate_offices);

/// new

export const canScreenWatch                   = () => findPrivilege(privileges.can_screen_watch);
export const canModerateAutomations           = () => findPrivilege(privileges.can_moderate_automations);
export const canModerateAnalytics             = () => findPrivilege(privileges.can_moderate_analytics);
export const canModerateAppointmentTemplates  = () => findPrivilege(privileges.can_moderate_appointment_templates);
export const canModerateMissingDocs           = () => findPrivilege(privileges.can_moderate_missing_docs);
export const canModerateUnfinishedTasks       = () => findPrivilege(privileges.can_moderate_all_unfinished_tasks);
export const canModerateTags                  = () => findPrivilege(privileges.can_moderate_tags);
export const canModerateForms                 = () => findPrivilege(privileges.can_moderate_forms);
export const canModerateCompanySettings       = () => findPrivilege(privileges.can_moderate_company_settings);
export const canModerateUserTypes             = () => findPrivilege(privileges.can_moderate_user_types);

export const canUserAllEmailAddresses         = () => findPrivilege(privileges.can_view_all_email_addresses);

export const canViewAdminCommunication        = () => findPrivilege(privileges.can_view_admin_communication);
export const canViewAdminCallCenter           = () => findPrivilege(privileges.can_view_admin_call_center);
export const callsNewContacts                 = () => findPrivilege(privileges.calls_new_contacts);
export const callsBusiness                    = () => findPrivilege(privileges.calls_business);
export const callAppointments                 = () => findPrivilege(privileges.calls_appointments);
export const callsContacts                    = () => findPrivilege(privileges.calls_contacts);
export const canCallOnMobile                  = () => findPrivilege(privileges.can_call_on_mobile);

export const canJumpSteps                     = () => findPrivilege(privileges.can_jump_steps);
export const canSeeLeadSources                = () => findPrivilege(privileges.can_see_lead_sources);
export const canEditCaseDetails                = () => findPrivilege(privileges.can_edit_case_details);
export const canEditCasePayments                = () => findPrivilege(privileges.can_edit_case_payments);

export const vanViewAllCases                = () => findPrivilege(privileges.can_view_all_cases);

export const canViewTickets = (viewing_user = store.getState().auth.viewing_user) => {

    if(viewing_user) {

        const michael = '63b4961769bd1d7e6de1ba74'
        const shirley = '63bec5901d83ec158af4e855'
        const diana = '6334544d99cda1303a430f1d'
        const kevin = '63345497404415304760ff6b'
        const john = '6300daa78f12344b4d635d8d'

        if(
            viewing_user._id === michael ||
            viewing_user._id === shirley ||
            viewing_user._id === diana ||
            viewing_user._id === kevin ||
            viewing_user._id === john 
        ) {
            return true
        }

    }

    return false;

}
