/*
Documentation

this function should return at most 2 email address
the first is the company email address
the second is the users email address if it is in the list of authorized email address

*/

const getAuthorizedSendingEmails = (user_email, company) => {

    const canUse = company && company.microsoft_email_addresses ? [...company.microsoft_email_addresses] : [];
    const authorized_emails = company.authorized_emails ? [...company.authorized_emails] : [];

    if(authorized_emails.includes(user_email)) {
        canUse.push(user_email)
    }

    return canUse


}

export default getAuthorizedSendingEmails;