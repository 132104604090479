import React from "react";
import { connect } from 'react-redux';
import { Button, Card, CardHeader, CardTitle, CardFooter } from "reactstrap";
import { setViewingUser } from 'store/functions/auth/auth';
import { toggleAlertBS } from 'store/functions/system/system'
import { toggleStandardLoader } from 'store/functions/system/system'

import * as configuration from '_settings/configuration';

import _users from  '_functions/users';
import _consultations from  '_functions/consultations';

import moment from 'moment';

const downloadCSV = (data, options) => {
    if(!options) options = {}
    var hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + data;
    hiddenElement.target = '_blank';
    hiddenElement.download = `${options.name ? options.name : 'export'}.csv`;
    hiddenElement.click();
}


const ConfigurationCheckbox = (props) => {



    return (
        <>

        {props.title ? (
            <>
                {props.hideHR !== true ? <hr className="mt-3 mb-2"/> : null}
                <h5>{props.title}</h5>
            </>
        ) : null}

        <div className="custom-control custom-checkbox">
            <input
                className="custom-control-input"
                type="checkbox"
                id={props.configuration}
                value={props.user.configuration.includes(props.configuration)}
                checked={props.user.configuration.includes(props.configuration)}
                onChange={(e) => props.setConfiguration(e, props.configuration)}
            />
            <label className="custom-control-label" htmlFor={props.configuration}>
                {props.text}
            </label>
        </div>

        </>

    );

}

class AccountConfiguration extends React.Component {

    state = {
        user: Object.assign({}, this.props.viewing_user),
        canSave: true
    }

    removeValueFromArray = (privileges, field) => privileges.filter(val => val !== field)

    setConfiguration = (e, field) => {

        const checked = e.target.value

        const user = Object.assign({}, this.state.user);

        // const checked = val === 'on' ? true : false;
        let configuration = [...user.configuration]

        if(checked === 'false') {

            configuration.push(field)

        } else {

            configuration = this.removeValueFromArray(configuration, field);

        }

        user.configuration = configuration;

        this.setState({user})

    }
    onSave = async () => {

        toggleStandardLoader(true)

            const updated = await _users.update(this.props.viewing_user._id, {configuration: this.state.user.configuration});

        if(updated.success) {
            toggleAlertBS(false, `Your profile has been updated successfully.`)
            setViewingUser()
        }

        toggleStandardLoader(false)

    }

    onDownload = async () => {
        console.log('hasdfasdf')
        const start = parseInt(moment().startOf('day').format('X'))
        const end = parseInt(moment().endOf('day').format('X'))
        const data = await _consultations.today(`?start=${start}&end=${end}`);
    
        if(data) downloadCSV(data.data, 'consults');
        console.log(data)
    }

    render() {

        const { user } = this.state

        return (

            <Card>

                <CardHeader>
                    <CardTitle onClick={this.onDownload} className="mb-0">Configuration</CardTitle>
                </CardHeader>

                <CardHeader>

                    <ConfigurationCheckbox
                        hideHR={true}
                        title="Dates"
                        configuration={configuration.feed_show_full_date}
                        text="Show time on feed entries as full date."
                        user={user}
                        setConfiguration={(e, configuration) => this.setConfiguration(e, configuration)}
                    />

                </CardHeader>

                <CardFooter className="text-right">
                    <Button
                        color="success"
                        onClick={this.onSave}
                        disabled={this.state.canSave ? false : true}
                    >
                        Save Profile
                    </Button>
                </CardFooter>

            </Card>

        );
    }
}

const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,
    };
};

export default connect(mapStateToProps, '')(AccountConfiguration);
