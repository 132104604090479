/*
Documentation

This file renders the parties card on the case home page

*/

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Card, CardHeader, CardTitle,  Col, Row } from "reactstrap";

import ConfirmationModal from 'components/functional/modals/Confirmation.js'

import { canUpdateCases } from '_functions/users/privileges'
import _case_parties from '_functions/case_parties';

import renderName from 'utils/renderName';
import { formatPhone } from 'utils/text';

import { io } from 'sockets';
import * as socketEvents from 'sockets/events';
import { toggleStartCallModal } from 'store/functions/call_center/call_center'
import { toggleAlertBS } from 'store/functions/system/system';

import ModalUpdateParties from './ModalUpdateParties';
import ModalUpdateContact from '../ModalUpdateContact';

import SendEmail from 'components/system/SendEmail';
import SendEmailModalWrapper from 'components/system/SendEmail/ModalWrapper';



class CaseParties extends Component {

    state = {
        contact: {},
        canSave: true,
        action: null,
        showModalEmail: false,
        showModalParties: false,
        showModalDeleteConfirmation: false,
        showModalUpdateContact: false,
        partyToContact: {}
    };

    toggleModalDeleteConfirmation = () => this.setState({showModalDeleteConfirmation: !this.state.showModalDeleteConfirmation})
    toggleModalUpdateContact = () => this.setState({showModalUpdateContact: !this.state.showModalUpdateContact})

    getAddress = (obj) => {
        if(obj.address_line_1 && obj.city && obj.state) {
            return (
                <>
                    <p className="text-muted text-sm mb--3">{obj.address_line_1} {obj.address_line_2}</p>
                    <p className="text-muted text-sm mb--2">{obj.city}, {obj.state} {obj.postal_code}</p>
                </>
            )
        } else if(obj.address_line_1) {
            return (
                <>
                    <p className="text-muted text-sm mb-1">{obj.address_line_1} {obj.address_line_2}</p>
                </>
            )
        }
    }

    getPhone = (obj) => {
        if(obj.phone) return <p onClick={() => toggleStartCallModal(true, obj)} className="text-muted cursor-pointer text-sm mb-0">{formatPhone(obj.phone)}</p>
    }

    getEmail = (obj) => {
        if(obj.phone) {
            if(obj.email) return <p className="text-muted text-sm mb--3"> <i className="fas fa-envelope text-info-original cursor-pointer mr-2" onClick={() => this.toggleModalEmail(obj)} />
{obj.email}</p>
        }
        if(obj.email) return <p className="text-muted text-sm mb-0"> <i className="fas fa-envelope text-info-original cursor-pointer mr-2" onClick={() => this.toggleModalEmail(obj)} />
{obj.email}</p>
    }

    toggleModalParties = () => this.setState({showModalParties: !this.state.showModalParties})
    
    toggleModalEmail = (obj) => {
        this.setState({showModalEmail: !this.state.showModalEmail, partyToContact: obj && obj._id ? obj : {}})
    }

    onEmailSendError = () => toggleAlertBS(true, 'Your email was not sent, please make sure that your account is synced by going to your account settings. If this issue continues try re-authenticating your email account.')


    onDeleteParty = (contact_id) => {

        this.setState({contact_id})
        this.toggleModalDeleteConfirmation()

    }

    onConfirmDeleteParty = async () => {

        await _case_parties.delete(this.state.contact_id)
        this.fetchParties()

    }

    fetchParties = async () => {
        const parties = await _case_parties.find(`?filter=case__${this.props.case._id}`)

        //if success and we already have state data due a socket request combine the data here, if not just add the data we got
        if(parties.success) {

            let data = parties.data

            // if(this.state.case_parties) { data = this.state.case_parties.concat(data) }
            this.setState({case_parties: data})

        }
    }

    listenForCasePartiesAdded = (data) => {
        if(data.data.case === this.props.case._id) {

            const case_parties = this.state.case_parties ? [...this.state.case_parties] : []
            case_parties.push(data.data)

            this.setState({case_parties})

        }
    }

    listenForCasePartiesDeleted = (data) => {

        let case_parties = this.state.case_parties ? [...this.state.case_parties] : []
        case_parties = case_parties.filter(c => c._id !== data.data._id)

        this.setState({case_parties})

    }


    componentWillUnmount = () => {

        // io.off(socketEvents.case_parties_added,           this.listenForCasePartiesAdded)
        // io.off(socketEvents.case_parties_deleted,         this.listenForCasePartiesDeleted)

    }

    componentDidMount = async () => {

        // io.on(socketEvents.case_parties_added,           this.listenForCasePartiesAdded)
        // io.on(socketEvents.case_parties_deleted,         this.listenForCasePartiesDeleted)

        this.fetchParties()

    }

    render() {

        const _case = this.props.case;
        const { case_parties, showModalParties, showModalDeleteConfirmation, showModalEmail, partyToContact, showModalUpdateContact } = this.state
        const { navPills, toggleModal } = this.props;

        if(navPills !== 1 ) return null

        const canUpdate = canUpdateCases();

        return (

            <>

            <Card className="card-color card-warning">

                <CardHeader>
                    <Row>

                        <Col md={6} className="align-self-center">
                            <CardTitle className="mb-0">Parties</CardTitle>
                        </Col>

                        <Col md={6} className="text-right align-self-center">
                            <button
                                disabled={!canUpdate}
                                onClick={this.toggleModalParties}
                                className="btn btn-success btn-s text-center"
                            >
                                Add Party
                            </button>
                        </Col>

                    </Row>
                </CardHeader>

                <div className="table-responsive parties-table">
                    <table className="table">

                        <tbody>

                            <tr>
                                <td className="font-weight-bold">
                                    <p className="text-dark text-sm font-weight-bold mb--1">
                                        <i
                                            className="fas fa-edit text-success mr-1 cursor-pointer"
                                            style={{cursor: 'pointer',opacity: canUpdate ? 1 : .5}}
                                            onClick={canUpdate ? this.toggleModalUpdateContact : null}
                                        />
                                        Client: <b className="text-capitalize">{renderName(_case.contact)}</b>
                                    </p>

                                    {this.getAddress(_case.contact)}
                                    {this.getEmail(_case.contact)}
                                    {this.getPhone(_case.contact)}

                                </td>
                            </tr>

                            {case_parties && case_parties.length ? case_parties.map(case_party => (
                                <tr key={case_party._id}>
                                    <td className="font-weight-bold">
                                        <p className="text-dark text-sm font-weight-bold mb--1">
                                            <i
                                                className="fas fa-trash text-danger mr-2"
                                                style={{cursor: 'pointer',opacity: canUpdate ? 1 : .5}}
                                                onClick={canUpdate ? () => this.onDeleteParty(case_party._id) : null}
                                            />
                                            {case_party.title}: <b className="text-capitalize">{renderName(case_party.contact)}</b>
                                        </p>

                                        {this.getAddress(case_party.contact)}
                                        {this.getEmail(case_party.contact)}
                                        {this.getPhone(case_party.contact)}

                                    </td>
                                </tr>
                            )) : null}

                        </tbody>
                    </table>
                </div>

            </Card>

            <ModalUpdateParties
                case={_case}
                showModal={showModalParties}
                toggleModal={this.toggleModalParties}
                fetchParties={this.fetchParties}
            />

            <ConfirmationModal
                  showModal={showModalDeleteConfirmation}
                  toggleModal={this.toggleModalDeleteConfirmation}
                  title="Remove Case Party"
                  body={<span>Are you sure you wish to remove this party from the case?</span>}
                  onConfirmation={this.onConfirmDeleteParty}
            />

            <ModalUpdateContact 
                case_id={_case._id}
                showModal={showModalUpdateContact}
                toggleModal={this.toggleModalUpdateContact}
            />

            <SendEmailModalWrapper
                showModal={showModalEmail}
                toggleModal={this.toggleModalEmail}
            >
                <SendEmail
                    onSendingStart={this.toggleModalEmail}
                    onError={this.onEmailSendError}
                    subject="Biz Head Law"
                    to={partyToContact && partyToContact.email ? [partyToContact.email]   : []}
                    contact={_case.contact ? _case.contact._id : undefined}
                    useStrictEmail={true}
                />
            </SendEmailModalWrapper>

            </>

        )

    }

}

CaseParties.propTypes = {
    case        : PropTypes.object.isRequired,
    navPills    : PropTypes.number.isRequired,
    toggleModal : PropTypes.func.isRequired,
}

export default CaseParties
