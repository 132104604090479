import StandardFormGroup from 'components/functional/inputs/StandardFormGroup';
import { CardFooter, Col, Row } from "reactstrap";
import { formatMoney } from 'utils/currency';

const InfoDetails = ({newCase, onSetState}) => (
    <CardFooter id="archk-details-lead-info">
        <h3 className="mb-4">PPP (LEAD INFO - NOTE REQUIRED TO SUBMIT TO PATRIOT)</h3>

        <Row>
            <Col lg={6}>
                <StandardFormGroup
                    obj={newCase}
                    objName="newCase"
                    onChange={onSetState}
                    type="date"
                    field="ppp_forgiveness_date"
                    title="PPP Forgiveness Date"
                />
            </Col>
            <Col lg={6}>
                <StandardFormGroup
                    obj={newCase}
                    objName="newCase"
                    onChange={onSetState}
                    type="date"
                    field="ppp_approval_date"
                    title="PPP Approval Date"
                />
            </Col>
        </Row>
        <Row>
            <Col lg={6}>
                <StandardFormGroup
                    obj={newCase}
                    objName="newCase"
                    onChange={onSetState}
                    type="number"
                    field="ppp_forgiveness_amount"
                    title={`PPP Forgiveness Amount ${newCase.ppp_forgiveness_amount ? formatMoney(newCase.ppp_forgiveness_amount) : ''}`}
                />
            </Col>
            <Col lg={6}>
                <StandardFormGroup
                    obj={newCase}
                    objName="newCase"
                    onChange={onSetState}
                    type="number"
                    field="ppp_approval_amount"
                    title={`PPP Approval Amount ${newCase.ppp_approval_amount ? formatMoney(newCase.ppp_approval_amount) : ''}`}
                />
            </Col>
        </Row>

        <StandardFormGroup
            obj={newCase}
            objName="newCase"
            onChange={onSetState}
            type="text"
            field="ppp_loan_number"
            title="PPP Loan Number"
            formGroupClasses="w-50"
        />
        <StandardFormGroup
            obj={newCase}
            objName="newCase"
            onChange={onSetState}
            type="text"
            field="ppp_lender"
            title="PPP Lender"
            formGroupClasses="w-50"
        />

        <StandardFormGroup
            obj={newCase}
            objName="newCase"
            onChange={onSetState}
            type="number"
            field="jobs_reported"
            title="Jobs Reported"
            formGroupClasses="w-50"
        />
    

        <hr />
        <h3 className="mb-4">Company Contact (LEAD INFO - NOTE REQUIRED TO SUBMIT TO PATRIOT)</h3>


        <Row>
            <Col lg={6}>
                <StandardFormGroup
                    obj={newCase}
                    objName="newCase"
                    onChange={onSetState}
                    type="text"
                    field="company_linkedin_url"
                    title="Linkedin URL"
                />
                <StandardFormGroup
                    obj={newCase}
                    objName="newCase"
                    onChange={onSetState}
                    type="text"
                    field="company_facebook_url"
                    title="Facebook URL"
                />
            </Col>

            <Col lg={6}>
                <StandardFormGroup
                    obj={newCase}
                    objName="newCase"
                    onChange={onSetState}
                    type="text"
                    field="company_twitter_url"
                    title="Twitter URL"
                />
                <StandardFormGroup
                    obj={newCase}
                    objName="newCase"
                    onChange={onSetState}
                    type="text"
                    field="company_website"
                    title="Company Website"
                />
            </Col>
        </Row>
       
    </CardFooter>
)

export default InfoDetails