import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import _case_workflow_tasks from '_functions/case_workflow_tasks'
import StandardFormGroup from 'components/functional/inputs/StandardFormGroup';

import { toggleStandardLoader } from 'store/functions/system/system';

import * as ANALYTIC_EVENTS from '_settings/analytic_events';
import _analytics from '_functions/analytics';

const required_form_fields = [
    'value',
]

class FormTypesSetFeePercent extends Component {

    state = {
        editing_task: {},
    };

    onFinishTask = async () => {

        let editing_task = Object.assign({}, this.state.editing_task);
        let errors = 0;

        required_form_fields.forEach((field) => {
            if(editing_task[field] && parseFloat(editing_task[field]) >= 0 && parseFloat(editing_task[field]) <= 1) {
                editing_task[field + "State"] = 'valid';
            } else {
                editing_task[field + "State"] = 'invalid';
                errors++
            }
        })

        this.setState({ editing_task });

        // do nothing iff we dont have a selected user
        if(!editing_task.value) return

        if(!errors) {

            toggleStandardLoader(true)

            await _case_workflow_tasks.finish(this.state.editing_task._id,  {
                value: parseFloat(editing_task.value),
                minutes: 0,
                user: this.props.viewing_user._id,
                case_workflow: this.props.case_workflow_id,
            })

            toggleStandardLoader(false)

            this.props.toggleModal();
            this.props.forceRefresh();
            _analytics.events.create({event: ANALYTIC_EVENTS.TASK_FINISHED});

        }

    }

    onAssignRole = (user) => {
        this.setState({editing_task: {...Object.assign({}, this.state.editing_task), value: user}})
    }

    componentDidMount = () => {

        const task = Object.assign({}, {
            ...this.props.editing_task,
            minutes: this.props.editing_task.default_billable_minutes
        })

        this.setState({editing_task: task})

    }

    componentWillReceiveProps = (nextProps) => {

        const currentTask = this.state.editing_task;
        const nextTask = nextProps.editing_task;
        if(currentTask && nextTask && currentTask._id !== nextTask._id) {

            const task = Object.assign({}, {
                ...nextProps.editing_task,
                minutes: nextProps.editing_task.default_billable_minutes
            })

            this.setState({editing_task: task})

        }

    }

    render() {

        const editing_task = this.state.editing_task || {};

        if(editing_task.type !== 'set fee percent') { return <></> }

        return (

            <>
                <p className="mb-0 text-sm font-weight-bold">Set Fee Percent</p>
                <hr className="my-3" />

                <StandardFormGroup
                    obj={editing_task}
                    objName='editing_task'
                    onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                    type="number"
                    field="value"
                    title="Enter Fee Percent. Ex: 25% - .25"
                />

                {editing_task.value ? (

                    <div className="text-right">

                        <hr className="my-4" />

                        <button
                            onClick={() => this.onFinishTask()}
                            className="btn btn-success"
                        >
                            Finish Task
                        </button>
                    </div>
                ) : null }

            </>

        )

    }

}

FormTypesSetFeePercent.propTypes = {
    editing_task        : PropTypes.object.isRequired,
    toggleModal         : PropTypes.func.isRequired,
    case_workflow_id    : PropTypes.string.isRequired,
}

const mapStateToProps = state => {

    return {
    	viewing_user: state.auth.viewing_user,
    };
};

export default connect(mapStateToProps, '')(FormTypesSetFeePercent);
