import { CardFooter, Col, Row, FormGroup, Input } from "reactstrap";

const InfoRep = ({newCase, onSetState}) => (
    <CardFooter id="archk-details-impact">

        <h3 className="mb-2">Business Impact</h3>
        

        <Row>
            <Col md={6}>

                <FormGroup>
                    <div className="custom-control custom-checkbox">
                        <input
                            className="custom-control-input"
                            id={`archk_checklist_government_shutdown`}
                            type="checkbox"
                            checked={newCase.government_shutdown === true}
                            onChange={(e) => onSetState({}, 'government_shutdown', !newCase.government_shutdown)}
                        />
                        
                        <label className="custom-control-label" htmlFor={`archk_checklist_government_shutdown`}>
                            Partial or full shutdown per government mandates
                        </label>
                    </div>
                </FormGroup>
                
                <FormGroup>
                    <div className="custom-control custom-checkbox">
                        <input
                            className="custom-control-input"
                            id={`archk_checklist_restricted_people_in_building`}
                            type="checkbox"
                            checked={newCase.restricted_people_in_building === true}
                            onChange={(e) => onSetState({}, 'restricted_people_in_building', !newCase.restricted_people_in_building)}
                        />
                        <label className="custom-control-label" htmlFor={`archk_checklist_restricted_people_in_building`}>
                            Restrictions on number of people in room or building
                        </label>
                    </div>
                </FormGroup>

                <FormGroup>
                    <div className="custom-control custom-checkbox">
                        <input
                            className="custom-control-input"
                            id={`archk_checklist_inability_to_attend_events`}
                            type="checkbox"
                            checked={newCase.inability_to_attend_events === true}
                            onChange={(e) => onSetState({}, 'inability_to_attend_events', !newCase.inability_to_attend_events)}
                        />
                        <label className="custom-control-label" htmlFor={`archk_checklist_inability_to_attend_events`}>
                            Inability to attend normal networking events and functions such as professional conferences
                        </label>
                    </div>
                </FormGroup>
            
                <FormGroup>
                    <div className="custom-control custom-checkbox">
                        <input
                            className="custom-control-input"
                            id={`archk_checklist_disruption_to_sales`}
                            type="checkbox"
                            checked={newCase.disruption_to_sales === true}
                            onChange={(e) => onSetState({}, 'disruption_to_sales', !newCase.disruption_to_sales)}
                        />
                        <label className="custom-control-label" htmlFor={`archk_checklist_disruption_to_sales`}>
                            Disruption to sales force's ability to function normally
                        </label>
                    </div>
                </FormGroup>
                
                <FormGroup>
                    <div className="custom-control custom-checkbox">
                        <input
                            className="custom-control-input"
                            id={`archk_checklist_supply_chain_interruptions`}
                            type="checkbox"
                            checked={newCase.supply_chain_interruptions === true}
                            onChange={(e) => onSetState({}, 'supply_chain_interruptions', !newCase.supply_chain_interruptions)}
                        />
                        <label className="custom-control-label" htmlFor={`archk_checklist_supply_chain_interruptions`}>
                            Supply Chain Interruptions
                        </label>
                    </div>
                </FormGroup>
            
                <FormGroup>
                    <div className="custom-control custom-checkbox">
                        <input
                            className="custom-control-input"
                            id={`archk_checklist_inability_to_access_equipment`}
                            type="checkbox"
                            checked={newCase.inability_to_access_equipment === true}
                            onChange={(e) => onSetState({}, 'inability_to_access_equipment', !newCase.inability_to_access_equipment)}
                        />
                        <label className="custom-control-label" htmlFor={`archk_checklist_inability_to_access_equipment`}>
                            Inability to access equipment
                        </label>
                    </div>
                </FormGroup>

                <FormGroup>
                    <div className="custom-control custom-checkbox">
                        <input
                            className="custom-control-input"
                            id={`archk_checklist_limited_capacity_to_operate`}
                            type="checkbox"
                            checked={newCase.limited_capacity_to_operate === true}
                            onChange={(e) => onSetState({}, 'limited_capacity_to_operate', !newCase.limited_capacity_to_operate)}
                        />
                        <label className="custom-control-label" htmlFor={`archk_checklist_limited_capacity_to_operate`}>
                            Limited Capacity To Operate
                        </label>
                    </div>
                </FormGroup>

                <FormGroup>
                    <div className="custom-control custom-checkbox">
                        <input
                            className="custom-control-input"
                            id={`archk_checklist_inability_to_work_for_vendors`}
                            type="checkbox"
                            checked={newCase.inability_to_work_for_vendors === true}
                            onChange={(e) => onSetState({}, 'inability_to_work_for_vendors', !newCase.inability_to_work_for_vendors)}
                        />
                        <label className="custom-control-label" htmlFor={`archk_checklist_inability_to_work_for_vendors`}>
                            Inability to work with vendors
                        </label>
                    </div>
                </FormGroup>

                <FormGroup>
                    <div className="custom-control custom-checkbox">
                        <input
                            className="custom-control-input"
                            id={`archk_checklist_reduction_in_customer_offerings`}
                            type="checkbox"
                            checked={newCase.reduction_in_customer_offerings === true}
                            onChange={(e) => onSetState({}, 'reduction_in_customer_offerings', !newCase.reduction_in_customer_offerings)}
                        />
                        <label className="custom-control-label" htmlFor={`archk_checklist_reduction_in_customer_offerings`}>
                            Reduction in services or goods offered to customers
                        </label>
                    </div>
                </FormGroup>

            </Col>
            <Col md={6}>
                
                <FormGroup>
                    <div className="custom-control custom-checkbox">
                        <input
                            className="custom-control-input"
                            id={`archk_checklist_lowered_hours_of_operation`}
                            type="checkbox"
                            checked={newCase.lowered_hours_of_operation === true}
                            onChange={(e) => onSetState({}, 'lowered_hours_of_operation', !newCase.lowered_hours_of_operation)}
                        />
                        <label className="custom-control-label" htmlFor={`archk_checklist_lowered_hours_of_operation`}>
                            Cut down in hours of operation
                        </label>
                    </div>
                </FormGroup>
            
                <FormGroup>
                    <div className="custom-control custom-checkbox">
                        <input
                            className="custom-control-input"
                            id={`archk_checklist_shifting_hours_to_sanitize`}
                            type="checkbox"
                            checked={newCase.shifting_hours_to_sanitize === true}
                            onChange={(e) => onSetState({}, 'shifting_hours_to_sanitize', !newCase.shifting_hours_to_sanitize)}
                        />
                        <label className="custom-control-label" htmlFor={`archk_checklist_shifting_hours_to_sanitize`}>
                            Shifting hours to increase sanitation of facility
                        </label>
                    </div>
                </FormGroup>

                <FormGroup>
                    <div className="custom-control custom-checkbox">
                        <input
                            className="custom-control-input"
                            id={`archk_checklist_canceled_projects`}
                            type="checkbox"
                            checked={newCase.canceled_projects === true}
                            onChange={(e) => onSetState({}, 'canceled_projects', !newCase.canceled_projects)}
                        />
                        <label className="custom-control-label" htmlFor={`archk_checklist_canceled_projects`}>
                            Projects were canceled or delayed to COVID-related disruptions
                        </label>
                    </div>
                </FormGroup>
            
                <FormGroup>
                    <div className="custom-control custom-checkbox">
                        <input
                            className="custom-control-input"
                            id={`archk_checklist_delayed_by_supply_chain`}
                            type="checkbox"
                            checked={newCase.delayed_by_supply_chain === true}
                            onChange={(e) => onSetState({}, 'delayed_by_supply_chain', !newCase.delayed_by_supply_chain)}
                        />
                        <label className="custom-control-label" htmlFor={`archk_checklist_delayed_by_supply_chain`}>
                            Delayed production timelines caused by supply chain interruptions.
                        </label>
                    </div>
                </FormGroup>

                <FormGroup>
                    <label className="form-control-label">Other (Please Explain)</label>
                    <Input 
                        style={{minHeight: 120}}
                        type="textarea"
                        value={newCase.other_impact}
                        onChange={(e) => onSetState({}, 'other_impact', e.target.value)}
                    />
                </FormGroup>

            </Col>
            </Row>

       
        
    </CardFooter>
)

export default InfoRep