


import React from "react";
import { CardBody, CardHeader, Col, Row, CardTitle } from "reactstrap";
import PropTypes from 'prop-types'
import Circle from 'components/markup/loading/Circle'
import moment from 'moment'
import { connect } from 'react-redux'
import renderName from "utils/renderName";
import A from 'components/markup/links/A'
import keys from 'keys';

class ContactViewAddress extends React.Component {

    state = {
        contact: {},
        appointments: [],
        canSave: true,
    }

    render() {

        const { consultations, users, viewing_user } = this.props

        return (
            <>

            <CardHeader className="bg-secondary">
                <CardTitle className="mb-0">Consultations</CardTitle>
            </CardHeader>

            <CardBody>

                {consultations && consultations.length ? (
                    consultations.map((c, i) => (
                        <>
                        <Row>
                            {console.log(c)}
                            <Col md={5}>
                                <h5>{moment.unix(c.start).format('MMM Do, h:mm A')} - <span className="font-weight-normal text-capitalize"> {renderName(users.find(u => u._id === c.user))}</span></h5>
                            </Col>

                            <Col md={7}>
                                <h5 className="font-weight-normal">
                                    {c.outcome ? c.outcome : (
                                         <span>
                                            No Outcome Selected:{' '}
                                            <A href={`${keys.BOOK_URL}?reschedule=${consultations[0]._id}&u=${viewing_user._id}&l=${consultations[0].lead_source ? consultations[0].lead_source : ''}`}>
                                                <i className="fas fa-calendar text-info-original " />
                                            </A>
                                            <A href={`${keys.BOOK_URL}/outcome/${consultations[0]._id}`}>
                                                <i className="fas fa-edit ml-3 text-success " />
                                            </A>
                                        </span>
                                    )}
                                </h5>
                            </Col>
                        </Row>
                        {i !== consultations.length ? <hr className="my-2" /> : ''}
                        </>
                    ))
                ) : (
                    <Row>
                        <Col xs={4}>
                            <h5>Consultations</h5>
                        </Col>
                        <Col xs={8}>
                            <h5 className="font-weight-normal">
                                {consultations ? "No consultations have been found for this contact" : <Circle />}
                            </h5>
                        </Col>
                    </Row>
                )}
              
            </CardBody>

            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,
        users: state.users.users
    };
};


ContactViewAddress.propTypes = {
    contact: PropTypes.object.isRequired
}

export default connect(mapStateToProps, '')(ContactViewAddress);

